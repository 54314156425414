<template>
	<div class='dark:border-dark-5'>
		<div class='font-medium text-2xl'>{{ $t('account.kyc.kyc_kyc_docs') }}</div>
    <div class='font-medium mt-2'>{{ $t('account.kyc.kyc_please_upload') }}</div>
		<div class='grid grid-cols-12 gap-4 gap-y-5 mt-5'>
			<div class='intro-y col-span-12 xl:overflow-x-visible overflow-x-scroll'>
        <table class='table table--lg'>
          <thead>
            <tr class='bg-theme-2'>
              <th class='dark:border-dark-5 whitespace-nowrap w-1/5'> {{ $t('account.kyc.kyc_category') }} </th>
              <th class='dark:border-dark-5 whitespace-nowrap w-1/5'> {{ $t('account.kyc.kyc_doc_name') }} </th>
              <th class='dark:border-dark-5 whitespace-nowrap w-3/5'> {{ $t('account.kyc.kyc_status') }} </th>
              <th class='dark:border-dark-5 whitespace-nowrap text-center w-1/5'> {{ $t('account.kyc.kyc_act_log') }} </th>
              <th class='dark:border-dark-5 whitespace-nowrap text-center w-1/12'> {{ $t('account.kyc.kyc_actions') }} </th>
            </tr>
          </thead>
          <tbody>
            <tr class='even:bg-gray-50' v-for='(item, index) in docList' :key='index'>
              <td class='dark:border-dark-5'>{{item.category}}</td>
              <td class='dark:border-dark-5 text-blue-500'>{{item.fileName}}</td>
              <td class='dark:border-dark-5'>
                <div class='alert show flex items-center justify-center h-5 p-3 text-sm' :class='item.class' role='alert' style="width: max-content;">
                <!-- <div class='alert show flex items-center h-5 p-3 text-sm justify-center text-blue-700 bg-blue-200' role='alert'> -->
                  {{item.status}}
                </div>
              </td>
              <td class='dark:border-dark-5 text-center'><a href="javascript:;" class="text-theme-1" @click='openActivityLogModal(index)'><u>{{ $t('dashboard.dashboard_view') }}</u></a></td>
              <td class='dark:border-dark-5'>
                <div class='grid grid-cols-2'>
                  <div class='flex justify-center'>
                    <UploadIcon class='w-4 h-4' @click='openFileUploadModal(index)'/>
                  </div>
                  <div class='flex justify-center' v-if='item.corporateInfoHeaderId'>
                    <MinusCircleIcon class='w-4 h-4 text-theme-6' @click='removeDoc(index)'/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
			</div>
			<div class='intro-y col-span-12 flex items-center justify-center sm:justify-start mt-5'>
				<button class='btn bg-theme-1 w-24 ml-2 text-white'>{{ $t('account.kyc.kyc_save') }}</button>
				<button class='btn bg-purple-800 w-42 ml-2 text-white' @click='submit'>{{ $t('account.kyc.kyc_submit') }}</button>
			</div>
		</div>
    <!-- BEGIN: File Upload Modal Content -->
    <div id='kyc-doc-file-upload' class='modal' tabindex='-1' aria-hidden='true'>
     <div class='modal-dialog modal-lg'>
        <div class='modal-content'>
          <!-- BEGIN: Modal Header -->
          <div class='modal-header'>
            <h2 class='font-medium text-base mr-auto'> {{ $t('account.kyc.kyc_file') }} </h2>
          </div> <!-- END: Modal Header -->
          <div class='m-8'>
            <div>
              <div v-bind='getRootProps()' class='flex justify-center border-theme-1 border-dashed border-2 rounded-lg cursor-pointer'>
                <div class='text-center py-5 text-theme-1'>
                  <template v-if='!files'>
                    <input v-bind='getInputProps()' >
                    <UploadCloudIcon class='w-24 h-20 text-theme-1' />
                    <div class='text-lg font-medium text-gray-600'>
                      {{ $t('account.kyc.kyc_drag_drop') }}<br>{{ $t('account.kyc.kyc_drag_drop1') }}
                    </div>
                    <div class=''>{{ $t('account.kyc.kyc_browse') }}</div>
                  </template>
                  <template v-else>
                    <div class='relative'>
                      <div class='absolute top-0 right-1'>
                        <XCircleIcon @click='removeFile' class='w-6 h-6' />
                      </div>
                      <FileTextIcon class='w-24 h-24'/>
                    </div>
                    {{files[0].name}}
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class='modal-footer text-right'>
            <button type='button' @click='save' class='btn bg-theme-1 w-full text-white' > Save </button>
          </div> <!-- END: Modal Footer   -->
        </div>
      </div>
    </div>
     <!--END: File Upload Modal Content -->
    <!-- BEGIN: Activity Log Modal Content -->
    <div id='kyc-activity-log' class='modal' tabindex='-1' aria-hidden='true'>
     <div class='modal-dialog modal-xl'>
        <div class='modal-content'>
          <!-- BEGIN: Modal Header -->
          <div class='modal-header'>
            <h2 class='font-medium text-base mr-auto'> {{ $t('account.kyc.kyc_act_log1') }} </h2>
          </div> <!-- END: Modal Header -->
          <div class='m-8'>
            <div>
              <table class='table table--lg'>
                <thead>
                  <tr class='bg-theme-2'>
                    <th class='dark:border-dark-5 whitespace-nowrap w-1/5'> {{ $t('account.kyc.kyc_updated_by') }} </th>
                    <th class='dark:border-dark-5 whitespace-nowrap w-2/5'> {{ $t('account.kyc.kyc_time_stamp') }} </th>
                    <th class='dark:border-dark-5 whitespace-nowrap w-3/5'> {{ $t('account.kyc.kyc_details') }} </th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
          </div>
          <div class='modal-footer text-right'>
          </div> <!-- END: Modal Footer   -->
        </div>
      </div>
    </div>
     <!--END: Activity Log Modal Content -->
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref, onMounted, reactive } from 'vue'
import _ from 'lodash'
import moment from 'moment'
import { useDropzone } from 'vue3-dropzone'
import companyService from '@/services/companyService'
import configService from '@/services/configService'
import fileService from '@/services/fileService'
import i18n from '@/plugins/i18n';
import $ from "jquery";

export default {
	setup() {
		const router = useRouter()
    const store = useStore()
    const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT
    const setDocIndex = ref()
    const setLogIndex = ref()
    const docList = ref([])
    const logList = ref([])
    const files = ref()

    const onDrop = (acceptFiles, rejectReasons) => {
      files.value = acceptFiles
    }

    const options = reactive({
      multiple: true,
      onDrop,
      accept: '.jpg, .csv, .txt, .pdf, .docx, .xlsx',
    })

    const { getRootProps, getInputProps, ...rest } = useDropzone(options)

    onMounted(async () => {
      const companyProfileSystemConfig = await configService.getCompanyProfileSystemConfig()
      companyProfileSystemConfig.kycDocumentCategory.forEach(item => {
        docList.value.push({
            corporateInfoHeaderId: '',
            category: item,
            status: i18n.global.t('account.kyc.kyc_doc_waiting'),
            class: 'alert-dark-soft',
          })
      })
      const response = await companyService.getCompanyCorporateinfo(store.state.account.company_uuid)
      for(let index in response.data){
        let defaultClass = 'alert-dark-soft'
        if (response.data[index].approvalStatus == 'Pending') {
          defaultClass = 'alert-warning-soft'
        }
        docList.value[_.findIndex(docList.value, {category: response.data[index].corporateInfoDocumentCategoryName})] = {
          corporateInfoHeaderId: response.data[index].corporateInfoHeaderId,
          category: response.data[index].corporateInfoDocumentCategoryName,
          status: response.data[index].approvalStatus,
          class: defaultClass,
          fileName: response.data[index].corporateInfoFiles[0].fileName
        }
        // const logsResponse = await companyService.getCompanyCorporateinfoLogs(store.state.account.company_uuid, response.data[index].corporateInfoHeaderId)
        // for(let index2 in logsResponse.data){
        //   logList.value[index] = {
        //     uploadBy: logsResponse.data[index2].uploadBy,
        //     timestamp: moment(logsResponse.data[index2].timestamp).format(dateTimeFormat),
        //     fileName: logsResponse.data[index2].fileName
        //   }
        // }
      }
    })

    const openFileUploadModal = (index) => {
      setDocIndex.value = index
      cash('#kyc-doc-file-upload').modal('show')
    }

    const openActivityLogModal = async (index) => {
      setLogIndex.value = index
      // console.log(docList.value[index].corporateInfoHeaderId)
      const logsResponse = await companyService.getCompanyCorporateinfoLogs(store.state.account.company_uuid, docList.value[index].corporateInfoHeaderId)
      for(let index in logList.value){
        logList.value[index] = {}
      }
      $('#kyc-activity-log table > tbody').html('')
      for(let index2 in logsResponse.data){
        logList.value[index2] = {
          uploadBy: logsResponse.data[index2].uploadBy,
          timestamp: moment(logsResponse.data[index2].timestamp).format(dateTimeFormat),
          fileName: logsResponse.data[index2].fileName
        }
        $('#kyc-activity-log table > tbody').append(
        `<tr class='even:bg-gray-50'>
          <td class='dark:border-dark-5'>`+logsResponse.data[index2].uploadBy+`</td>
          <td class='dark:border-dark-5'>`+moment(logsResponse.data[index2].timestamp).format(dateTimeFormat)+`</td>
          <td class='dark:border-dark-5'>Uploaded file: `+logsResponse.data[index2].fileName+`</td>
        </tr>`)
      }
      // $('[data-index]').hide()
      // $('[data-index='+index+']').show()
      cash('#kyc-activity-log').modal('show')
    }

    const gotoBack = () => {
      store.commit('account/SET_STEP', {step: 'currency-settings'})
      router.go(-1)
    }

    const submit = () => {
      companyService.requestCompanyApprove(store.state.account.company_uuid, store.state.auth.user_id)
    }

    const removeFile = () => {
      files.value = null
    }

    const save = async () => {
      let formData = new FormData()
      formData.append('file', files.value[0])
      try {
        const fileId = await fileService.fileUpload('kyc', formData)
        if(docList.value[setDocIndex.value].corporateInfoHeaderId){
          await companyService.updateCompanyCoporateInfo(store.state.account.company_uuid, docList.value[setDocIndex.value].corporateInfoHeaderId, fileId)
          files.value = null
          cash('#kyc-doc-file-upload').modal('hide')
        } else {
          const response = await companyService.setCompanyCoporateInfo(store.state.account.company_uuid, docList.value[setDocIndex.value].category, fileId)
          files.value = null
          docList.value[setDocIndex.value].corporateInfoHeaderId = response.data[0]
          docList.value[setDocIndex.value].status = 'Pending'
          docList.value[setDocIndex.value].class = 'alert-warning-soft'
          cash('#kyc-doc-file-upload').modal('hide')
        }
      } catch (err) {
        toast({status: "error", title: i18n.global.t('account.kyc.kyc_doc_fail'), content: err})
      }

    }

    const removeDoc = async (index) => {
      await companyService.unsubscribeCoporateInfo(store.state.account.company_uuid, docList.value[index].corporateInfoHeaderId)
      docList.value[index].corporateInfoHeaderId = ''
      docList.value[index].status = i18n.global.t('account.kyc.kyc_doc_waiting')
      docList.value[index].class = 'alert-dark-soft'
    }

    return {
      gotoBack,
      submit,
      openFileUploadModal,
      openActivityLogModal,
      setLogIndex,
      moment,
      docList,
      logList,
      save,
      removeDoc,
      getRootProps,
      getInputProps,
      files,
      removeFile,
      ...rest,
    }
	},
}
</script>
