{ 
  "main": {
    "menu": {
      "menu_permissions": "権限",
      "menu_system_config": "システム構成",
      "menu_user_management": "ユーザー管理",
      "menu_user_group": "ユーザー・グループ",
      "menu_new_users": "新しい利用者",
      "menu_relasionship": "関係",
      "menu_workflow": "ワークフロー",
      "menu_dashboard": "ダッシュボード",
      "menu_invoices": "インボイス",
      "menu_companies": "企業",
      "menu_company_onboarding": "会社のオンボーディング",
      "menu_profile": "プロフィール"
    },
    "ecosystem": {
      "ecosystem_public_ecosystem": "公共のエコシステム"
    }
  },
  "account": { 
    "account_settings": "設定", 
    "account_profile": "プロフィール", 
    "account_role_funder_admin": "資金提供者管理者",
    "account_role_buyer_admin": "バイヤー管理者",
    "account_role_seller_admin": "セラー管理者",
    "account_role_system_admin": "システム管理者",
    "account_company": "所属企業", 
    "account_bank": "登録口座", 
    "account_currency": "取引通貨", 
    "account_kyc": "KYC", 
    "account_holidays": "祝日設定", 
    "account_main_logout": "ログアウト", 
    "account_main_appli": "応用", 
    "account_main_hi": "こんにちは,", 
    "bank": { 
      "bank_info": "口座情報", 
      "bank_name": "金融機関名", 
      "bank_remove": "削除", 
      "bank_branch_name": "支店名", 
      "bank_address": "支店住所", 
      "bank_account_number": "口座番号", 
      "bank_swift_code": "Swiftコード", 
      "bank_currency": "決済通貨", 
      "bank_add_more": "口座を追加", 
      "bank_save": "保存", 
      "bank_add_success": "口座情報を正しく保存しました", 
      "bank_add_fail": "口座情報を保存できませんでした", 
      "bank_please": "入力情報が正しくありません", 
      "bank_update_success": "口座情報を更新しました", 
      "bank_update_fail": "口座情報を更新できませんでした", 
      "bank_ph_name": "金融機関支店名", 
      "bank_ph_address": "支店住所", 
      "bank_ph_account": "口座番号", 
      "bank_ph_swift": "swiftコード" 
    }, 
    "company": { 
      "company_info": "企業情報", 
      "company_display_name": "会社表示名", 
      "company_legal_name": "会社正式名称", 
      "company_reg_no": "法人番号", 
      "company_tax_no": "税番号（日本法人の場合は法人番号）", 
      "company_reg_address": "法人住所", 
      "company_description": "事業内容", 
      "company_city": "市町村", 
      "company_state": "都道府県", 
      "company_country": "所在国", 
      "company_phone": "電話番号", 
      "company_email": "メール", 
      "company_type": "会社タイプ", 
      "company_company": "会社", 
      "company_funder": "金融機関", 
      "company_save": "保存", 
      "company_add_success": "企業情報を正しく保存しました", 
      "company_add_fail": "企業情報を保存できませんでした", 
      "company_please": "入力情報が正しくありません", 
      "company_please1": "The ",
      "company_please2": " field is missing.",
      "company_ph_name": "会社名", 
      "company_ph_reg": "法人番号", 
      "company_ph_tax": "税番号（日本法人の場合は法人番号）", 
      "company_ph_add1": "都道府県", 
      "company_ph_add2": "市町村", 
      "company_ph_add3": "番地・部屋番号", 
      "company_ph_desc": "事業内容", 
      "company_ph_city": "市町村", 
      "company_ph_state": "市町村", 
      "company_ph_contact": "電話番号", 
      "company_ph_email": "メール" 
    }, 
    "currency": { 
      "currency_info": "取引通貨設定", 
      "currency_code": "通貨コード", 
      "currency_name": "通貨名", 
      "currency_symbol": "通貨記号", 
      "currency_support": "サポート", 
      "currency_default": "デフォルト", 
      "currency_save": "保存", 
      "currency_add_success": "取引通貨を正しく保存しました", 
      "currency_add_fail": "取引通貨を保存できませんでした", 
      "currency_please": "入力情報が正しくありません" 
    }, 
    "holidays": { 
      "holidays_holidays": "祝日設定", 
      "holidays_add_new": "祝日を追加", 
      "holidays_no_events": "祝日が登録されていません", 
      "holidays_remove": "削除", 
      "holidays_recycle_bin": "削除アイテム", 
      "holidays_no_recycle": "消去アイテムはありません", 
      "holidays_new": "祝日を追加", 
      "holidays_title": "祝日名称", 
      "holidays_number": "日数", 
      "holidays_time": "時間", 
      "holidays_add": "追加", 
      "holidays_ph_please": "祝日名称を入力してください" 
    }, 
    "kyc": { 
      "kyc_please_upload": "必要な書類をアップロードしてください。",
      "kyc_kyc_docs": "KYC書類", 
      "kyc_category": "書類カテゴリ", 
      "kyc_doc_name": "ドキュメント名",
      "kyc_status": "ステータス", 
      "kyc_act_log": "活動記録",
      "kyc_act_log1": "活動記録",
      "kyc_updated_by": "更新者",
      "kyc_time_stamp": "タイムスタンプ",
      "kyc_details": "詳細",
      "kyc_actions": "アクション", 
      "kyc_save": "保存", 
      "kyc_submit": "承認申請", 
      "kyc_file": "ファイルをアップロード", 
      "kyc_drag_drop": "ドラッグ＆ドロップでファイルを追加", 
      "kyc_drag_drop1": "or", 
      "kyc_browse": "検索", 
      "kyc_doc_waiting": "書類をアップロード中です", 
      "kyc_doc_fail": "書類をアップロードできませんでした" 
    }, 
    "profile": { 
      "profile_information": "ユーザー情報", 
      "profile_username": "ユーザー名", 
      "profile_display_name": "表示名", 
      "profile_first_name": "名", 
      "profile_last_name": "姓", 
      "profile_email": "メール", 
      "profile_phone_number": "電話番号", 
      "profile_save": "保存", 
      "profile_email_fail": "本登録が未了です。受信BOXを確認してください", 
      "profile_resend": "本登録メールを再送する場合はこちら", 
      "profile_update_success": "ユーザー情報を更新しました", 
      "profile_update_fail": "ユーザー情報を更新できませんでした", 
      "profile_please": "入力情報が正しくありません", 
      "profile_ph_name": "会社名", 
      "profile_ph_reg": "法人番号", 
      "profile_ph_tax": "税番号（日本法人の場合は法人番号）", 
      "profile_ph_city": "市町村" 
    } 
  }, 
  "alerts": { 
    "alerts_alert": "通知センター", 
    "alerts_show_all": "Show All Alerts", 
    "alerts_all": "全て", 
    "alerts_unread": "未読", 
    "alerts_mark_read": "全て既読にする", 
    "alerts_ok": "OK", 
    "alerts_cancel": "Cancel", 
    "alerts_ph_search": "キーワード検索", 
    "alerts_ph_nomatch": "検索結果が見つかりませんでした", 
    "alerts_ttl_notif": "通知", 
    "alerts_ttl_timestamp": "タイムスタンプ", 
    "alerts_ttl_status": "直近ステータス", 
    "alerts_ttl_actions": "アクション" 
  }, 
  "auth": { 
    "forgot": { 
      "forgot_forgot": "パスワードをお忘れの場合", 
      "forgot_send_reset": "パスワードを初期化", 
      "forgot_reset_success": "パスワードを初期化しました", 
      "forgot_please": "登録Emailにパスワード設定メールをお送りしました", 
      "forgot_reset_error": "パスワードを初期化できませんでした", 
      "forgot_ph_email": "Emailを入力" 
    }, 
    "login": { 
      "login_login": "ログイン", 
      "login_register": "登録", 
      "login_portal": "Genie Financial Portal", 
      "login_welcome": "ユーザー名とパスワードを入力し、ログインしてください", 
      "login_username": "ユーザー名", 
      "login_password": "パスワード", 
      "login_forgot": "パスワードをお忘れの場合", 
      "login_remember": "ログイン情報を記憶", 
      "login_login_fail": "ログイン情報に誤りがあります", 
      "login_wrong": "ユーザー名、パスワードを確認してください", 
      "login_new": "初めての方はこちら", 
      "login_new1": "アカウント登録", 
      "login_ph_user": "ユーザー名を入力", 
      "login_ph_pass": "パスワードを入力" 
    }, 
    "register": { 
      "register_portal": "Genie Financial Portal", 
      "register_new": "新規アカウント登録", 
      "register_first_name": "名", 
      "register_last_name": "姓", 
      "register_username": "ユーザー名", 
      "register_email": "勤務先Email", 
      "register_display_name": "表示名", 
      "register_confirm_password": "パスワードを入力（確認）", 
      "register_sign_up": "登録", 
      "register_already": "既にアカウントをお持ちの方はこちら", 
      "register_instead": "ログイン", 
      "register_ph_first": "名", 
      "register_ph_last": "姓", 
      "register_ph_user": "ユーザー名", 
      "register_ph_email": "勤務先Email", 
      "register_ph_display": "表示名", 
      "register_ph_passconfirm": "パスワードを入力（確認）", 
      "register_s_confirm": "確認用パスワードが一致しません", 
      "register_s_wrong": "アカウントを登録できませんでした。後ほど改めてお試し頂くか、管理者にお問い合わせください。", 
      "register_s_error": "登録エラー"
    }, 
    "reset": { 
      "reset_reset": "パスワードを初期化", 
      "reset_few": "あと数クリックで登録完了です!", 
      "reset_ecommerce": "e-commerce accounts in one place", 
      "reset_instead": "ログイン", 
      "reset_reset_success": "パスワードを初期化しました", 
      "reset_you_can": "新しいパスワードでログインできます", 
      "reset_reset_fail": "パスワードを初期化できませんでした", 
      "reset_ph_newpass": "新しいパスワード" 
    }, 
    "update": { 
      "update_update": "パスワードを更新", 
      "update_current": "現在のパスワード", 
      "update_new": "新しいパスワード", 
      "update_required": "入力必須", 
      "update_update_success!": "正しく更新しました", 
      "update_password_success": "パスワードが更新されました", 
      "update_update_failed": "更新できませんでした", 
      "update_please": "入力内容を確認してください" 
    }, 
    "validate": { 
      "validate_genie": "Genie", 
      "validate_portal": "Genie Financial Portal", 
      "validate_new": "新規アカウント登録", 
      "validate_validate": "電話番号認証", 
      "validate_enter": "認証コードを入力", 
      "validate_please_wait": "15秒ほどお待ちください。", 
      "validate_request": "認証コードを再送", 
      "validate_validate_mobile": "電話番号認証が完了しました", 
      "validate_back": "アカウント登録に戻る" 
    } 
  }, 
  "company": { 
    "companies": { 
      "companies_ph_nomatch": "レコードが見つかりません", 
      "companies_ttl_display": "会社表示名", 
      "companies_ttl_legal": "会社正式名称", 
      "companies_ttl_reg": "法人番号", 
      "companies_ttl_tax": "税番号（日本法人の場合は法人番号）", 
      "companies_ttl_address": "住所", 
      "companies_ttl_city": "都道府県", 
      "companies_ttl_country": "所在国", 
      "companies_ttl_contact": "電話番号", 
      "companies_ttl_email": "メール", 
      "companies_ttl_actions": "アクション" 
    }, 
    "detail": { 
      "detail_company_info": "企業情報", 
      "detail_bank_info": "口座情報", 
      "detail_currency_settings": "取引通貨設定", 
      "detail_kyc_documents": "KYC書類", 
      "detail_user_lists": "ユーザーリスト" 
    } 
  }, 
  "dashboard": { 
    "dashboard_dashboard": "ダッシュボード", 
    "dashboard_reload_data": "データ更新", 
    "dashboard_pending": "ペンディングアイテム",
    "dashboard_transaction_complete": "取引完了",
    "dashboard_awaiting_final_disbursement_acknowledgement_communication": "販売者の確認について資金提供者に電子メールで通知",
    "dashboard_awaiting_seller_acknowledge_receive_of_final_disbursement": "売り手が資金提供者からの決済を承認",
    "dashboard_awaiting_final_fund_disbursement_notification_to_seller": "資金決済のセラーへのメール通知",
    "dashboard_awaiting_funder_final_disbursement": "セラーへの残高決済金額の支払い",
    "dashboard_funder_acknowledged_receive_of_repayment": "資金提供者はバイヤーからの返済を承認します",
    "dashboard_awaiting_funder_acknowledge_repayment": "バイヤーからの返済に関する資金提供者への電子メール通知",
    "dashboard_awaiting_buyer_repayment_on_due_date": "買い手が期日に支払いを行う",
    "dashboard_awaiting_disbursement_acknowledgement_communication": "支払い確認通知待ち",
    "dashboard_awaiting_seller_acknowledge_disbursement": "売り手が支払いを承認するのを待っています",
    "dashboard_awaiting_fund_disbursement_notification_to_seller": "売り手への資金支払い通知を待っています",
    "dashboard_awaiting_funder_disbursement": "資金提供者の支払いを待っています",
    "dashboard_awaiting_first_disbursement_acknowledgement_communication": "販売者の確認について資金提供者に電子メールで通知",
    "dashboard_awaiting_seller_acknowledge_receive_of_first_disbursement": "売り手が資金提供者からの支払いを承認",
    "dashboard_awaiting_first_fund_disbursement_notification_to_seller": "資金支払いのための販売者への電子メール通知",
    "dashboard_awaiting_funder_first_disbursement": "融資額から利息と手数料を差し引いた支払い",
    "dashboard_awaiting_bidding_result": "サプライヤーとバイヤーへのメール通知",
    "dashboard_bidding_in_progress": "資金提供者承認トランザクション",
    "dashboard_invite_funders_to_bid": "資金提供者へのメール通知",
    "dashboard_awaiting_seller_acknowledgement": "販売者の承認待ち",
    "dashboard_awaiting_buyer_acknowledgement": "バイヤー承認トランザクション",
    "dashboard_start": "資金調達リクエストが提出されました",
    "dashboard_notification_sent_to_buyer": "購入者に通知を送信",
    "dashboard_transaction_acknowledged_by_buyer": "購入者が承認したトランザクション",
    "dashboard_invitation_sent_to_funders": "資金提供者に招待状を送信",
    "dashboard_transaction_approved_by_funder": "資金提供者によってトランザクションが承認されました",
    "dashboard_first_fund_disbursement_instruction_sent_to_funder": "最初の資金支払い指示を資金提供者に送信",
    "dashboard_first_fund_disbursed_by_funder": "資金提供者によって支払われた最初の資金",
    "dashboard_first_fund_disbursement_notification_sent_to_seller": "最初の資金支払い通知が売り手に送信されます",
    "dashboard_receipt_of_first_fund_acknowledged_by_seller": "売り手による最初の資金受領確認",
    "dashboard_repayment_instruction_sent_to_buyer": "返金指示を購入者に送信",
    "dashboard_repaid_by_buyer": "購入者が返金",
    "dashboard_receipt_of_repayment_acknowledged_by_funder": "資金提供者による返済承認の受領",
    "dashboard_final_fund_disbursement_instruction_sent_to_funder": "最終的な資金支払い指示を資金提供者に送信",
    "dashboard_final_fund_disbursed_by_funder": "資金提供者によって支払われた最終資金",
    "dashboard_final_fund_disbursement_notification_sent_to_seller": "資金支払いの最終通知を売り手に送信",
    "dashboard_receipt_of_final_fund_acknowledged_by_seller": "売り手による最終資金受領確認",
    "dashboard_transaction_declined_by_buyer": "取引は購入者によって拒否されました",
    "dashboard_transaction_not_approved_by_funder": "トランザクションは資金提供者によって承認されていません",
    "dashboard_receipt_of_first_funds_declined_by_seller": "最初の資金の受け取りが売り手によって拒否されました",
    "dashboard_receipt_of_repayment_declined_by_funder": "返済の受領が資金提供者によって拒否されました",
    "dashboard_receipt_of_final_funds_declined_by_seller": "最終的な資金の受領は売り手によって拒否されました",
    "dashboard_notification_sent_to_seller": "売り手に送信される通知",
    "dashboard_transaction_acknowledged_by_seller": "販売者が承認した取引",
    "dashboard_fund_disbursement_instruction_sent_to_funder": "資金支払い指示を資金提供者に送信",
    "dashboard_fund_disbursed_by_funder": "資金提供者によって支払われた資金",
    "dashboard_fund_disbursement_notification_sent_to_seller": "資金支払い通知を売り手に送信",
    "dashboard_receipt_of_fund_acknowledged_by_seller": "売り手による資金受領確認",
    "dashboard_notification_sent_to_funder": "資金提供者に通知を送信",
    "dashboard_invoice": "インボイス バッチNo.", 
    "dashboard_created": "作成日 :", 
    "dashboard_view": "閲覧", 
    "dashboard_no_pending": "ペンディングアイテムはありません", 
    "dashboard_calendar": "祝日カレンダー", 
    "borrowers": { 
      "borrowers_companies": "融資対象企業" 
    }, 
    "cards": { 
      "cards_line_info": "クレジットライン情報", 
      "cards_limit": "利用可能枠: ", 
      "cards_funded_amount": "支払金額: ", 
      "cards_total_allocated": "総配分額: ", 
      "cards_outstand_amount": "未払いの支払い額: ", 
      "cards_utilized_amount": "使用中の金額: ", 
      "cards_overall_limit": "クレジットライン総額: ", 
      "cards_financing_funded": "ファイナンス実行済", 
      "cards_count": "件数: ", 
      "cards_total_amount": "総額: ", 
      "cards_requested": "ファイナンス申請済" 
    } 
  }, 
  "eco": { 
    "relationship": { 
      "relationship_systems": "エコシステム", 
      "relationship_create": "エコシステムを作成する", 
      "relationship_ph_nomatch": "レコードが見つかりません", 
      "relationship_ttl_name": "企業名", 
      "relationship_ttl_funder": "レンダー", 
      "relationship_ttl_buyer": "バイヤー", 
      "relationship_ttl_seller": "セラー", 
      "relationship_ttl_expire": "期日", 
      "relationship_ttl_status": "ステータス", 
      "relationship_ttl_actions": "アクション" 
    }, 
    "modal": { 
      "modal_system": "エコシステム", 
      "modal_name": "企業名: ", 
      "modal_buyer": "バイヤー: ", 
      "modal_seller": "セラー: ", 
      "modal_funder": "レンダー: ", 
      "modal_seller_credit": "与信先（セラー）: ", 
      "modal_buyer_credit": "与信先（バイヤー）: ", 
      "modal_base_currency": "取引通貨コード: ", 
      "modal_interest": "年利率(%): ", 
      "modal_interest_duration": "金利計算日数: ", 
      "modal_expired": "期日:", 
      "modal_status": "ステータス:", 
      "modal_buyer_workflow": "三者間取引（バイヤー承認取引）", 
      "modal_seller_workflow": "二者間取引（セラー相対取引）", 
      "modal_delete": "消去", 
      "modal_active": "Active", 
      "modal_save": "保存" 
    }, 
    "workflow": { 
      "workflow_workflow": "ワークフロー" 
    } 
  }, 
  "error": { 
    "denied": { 
      "denied_403": "403", 
      "denied_oops": "このページへのアクセス権限がありません", 
      "denied_mistyped": "URLが誤っているようです", 
      "denied_back": "ホームへ戻る" 
    }, 
    "error": { 
      "error_404": "404", 
      "error_oops": "ページが見つかりません", 
      "error_mistyped": "URLが誤っているようです", 
      "error_back": "ホームへ戻る" 
    } 
  }, 
  "invoice": { 
    "invoice_search": "探す",
    "invoice_page_size": "ページサイズ",
    "invoice_invoices": "インボイス", 
    "invoice_upload_invoice": "インボイス", 
    "invoice_upload_invoice2": "を提出", 
    "invoice_field": "フィールド", 
    "invoice_last_updated": "最終更新日", 
    "invoice_seller": "セラー", 
    "invoice_buyer": "バイヤー", 
    "invoice_funder": "ファンダー", 
    "invoice_batch_no": "バッチNo.", 
    "invoice_batch_st": "バッチ状況", 
    "invoice_batch_remark": "備考", 
    "invoice_type": "タイプ", 
    "invoice_like": "同じ", 
    "invoice_nlike": "同じではない", 
    "invoice_value": "金額", 
    "invoice_go": "進む", 
    "invoice_reset": "リセット", 
    "invoice_pending_action": "ペンディングアイテム", 
    "invoice_my_invoice": "インボイスリスト", 
    "invoice_my_bid": "オファーリスト", 
    "invoice_invoice_partner": "パートナー企業からのインボイスです", 
    "invoice_empty_table": "テーブル内のデータなし",
    "invoice_processing": "処理...",
    "invoice_ph_search": "検索", 
    "invoice_ph_nomatch": "レコードが見つかりません", 
    "invoice_ttl_batch": "バッチNo.", 
    "invoice_ttl_buyer": "バイヤー", 
    "invoice_ttl_seller": "セラー", 
    "invoice_ttl_total": "総額", 
    "invoice_ttl_lastupdate": "最終更新日", 
    "invoice_ttl_stage": "現在のステージ", 
    "invoice_ttl_bidendtime": "入札終了時間",
    "invoice_ttl_remark": "備考", 
    "invoice_ttl_status": "ステータス", 
    "invoice_ttl_created": "作成日", 
    "invoice_ttl_actions": "アクション", 
    "upload": { 
      "upload_upload_invoice": "インボイスを提出", 
      "upload_upload_selecttype": "文書タイプを選択", 
      "upload_company_name": "会社名", 
      "upload_seller_name": "販売者名",
      "upload_buyer_name": "購入者名",
      "upload_bid_end": "金融機関による審査期限", 
      "upload_select_disb": "振込先口座を選択", 
      "upload_select_disb1": "振込元口座を選択", 
      "upload_batch_remark": "備考を入力", 
      "upload_upload_documents": "補足書類を提出", 
      "upload_payment_date": "支払期日は本日以降である必要があります", 
      "upload_amount_zero": "金額はゼロであってはなりません", 
      "upload_uploading": "アップロード中", 
      "upload_number": "書類No.", 
      "upload_type": "書類タイプ", 
      "upload_date": "書類日付", 
      "upload_payment_date1": "支払期日", 
      "upload_currency_code": "通貨コード", 
      "upload_amount": "金額", 
      "upload_support": "補足書類", 
      "upload_action": "アクション", 
      "upload_remark": "備考", 
      "upload_cancel": "キャンセル", 
      "upload_submit": "提出", 
      "upload_upload_fail": "アップロードに失敗しました",
      "upload_upload_s_file_error": "ファイル形式エラー",
      "upload_upload_s_invoice_fail": "請求書のアップロードに失敗しました"
    }, 
    "support": { 
      "support_support": "アップロード中", 
      "support_s_cannot_upload1": "アップロードできません ",
      "support_s_cannot_upload2": " ファイル",
      "support_s_upload_fail": "サポートドキュメントのアップロードに失敗しました"
    }, 
    "detail": { 
      "detail_upload_document": "ドキュメントをアップロード",
      "invoice": "インボイス", 
      "batch_no": "バッチNo.", 
      "uploads": "書類をアップロード", 
      "yellow_warning": "請求書の補足書類をアップロードしてください。",
      "no": "インボイスNo.", 
      "amount": "インボイス金額", 
      "date": "インボイス日付", 
      "payment_date": "支払期日", 
      "upload_date": "提出日", 
      "actions": "アクション", 
      "more": "詳細を見る", 
      "less": "詳細を閉じる", 
      "provenance_txt": "実行履歴", 
      "documents": { 
        "documents_supporting": "補足書類", 
        "documents_uploaded": "提出日", 
        "documents_uploaded_by": "提出者", 
        "documents_verifying": "承認待ち" 
      }, 
      "provenance": { 
        "provenance_verifying": "承認待ち", 
        "provenance_verified": "承認済み", 
        "provenance_not_verified": "非承認", 
        "provenance_pending": "ペンディング", 
        "provenance_not_started": "未開始", 
        "provenance_updated": "更新日: ", 
        "provenance_hash": "ハッシュコード: ", 
        "provenance_approval": "承認者: " 
      } 
    }, 
    "batch": { 
      "signature": { 
        "signature_undo": "署名を取り消す", 
        "signature_clear": "署名を消去" 
      }, 
      "eco": { 
        "eco_batch_details": "バッチ詳細: ", 
        "eco_batch_workflow_seller_led": "セラー主導",
        "eco_batch_workflow_buyer_led": "バイヤー主導",
        "eco_batch_status_expired": "期限切れ",
        "eco_batch_status_rejected": "拒否されました",
        "eco_batch_status_funded": "資金提供",
        "eco_batch_status_completed": "完了",
        "eco_batch_status_pending": "保留中",
        "eco_batch_status_pending_repayment": "返済保留中",
        "eco_batch_status_pending_final_disbursement": "最終支払い保留中",
        "eco_batch_status_bidding_expired": "入札期限切れ",
        "eco_batch_info": "バッチ情報", 
        "eco_buyer_company": "バイヤー", 
        "eco_seller_company": "セラー", 
        "eco_funder_company": "金融機関", 
        "eco_no_batch": "バッチ件数", 
        "eco_upload_date": "提出日", 
        "eco_total_amount": "総額", 
        "eco_bank_details": "口座情報", 
        "eco_disbursement_account": "振込元口座", 
        "eco_account_no": "口座番号: ", 
        "eco_bank_name": "金融機関名: ", 
        "eco_address": "支店住所: ", 
        "eco_swift": "Swiftコード: ", 
        "eco_formula": "フォーミュラ", 
        "eco_interest": "年利率(% p.a.)", 
        "eco_interest_earn": "受取金利", 
        "eco_platform_fee": "プラットフォーム手数料", 
        "eco_disbursement_amount": "申請者への振込金額（金利、手数料控除後）", 
        "eco_repayment_account": "返済先口座", 
        "eco_repayment_amount": "金融機関への返済金額", 
        "eco_repayment_date": "返済期日", 
        "eco_decline": "却下", 
        "eco_approve": "承認", 
        "eco_submit_disb": "支払通知を送信", 
        "eco_receive_disb": "入金を確認しました", 
        "eco_upload_advice": "返済の証憑書類（支払通知書）をアップロード", 
        "eco_repay_advice": "支払通知書を確認しました", 
        "eco_approve_invoice": "インボイスを承認", 
        "eco_batch_no": "バッチNo.", 
        "eco_invoice_upload": "インボイス提出日", 
        "eco_invoice_amount": "インボイス金額", 
        "eco_payment_due": "返済期日", 
        "eco_select_disb": "振込元口座を選択", 
        "eco_remark": "注記", 
        "eco_cancel": "キャンセル", 
        "eco_approve_trx": "取引を承認", 
        "eco_total_invoice": "インボイス金額合計", 
        "eco_value_date": "支払実行日（Value Date）", 
        "eco_invoice_due": "インボイス期日", 
        "eco_no_days": "金利計算日数", 
        "eco_decline_invoice": "インボイスを却下", 
        "eco_upload_disb": "支払通知書をアップロード", 
        "eco_payment_upload": "支払通知書をアップロード", 
        "eco_or": "or", 
        "eco_browse": "検索", 
        "eco_currency": "通貨コード", 
        "eco_submit": "提出", 
        "eco_advice_no": "支払通知書No.", 
        "eco_advice_file": "ファイル", 
        "eco_advice_amount": "金額", 
        "eco_advice_date": "日付", 
        "eco_confirm": "確認", 
        "eco_receive_repay": "返済資金の入金を確認しました", 
        "eco_drag_drop": "ドラッグ＆ドロップでファイルを追加",
        "eco_s_approve_fail": "承認に失敗しました",
        "eco_s_please_interest": "金利を設定してください",
        "eco_s_please_valid": "有効な返済銀行口座を選択してください",
        "eco_s_seller_ack_fail": "売り手の承認は失敗しました",
        "eco_s_decline_fail": "辞退に失敗しました",
        "eco_s_funder_approve_fail": "ファンダーは承認が失敗したことを認めます",
        "eco_s_funder_decline_fail": "ファンダーは辞退が失敗したことを認めます",
        "eco_s_platform_error": "プラットフォーム料金計算エラー",
        "eco_s_funder_advice_fail": "支払いアドバイスを提出するファンダーは失敗しました",
        "eco_s_funder_advice_req": "支払通知番号/金額フィールドは必須です。",
        "eco_s_funder_payment_req": "支払いアドバイスファイルをアップロードしてください",
        "eco_s_ack_fail": "確認に失敗しました",
        "eco_s_upload_fail": "返済アドバイスのアップロードに失敗しました",
        "eco_s_funder_ack_fail": "ファンダーの承認に失敗しました",
        "eco_s_signature_req": "Your signature is required!",
        "eco_s_funder_decline": "ファンダーの辞退に失敗しました",
        "eco_batch_message1": "入札終了時間が過ぎたため、バイヤーはこの請求書を承認しませんでした",
        "eco_batch_message2": "この取引は資金提供者によって承認されませんでした",
        "eco_batch_message3": "この請求書は期限切れです。支払期日は"
      }, 
      "led": { 
        "led_maturity": "融資期日", 
        "led_note": "備考: ", 
        "led_interest_r": "年利率(% p.a.)", 
        "led_monthly": "Monthly", 
        "led_yearly": "Yearly", 
        "led_disb_amt_fee": "申請者への振込金額（プラットフォーム手数料控除後）", 
        "led_reject_fail": "請求書の拒否に失敗しました"
      } 
    } 
  }, 
  "onboard": { 
    "onboard_pending_approval": "承認待ちの",
    "onboard_pending_request": "保留中の要求",
    "onboard_no_data": "利用可能なデータがありません",
    "onboard_rejected_request": "拒否されたリクエスト",
    "onboard_remove_company": "この会社を削除しますか？",
    "onboard_onboard": "企業登録", 
    "onboard_company_display_name": "会社表示名", 
    "onboard_required": "必須", 
    "onboard_company_email": "法人ユーザーメール", 
    "onboard_invite": "招待する", 
    "onboard_onboared": "登録済み企業", 
    "onboard_credit": "クレジットライン上限", 
    "onboard_start": "利用開始日", 
    "onboard_end": "利用終了日", 
    "onboard_status": "ステータス", 
    "onboard_actions": "アクション", 
    "onboard_reinvite": "再招待", 
    "onboard_available": "サービス利用中企業", 
    "onboard_company_name": "企業名", 
    "onboard_company_code": "法人コード", 
    "onboard_email": "メール ", 
    "onboard_display_name": "会社表示名 ", 
    "onboard_address": "法人住所 ", 
    "onboard_pending": "ペンディング", 
    "onboard_invite_company": "法人を招待する", 
    "onboard_currency_code": "通貨コード", 
    "onboard_credit_limit": "クレジットライン上限", 
    "onboard_end_date": "利用終了日", 
    "onboard_cancel": "キャンセル", 
    "onboard_invite_success": "招待が完了しました", 
    "onboard_reject_success": "成功を辞退！",
    "onboard_approve_success": "成功を承認してください！",
    "onboard_remove_success": "成功を取り除いてください！",
    "onboard_failed": "失敗した！",
    "onboard_invite_fail": "招待に失敗しました！",
    "onboard_invite_success1": "法人登録が正しく完了しました", 
    "onboard_invite_success2": "招待を拒否しました",
    "onboard_invite_success3": "招待を正常に承認しました",
    "onboard_invite_success4": "会社を無事に削除しました",
    "onboard_upload_fail": "データが正しく処理されませんでした",
    "onboard_ph_display": "会社表示名", 
    "onboard_s_please": "必須フィールドに入力してください"
  }, 
  "permissions": { 
    "add": { 
      "add_create": "ユーザー権限設定", 
      "add_name": "Permission Name", 
      "add_required": "必須", 
      "add_resource": "Resource Url", 
      "add_verbs": "Access Verbs", 
      "add_get": "Get", 
      "add_post": "Post", 
      "add_put": "Put", 
      "add_delete": "消去", 
      "add_valid": "有効期限", 
      "add_format": "Required Date Format", 
      "add_unexpired": "Unexpired", 
      "add_type": "タイプ", 
      "add_menu": "メニュー", 
      "add_api": "API", 
      "add_create_success": "権限設定が追加されました", 
      "add_create_success_done": "権限設定が正しく追加されました", 
      "add_success": "新しい権限が設定されました", 
      "add_create_fail": "設定が正しく処理されませんでした", 
      "add_valid_error": "入力事項に誤りがあります", 
      "add_please_fields": "入力フォームを確認してください", 
      "add_please.": "入力フォームを確認してください" 
    }, 
    "edit": { 
      "edit_update": "権限を更新する", 
      "edit_name": "Permission Name", 
      "edit_required": "必須", 
      "edit_resource": "Resource Url", 
      "edit_verbs": "Access Verbs", 
      "edit_get": "Get", 
      "edit_post": "Post", 
      "edit_put": "Put", 
      "edit_delete": "消去", 
      "edit_valid": "有効期限", 
      "edit_format": "Required Date Format", 
      "edit_unexpired": "Unexpired", 
      "edit_type": "タイプ", 
      "edit_menu": "メニュー", 
      "edit_api": "API", 
      "edit_update_success": "権限設定が更新されました", 
      "edit_success": "権限設定が正しく更新されました", 
      "edit_update_fail": "更新リクエストが正しく処理されませんでした", 
      "add_valid_error": "入力事項に誤りがあります", 
      "add_please_fields": "入力フォームを確認してください", 
      "edit_please.": "入力フォームを確認してください" 
    }, 
    "list": { 
      "edit_list_permissions": "ユーザー権限", 
      "edit_list_add": "新しいユーザー権限を追加する", 
      "edit_list_print": "印刷", 
      "edit_list_excel": "エクセル出力", 
      "edit_list_pdf": "PDF出力", 
      "edit_list_name": "PERMISSION NAME", 
      "edit_list_url": "URL", 
      "edit_list_expire": "有効期限", 
      "edit_list_type": "タイプ", 
      "edit_list_actions": "アクション", 
      "edit_list_edit": "編集", 
      "edit_list_delete": "消去", 
      "edit_list_confirm": "実行してよろしいですか?", 
      "edit_list_confirm_delete": "権限設定を消去してよろしいですか?", 
      "edit_list_cannot": "この操作は取消できません", 
      "edit_list_cancel": "キャンセル", 
      "edit_delete_done": "権限の消去が正しく完了しました", 
      "edit_delete_fail": "権限の消去リクエストが正しく処理されませんでした", 
      "edit_list_ph_search": "検索" 
    } 
  }, 
  "roles": { 
    "roles_list_permissions": "ユーザー権限", 
    "roles_list_grant": "権限を付与", 
    "roles_list_grant_role ": "実行可能な権限を設定", 
    "roles_list_available": "ユーザー権限リスト", 
    "roles_list_name": "PERMISSION NAME", 
    "roles_list_url": "URL", 
    "roles_list_expire": "有効期限", 
    "roles_list_type": "タイプ", 
    "roles_list_cancel": "キャンセル", 
    "roles_list_ok": "OK ", 
    "roles_list_confirm": "実行してよろしいですか?", 
    "roles_list_confirm_remove": "消去してよろしいですか?", 
    "roles_list_confirm_remove1": "入力内容を確認してください", 
    "roles_list_confirm_remove2": "この操作は取消できません", 
    "roles_list_remove": "消去", 
    "roles_list_ph_nomatch": "レコードが見つかりません", 
    "roles_list_ttl_name": "Permission Name", 
    "roles_list_ttl_type": "タイプ", 
    "roles_list_ttl_actions": "アクション", 
    "grant": { 
      "grant_delete_success": "消去が正しく処理されました", 
      "grant_delete_success_has1": "", 
      "grant_delete_success_has2": "が削除されました", 
      "grant_delete_fail": "消去リクエストが正しく処理されませんでした", 
      "grant_delete_permission_done": "消去が正しく処理されました", 
      "grant_delete_permission_fail": "消去リクエストが正しく処理されませんでした", 
      "grant_add_permission_done": "権限設定が正しく追加されました", 
      "grant_add_permission_fail": "リクエストが正しく処理されませんでした", 
      "grant_access": "アクセス権限を付与" 
    }, 
    "list": { 
      "list_roles": "ユーザー属性", 
      "list_add": "属性を新規追加", 
      "list_name": "ユーザー属性名", 
      "list_confirm": "入力内容を確認してください", 
      "list_confirm_delete": "消去してよろしいですか?", 
      "list_confirm_delete1": "属性に誤りはありませんか?", 
      "list_confirm_delete2": "この操作は取消できません", 
      "list_cancel": "キャンセル", 
      "list_delete": "消去", 
      "list_ph_search": "検索", 
      "list_ph_input": "ユーザー属性を入力", 
      "list_ph_nomatch": "レコードが見つかりません", 
      "list_ttl_name": "ユーザー属性名", 
      "list_ttl_actions": "アクション" 
    } 
  }, 
  "settings": { 
    "configure": { 
      "settings_configure_configure": "システム設定", 
      "settings_configure_add_group": "新しいグループを追加", 
      "settings_configure_name": "名前", 
      "settings_configure_value": "値", 
      "settings_configure_data_type": "データタイプ", 
      "settings_configure_save": "保存", 
      "settings_configure_remove": "消去", 
      "settings_configure_add_configuration": "設定を追加する", 
      "settings_configure_json": "Json", 
      "settings_configure_cancel": "キャンセル", 
      "settings_configure_add": "追加", 
      "settings_configure_confirm": "入力内容を確認してください", 
      "settings_confirmdddd": "この操作は取消できません", 
      "settings_confirm": "この操作は取消できません", 
      "settings_configure_cannot": "この操作は取消できません", 
      "settings_configure_delete": "消去", 
      "settings_add_done": "新しいグループの追加が正しく完了しました", 
      "settings_add_fail": "グループが追加できませんでした", 
      "settings_save_done": "設定が正しく保存されました", 
      "settings_add_config_done": "設定が正しく追加されました", 
      "settings_add_config_fail": "リクエストが正しく処理されませんでした", 
      "settings_delete_config_done": "設定が正しく消去されました", 
      "settings_delete_config_fail": "リクエストが正しく処理されませんでした", 
      "settings_configure_group_name": "グループ名" 
    } 
  }, 
  "users": { 
    "add": { 
      "users_add_create": "ユーザーを追加", 
      "users_add_first_name": "名", 
      "users_add_required": "必須", 
      "users_add_last_name": "姓", 
      "users_add_email": "メール", 
      "users_add_required_email": "Emailアドレスを正しく入力してください", 
      "users_add_user_name": "ユーザー名", 
      "users_add_display_name": "表示名", 
      "users_add_user_role": "ユーザー属性", 
      "users_add_multiple": "複数選択", 
      "users_add_password": "パスワード", 
      "users_add_confirm_password": "パスワード（再入力）", 
      "users_add_update_success": "新規追加されました", 
      "users_add_please": "Emailに登録情報を送信しました", 
      "users_add_update_fail": "新規追加ができませんでした", 
      "users_add_please1": "入力事項を確認してください" 
    }, 
    "edit": { 
      "users_edit_user_data": "ユーザーデータ", 
      "users_edit_company_info": "企業情報", 
      "users_edit_bank_info": "口座情報", 
      "users_edit_currency": "通貨設定", 
      "users_edit_kyc_docs": "KYC書類", 
      "users_edit_first_name": "名", 
      "users_edit_required": "必須", 
      "users_edit_last_name": "姓", 
      "users_edit_email": "メール", 
      "users_edit_required_email": "Emailアドレスを正しく入力してください", 
      "users_edit_display_name": "表示名", 
      "users_edit_user_role": "ユーザー属性", 
      "users_edit_multiple": "複数選択", 
      "users_edit_update_user": "ユーザー情報を更新", 
      "users_edit_update_success": "正しく更新しました", 
      "users_edit_please": "Emailに登録情報を送信しました", 
      "users_edit_update_fail": "更新できませんでした", 
      "users_edit_please1": "入力事項を確認してください" 
    }, 
    "new": { 
      "users_new_users": "企業一覧", 
      "users_new_dialog": "ダイアログを開く", 
      "users_new_ph_nomatch": "レコードが見つかりません", 
      "users_new_ttl_reg": "法人番号", 
      "users_new_ttl_name": "会社名", 
      "users_new_ttl_legal": "会社正式名称", 
      "users_new_ttl_add": "法人住所", 
      "users_new_ttl_type": "タイプ", 
      "users_new_ttl_contact": "電話番号", 
      "users_new_ttl_email": "メール", 
      "users_new_ttl_actions": "アクション" 
    }, 
    "list": { 
      "users_list_users": "ユーザー一覧", 
      "users_list_add": "ユーザーを新規追加", 
      "users_list_showing": "1－10件目を表示", 
      "users_list_edit": "編集", 
      "users_list_delete": "消去", 
      "users_list_ellipsis": "...", 
      "users_list_ph_search": "検索..." 
    } 
  } 
}