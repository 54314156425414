import { appAxios } from "@/plugins/axios"
import journalBatchService from "./journalBatchService"

const funderGetMyBidWorkflowIds = (companyId) => {
  return new Promise(async resolve => {
    const api = `/bidding/v1/${companyId}/invitations`
    const invoices = await appAxios.get(api).then(res => _.filter(res.data, {status: 'Open', voted: true}))

    const funderVotedWorkflowIds = await Promise.all(invoices.map(async invoice => {
      return invoice.workflowExecutionReferenceId
    }))

    resolve(funderVotedWorkflowIds)
  })
}

const funderGetMyBidInvoices = (companyId) => {
  return new Promise(async resolve => {
    const api = `/bidding/v1/${companyId}/invitations`
    const invoices = await appAxios.get(api).then(res => _.filter(res.data, {status: 'Open', voted: true}))

    const funderVotedBatches = await Promise.all(invoices.map(async invoice => {
      const batch = await journalBatchService.getBatchDetailByExecutionReferenceId(invoice.workflowExecutionReferenceId)
      return batch
    }))

    resolve(funderVotedBatches)
  })
}

const vote = ({workflowExecutionReferenceId, companyId, bidValue, valueDate, interestRateDuration, repaymentAccount}) => {
  return new Promise(async (resolve, reject) => {
    const api = `/bidding/v1/${workflowExecutionReferenceId}/vote`
    try {
      const response = await appAxios.put(api, {
        vote: { companyId, bidValue, valueDate, interestRateDuration, repaymentAccount }
      })
      if(response.status === 'error') reject(response.error.response.data)
      resolve(response.data)
    } catch (err) {
      reject(err)
    }
  })
}

const reject = ({workflowExecutionReferenceId, companyId, remark}) => {
  return new Promise(async (resolve, reject) => {
    const api = `/bidding/v1/${workflowExecutionReferenceId}/reject`
    const response = await appAxios.put(api, { reject: { companyId, remark}})
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export default {
  funderGetMyBidWorkflowIds,
  funderGetMyBidInvoices,
  vote,
  reject
}
