<template>
  <div id="relationship-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl" style="width: 90vw;">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto"> {{ $t('eco.modal.modal_system') }} </h2>
        </div> <!-- END: Modal Header -->
        <div class="m-8 mt-4">
          <div class="grid grid-cols-3 gap-4 mt-4">
      <div>
        <label>{{ $t('eco.modal.modal_name') }} </label>
        <input type="text" :readonly=ecoSystemBody.editMode v-model="ecoSystemBody.name" class="form-control" />
        <template v-if="$v.name.$error">
          <div v-for="(error, index) in $v.name.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_buyer') }} </label>
        <select :disabled=ecoSystemBody.editMode v-model="ecoSystemBody.buyerCompanyId" class="form-select">
          <option v-for="company in systemCompanies" :value="company.companyId" :key="company.companyId">{{company.companyDisplayName}}</option>
        </select>
        <template v-if="$v.buyerCompanyId.$error">
          <div v-for="(error, index) in $v.buyerCompanyId.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_seller') }} </label>
        <select :disabled=ecoSystemBody.editMode class="form-select" v-model="ecoSystemBody.sellerCompanyId">
          <option v-for="company in systemCompanies" :value="company.companyId" :key="company.companyId">
            {{company.companyDisplayName}}
          </option>
        </select>
        <template v-if="$v.sellerCompanyId.$error">
          <div v-for="(error, index) in $v.sellerCompanyId.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_funder') }} </label>
        <select :disabled=ecoSystemBody.editMode class="form-select" v-model="ecoSystemBody.funderCompanyId">
          <option v-for="company in funderCompanies" :value="company.companyId" :key="company.companyId">
            {{company.companyDisplayName}}
          </option>
        </select>
        <template v-if="$v.funderCompanyId.$error">
          <div v-for="(error, index) in $v.funderCompanyId.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_seller_credit') }} </label>
        <input type="text" v-model="ecoSystemBody.sellerCredit" class="form-control" />
        <template v-if="$v.sellerCredit.$error">
          <div v-for="(error, index) in $v.sellerCredit.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_buyer_credit') }} </label>
        <input type="text" v-model="ecoSystemBody.buyerCredit" class="form-control" />
        <template v-if="$v.buyerCredit.$error">
          <div v-for="(error, index) in $v.buyerCredit.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_base_currency') }} </label>
        <select :disabled="ecoSystemBody.editMode" class="form-select" v-model="ecoSystemBody.baseCurrencyCode">
          <option v-for="currency in currencies" :key="currency.currencyCode" :value="currency.currencyCode">
            {{currency.currencyCode}}
          </option>
        </select>
        <template v-if="$v.baseCurrencyCode.$error">
          <div v-for="(error, index) in $v.baseCurrencyCode.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_interest') }} </label>
        <input type="text" v-model="ecoSystemBody.interestRate" class="form-control" />
        <template v-if="$v.interestRate.$error">
          <div v-for="(error, index) in $v.interestRate.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_interest_duration') }} </label>
        <select v-model="ecoSystemBody.interestRateDuration" class="form-select">
          <option v-for="duration in interestRateDuration" :value="duration" :key="duration">
            {{duration}}
          </option>
        </select>
        <template v-if="$v.interestRateDuration.$error">
          <div v-for="(error, index) in $v.interestRateDuration.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_expired') }} ({{ $t('eco.modal.modal_status') }} {{(ecoSystemBody.status)??'-'}})</label>
        <DatePicker v-model="ecoSystemBody.expiredDate" mode="datetime" :masks="{inputDateTime: dateTimeFormat}">
          <template v-slot="{ inputValue, inputEvents }">
            <input
              id="expired-date"
              class="form-control"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
        <template v-if="$v.expiredDate.$error">
          <div v-for="(error, index) in $v.expiredDate.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_buyer_workflow') }}</label>
        <select :disabled=ecoSystemBody.editMode v-model="ecoSystemBody.buyerLedWorkflowId" class="form-select">
          <option v-for="workflow in workflowLists" :value="workflow.workflowId" :key="workflow.workflowId">
              {{workflow.name}}
          </option>
        </select>
        <template v-if="$v.buyerLedWorkflowId.$error">
          <div v-for="(error, index) in $v.buyerLedWorkflowId.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div>
        <label>{{ $t('eco.modal.modal_seller_workflow') }}</label>
        <select :disabled=ecoSystemBody.editMode v-model="ecoSystemBody.sellerLedWorkflowId" class="form-select">
          <option v-for="workflow in workflowLists" :value="workflow.workflowId" :key="workflow.workflowId">
              {{workflow.name}}
          </option>
        </select>

        <template v-if="$v.sellerLedWorkflowId.$error">
          <div v-for="(error, index) in $v.sellerLedWorkflowId.$errors" :key="index" class="text-theme-6 mt-2">
            {{ error.$message }}
          </div>
        </template>
      </div>
    </div>
    <hr class="my-8"/>
    <div class="flex justify-end">
      <button class="w-32 btn" @click="deleteEcoSystem" :disabled='loading' v-if="ecoSystemBody.status == 'Pending' || ecoSystemBody.status == 'Active'">
        {{ $t('eco.modal.modal_delete') }}
        <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
      </button>
       <button class="w-32 btn btn-primary" @click="activeEcoSystem" :disabled='loading' v-if="ecoSystemBody.status == 'Pending'">
        {{ $t('eco.modal.modal_active') }}
        <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
      </button>
      <button class="w-32 btn btn-primary" @click="saveEcoSystem" :disabled='loading'>
        {{ $t('eco.modal.modal_save') }}
        <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
      </button>
    </div>
    <hr class="my-8"/>
        </div>
    </div>
  </div>
 </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {sysAxios, appAxios} from "@/plugins/axios";
import moment from "moment";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import companyService from "@/services/companyService";
import configService from "@/services/configService";
import workflowService from "@/services/workflowService";

export default {
   props: {
    callback: {
      type: Function,
    },
    editObject:{
      type: Object
    }
  },
  setup(props) {
    const ecoSystemBody = computed(()=> { return props.editObject});

    const dateFormat = process.env.VUE_APP_DATE_FORMAT;
    const loading = ref(true)

     const rules = computed(() => {
      return {
        name: { required },
        buyerCompanyId: { required },
        sellerCompanyId: { required },
        funderCompanyId: { required },
        sellerCredit: { required },
        buyerCredit: { required },
        interestRate: { required },
        interestRateDuration: { required },
        baseCurrencyCode: { required },
        expiredDate:{ required },
        buyerLedWorkflowId: { required },
        sellerLedWorkflowId: { required },
      }
    })
    const workflowLists = ref({})
    const systemCompanies = ref([])
    const funderCompanies = ref([])
    const currencies = ref([])
    const interestRateDuration = ref([])

    const $v = useVuelidate(rules.value, ecoSystemBody);
    const deleteEcoSystem = async () => {
      ecoSystemBody.value.status ='Deleted';
      updateEcosystem()
    }
    const activeEcoSystem = async () => {
      ecoSystemBody.value.status ='Active';
      updateEcosystem()
    }
    const saveEcoSystem = async () => {
      loading.value = true;
      $v.value.$touch();
      if(!$v.value.$invalid) {
        if(!ecoSystemBody.value.editMode) {
          ecoSystemBody.value.buyerCompanyName = systemCompanies.value.filter(c => c.companyId == ecoSystemBody.value.buyerCompanyId)[0].companyDisplayName;
          ecoSystemBody.value.sellerCompanyName = systemCompanies.value.filter(c => c.companyId == ecoSystemBody.value.sellerCompanyId)[0].companyDisplayName;
          ecoSystemBody.value.funderCompanyName = funderCompanies.value.filter(f => f.companyId == ecoSystemBody.value.funderCompanyId)[0].companyDisplayName;
          ecoSystemBody.value.expiredDate = moment.utc(ecoSystemBody.value.expiredDate).format();
          updateEcosystem()
        }
        else {
          updateEcosystem()
        }
      }
      loading.value = false;
    };
    const updateEcosystem = async () => {
      await companyService.updateEcoSystem(ecoSystemBody.value)
      cash("#relationship-modal").modal("hide");
      props.callback()
    }
    const init = async () => {
      const companyProfileSystemConfig = await configService.getCompanyProfileSystemConfig().then(res => res)
      const genieSystemConfigure = await configService.getGenieGlobalSettings().then(res => res)
      currencies.value = companyProfileSystemConfig.currencies
      interestRateDuration.value = genieSystemConfigure.interestRateDuration
      systemCompanies.value = await companyService.getCompaniesByType('Company').then(res => res.data)
      funderCompanies.value = await companyService.getCompaniesByType('Funder').then(res => res.data)
      workflowLists.value = await workflowService.getWorkflowLists(res => res.data)
      loading.value = false
    }

    onMounted(async () => {
      init()
    })
    return {
      $v,
      rules,
      saveEcoSystem,
      deleteEcoSystem,
      activeEcoSystem,
      workflowLists,
      loading,
      dateFormat,
      currencies,
      interestRateDuration,
      systemCompanies,
      funderCompanies,
      ecoSystemBody
    }
  },
}
</script>
