import { sysAxios, appAxios } from '@/plugins/axios'
import _ from 'lodash'

export const login = (payload) => {
  return new Promise((resolve, reject) => {
  	const api = '/user/v1/auth';
		sysAxios.post(api, payload).then(async response => {
			if(response.status === 'error') reject(response)
      resolve(response)
		})
	})
}

export const register = (formData) => {
  return new Promise((resolve, reject) => {
    appAxios.post('/company/v1/user', formData).then(async res => {
      if(res.status === 201) resolve(res)
      else reject(res.error.response.data)
    })
  })
}

export const forgotPassword = (emailAddress) => {
  return new Promise((resolve, reject) => {
    const api = `user/v1/${emailAddress}/resetpassword`;
    sysAxios.post(api, {applicationDomain: 'genie'}).then(res => {
      if(res.status === 200) resolve(res)
      else reject(res.error.response.data)
    })
  })
}

export const resetPasswordCheckValidity = (checkValidity) => {
  return new Promise(async (resolve, reject) => {
    const api = `user/v1/resetpassword/${checkValidity}/checkvalidity`;
    try {
      const response = await sysAxios.post(api)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const resetPassword = (userId, newPassword) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `/user/v1/${userId}/updatesecret`;
      const response = await sysAxios.put(api, {newSecret: newPassword})
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const updatePassword = (userId, newPassword) => {
  return new Promise(async (resolve, reject) => {
    const api = `/user/v1/${userId}/updatesecret`;
    const response = await sysAxios.put(api, {newSecret: newPassword})
    if(response.status === 200) resolve(response)
    else reject(response.error.response.data)
  })
}
export const getUsers = () => {
  const api = "user/v1/"
  return sysAxios.get(api)
}

export const getUserProfileByUserID = (userId) => {
  const account = `user/v1/${userId}`;
  return sysAxios.get(account);
}

export const updateUserProfile = (userId, profile) => {
  const api = `/user/v1/${userId}`
  // console.log('api: ', api)
  return sysAxios.put(api, profile)
}

export const createUser = ({formData, roles}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = "/user/v1";
      const createResponse = await sysAxios.post(api, formData)
      if(createResponse.status === 'error') reject(createResponse.error.response.data)
      else {
        const grantUserRoleAPI = "/access/v1/authorization"
        const grantUserRoleResponse = await sysAxios.post(grantUserRoleAPI, {userId: createResponse.data, applicationDomain: "genie", roleIds: roles})
        if(grantUserRoleResponse.status === 'error') reject(grantUserRoleResponse.error.response.data)
        else resolve(grantUserRoleResponse)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteUser = (userId) => {
  const api = `user/v1/${userId}`;
  return sysAxios.delete(api)
}

export const getRoles = () => {
  const api = "access/v1/role";
  return sysAxios.get(api)
}

export const isGuest = (userId) => {
  return new Promise(async resolve => {
    const api = `access/v1/authorization/user/${userId}`
    const response = await sysAxios.get(api)
    if(_.find(response.data.roles, {roleName: 'guest'})) resolve(true)
    else resolve(false)
  })
}

export default {
  login,
  register,
  forgotPassword,
  resetPasswordCheckValidity,
  resetPassword,
  updatePassword,
  getUsers,
  getUserProfileByUserID,
  updateUserProfile,
  createUser,
  deleteUser,
  getRoles,
  isGuest
}
