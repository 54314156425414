<template>
  <div class="">
    <div class="font-medium text-2xl">KYC Documents</div>
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12">
        <table class="table table--lg">
          <thead>
            <tr class="bg-theme-2">
              <th class="whitespace-nowrap w-1/5">DOCUMENT CATEGORY</th>
              <th class="whitespace-nowrap w-3/5">STATUS</th>
              <th class="whitespace-nowrap text-center w-1/12">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr class="even:bg-gray-200" v-for="(item, index) in docList" :key="index">
              <td class="">{{ item.category }}</td>
              <td class="">
                <div class="alert alert-warning-soft show flex items-center justify-center h-5 p-3 text-sm" role="alert">
                  {{ item.status }}
                </div>
              </td>
              <td class="">
                <div class="grid grid-cols-2">
                  <div class="flex justify-center">
                    <UploadIcon class="w-4 h-4" @click="openFileUploadModal(index)"/>
                  </div>
                  <div class="flex justify-center" v-if="item.corporateInfoHeaderId">
                    <MinusCircleIcon class="w-4 h-4" @click="removeDoc(index)"/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="intro-y col-span-12 flex items-center justify-center sm:justify-start mt-5">
        <button class="btn bg-red-600 w-24 ml-2 text-white">Save</button>
        <button class="btn bg-purple-800 w-42 ml-2 text-white" @click="submit">
          Submit for Approval
        </button>
      </div>
    </div>
    <!-- BEGIN: File Upload Modal Content -->
    <div id="kyc-doc-file-upload" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">File Upload</h2>
          </div>
          <!-- END: Modal Header -->
          <div class="m-8">
            <div>
              <div v-bind="getRootProps()" class="flex justify-center border-red-400 border-dashed border-2 rounded-lg cursor-pointer">
                <div class="text-center py-5">
                  <template v-if="!files">
                    <input v-bind="getInputProps()" />
                    <UploadCloudIcon class="w-24 h-20 text-red-400" />
                    <div class="text-lg font-medium text-gray-600">
                      Drag and drop here<br />or
                    </div>
                    <div class="text-red-400">browse</div>
                  </template>
                  <template v-else>
                    <div class="relative">
                      <div class="absolute top-0 right-1">
                        <XCircleIcon @click="removeFile" class="w-6 h-6" />
                      </div>
                      <FileTextIcon class="w-24 h-24" />
                    </div>
                    {{ files[0].name }}
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button type="button" @click="save" class="btn bg-red-400 w-full text-white">Save</button>
          </div>
          <!-- END: Modal Footer   -->
        </div>
      </div>
    </div>
    <!--END: File Upload Modal Content -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref, onMounted, reactive } from 'vue'
import _ from 'lodash'
import { useDropzone } from 'vue3-dropzone'
import companyService from '@/services/companyService'
import configService from '@/services/configService'
import fileService from '@/services/fileService'
import i18n from '@/plugins/i18n';

export default {
	setup() {
    const store = useStore()
    const setDocIndex = ref()
    const docList = ref([])
    const files = ref()

    const onDrop = (acceptFiles, rejectReasons) => {
      files.value = acceptFiles
    }

    const options = reactive({
      multiple: true,
      onDrop,
      accept: '.jpg, .csv, .txt, .pdf, .docx, .xlsx',
    })

    const { getRootProps, getInputProps, ...rest } = useDropzone(options)

    onMounted(async () => {
      const companyProfileSystemConfig = await configService.getCompanyProfileSystemConfig()
      companyProfileSystemConfig.kycDocumentCategory.forEach(item => {
        docList.value.push({
            corporateInfoHeaderId: '',
            category: item,
            status: 'waiting for document upload',
          })
      })
      const response = await companyService.getCompanyCorporateinfo(store.state.account.company_uuid)
      for(let index in response.data){
        docList.value[_.findIndex(docList.value, {category: response.data[index].corporateInfoDocumentCategoryName})] = {
          corporateInfoHeaderId: response.data[index].corporateInfoHeaderId,
          category: response.data[index].corporateInfoDocumentCategoryName,
          status: response.data[index].approvalStatus
        }
      }
    })

    const openFileUploadModal = (index) => {
      setDocIndex.value = index
      cash('#kyc-doc-file-upload').modal('show')
    }

    const submit = () => {
      companyService.requestCompanyApprove(store.state.account.company_uuid, store.state.auth.user_id)
    }

    const removeFile = () => {
      files.value = null
    }

    const save = async () => {
      let formData = new FormData()
      formData.append('file', files.value[0])
      try {
        const fileId = await fileService.fileUpload('kyc', formData)
        if(docList.value[setDocIndex.value].corporateInfoHeaderId){
          await companyService.updateCompanyCoporateInfo(store.state.account.company_uuid, docList.value[setDocIndex.value].corporateInfoHeaderId, fileId)
          files.value = null
          cash('#kyc-doc-file-upload').modal('hide')
        } else {
          const response = await companyService.setCompanyCoporateInfo(store.state.account.company_uuid, docList.value[setDocIndex.value].category, fileId)
          files.value = null
          docList.value[setDocIndex.value].corporateInfoHeaderId = response.data[0]
          docList.value[setDocIndex.value].status = 'Pending'
          cash('#kyc-doc-file-upload').modal('hide')
        }
      } catch (err) {
        toast({status: "error", title: i18n.global.t('account.kyc.kyc_doc_fail'), content: err})
      }
    }

    const removeDoc = async (index) => {
      await companyService.unsubscribeCoporateInfo(store.state.account.company_uuid, docList.value[index].corporateInfoHeaderId)
      docList.value[index].corporateInfoHeaderId = ''
      docList.value[index].status = 'waiting for document upload'
    }

    return {
      submit,
      openFileUploadModal,
      docList,
      save,
      removeDoc,
      getRootProps,
      getInputProps,
      files,
      removeFile,
      ...rest,
    }
	},
}
</script>
