const state = () => {
  return {
    darkMode: false,
    menu: [],
    permissions: [],
    ecoSystem: [],
    locale: {
      name: 'English',
      key: 'en'
    },
    defaultEcosystem: {
      ecosystemId: '00000000-0000-0000-0000-000000000000',
      name: 'Public ecosystem',
      langName: 'main.ecosystem.ecosystem_public_ecosystem'
    },
    locales: [
    {
      name: 'English',
      key: 'en'
    },
    {
      name: '日本語',
      key: 'ja'
    },
    {
      name: 'ไทย',
      key: 'th'
    }
    ],
    provenanceHistoryUpdateNeed: false
  };
};

export default state;
