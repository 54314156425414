<template>
  <div v-if='loading' class='py-16'>
    <div class='w-full h-8 px-8'>
      <LoadingIcon icon='spinning-circles' color='gray' class='w-4 h-4 py-8' />
    </div>
  </div>
  <div v-else class='report-timeline mt-5 relative'>
    <div v-for='(item, index) in provenance' class='intro-x relative flex items-start pb-5' :key='index'>
      <div class='w-6 h-6 shadow-lg flex-none image-fit rounded-full overflow-hidden bg-gray-500 ml-2'></div>
      <div class='px-5 ml-4 flex-1'>
        <div v-if='item.loading' class='flex items-center'>
          <div
            class='alert show flex items-center h-5 p-3 text-sm justify-center alert-secondary'
            role='alert'
          >
            <LoadingIcon icon="puff" color="gray" class="w-3 h-3 mr-2" />
            <span class='pr-3'>{{ $t('invoice.detail.provenance.provenance_verifying') }}</span>
          </div>
        </div>
        <div v-else class='flex items-center'>
          <div
            v-if="item.state == 'Completed'"
            :class="`alert show flex items-center h-5 p-3 text-sm justify-center ${item.verified? 'bg-green-200' : 'bg-red-300' }`"
            role='alert'
          >
            <ShieldIcon v-if="item.verified && !item.loading" class="w-3 h-3 mr-3"/>
            <ShieldOffIcon v-else class="w-3 h-3 mr-3" />
            <span v-if="item.loading" class="pr-3">
              <span>{{ $t('invoice.detail.provenance.provenance_verifying') }}</span>
            </span>
            <span v-else>
              <span v-if="item.verified">{{ $t('invoice.detail.provenance.provenance_verified') }}</span>
              <span v-else>{{ $t('invoice.detail.provenance.provenance_not_verified') }}</span>
            </span>
          </div>
          <div
            v-else
            :class="`alert show flex items-center h-5 p-3 text-sm justify-center ${item.firstPending && batchDetails.batchStatus != 'Expired' ? 'bg-yellow-200' : 'bg-gray-200'}`"
            role='alert'
          >
            <SendIcon class='w-3 h-3 mr-3'/>
            <span v-if="item.firstPending && batchDetails.batchStatus != 'Expired'" class='pr-3'>{{ $t('invoice.detail.provenance.provenance_pending') }}</span>
            <span v-else class='w-20'>{{ $t('invoice.detail.provenance.provenance_not_started') }}</span>
          </div>
          <div class='items-center'>
            <!-- <p class='font-bold pl-3 text-ellipsis'>{{ProvenanceLang[item.statusName]}}</p> -->
            <p class='font-bold pl-3 text-ellipsis'>{{ $t('dashboard.dashboard_'+item.statusName.replace(/\ /g, '_').toLowerCase()) }}</p>
            <div v-if='item.updateTime != undefined && item.updateTime != "0001-01-01T00:00:00"' class='text-gray-500 ml-3'>
              {{ $t('invoice.detail.provenance.provenance_updated') }}  {{moment(item.updateTime).format(dateTimeFormat)}}<br />
              {{ $t('invoice.detail.provenance.provenance_hash') }} <span class="cursor-pointer">{{item.transactionHash && item.transactionHash.substring(0,25)}}.....</span>
            </div>
            <div class='text-gray-500 ml-3' v-if="batchDetails.bidEndTime != undefined && item.statusName=='INVITATION_SEND_TO_FUNDERS'">
              {{ $t('invoice.detail.provenance.provenance_approval') }} {{moment(batchDetails.bidEndTime).format(dateTimeFormat)}}
            </div>
          </div>
        </div>
        <hr class='mt-5' />
      </div>
    </div>
  </div>
</template>
<script>

import {ref, onMounted, watchEffect} from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import ProvenanceLang from '@/utils/provenanceLanguage'
import { sysAxios } from '@/plugins/axios'
import workflowService from '@/services/workflowService'
import ledgerService from '@/services/ledgerService'
import traceabilityService from '@/services/traceabilityService'
import journalBatchService from '@/services/journalBatchService'

export default {
  props: {
    batchDetails: {
      type: Object,
      required: true
    },
    paymentAdviceWorksStatus: {
      type: Object,
      required: true
    },
    journalBatchHeaderId: {
      type: String,
      required: true
    },
    journalBatchEntryId: {
      type: String,
      required: true
    },
    lastWorkStatus: {
      type: Object,
      required: true
    },
    currentWorkflow: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const loading = ref(true)
    const provenance = ref([])
    const documents = ref([]);
    const batchDetails = ref(props.batchDetails)
    const paymentAdviceWorksStatus = ref(props.paymentAdviceWorksStatus)
    const lastWorkStatus = ref(props.lastWorkStatus)
    const currentWorkflow = ref(props.currentWorkflow)
    const provenancePendingStatusIndex = ref(0)
    const verifyRequestBody = ref({
      batchId: props.batchDetails.batchNumber,
      batchTotal: props.batchDetails.batchInformation.totalAmount,
      batchCurrency: props.batchDetails.currencyCode,
      batchEntriesBreakup: [{
        entryType: 'INV',
        entryQuantity: props.batchDetails.numberOfBatchEntries
      }],
      batchEntries: [],
      TransactionWorkflowStatuses: []
    })
    const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT

    const provenanceApi = async() => {
      loading.value = true
      provenance.value = []
      lastWorkStatus.value = await workflowService.getLastWorkflowStatus([batchDetails.value.workflowExecutionReferenceId])
      const currentWorkflowStatus = await workflowService.getCurrentWorkflowStatus(batchDetails.value.workflowExecutionReferenceId)
      paymentAdviceWorksStatus.value = _.find(props.paymentAdviceWorksStatus, {WorkflowId: currentWorkflowStatus.rootWorkflowId}).StatusNames
      provenancePendingStatusIndex.value = currentWorkflowStatus.workflows.length
      currentWorkflowStatus.workflows.map(item => {
        let subProvenance = item.statusTransitions
        subProvenance = subProvenance.sort((a, b) => {
          if(a.order === b.order) return 0
          else if(a.order > b.order) return 1
          else return -1
        })
        provenance.value.push(...subProvenance)
      })

      for(var i = 0; i < provenance.value.length - 1; i++) {
        if( provenance.value[i].statusName === lastWorkStatus.value.statusName ) provenance.value[i + 1]['firstPending'] = true
      }

      documents.value = await journalBatchService.getBatchEntrySupportDocuments({journalBatchHeaderId: props.journalBatchHeaderId, journalBatchEntryId: props.journalBatchEntryId})
      const paymentAdviceData = await ledgerService.getPaymentAdvice(batchDetails.value.workflowExecutionReferenceId)
      verifyRequestBody.value.TransactionWorkflowStatuses = []
      await Promise.all(
        provenance.value.map(async status => {
          var paymentAdvice = null
          if(status.state === 'Completed') {
            if(paymentAdviceWorksStatus.value.includes(status.statusName) && paymentAdviceData.length) {
              let paymentAdviceEntity = paymentAdviceData.filter(workflow => workflow.extraData.workflowStatusName === status.statusName)
              if(paymentAdviceEntity.length){
                await sysAxios.get(`${paymentAdviceEntity[0].paymentAdviceUri}/info`).then(res => {
                  paymentAdvice = {paymentAdviceFileName: res.data.fileName, dataHash: {...res.data.dataHash}}
                })
                verifyRequestBody.value.TransactionWorkflowStatuses.push({
                  entryHash: status.entryHash,
                  metadata: {
                    batchId: batchDetails.value.batchNumber,
                    parentEntryHash: batchDetails.value.traceId,
                    status: status.statusName,
                    identity: status.updateBy,
                  }
                })
              }
            } else {
              verifyRequestBody.value.TransactionWorkflowStatuses.push({
                entryHash: status.entryHash,
                metadata: {
                  batchId: batchDetails.value.batchNumber,
                  parentEntryHash: batchDetails.value.traceId,
                  status: status.statusName,
                  identity: status.updateBy,
                }
              })
            }
          }
        })
      )
      let workStatusList = []
      verifyRequestBody.value.TransactionWorkflowStatuses.forEach(async (workStatus, index) => {
        provenance.value[index].loading= true
        Object.keys(workStatus).forEach(async k => (workStatus[k] == null || typeof workStatus[k] == 'undefined') && delete workStatus[k])
        workStatusList.push(workStatus)
      })
      const statusVerifications = await traceabilityService.verifyWorkflowStatus({traceId: batchDetails.value.traceId, workStatusList: workStatusList})
      statusVerifications.forEach((verification, index) => {
        provenance.value[index].transactionHash = verification.entryHash
        provenance.value[index].verified = verification.verification
        provenance.value[index].loading= false
      })

      // console.log(provenance)
    }

    const init = async() => {
      await provenanceApi()
      loading.value = false
      store.dispatch('main/UpdatedProvenanceHistory')
    }

    watchEffect(() => {
      if(store.state.main.provenanceHistoryUpdateNeed) {
        init()
      }
    })

    onMounted(() => {
      init()
    })

    return {
      loading,
      provenance,
      ProvenanceLang,
      moment,
      dateTimeFormat
    }
  },
}
</script>
