<template>
  <div class="intro-y col-span-12 mt-5">
    <div class="intro-y box p-8 pt-3">
      <h2 class="text-lg font-medium mr-auto">{{ $t('onboard.onboard_onboard') }}</h2>
      <form class="validate-form pt-6 grid grid-cols-7 gap-4" @submit.prevent="invite">
        <div class="input-form col-span-12 lg:col-span-2" v-if='isCompany'>
          <label for="vender-code" class="form-label w-full flex flex-col sm:flex-row">
            {{ $t('account.company.company_reg_no') }}
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('onboard.onboard_required') }}</span>
          </label>
          <input type="text" v-model="inviteNewPayload.parameters[0].reg_no" class="form-control" :placeholder="$t('account.company.company_reg_no')"/>
        </div>
        <div class="input-form col-span-12 lg:col-span-2">
          <label for="vender-code" class="form-label w-full flex flex-col sm:flex-row">
            {{ $t('onboard.onboard_company_display_name') }}
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('onboard.onboard_required') }}</span>
          </label>
          <input type="text" v-model="inviteNewPayload.parameters[0].value" class="form-control" :placeholder="$t('onboard.onboard_ph_display')"/>
        </div>
        <div class="input-form col-span-12 lg:col-span-2">
          <label for="seller-email" class="form-label w-full flex flex-col sm:flex-row">
            {{ $t('onboard.onboard_company_email') }}
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('onboard.onboard_required') }}</span>
          </label>
          <input type="email" v-model="inviteNewPayload.to" class="form-control" placeholder="seller@email.com"/>
        </div>
        <div class="col-span-12 lg:col-span-1 items-end flex">
          <button class="btn btn-primary w-full mx-5" @click="inviteNewCompany">{{ $t('onboard.onboard_invite') }}</button>
        </div>
      </form>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='isCompany'>
      <div class="flex flex justify-between cursor-pointer" @click="showTable($event)" style="align-items: center;">
        <p class="text-2xl">{{ $t('onboard.onboard_pending_approval') }}</p>
        <ChevronDownIcon />
      </div>
      <div class="overflow-x-scroll xl:overflow-x-visible">
        <table class="table table--lg border-l border-r mt-4 table-auto xl:table-fixed">
          <thead>
            <tr class="bg-theme-2">
              <th class="dark:border-dark-5 whitespace-nowrap w-2/7"> {{ $t('onboard.onboard_company_name') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_email') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr class="even:bg-gray-100" v-for="company in companyInvitations" :key="company.companyId">
              <td class="dark:border-dark-5">{{company.displayName}}</td>
              <td class="dark:border-dark-5">{{company.registrationNo}}</td>
              <td class="dark:border-dark-5">{{company.email}}</td>
              <td class="dark:border-dark-5">
                <div class="alert show flex items-center h-5 p-3 text-sm justify-center text-yellow-700 bg-yellow-100" role="alert">
                  {{ $t('onboard.onboard_pending') }}
                </div>
              </td>
              <td class="dark:border-dark-5 flex justify-center">
                <button @click="approveInvitation(company.circleId)" class="btn btn-primary w-24 inline-block"> Approve </button>
                <button @click="declineInvitation(company.circleId)" class="btn btn-primary w-24 inline-block ml-2"> Reject </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center pt-4 text-gray-700" v-if="companyInvitations.length < 1">{{ $t('onboard.onboard_no_data') }}</div>
      </div>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='isCompany'>
      <div class="flex flex justify-between cursor-pointer" @click="showTable($event)" style="align-items: center;">
        <p class="text-2xl">{{ $t('onboard.onboard_pending_request') }}</p>
        <ChevronDownIcon />
      </div>
      <div class="overflow-x-scroll xl:overflow-x-visible">
        <table class="table table--lg border-l border-r mt-4 table-auto xl:table-fixed">
          <thead>
            <tr class="bg-theme-2">
              <th class="dark:border-dark-5 whitespace-nowrap w-2/7"> {{ $t('onboard.onboard_company_name') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_email') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr class="even:bg-gray-100" v-for="company in pendingRequestCompanies" :key="company.companyId">
              <td class="dark:border-dark-5">{{company.displayName}}</td>
              <td class="dark:border-dark-5">{{company.registrationNo}}</td>
              <td class="dark:border-dark-5">{{company.email}}</td>
              <td class="dark:border-dark-5">
                <div class="alert show flex items-center h-5 p-3 text-sm justify-center text-yellow-700 bg-yellow-100" role="alert">
                  {{ $t('onboard.onboard_pending') }}
                </div>
              </td>
              <td class="dark:border-dark-5 flex justify-center">
                <div class='grid grid-cols-1'>
                  <div class='flex justify-center'>
                    <MinusCircleIcon class='w-4 h-4 text-theme-6' @click='removeCircle(company.circleId)'/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center pt-4 text-gray-700" v-if="pendingRequestCompanies.length < 1">{{ $t('onboard.onboard_no_data') }}</div>
      </div>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='isCompany'>
      <div class="flex flex justify-between cursor-pointer" @click="showTable($event)" style="align-items: center;">
        <p class="text-2xl">{{ $t('onboard.onboard_onboared') }}</p>
        <ChevronDownIcon />
      </div>
      <div class="overflow-x-scroll xl:overflow-x-visible">
        <table class="table table--lg border-l border-r mt-4 table-auto xl:table-fixed">
          <thead>
            <tr class="bg-theme-2">
              <th class="dark:border-dark-5 whitespace-nowrap w-2/7"> {{ $t('onboard.onboard_company_name') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_email') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr class="even:bg-gray-100" v-for="company in onboardedCompanies" :key="company.companyId">
              <td class="dark:border-dark-5">{{company.displayName}}</td>
              <td class="dark:border-dark-5">{{company.registrationNo}}</td>
              <td class="dark:border-dark-5">{{company.email}}</td>
              <td class="dark:border-dark-5">
                <div 
                  :class="`alert show flex items-center h-5 p-3 text-sm justify-center text-green-700 bg-green-100`"
                  role="alert">
                  Onboarded
                </div>
              </td>
              <td class="dark:border-dark-5">
                <div class='grid grid-cols-1'>
                  <div class='flex justify-center'>
                    <MinusCircleIcon class='w-4 h-4 text-theme-6' @click='removeCircle(company.circleId)'/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center pt-4 text-gray-700" v-if="onboardedCompanies.length < 1">{{ $t('onboard.onboard_no_data') }}</div>
      </div>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='isCompany'>
      <div class="flex flex justify-between cursor-pointer" @click="showTable($event)" style="align-items: center;">
        <p class="text-2xl">{{ $t('onboard.onboard_rejected_request') }}</p>
        <ChevronDownIcon />
      </div>
      <div class="overflow-x-scroll xl:overflow-x-visible">
        <table class="table table--lg border-l border-r mt-4 table-auto xl:table-fixed">
          <thead>
            <tr class="bg-theme-2">
              <th class="dark:border-dark-5 whitespace-nowrap w-2/7"> {{ $t('onboard.onboard_company_name') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_email') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
              <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr class="even:bg-gray-100" v-for="company in rejectedRequestCompanies" :key="company.companyId">
              <td class="dark:border-dark-5">{{company.displayName}}</td>
              <td class="dark:border-dark-5">{{company.registrationNo}}</td>
              <td class="dark:border-dark-5">{{company.email}}</td>
              <td class="dark:border-dark-5">
                <div class="alert show flex items-center h-5 p-3 text-sm justify-center bg-red-500 text-white" role="alert">
                  Rejected
                </div>
              </td>
              <td class="dark:border-dark-5 flex justify-center">
                <div class='grid grid-cols-1'>
                  <div class='flex justify-center'>
                    <MinusCircleIcon class='w-4 h-4 text-theme-6' @click='removeCircle(company.circleId)'/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center pt-4 text-gray-700" v-if="rejectedRequestCompanies.length < 1">{{ $t('onboard.onboard_no_data') }}</div>
      </div>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='!isCompany'>
      <p class="text-2xl pb-4">{{ $t('onboard.onboard_onboared') }}</p>
      <table class="table table--lg border-l border-r">
        <thead>
          <tr class="bg-theme-2">
            <th class="dark:border-dark-5 whitespace-nowrap w-2/6"> {{ $t('onboard.onboard_company_name') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_credit') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_start') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_end') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr class="even:bg-gray-100" v-for="company in onboardedCompanies" :key="company.relationshipId">
            <td class="dark:border-dark-5">{{company.companyLegalName}}</td>
            <td class="dark:border-dark-5">{{company.registrationNo}}</td>
            <td class="dark:border-dark-5">{{company.creditLimit}}</td>
            <td class="dark:border-dark-5">{{moment(company.startDate).format(dateFormat)}}</td>
            <td class="dark:border-dark-5">{{moment(company.endDate).format(dateFormat)}}</td>
            <td class="dark:border-dark-5">
              <div
                :class="`alert show flex items-center h-5 p-3 text-sm justify-center text-green-700 bg-green-100
                ${company.approvalStatus == 'Approved' ? 'text-green-700 bg-green-100' : 'text-green-700 bg-green-100'}`"

                role="alert">
                {{company.approvalStatus}}
              </div>
            </td>
            <td class="dark:border-dark-5 flex justify-center">
              <button class="btn btn-outline-primary w-24 inline-block" v-if="company.approvalStatus != 'Approved'"> {{ $t('onboard.onboard_reinvite') }} </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="intro-y col-span-12 mt-10 box p-4" v-if='!isCompany'>
      <p class="text-2xl pb-4">{{ $t('onboard.onboard_available') }}</p>
      <table class="table table--lg border-l border-r">
        <thead>
          <tr class="bg-theme-2">
            <th class="dark:border-dark-5 whitespace-nowrap w-2/6"> {{ $t('onboard.onboard_company_name') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_company_code') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_email') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_display_name') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7"> {{ $t('onboard.onboard_status') }} </th>
            <th class="dark:border-dark-5 whitespace-nowrap w-1/7 text-center"> {{ $t('onboard.onboard_actions') }} </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr class="even:bg-gray-100" v-for="company in availableCompanies" :key="company.companyId">
            <td class="dark:border-dark-5">{{company.companyLegalName}}</td>
            <td class="dark:border-dark-5">{{company.registrationNo}}</td>
            <td class="dark:border-dark-5">{{company.primaryEmail}}</td>
            <td class="dark:border-dark-5">{{company.companyDisplayName}}</td>
            <td class="dark:border-dark-5">
              <div class="alert show flex items-center h-5 p-3 text-sm justify-center text-yellow-700 bg-yellow-100" role="alert">
                {{ $t('onboard.onboard_pending') }}
              </div>
            </td>
            <td class="dark:border-dark-5 flex justify-center">
              <button @click="openCompanyInviteModal(company)" class="btn btn-primary w-24 inline-block"> {{ $t('onboard.onboard_invite') }} </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="invite-company-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto"> {{ $t('onboard.onboard_invite_company') }} </h2>
          </div>
          <!-- END: Modal Header -->
          <div class="modal-body mx-8">
            <div class="grid grid-cols-2 grid-flow-row gap-4">
              <div class="self-center">{{ $t('onboard.onboard_currency_code') }}</div>
              <div class="self-center">
                <select v-model="invitePayload.currencyCode" class="form-select">
                  <option v-for="(currency, currency_index) in currencies" :key="currency_index">
                    {{currency.currencyCode}}
                  </option>
                </select>
              </div>
              <div class="self-center">{{ $t('onboard.onboard_credit_limit') }}</div>
              <div class="self-center">
                <input type="number" v-model="invitePayload.creditLimit" class="form-control"/>
              </div>
              <div class="self-center">{{ $t('onboard.onboard_end_date') }}</div>
              <div class="self-center">
                <Litepicker
                  v-model="invitePayload.endDate"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    zIndex: 10001,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button type="button" class="btn btn-primary w-24 mr-1" @click="inviteCompany" :disabled="modalLoading">
              {{ $t('onboard.onboard_invite') }}
              <LoadingIcon v-if="modalLoading" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24"> {{ $t('onboard.onboard_cancel') }} </button>
          </div> <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import moment from "moment";
import toast from "@/utils/toast";
import companyService from "@/services/companyService";
import communicationService from "@/services/communicationService";
import configService from "@/services/configService";
import i18n from '@/plugins/i18n';
import $ from 'jquery'

export default {
  setup() {
    const store = useStore();
    const invitePayload = ref({
      companyId: '',
      currencyCode: '',
      creditLimit: 1000,
      endDate: new Date(),
      approvalStatus: 'Pending'
    });
    const inviteNewPayload = ref({
      emailTemplateId: "",
      to: "",
      cc: "string",
      subject: "string",
      parameters: [
        {
          name: "company_name",
          value: "",
          reg_no: ""
        }
      ],
      whenToSend: new Date(),
      onCompleteEventHandler: null
    })
    const emailTemplate = ref("");
    const currencies = ref([]);
    const onboardedCompanies = ref([]);
    const availableCompanies = ref([]);
    const pendingRequestCompanies = ref([]);
    const companyInvitations = ref([]);
    const rejectedRequestCompanies = ref([]);
    const loading = ref(true);
    const modalLoading = ref(false);
    const dateFormat = process.env.VUE_APP_DATETIME_FORMAT;
    const endDate = new Date();
    const isCompany = ref(false)

    const openCompanyInviteModal = (company) => {
      invitePayload.value.companyId = company.companyId
      cash("#invite-company-modal").modal("show")
    }

    const inviteCompany = async () => {
      modalLoading.value = true
      try {
        const invitation = {
          ...invitePayload.value,
          endDate: new Date(invitePayload.value.endDate),
          creditLimit: parseInt(invitePayload.value.creditLimit)
        }
        await companyService.inviteRelationShip({companyId: store.state.account.company_uuid, invitation})
        cash("#invite-company-modal").modal("hide")
        toast({status: "success", title: i18n.global.t('onboard.onboard_invite_success'), content: i18n.global.t('onboard.onboard_invite_success1')})
        init()
      } catch (err) {
        toast({status: "error", title: i18n.global.t('onboard.onboard_invite_fail'), content: err})
      }
      modalLoading.value = false;
    }

    const inviteNewCompany = async () => {
      if (isCompany.value) {
        try {
          await companyService.postCompanyCircles(store.state.account.company_uuid, inviteNewPayload.value.parameters[0].reg_no, inviteNewPayload.value.parameters[0].value, inviteNewPayload.value.to).then((res) => {
            console.log(res)
          })
          inviteNewPayload.value.parameters[0].reg_no = ""
          inviteNewPayload.value.parameters[0].value = ""
          inviteNewPayload.value.to = ""
          toast({status: "success", title: i18n.global.t('onboard.onboard_invite_success'), content: i18n.global.t('onboard.onboard_invite_success1')})
          init()
        } catch (err) {
          toast({status: "error", title: i18n.global.t('onboard.onboard_invite_fail'), content: i18n.global.t('onboard.onboard_s_please')})
        }
      } else {
        const api = 'communications/v1/email/templates/render'
        try {
          await communicationService.inviteNewCompany(inviteNewPayload.value)
          inviteNewPayload.value.parameters[0].value = ""
          inviteNewPayload.value.to = ""
          toast({status: "success", title: i18n.global.t('onboard.onboard_invite_success'), content: i18n.global.t('onboard.onboard_invite_success1')})
          init()
        } catch (err) {
          toast({status: "error", title: i18n.global.t('onboard.onboard_invite_fail'), content: i18n.global.t('onboard.onboard_s_please')})
        }
      }
    }

    const approveInvitation = async (circleId) => {
      try {
        await companyService.updateCircleStatus(circleId, "approved").then((res) => {
          console.log(res)
        })
        toast({status: "success", title: i18n.global.t('onboard.onboard_approve_success'), content: i18n.global.t('onboard.onboard_invite_success3')})
        init()
      } catch (err) {
        toast({status: "error", title: i18n.global.t('onboard.onboard_failed'), content: ""})
      }
    }

    const declineInvitation = async (circleId) => {
      try {
        await companyService.updateCircleStatus(circleId, "rejected").then((res) => {
          console.log(res)
        })
        toast({status: "success", title: i18n.global.t('onboard.onboard_reject_success'), content: i18n.global.t('onboard.onboard_invite_success2')})
        init()
      } catch (err) {
        toast({status: "error", title: i18n.global.t('onboard.onboard_failed'), content: ""})
      }
    }

    const removeCircle = async (circleId) => {
      if (confirm(i18n.global.t('onboard.onboard_remove_company'))) {
        try {
          await companyService.deleteCompanyCircle(circleId).then((res) => {
            console.log(res)
          })
          toast({status: "success", title: i18n.global.t('onboard.onboard_remove_success'), content: i18n.global.t('onboard.onboard_invite_success4')})
          init()
        } catch (err) {
          toast({status: "error", title: i18n.global.t('onboard.onboard_failed'), content: ""})
        }
      }
    }

    const showTable = (event) => {
      let table = $(event.target).next()
      table.slideToggle()
    }

    const getConfig = async () => {
      const companyProfileSystemConfig = await configService.getCompanyProfileSystemConfig()
      const genieGlobalSystemConfig = await configService.getGenieGlobalSettings()
      currencies.value = companyProfileSystemConfig.currencies
      invitePayload.value.currencyCode = 'SGD'
      emailTemplate.value = genieGlobalSystemConfig.platformRegistrationInvitation
      inviteNewPayload.value.emailTemplateId = genieGlobalSystemConfig.platformRegistrationInvitation.emailTemplateId
      inviteNewPayload.value.subject = genieGlobalSystemConfig.platformRegistrationInvitation.subject
      inviteNewPayload.value.cc = genieGlobalSystemConfig.platformRegistrationInvitation.cc

      return new Promise(resolve => {resolve("configuration was done")});
    }

    const init = async () => {
      if (store.state.account.company_type.toLowerCase() == 'company') {
        onboardedCompanies.value = await companyService.getCompanyCircles(store.state.account.company_uuid, "approved").then(res => res.data)
        pendingRequestCompanies.value = await companyService.getCompanyCircles(store.state.account.company_uuid, "pending").then(res => res.data)
        companyInvitations.value = await companyService.getCompanyInvitations(store.state.account.company_uuid, "pending").then(res => res.data)
        rejectedRequestCompanies.value = await companyService.getCompanyInvitations(store.state.account.company_uuid, "rejected").then(res => res.data)

        // console.log(availableCompanies)
        // console.log(pendingRequestCompanies)
        // console.log(companyInvitations)
      } else {
        onboardedCompanies.value = await companyService.getOnboardedCompanies(store.state.account.company_uuid).then(res => res.data)

        availableCompanies.value = await companyService.getCompaniesByType('company').then(res => {
          const companies  = []
          res.data.map(company => {
            const index = _.findIndex(onboardedCompanies.value, {companyId: company.companyId})
            if(index != -1){
              onboardedCompanies.value[index] = {...onboardedCompanies.value[index], ...company}
            } else companies.push(company)
          })
          return companies;
        })

        console.log(availableCompanies)
        console.log(onboardedCompanies)
      }


      return new Promise(resolve => {resolve("component initialize was done")})
    }

    onMounted( async () => {
      await getConfig();
      await init();
      loading.value = false;
      // console.log(store.state.account.company_type)
      if(store.state.account.company_type.toLowerCase() == 'company'){
        isCompany.value = true
      }
    })

    return {
      isCompany,
      onboardedCompanies,
      availableCompanies,
      pendingRequestCompanies,
      companyInvitations,
      rejectedRequestCompanies,
      showTable,
      currencies,
      loading,
      modalLoading,
      endDate,
      dateFormat,
      moment,
      openCompanyInviteModal,
      approveInvitation,
      declineInvitation,
      removeCircle,
      invitePayload,
      inviteNewPayload,
      inviteCompany,
      inviteNewCompany
    }
  }
}
</script>
