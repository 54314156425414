<template>
  <div class="">
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12">
        <table class="table table--lg">
          <thead>
            <tr class="bg-theme-2">
              <th class="whitespace-nowrap">CURRENCY CODE</th>
              <th class="whitespace-nowrap">CURRENCY NAME</th>
              <th class="whitespace-nowrap">CURRENCY SYMBOL</th>
              <th class="whitespace-nowrap">SUPPORT</th>
              <th class="whitespace-nowrap">DEFAULT</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="even:bg-gray-200"
              v-for="(currency, index) in currencies"
              :key="index"
            >
              <td class="">{{ currency.currencyCode }}</td>
              <td class="">{{ currency.currencyName }}</td>
              <td class="">{{ currency.currencySymbol }}</td>
              <td class="">
                <input
                  id="input-wizard-4-currency-eur"
                  v-model="support"
                  type="checkbox"
                  class="form-check-input"
                  :value="currency.currencyCode"
                />
              </td>
              <td class="">
                <input
                  id="input-wizard-4-currency-eur-default"
                  v-model="defaultCurrency"
                  type="radio"
                  class="form-check-input"
                  name="default-currency"
                  :value="currency.currencyCode"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="
          intro-y
          col-span-12
          flex
          items-center
          justify-center
          sm:justify-start
          mt-5
        "
      >
        <button class="btn btn-primary w-24" @click="save">Save</button>
      </div>
    </div>
    <div id="success-notification-content" class="toastify-content hidden">
      <div class="flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Successfly Set Currencies!</div>
        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden">
      <div class="flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Setting Currencies was failed!</div>
          <div class="text-gray-600 mt-1">
            Please fillin correct value of the company profile.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import _ from "lodash";
import toast from "@/utils/toast";
import companyService from "@/services/companyService";
import configService from "@/services/configService";
import { appAxios } from "@/plugins/axios";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const currencyInfo = ref([]);
    const support = ref([]);
    const currencies = ref(null);
    const defaultCurrency = ref(null);
    const requestMethod = ref("post");

    onMounted(async () => {
      const systemConfig = await configService.getCompanyProfileSystemConfig();
      currencies.value = systemConfig.currencies;
      const response = await companyService.getCompanyCurrencies(
        store.state.account.company_uuid
      );
      if (response.data.length) {
        requestMethod.value = "put";
        response.data.forEach((item) => {
          if (item.isDefault) defaultCurrency.value = item.currencyCode;
          support.value.push(item.currencyCode);
        });
        currencies.value = { ...response.data, ...currencies.value };
      }
    });
    const save = () => {
      currencyInfo.value = _.filter(currencies.value, (currency) => {
        if (currency.currencyCode === defaultCurrency.value)
          currency.isDefault = true;
        else currency.isDefault = false;
        return support.value.includes(currency.currencyCode);
      });
      const currencyRegister = `/company/v1/${store.state.account.company_uuid}/currencies`;
      appAxios[requestMethod.value](currencyRegister, currencyInfo.value).then(
        (res) => {
          if (res.status === "error") {
            toast({
              status: "error",
              title: "Setting Currencies was failed!",
              content: res.error.response.data,
            });
          } else {
            toast({
              status: "success",
              title: "Successfully Set Currencies!",
              content: "",
            });
          }
        }
      );
    };
    return {
      currencies,
      support,
      defaultCurrency,
      currencyInfo,
      save,
    };
  },
};
</script>
