<template>
  <div>
    <h1>{{ $t('eco.workflow.workflow_workflow') }}</h1>
  </div>
</template>
<script>
import {ref} from "vue";

export default {
  setup() {
    
  },
}
</script>
