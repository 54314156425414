<template>
  <div class="dark:border-dark-5">
    <div class="font-medium text-2xl">{{ $t('account.currency.currency_info') }}</div>
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12 xl:overflow-x-visible overflow-x-scroll">
        <table class="table table--lg">
          <thead>
            <tr class="bg-theme-2">
              <th class="dark:border-dark-5 whitespace-nowrap">
                {{ $t('account.currency.currency_code') }}
              </th>
              <th class="dark:border-dark-5 whitespace-nowrap">
                {{ $t('account.currency.currency_name') }}
              </th>
              <th class="dark:border-dark-5 whitespace-nowrap">
                {{ $t('account.currency.currency_symbol') }}
              </th>
              <th class="dark:border-dark-5 whitespace-nowrap text-center">{{ $t('account.currency.currency_default') }}</th>
              <th class="dark:border-dark-5 whitespace-nowrap text-center">{{ $t('account.currency.currency_support') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="even:bg-gray-50"
              v-for="(currency, index) in currencies"
              :key="index"
            >
              <td class="dark:border-dark-5">{{ currency.currencyCode }}</td>
              <td class="dark:border-dark-5">{{ currency.currencyName }}</td>
              <td class="dark:border-dark-5">{{ currency.currencySymbol }}</td>
              <td class="dark:border-dark-5 text-center">
                <input
                  id="input-wizard-4-currency-eur-default"
                  v-model="defaultCurrency"
                  type="radio"
                  class="form-check-input"
                  name="default-currency"
                  :value="currency.currencyCode"
                />
              </td>
              <td class="dark:border-dark-5 text-center">
                <input
                  id="input-wizard-4-currency-eur"
                  v-model="support"
                  type="checkbox"
                  class="form-check-input"
                  :value="currency.currencyCode"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="
          intro-y
          col-span-12
          flex
          items-center
          justify-center
          sm:justify-start
          mt-5
        "
      >
        <button class="btn btn-primary w-24" @click="gotoNext">{{ $t('account.currency.currency_save') }}</button>
      </div>
    </div>
    <div id="success-notification-content" class="toastify-content hidden">
      <div class="flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('account.currency.currency_add_success') }}</div>
        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden">
      <div class="flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('account.currency.currency_add_fail') }}</div>
          <div class="text-gray-600 mt-1">
            {{ $t('account.currency.currency_please') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import _ from "lodash";
import toast from "@/utils/toast";
import companyService from "@/services/companyService";
import configService from "@/services/configService"
import { appAxios } from "@/plugins/axios";
import i18n from '@/plugins/i18n';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const currencyInfo = ref([]);
    const support = ref([]);
    const currencies = ref(null);
    const defaultCurrency = ref(null);
    const requestMethod = ref("post");

    onMounted(async () => {
      const systemConfig = await configService.getCompanyProfileSystemConfig();
      currencies.value = systemConfig.currencies;
      const response = await companyService.getCompanyCurrencies(
        store.state.account.company_uuid
      );
      if (response.data.length) {
        requestMethod.value = "put";
        response.data.forEach((item) => {
          if (item.isDefault) defaultCurrency.value = item.currencyCode;
          support.value.push(item.currencyCode);
        });
        currencies.value = { ...response.data, ...currencies.value };
      }
    });
    const gotoBack = () => {
      store.commit("account/SET_STEP", { step: "bank-information" });
      router.go(-1);
    };
    const gotoNext = () => {
      currencyInfo.value = _.filter(currencies.value, (currency) => {
        if (currency.currencyCode === defaultCurrency.value)
          currency.isDefault = true;
        else currency.isDefault = false;
        return support.value.includes(currency.currencyCode);
      });
      // console.log(currencyInfo.value);
      const currencyRegister = `/company/v1/${store.state.account.company_uuid}/currencies`;
      appAxios[requestMethod.value](currencyRegister, currencyInfo.value).then(
        (res) => {
          if (res.status === "error") {
            toast({
              status: "error",
              title: i18n.global.t('account.currency.currency_add_fail'),
              content: res.error.response.data,
            });
          } else {
            toast({
              status: "success",
              title: i18n.global.t('account.currency.currency_add_success'),
              content: "",
            });
          }
        }
      );
    };
    return {
      gotoBack,
      gotoNext,
      currencies,
      support,
      defaultCurrency,
      currencyInfo,
    };
  },
};
</script>
