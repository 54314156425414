<template>
  <router-view></router-view>
</template>

<script>

export default {
  setup() {
  }
}
</script>
