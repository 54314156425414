<template>
  <div class="">
		<div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
			<div class="intro-y col-span-12">
        <table class="table table--lg">
          <thead>
            <tr class="bg-theme-2">
              <th class="whitespace-nowrap"> FIRST NAME </th>
              <th class="whitespace-nowrap"> LAST NAME </th>
              <th class="whitespace-nowrap"> EMAIL </th>
              <th class="whitespace-nowrap"> DISPLAY NAME </th>
              <th class="whitespace-nowrap"> USERNAME </th>
              <th class="whitespace-nowrap"> ACTION </th>
            </tr>
          </thead>
          <tbody v-if="userList.length > 0" >
            <tr class="even:bg-gray-200" v-for="(user, index) in userList" :key="index">
              <td class="">{{user.firstName}}</td>
              <td class="">{{user.lastName}}</td>
              <td class="">{{user.emailAddress}}</td>
              <td class=""> {{user.displayName}}</td>
              <td class=""> {{user.userName}}</td>
              <td class="">
                <div class="grid grid-cols-2">
                    <a
                    :href="'/users/edit/' + user.userId"
                    class="items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                  </a>
                   <a
                    class="items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="deleteUser(user.userId)"
                  >
                    <TrashIcon class="w-4 h-4 mr-2" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
             <tr class="even:bg-gray-200">
              <td class="" colspan="6">No user found.</td>
              </tr>
          </tbody>
        </table>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue"
import _ from "lodash"
import companyService from "@/services/companyService"
import userService from "@/services/userService"

export default {
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
	setup(props) {
    const userList = ref([]);
    onMounted(async () => {
      const response = await companyService.getCompanyUsers(props.companyId)
      if(response.data.length) {
        response.data.forEach(item => {
          userService.getUserProfileByUserID(item.userId).then(res => {
            userList.value.push(res.data);
          })
        });
      }
    })

    const deleteUser = async (userId) => {
      await companyService.unlinkUserToCompany(props.companyId, userId)
      _.remove(userList.value, {userId: userId});
    }

    return {
      userList,
      deleteUser
    }
	},
}
</script>
