/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export default{
    getAccount: state => state.account,
    getCompanyId: state => state.company_uuid,
    getCompanytype: state => state.company_type,
    getCompanyHolidays: state => state.holidays
};
