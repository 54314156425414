import { sysAxios } from '@/plugins/axios'

const getNotificationByCompanyId = (companyId) => {
  return new Promise( async (resolve) => {
    const api = `communications/v1/notification/${companyId}`
    let notifications = []
    notifications = await sysAxios.get(api + '?status=Complete').then(res => {return res.data})
    await sysAxios.get(api + '?status=Read').then(res => {
      notifications.push(...res.data)
    })
    resolve(notifications)
  })
}

const notificationMarkAsRead = (notificationId) => {
  return new Promise( async (resolve, reject) => {
    try{
      const api = `communications/v1/notification/${notificationId}/Read`
      const response = await sysAxios.put(api)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

const notificationMarkReadAll = (notifications) => {
  // console.log("notifications: ", notifications)

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Promise.all(notifications.map( async notification => {
        const api = `communications/v1/notification/${notification.notificationId}/read`
        return await sysAxios.put(api)
      }))
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

const inviteNewCompany = (inviteNewPayload) => {
  return new Promise( async (resolve, reject) => {
    try{
      const api = "communications/v1/email/templates/render"
      const response = await sysAxios.post(api, inviteNewPayload)
      response.status === 'error' ? reject(response.error.response.data) : resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export default {
  getNotificationByCompanyId,
  notificationMarkAsRead,
  notificationMarkReadAll,
  inviteNewCompany
}
