<template>
  <div class='grid grid-cols-12 gap-6'>
    <div class='col-span-12'>
      <div class='grid grid-cols-12 gap-6'>
        <!-- BEGIN: General Report -->
        <div class='col-span-12 mt-8'>
          <div class='intro-y flex items-center h-10'>
            <h2 class='text-lg font-medium truncate mr-5'>{{ $t('dashboard.dashboard_dashboard') }}</h2>
            <a href='' class='ml-auto flex text-theme-1 dark:text-theme-10'>
              <RefreshCcwIcon class='w-4 h-4 mr-3' /> {{ $t('dashboard.dashboard_reload_data') }}
            </a>
          </div>
        </div>
        <!-- END: General Report -->

        <!-- START: Cards Data -->
        <Cards :dashboardData="dashboardData.data"/>
        <!-- END: Cards Data -->

        <!-- STAER: Active Report-->
        <div class='col-span-12 grid grid-cols-3 gap-6 mt-5'>
          <div class='intro-y box p-5'>
            <div class='flex items-center'>
              <ListIcon class='w-6 h-6 mr-3' /><span class='text-lg'>{{ $t('dashboard.dashboard_pending') }}</span>
            </div>
            <div class='report-timeline mt-5 relative' v-if='pendingActions.length > 0'>
              <div class='intro-x relative flex items-start pb-5' v-for='pendingAction in pendingActions' :key='pendingAction.workflowExecutionReferenceId'>
                <div class='w-6 h-6 shadow-lg flex-none image-fit rounded-full overflow-hidden bg-gray-500 ml-2'></div>
                <div class='px-5 ml-4 flex-1'>
                  <div class='items-center'>
                    <!-- <span class='font-bold'>{{ProvenanceLang[pendingAction.action]}}</span> -->
                    <span class='font-bold'>{{ $t('dashboard.dashboard_'+pendingAction.action.replace(/\ /g, '_').toLowerCase()) }}</span>
                    <div class='mt-2'>{{ $t('dashboard.dashboard_invoice') }} {{pendingAction.batchNumber}}</div>
                    <div class='text-gray-500'>{{ $t('dashboard.dashboard_created') }}  {{pendingAction.createdTime}}</div>
                    <a :href='`/invoice/detail/${pendingAction.workflowExecutionReferenceId}`'><button class='mt-2 btn h-6 w-16 bg-pink-700 text-white btn-sm'>{{ $t('dashboard.dashboard_view') }}</button></a>
                  </div>
                </div>
              </div>
            </div>
            <div class='mt-5 relative' v-else>
              <div class='intro-x relative flex items-start pb-5'>
                <div class='px-5 ml-4 flex-1'>
                  <div class='items-center'>
                    {{ $t('dashboard.dashboard_no_pending') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='intro-y'>
            <div class="bg-theme-2">
              <img alt='' class='intro-x w-full h-36 p-5' src='/Calendar-bro.svg' />
            </div>
            <div class='box p-8'>
              <h4 class='text-lg font-bold'>{{ $t('dashboard.dashboard_calendar') }}</h4>
              <div class='mt-3 overflow-y-auto h-48 scroll-primary'>
                <div v-for='holiday in holidays' :key='holiday.holidayCalendarEntryId' class='cursor-pointer relative flex items-center mt-2'>
                  <div class='w-8 mr-1 bg-pink-200 p-1 rounded-md'>
                    <CalendarIcon class='notification__icon dark:text-gray-300 text-pink-700 text-sm' />
                  </div>
                  <div class='ml-2 overflow-hidden'>
                    <div class='flex items-center'>
                      <a href='javascript:;' class='truncate mr-5'>{{moment(holiday.startDate).format(dateFormat)}} ~ {{moment(holiday.endDate).format(dateFormat)}}</a>
                    </div>
                    <div class='flex items-center'>
                      <a href='javascript:;' class='font-medium truncate mr-5'>{{holiday.description}}</a>
                    </div>
                    <div class='w-full truncate text-gray-600 mt-0.5'>{{holiday.label}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ActiveBorrowers v-if="userRole == 'Funder Admin'"/>
        </div>
        <!-- END: Active Report -->
      </div>
    </div>
    <InvoiceUploadModal />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import companyService from '@/services/companyService'
import journalBatchService from '@/services/journalBatchService'
import InvoiceUploadModal from '../invoice/InvoiceUploadModal'
import Cards from './Cards.vue'
import ActiveBorrowers from './ActiveBorrowers.vue'
import ProvenanceLang from '@/utils/provenanceLanguage'

export default defineComponent({
  components: {
    InvoiceUploadModal,
    Cards,
    ActiveBorrowers
  },

  setup() {
    const dateFormat = process.env.VUE_APP_DATE_FORMAT
    const store = useStore()
    const defaultEcosystemId = ref(store.state.main.defaultEcosystem.ecosystemId)
    const company_uuid = store.state.account.company_uuid
    const holidays = store.state.account.holidays
    const pendingActions = ref([])
    const dashboardData = ref({})

    const init = async () => {
      pendingActions.value = []
      let tempBatch = []
      let dashboardApi = ''
      if(defaultEcosystemId.value === '00000000-0000-0000-0000-000000000000') dashboardApi = `/company/v1/${company_uuid}/dashboarddata`
      else dashboardApi = `/company/v1/ecosystem/${defaultEcosystemId.value}/${company_uuid}/dashboarddata`

      if(company_uuid !== '00000000-0000-0000-0000-000000000000') {
        dashboardData.value = await companyService.getCompanyDashboardData({companyId: company_uuid, ecosystemId: defaultEcosystemId.value})
        let pendingItem = dashboardData.value.data.transactionsSnapShot.pendingForAction.groupingByAction
        let pendingAction = {}
        if(pendingItem.length > 0) {
          for(let i = 0; i < pendingItem.length; i++) {
              for(let z = 0; z < pendingItem[i].workflowExecutionids.length; z++){
                const batchData = await journalBatchService.getBatchDetailByExecutionReferenceId(pendingItem[i].workflowExecutionids[z])
                batchData.pendingItemAction = pendingItem[i].action
                batchData.createdTimeUnix = moment(batchData.createdTime).unix()
                tempBatch.push(batchData)
              }
              // console.log(tempBatch)
          }
          tempBatch = _.orderBy(tempBatch, 'createdTimeUnix', 'desc')
          for (var j = 0; j < tempBatch.length; j++) {
            pendingAction = {}
            pendingAction.action = tempBatch[j].pendingItemAction
            pendingAction.batchNumber = tempBatch[j].batchNumber
            pendingAction.workflowExecutionReferenceId = tempBatch[j].workflowExecutionReferenceId
            pendingAction.createdTime = moment(tempBatch[j].createdTime).format(dateFormat)
            pendingAction.initiatedByCompanyName = tempBatch[j].initiatedByCompanyName
            pendingAction.createdTimeUnix = tempBatch[j].createdTimeUnix
            pendingActions.value.push(pendingAction)
          }
        }
        if(store.state.account.company_type.toLowerCase() == 'funder') {
          if(dashboardData.value.data.bidInvitations != null) {
            let pendingBid = dashboardData.value.data.bidInvitations.open
            if(pendingBid.workflowExecutionids.length > 0) {
              for(let i=0;i<pendingBid.workflowExecutionids.length;i++) {
                const batchData = await journalBatchService.getBatchDetailByExecutionReferenceId(pendingBid.workflowExecutionids[i])
                batchData.createdTimeUnix = moment(batchData.createdTime).unix()
                tempBatch.push(batchData)
              }
              console.log(tempBatch)
              tempBatch = _.orderBy(tempBatch, 'createdTimeUnix', 'desc')
              for (var i = 0; i < tempBatch.length; i++) {
                pendingAction = {}
                pendingAction.action = 'BIDDING_IN_PROGRESS'
                pendingAction.batchNumber = tempBatch[i].batchNumber
                pendingAction.workflowExecutionReferenceId = tempBatch[i].workflowExecutionReferenceId
                pendingAction.createdTime = moment(tempBatch[i].createdTime).format(dateFormat)
                pendingAction.initiatedByCompanyName = tempBatch[i].initiatedByCompanyName
                pendingAction.createdTimeUnix = tempBatch[i].createdTimeUnix
                pendingActions.value.push(pendingAction)
              }
            }
          }
        }
        pendingActions.value = _.orderBy(pendingActions.value, 'createdTimeUnix', 'desc')
      }
      console.log(dashboardData.value)
    }

    watchEffect(() => {
      if(store.state.main.defaultEcosystem.ecosystemId !== defaultEcosystemId.value) {
        defaultEcosystemId.value = store.state.main.defaultEcosystem.ecosystemId
        init()
      }
    })

    onMounted(async () => {
      init()
    })

    return {
      dateFormat,
      dashboardData,
      pendingActions,
      holidays,
      moment,
      ProvenanceLang,
      userRole: store.state.auth.user_role
    }
  }
})
</script>
