<template>
  <div>
		<div class='font-medium text-2xl'>{{ $t('account.holidays.holidays_holidays') }}</div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Calendar Content -->
      <div class="col-span-12 xl:col-span-8 xxl:col-span-9">
        <div class="box p-5" v-if="holidays">
          <Calendar :holidays="holidays" :addToRecycleDays="addToRecycleDays" />
        </div>
      </div>
      <!-- END: Calendar Content -->
      <!-- BEGIN: Calendar Side Menu -->
      <div class="col-span-12 xl:col-span-4 xxl:col-span-3">
        <div class="box p-5 intro-y">
          <button type="button" class="btn btn-primary w-full mt-2" @click="openAddLockDaysModal">
            <Edit3Icon class="w-4 h-4 mr-2" /> {{ $t('account.holidays.holidays_add_new') }}
          </button>
          <FullCalendarDraggable
            id="draft-calendar-events"
            :options="dragableOptions"
            class="border-t border-b border-gray-200 dark:border-dark-5 mt-6 mb-5 py-3"
          >
            <div v-for='(lockDay, index) in draftLockDays' :key = 'index' class="relative">
              <div
                class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center"
                :data-event="JSON.stringify(lockDay)"
              >
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <div class="pr-10">
                  <div class="event__title truncate">{{lockDay.title}}</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">{{lockDay.duration.days}}</span> Days
                  </div>
                </div>
              </div>
              <a class="flex items-center absolute top-0 bottom-0 my-auto right-0" @click="openEditLockDaysModal(index)">
                <EditIcon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div id="draft-calendar-no-events" class="text-gray-600 p-3 text-center hidden">
              {{ $t('account.holidays.holidays_no_events') }}
            </div>
          </FullCalendarDraggable>
          <div class="form-check">
            <label class="form-check-label" for="checkbox-events">{{ $t('account.holidays.holidays_remove') }}</label>
            <input
              id="checkbox-events"
              class="show-code form-check-switch ml-auto"
              type="checkbox"
            />
          </div>
        </div>
        <div class="box p-5 intro-y mt-3" id="event-trash-bin">
          <p class="flex items-center text-xl">{{ $t('account.holidays.holidays_recycle_bin') }}</p>
          <FullCalendarDraggable
            id="recycle-calendar-events"
            :options="recycleDragableOptions"
            class="border-t border-b border-gray-200 dark:border-dark-5 mt-6 mb-5 py-3"
          >
            <div v-for='(recycleDay, index) in recycleDays' :key = 'index' class="relative">
              <div
                class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center"
                :data-event="JSON.stringify(recycleDay)"
              >
                <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                <div class="pr-10">
                  <div class="event__title truncate">{{recycleDay.title}}</div>
                  <div class="text-gray-600 text-xs mt-0.5">
                    <span class="event__days">{{recycleDay.duration.days}}</span> Days
                  </div>
                </div>
              </div>
              <a class="flex items-center absolute top-0 bottom-0 my-auto right-0" @click="removeRecycleDay(index)">
                <Trash2Icon class="w-4 h-4 text-gray-600" />
              </a>
            </div>
            <div id="recycle-calendar-no-events" class="text-gray-600 p-3 text-center hidden">
              {{ $t('account.holidays.holidays_no_recycle') }}
            </div>
          </FullCalendarDraggable>
        </div>
      </div>
      <!-- END: Calendar Side Menu -->
    </div>
  </div>
  <!-- BEGIN: Add new Lock day Modal Content -->
    <div id='add-new-lock-days-modal' class='modal' tabindex='-1' aria-hidden='true'>
     <div class='modal-dialog modal-lg'>
        <div class='modal-content'>
          <!-- BEGIN: Modal Header -->
          <div class='modal-header'>
            <h2 class='font-medium text-base mr-auto'> {{ $t('account.holidays.holidays_new') }} </h2>
          </div> <!-- END: Modal Header -->
          <div class='m-8'>
            <div class="grid gap-y-3">
              <label for='lock-day-title'>{{ $t('account.holidays.holidays_title') }}</label>
              <input id='lock-day-title' type='text' v-model="newLockDay.title" class="form-control" :placeholder="$t('account.holidays.holidays_ph_please')"/>
              <label for='lock-day-no-of-days'>{{ $t('account.holidays.holidays_number') }}</label>
              <input id='lock-day-no-of-days' v-model="newLockDay.duration.days" type='number' class="form-control" />
            </div>
          </div>
          <div class='modal-footer text-right'>
            <button
              type='button'
              @click='addLockDays'
              class='btn bg-theme-1 w-full text-white'
              :disabled="!newLockDay.title.length || !newLockDay.duration.days" > {{ $t('account.holidays.holidays_add') }} </button>
          </div> <!-- END: Modal Footer   -->
        </div>
      </div>
    </div>
  <!--END: Add New Lock Day Modal Content -->
  <!-- BEGIN: Edit Draft Lock day Modal Content -->
    <div id='edit-lock-days-modal' class='modal' tabindex='-1' aria-hidden='true'>
     <div class='modal-dialog modal-lg'>
        <div class='modal-content'>
          <!-- BEGIN: Modal Header -->
          <div class='modal-header'>
            <h2 class='font-medium text-base mr-auto'> New Lock Days </h2>
          </div> <!-- END: Modal Header -->
          <div class='m-8'>
            <div class="grid gap-y-3">
              <label for='lock-day-title'>Description</label>
              <input id='lock-day-title' type='text' v-model="draftLockDays[editDraftIndex].title" class="form-control" :placeholder="$t('account.holidays.holidays_ph_please')"/>
              <label for='lock-day-no-of-days'>Number of Days</label>
              <input id='lock-day-no-of-days' v-model="draftLockDays[editDraftIndex].duration.days" type='number' class="form-control" />
            </div>
          </div>
          <div class='modal-footer text-right'>
            <button
              type='button'
              data-dismiss='modal'
              class='btn bg-red-400 w-full text-white'
              :disabled="!draftLockDays[editDraftIndex].title.length || !draftLockDays[editDraftIndex].duration.days" > Save </button>
          </div> <!-- END: Modal Footer   -->
        </div>
      </div>
    </div>
  <!--END: End Draft Lock Day Modal Content -->
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Calendar from '@/components/calendar/Main.vue'
import CompanyService from '@/services/companyService'
import moment from 'moment'

export default {
  components: { Calendar },
  setup() {
    const store = useStore()
    const holidays = ref(null)
    const draftLockDays = ref([
      {
        title: 'Mary Crismas',
        duration: {
          days: 3,
        },
        time: '10:00 AM'
      }, {
        title: 'Happy New Year',
        duration: {
          days: 2,
        },
        time: '7:00 AM'
      }
    ])
    const editDraftIndex = ref(0)
    const recycleDays = ref([])
    const newLockDay = ref({ title: '', duration: {days: 1}, time: '' })
    const addToRecycleDays = (removedEvent) => recycleDays.value.push(removedEvent)


    const openAddLockDaysModal = () => cash('#add-new-lock-days-modal').modal('show')
    const openEditLockDaysModal = (index) => {
      editDraftIndex.value = index
      cash('#edit-lock-days-modal').modal('show')
    }
    const removeRecycleDay = (index) => {
      recycleDays.value.splice(index, 1);
    }
    const addLockDays = () => {
      draftLockDays.value.push(newLockDay.value)
      cash('#add-new-lock-days-modal').modal('hide')
      newLockDay.value = {title: '', duration: {days: 1}, time: ''}
    }
    const dragableOptions = {
      itemSelector: ".event",
      eventData(eventEl) {
        return cash(eventEl).data("event")
      }
    }
    const recycleDragableOptions = {
      itemSelector: ".event",
      eventData(eventEl) {
        return cash(eventEl).data("event")
      }
    }

    const init = async () => {
      var events = await CompanyService.getHolidays(store.state.account.company_uuid)
      holidays.value = events.map(event => {
        return {
          title: event.description,
          id: event.holidayCalendarEntryId,
          label: event.label,
          start: moment(event.startDate).format("YYYY-MM-DD"),
          end: moment(event.endDate).format("YYYY-MM-DD")
        }
      })
    }

    onMounted(() => {
      init()
    })

    return {
      holidays,
      dragableOptions,
      recycleDragableOptions,
      draftLockDays,
      editDraftIndex,
      recycleDays,
      newLockDay,
      addLockDays,
      openAddLockDaysModal,
      openEditLockDaysModal,
      removeRecycleDay,
      addToRecycleDays
    }
  }
}
</script>
