import { appAxios } from '@/plugins/axios'
import moment from 'moment'

export const getEstimate = ({workflowExecutionReferenceId, bidValue, interestRateDuration, valueDate}) => {
  var date = moment(valueDate);
  return new Promise(async resolve => {
    if(date.isValid()) {
      const api = `/workflow/v2/buyer-led-invoice-financing-workflow-0/estimates?what=PayableAmounts&refId=${workflowExecutionReferenceId}&interestRate=${bidValue}&interestRateDuration=${interestRateDuration}&valueDate=${valueDate}`
      const response = await appAxios.get(api)
      resolve({status: 'success', data: response.data})
    } resolve({status: 'error', messages: ['the value date is invalid']})
  })
}

export const getValueDateOfInvoice = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    const api = `/workflow/v2/buyer-led-invoice-financing-workflow-0/estimates?what=ValueDate&refId=${workflowExecutionReferenceId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

export const sellerApproveAcknowledge = ({workflowExecutionReferenceId, remarks, signatureUrl, disbursableBankAccount}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/seller-acknowledge-the-transaction-branch/0'
    const response = await appAxios.post(api, {
      externalReferenceId: workflowExecutionReferenceId,
      remarks,
      signatureUri: signatureUrl,
      disbursableBankAccount
    })
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const sellerDeclineAcknowledge = ({workflowExecutionReferenceId, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/seller-not-acknowledge-the-transaction-branch/0'
    const response = await appAxios.post(api, { externalReferenceId: workflowExecutionReferenceId, remarks,})
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderSubmitDisbursementAdvice = (disbursableData) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/funder-identified-after-bidding-branch/20'
    const response = await appAxios.post(api, disbursableData)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const sellerAcknowledgeOfReceiveDisbursement = ({workflowExecutionReferenceId, signatureUrl, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/seller-acknowledged-receive-of-disbursement-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      signatureUri: signatureUrl,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const sellerDeclineOfReceiveDisbursement = ({workflowExecutionReferenceId, signatureUrl, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/seller-not-acknowledge-receive-of-disbursement-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      signatureUri: signatureUrl,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const buyerUploadRepaymentAdvice = (repaymentAdvice) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/seller-acknowledged-receive-of-disbursement-branch/20'
    const response = await appAxios.post(api, repaymentAdvice)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderAcknowledgeOfRepaymentComfirm = ({workflowExecutionReferenceId, signatureUrl, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/funder-acknowledge-received-of-repayment-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      signatureUri: signatureUrl,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderAcknowledgeOfRepaymentDecline = ({workflowExecutionReferenceId, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/buyer-led-invoice-financing-workflow-0/funder-not-acknowledged-receive-of-repayment-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      remarks
    }
    await appAxios.post(api, requestBody)
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export default {
  getEstimate,
  getValueDateOfInvoice,
  sellerApproveAcknowledge,
  sellerDeclineAcknowledge,
  funderSubmitDisbursementAdvice,
  sellerAcknowledgeOfReceiveDisbursement,
  sellerDeclineOfReceiveDisbursement,
  buyerUploadRepaymentAdvice,
  funderAcknowledgeOfRepaymentComfirm,
  funderAcknowledgeOfRepaymentDecline
}
