const lang = {
  TRANSACTION_COMPLETE: 'Transaction complete',
  AWAITING_FINAL_DISBURSEMENT_ACKNOWLEDGEMENT_COMMUNICATION: 'Email notification to funder on seller acknowledgement',
  AWAITING_SELLER_ACKNOWLEDGE_RECEIVE_OF_FINAL_DISBURSEMENT: 'Seller Acknowledge Settlement from Funder',
  AWAITING_FINAL_FUND_DISBURSEMENT_NOTIFICATION_TO_SELLER: 'Email notification to seller for fund Settlement',
  AWAITING_FUNDER_FINAL_DISBURSEMENT: 'Disbursement of balance settlement amount to seller',
  FUNDER_ACKNOWLEDGED_RECEIVE_OF_REPAYMENT: 'Funder Acknowledge Repayment from Buyer',
  AWAITING_FUNDER_ACKNOWLEDGE_REPAYMENT: 'Email notification to Funder on Repayment from Buyer',
  AWAITING_BUYER_REPAYMENT_ON_DUE_DATE: 'Buyer Makes Payment On Due Date',
  AWAITING_DISBURSEMENT_ACKNOWLEDGEMENT_COMMUNICATION: 'Awaiting disbursement acknowledgement communication',
  AWAITING_SELLER_ACKNOWLEDGE_DISBURSEMENT: 'Awaiting seller acknowledge disbursement',
  AWAITING_FUND_DISBURSEMENT_NOTIFICATION_TO_SELLER: 'Awaiting fund disbursement notification to seller',
  AWAITING_FUNDER_DISBURSEMENT: 'Awaiting funder disbursement',
  AWAITING_FIRST_DISBURSEMENT_ACKNOWLEDGEMENT_COMMUNICATION: 'Email notification to funder on seller acknowledgement',
  AWAITING_SELLER_ACKNOWLEDGE_RECEIVE_OF_FIRST_DISBURSEMENT: 'Seller Acknowledge Disbursement from Funder',
  AWAITING_FIRST_FUND_DISBURSEMENT_NOTIFICATION_TO_SELLER: 'Email notification to seller for fund disbursement',
  AWAITING_FUNDER_FIRST_DISBURSEMENT: 'Disbursement of the amount financed less interest and fees',
  AWAITING_BIDDING_RESULT: 'Email notification to Supplier and Buyer',
  BIDDING_IN_PROGRESS: 'Funder Approve Transaction',
  INVITE_FUNDERS_TO_BID: 'Email notification to Funder',
  AWAITING_SELLER_ACKNOWLEDGEMENT: 'Awaiting seller acknoledgement',
  AWAITING_BUYER_ACKNOWLEDGEMENT: 'Buyer Approve Transaction',
  START: 'Financing Request Submitted',
  NOTIFICATION_SENT_TO_BUYER: 'Notification send to buyer',
  TRANSACTION_ACKNOWLEDGED_BY_BUYER: 'Transaction acknowledged by buyer',
  INVITATION_SENT_TO_FUNDERS: 'Invitation send to funders',
  TRANSACTION_APPROVED_BY_FUNDER: 'Transaction approved by funder',
  FIRST_FUND_DISBURSEMENT_INSTRUCTION_SENT_TO_FUNDER: 'First fund disbursement instruction send to funder',
  FIRST_FUND_DISBURSED_BY_FUNDER: 'First fund disbursed by funder',
  FIRST_FUND_DISBURSEMENT_NOTIFICATION_SENT_TO_SELLER: 'First fund disbursement notification send to seller',
  RECEIPT_OF_FIRST_FUND_ACKNOWLEDGED_BY_SELLER: 'Receipt of first fund acknowledge by seller',
  REPAYMENT_INSTRUCTION_SENT_TO_BUYER: 'Repayment instruction send to buyer',
  REPAID_BY_BUYER: 'Repaid by buyer',
  RECEIPT_OF_REPAYMENT_ACKNOWLEDGED_BY_FUNDER: 'Receipt of repayment acknowledge by funder',
  FINAL_FUND_DISBURSEMENT_INSTRUCTION_SENT_TO_FUNDER: 'Final fund disbursement instruction send to funder',
  FINAL_FUND_DISBURSED_BY_FUNDER: 'Final fund disbursed by funder',
  FINAL_FUND_DISBURSEMENT_NOTIFICATION_SENT_TO_SELLER: 'Final fund disbursement notification send to seller',
  RECEIPT_OF_FINAL_FUND_ACKNOWLEDGED_BY_SELLER: 'Receipt of final fund acknowledge by seller',
  START: 'Start',
  TRANSACTION_DECLINED_BY_BUYER: 'Transaction declined by buyer',
  TRANSACTION_NOT_APPROVED_BY_FUNDER: 'Transaction not approved by funder',
  RECEIPT_OF_FIRST_FUNDS_DECLINED_BY_SELLER: 'Receipt of first funds declined by seller',
  REPAID_BY_BUYER: 'Repaid by buyer',
  RECEIPT_OF_REPAYMENT_DECLINED_BY_FUNDER: 'Receipt of repayment declined by funder',
  RECEIPT_OF_FINAL_FUNDS_DECLINED_BY_SELLER: 'Receipt of final funds declined by seller',
  NOTIFICATION_SENT_TO_SELLER: 'Notification send to seller',
  TRANSACTION_ACKNOWLEDGED_BY_SELLER: 'Transaction acknowledged by seller',
  FUND_DISBURSEMENT_INSTRUCTION_SENT_TO_FUNDER: 'Fund disbursement instruction send to funder',
  FUND_DISBURSED_BY_FUNDER: 'Fund disbursed by funder',
  FUND_DISBURSEMENT_NOTIFICATION_SENT_TO_SELLER: 'Fund disbursement notification send to seller',
  RECEIPT_OF_FUND_ACKNOWLEDGED_BY_SELLER: 'Receipt of fund acknowledge by seller',
  NOTIFICATION_SENT_TO_FUNDER: 'Notification send to funder'
}

export default lang