<template>
  <div>
    <div class='intro-y flex items-center mt-8 divide-x-2 divide-gray-500 justify-start'>
      <div class='mr-2'>
        <h2 class='text-lg font-medium mr-auto'>{{ $t('invoice.detail.invoice') }}</h2>
      </div>
      <div class='text-lg font-medium mr-auto pl-2' style="border: none;">
        <span class='p-1 text-white text-sm' style="background-color: #54306F;">{{ $t('invoice.detail.batch_no') }} {{batchDetails.batchNumber}}</span>
      </div>
    </div>
    <div class='intro-y box p-5 mt-5'>
      <span class='text-lg'>{{ $t('invoice.detail.uploads') }}</span>
      <div class="bg-yellow-100 p-2 mt-4">{{ $t('invoice.detail.yellow_warning') }}</div>
      <table class='table table--lg border-l border-r mt-4'>
        <thead>
          <tr class='bg-theme-2'>
            <th> {{ $t('invoice.detail.no') }} </th>
            <th> {{ $t('invoice.detail.amount') }} </th>
            <th> {{ $t('invoice.detail.date') }} </th>
            <th> {{ $t('invoice.detail.payment_date') }} </th>
            <th> {{ $t('invoice.detail.upload_date') }} </th>
            <th> {{ $t('invoice.detail.actions') }} </th>
          </tr>
        </thead>
        <tbody v-for='(item, index) in journalBatchEntry' :key='index'>
          <tr class="even:bg-gray-50">
            <td class='dark:border-dark-5'>{{item.vendorDocumentReferenceNumber}}</td>
            <td class='dark:border-dark-5'>{{item.currencyCode +' '+item.amount.toFixed(2)}}</td>
            <td class='dark:border-dark-5'>{{moment(item.postingDate).local().format(dateFormat)}}</td>
            <td class='dark:border-dark-5'>{{moment(item.dueDate).local().format(dateFormat)}}</td>
            <td class='dark:border-dark-5'>{{moment(item.createdTime).format(dateTimeFormat)}}</td>
            <td class='dark:border-dark-5'>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class='intro-y box p-5 mt-5 invoiceSupportingDocuments' v-for='(item, index) in journalBatchEntry' :key='index'>
      <div class="flex">
        <span class='text-lg text-theme-1 font-bold'>{{item.vendorDocumentReferenceNumber}}</span>
        <input type="file" class="invisible w-0" :id="'fileUpload-'+index" @change='uploadDocument($event, index)'>
        <button class='btn btn-primary' @click='clickFileUpload(index)'>
          <span>{{ $t('invoice.detail.detail_upload_document') }}</span>
        </button>
      </div>
      <table class='table table--lg border-l border-r mt-4'>
        <thead>
          <tr class="bg-theme-2 font-bold" v-bind="$attrs">
            <td class="dark:border-dark-5">{{ $t('invoice.detail.documents.documents_supporting') }}</td>
            <td class="dark:border-dark-5">{{ $t('invoice.detail.documents.documents_uploaded') }}</td>
            <td class="dark:border-dark-5">{{ $t('invoice.detail.documents.documents_uploaded_by') }}</td>
            <td class="dark:border-dark-5"></td>
            <td class="dark:border-dark-5"></td>
            <td class="dark:border-dark-5">{{ $t('invoice.detail.actions') }}</td>
          </tr>
        </thead>
        <tbody>
          <Documents
            v-if='!supportingDocumentAccordionIndex.includes(index)'
            :journalBatchHeaderId='item.journalBatchHeaderId'
            :journalBatchEntryId='item.journalBatchEntryId'
            :entryType='item.documentType'
            :traceId='batchDetails.traceId'
            :documentNumber='item.documentNumber'
            :batchId='batchDetails.batchNumber'
            :ref="el => {documentsRef[index] = el}"
          />
        </tbody>
      </table>
    </div>
    <div class='grid grid-cols-1 md:grid-cols-2 gap-4 mt-6'>
      <div class='intro-y box p-5 mt-5'>
        <div class='flex items-center'>
          <LockIcon class='w-6 h-6 mr-3' /><span class='text-lg'>{{ $t('invoice.detail.provenance_txt') }}</span>
        </div>
        <img alt='' class='intro-x w-full h-48' :src='require(`@/assets/images/security-cuate.svg`)'/>
        <ProvenanceHisotry
          v-if="initComponent"
          :batchDetails='batchDetails'
          :paymentAdviceWorksStatus='paymentAdviceWorksStatus'
          :journalBatchHeaderId='journalBatchEntry[0].journalBatchHeaderId'
          :journalBatchEntryId='journalBatchEntry[0].journalBatchEntryId'
          :lastWorkStatus='lastWorkStatus'
          :currentWorkflow='currentWorkflow'
        />
      </div>
      <PublicSellerLedBatchDetails
        v-if="initComponent && batchDetails.ecosystemId === '00000000-0000-0000-0000-000000000000' && batchDetails.workflowLed === 'Seller Led'"
        :batchDetail='batchDetails'
        :workflowExecutionReferenceId='props.workflowExecutionReferenceId'
        :adminCompany='adminCompany'
        :provenance='provenance'
        :lastWorkStatus='lastWorkStatus'
      />
      <PublicBuyerLedBatchDetails
        v-if="initComponent && batchDetails.ecosystemId === '00000000-0000-0000-0000-000000000000' && batchDetails.workflowLed === 'Buyer Led'"
        :batchDetail='batchDetails'
        :workflowExecutionReferenceId='props.workflowExecutionReferenceId'
        :adminCompany='adminCompany'
        :provenance='provenance'
        :lastWorkStatus='lastWorkStatus'
      />
      <Eco0BatchDetails
        v-if="initComponent && batchDetails.ecosystemId !== '00000000-0000-0000-0000-000000000000'"
        :batchDetail='batchDetails'
        :workflowExecutionReferenceId='props.workflowExecutionReferenceId'
        :adminCompany='adminCompany'
        :provenance='provenance'
        :lastWorkStatus='lastWorkStatus'
      />
    </div>
  </div>
</template>
<style type="text/css">
  .invoiceSupportingDocuments > .flex {
    justify-content: space-between;
    align-items: center;
  }
</style>
<script>
import { ref, onMounted } from 'vue'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
import toast from '@/utils/toast'
import i18n from '@/plugins/i18n'

import journalBatchService from '@/services/journalBatchService'
import fileService from '@/services/fileService';
import companyService from '@/services/companyService'
import workflowService from '@/services/workflowService'
import configService from '@/services/configService'
import Documents from './Documents.vue'
import ProvenanceHisotry from './Provenance.vue'
import Eco0BatchDetails from './batchDetails/Eco0.vue'
import PublicSellerLedBatchDetails from './batchDetails/public/SellerLed.vue'
import PublicBuyerLedBatchDetails from './batchDetails/public/BuyerLed.vue'

export default {
  props: {
    workflowExecutionReferenceId: {
      type: String,
      required: true
    }
  },
  components: {
    Documents,
    ProvenanceHisotry,
    Eco0BatchDetails,
    PublicSellerLedBatchDetails,
    PublicBuyerLedBatchDetails
  },
  setup(props) {
    const documentsRef = ref([])

    const journalBatchEntry = ref()
    const adminCompany = ref()

    const currentWorkflow = ref()
    const provenance = ref([])
    const lastWorkStatus = ref()

    const dateFormat = process.env.VUE_APP_DATE_FORMAT
    const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT
    const batchDetails = ref({
      batchNumber: null,
      workflowLed: '',
      bankDetails: {
        bank: null,
      },
      batchInformation: {
        bidEndTime: null,
        paymentDueDate: null,
        buyerCompany: null,
        sellerCompany: null,
        funderCompany: null,
        noOfBatchEntries: 0,
        uploadDate: null,
        totalAmount: null,
      },
      formula: {
        interestRate: null,
        processingFeeAmount: null,
        disbursableAmountToSeller: null,
        disbursableDate: null,
        platformFeeRate: null,
        platformFeeAmount: null,
        platformFeeDate: null,
        repaymentAmountToFunder: null,
        repaymentDate: null
      }
    })
    const valueDate = ref()
    const bidValue = ref(null)
    const paymentAdviceWorksStatus = ref([])
    const supportingDocumentAccordionIndex = ref([])
    const initComponent = ref(false)

    const accordion = (index) => {
      if(supportingDocumentAccordionIndex.value.includes(index)) _.remove(supportingDocumentAccordionIndex.value, (item) => {
        return item == index
      })
      else supportingDocumentAccordionIndex.value.push(index)
    }

    const clickFileUpload = (index) => {
      $('#fileUpload-'+index).click()
    }

    const uploadDocument = (event, index) => {
      let requestBody = []
      event.target.files.forEach(async file => {
        let formData = new FormData();
        formData.append('file', file)
        try {
          const response = await fileService.fileUpload('supporting_document', formData)
          requestBody.push({
            documentName: file.name,
            documentURI: process.env.VUE_APP_SYSTEM_API_URL + "/uploads/v1/" + response
          })
          const response2 = await journalBatchService.postBatchEntrySupportDocuments({journalBatchHeaderId: journalBatchEntry.value[index].journalBatchHeaderId, journalBatchEntryId: journalBatchEntry.value[index].journalBatchEntryId, requestBody: requestBody})
          documentsRef.value[index].init()
          // props.addSupportDoc(props.batchIndex, props.index, response, file.name);
        } catch (err) {
          toast({status: "error", title: i18n.global.t('invoice.support.support_s_upload_fail'), content: err})
        }
      })
    }

    const init = async () => {
      //geting invoice detail information
      const response = await journalBatchService.getBatchDetailByExecutionReferenceId(props.workflowExecutionReferenceId)
      const batch = {
        ...response,
        traceId: response.traceId,
        batchInformation: {
          bidEndTime: response.bidEndTime,
          paymentDueDate: response.paymentDueDate,
          uploadDate: moment.utc(response.createdTime).format(dateFormat),
          totalAmount: response.totalAmount,
          noOfBatchEntries: response.numberOfBatchEntries
        },
        formula: {
          interestRate: response.interestRate,
          interestRateDuration: response.interestRateDuration,
          platformFeeRate: response.processingFeeRateForFunder,
          repaymentAmountToFunder: null,
          // repaymentAmountToFunder: res.data.totalAmount,
          repaymentDate: moment(response.maturityDate).local().format(dateFormat),
          maturityDate: moment(response.maturityDate).local().format(dateFormat),
          valueDate: moment(response.valueDate).local().format(dateFormat),
          numberOfDays: moment(response.maturityDate).diff(moment(response.valueDate), 'days')
        }
      }
      batchDetails.value = {...batchDetails.value, ...batch}

      //getting documents of invoice
      journalBatchEntry.value = await journalBatchService.getBatchEntities(batchDetails.value.journalBatchHeaderId)

      //getting global values to use invoice detail page
      const genieGlobalSetting = await configService.getGenieGlobalSettings()
      adminCompany.value = genieGlobalSetting.adminCompanyId

      // provenance.value = await workflowService.getProvenanceHistory(props.workflowExecutionReferenceId)
      // new temp
      currentWorkflow.value = await workflowService.getCurrentWorkflowStatus(props.workflowExecutionReferenceId)
      let { workflows } = currentWorkflow.value
      provenance.value = workflows.map(item => item.statusTransitions.sort((a, b) => a.order - b.order)).flat()

      lastWorkStatus.value = await workflowService.getLastWorkflowStatus([props.workflowExecutionReferenceId])

      paymentAdviceWorksStatus.value = genieGlobalSetting.paymentAdviceWorksStatus
      let buyerLedWorkflowId = genieGlobalSetting.buyerLedWorkflowId
      let sellerLedWorkflowId = genieGlobalSetting.sellerLedWorkflowId

      //getting buyer and seller workflow id from ecosystem endpoint instead of global config in case the batch is on the private ecosystem
      if(batchDetails.value.ecosystemId !== '00000000-0000-0000-0000-000000000000') {
        const ecosystem = await companyService.getEcosystemById(batchDetails.value.ecosystemId)
        buyerLedWorkflowId = ecosystem.buyerLedWorkflowId
        sellerLedWorkflowId = ecosystem.buyerLedWorkflowId
      }
      
      // batchDetails.value.workflowLed = await workflowService.getBatchWorkflowLed({
      //   workflowExecutionReferenceId: batchDetails.value.workflowExecutionReferenceId,
      //   buyerLedWorkflowId,
      //   sellerLedWorkflowId
      // })
      // new temp
      if(currentWorkflow.value.rootWorkflowId === buyerLedWorkflowId) {
        batchDetails.value.workflowLed = 'Buyer Led';
      } else if(currentWorkflow.value.rootWorkflowId === sellerLedWorkflowId) {
        batchDetails.value.workflowLed = 'Seller Led';
      } else {
        batchDetails.value.workflowLed = await workflowService.getBatchWorkflowLed({
          workflowExecutionReferenceId: batchDetails.value.workflowExecutionReferenceId,
          buyerLedWorkflowId,
          sellerLedWorkflowId
        })
      }
    }

    onMounted(async () => {
      await init()
      // console.log('batchDetails: ', batchDetails.value)
      initComponent.value = true
    })

    return {
      props,
      adminCompany,
      currentWorkflow,
      provenance,
      lastWorkStatus,
      accordion,
      clickFileUpload,
      uploadDocument,
      batchDetails,
      bidValue,
      dateFormat,
      dateTimeFormat,
      initComponent,
      journalBatchEntry,
      moment,
      paymentAdviceWorksStatus,
      supportingDocumentAccordionIndex,
      valueDate,
      documentsRef,
    }
  },
}
</script>
