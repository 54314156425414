<template>
  <!-- BEGIN: Top Bar -->
  <div class='top-bar'>
    <!-- BEGIN: Breadcrumb -->
    <div class='-intro-x breadcrumb mr-auto hidden sm:flex'>
      <a href=''>{{ $t('account.account_main_appli') }}</a>
      <ChevronRightIcon class='breadcrumb__icon' />
      <a href='' class='breadcrumb--active'>{{ $t('dashboard.dashboard_dashboard') }}</a>
    </div>
    <!-- END: Breadcrumb -->
    <!-- START: Localization language select -->
    <div class='flex justify-center mr-4'>
      <div class='dropdown'>
        <button class='dropdown-toggle btn btn-primary' aria-expanded='false'>
          {{computedLocale.name}}
          <ChevronDownIcon class="w-4 h-4 ml-2" />
        </button>
        <div class='dropdown-menu'>
          <div class='dropdown-menu__content box dark:bg-dark-1 p-2'>
            <a
              v-for='locale in locales'
              href='javascript:;'
              data-dismiss="dropdown"
              class='block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md'
              :key='locale.key'
              @click="setLocale(locale)"
            >{{locale.name}}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Localization language select -->
    <!-- START: Eco system select -->
    <div v-if="user.user_role !== 'Genie Admin'" class='flex justify-center mr-4'>
      <div class='dropdown'>
        <button class='dropdown-toggle btn btn-primary' aria-expanded='false'>
          {{computedDefaultEcosystem.name}}
          <ChevronDownIcon class="w-4 h-4 ml-2" />
        </button>
        <div class='dropdown-menu w-40'>
          <div class='dropdown-menu__content box dark:bg-dark-1 p-2'>
            <a
              v-for='system in ecoSystems'
              href='javascript:;'
              data-dismiss="dropdown"
              class='block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md'
              :key='system.ecosystemId'
              @click="updateDefaultEcosystem(system)"
            >{{system.name}}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Eco system select -->
    <!-- BEGIN: Notifications -->
    <div class='intro-x dropdown mr-auto sm:mr-6'>
      <div :class="`dropdown-toggle notification cursor-pointer ${unreadAlerts ? 'notification--bullet': ''}`" role='button' aria-expanded='false'>
        <BellIcon class='notification__icon dark:text-gray-300' />
      </div>
      <div class='notification-content pt-2 dropdown-menu'>
        <div class='notification-content__box dropdown-menu__content box dark:bg-dark-6'>
          <div class='notification-content__title'>
            {{ $t('alerts.alerts_alert') }}
          </div>
          <hr />
          <div
            v-for='(alert, index) in alerts.slice(0, 5)'
            class='cursor-pointer relative flex items-center py-2'
            :key='index'
            @click="openAcknowledgeNotificationModal(alert)">
            <div>
              <div class='w-8 mr-1 bg-pink-200 p-1 rounded-full text-center'>
                <FileTextIcon class='notification__icon dark:text-gray-300 text-pink-700 text-sm w-4' />
              </div>
            </div>
            <div class='ml-2 overflow-hidden text-left'>
              <div class='flex items-center'>
                <a href='javascript:;' class='font-medium truncate mr-5'>{{alert.title}}</a>
              </div>
              <div class='w-full truncate text-gray-600 mt-0.5 text-xs'>
                {{moment(alert.when).format(dateTimeFormat)}}
              </div>
            </div>
          </div>
          <hr />
          <button class='btn btn-primary w-full px-2 mt-2' data-dismiss="dropdown" @click='gotoAlertCenter'>{{ $t('alerts.alerts_show_all') }}</button>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Calendar -->
    <div class='intro-x dropdown mr-auto sm:mr-6'>
      <div :class="`dropdown-toggle notification cursor-pointer ${dayDiff < 8 ? 'notification--bullet': ''}`" role='button' aria-expanded='false'>
        <CalendarIcon class='notification__icon dark:text-gray-300' />
      </div>
      <div class='notification-content pt-2 dropdown-menu'>
        <div class='notification-content__box dropdown-menu__content box dark:bg-dark-6'>
          <div class='notification-content__title'>{{ $t('dashboard.dashboard_calendar') }}</div>
          <div v-for='holiday in holidays' :key='holiday.holidayCalendarEntryId' class='cursor-pointer relative flex items-center mt-2'>
            <div class='w-8 mr-1 bg-pink-200 p-1 rounded-md'>
              <CalendarIcon class='notification__icon dark:text-gray-300 text-pink-700 text-sm' />
            </div>
            <div class='ml-2 overflow-hidden'>
              <div class='flex items-center'>
                <a href='javascript:;' class='truncate mr-5'>{{moment(holiday.startDate).format(dateFormat)}} ~ {{moment(holiday.endDate).format(dateFormat)}}</a>
              </div>
              <div class='flex items-center'>
                <a href='javascript:;' class='font-medium truncate mr-5'>{{holiday.description}}</a>
              </div>
              <div class='w-full truncate text-gray-600 mt-0.5'>{{holiday.label}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Calendar -->
    <div class='pr-2'>
      <span>{{ $t('account.account_main_hi') }} </span>
    </div>
    <!-- BEGIN: Account Menu -->
    <div class='intro-x dropdown h-8'>
      <div class='dropdown-toggle h-8 flex items-center text-theme-1' role='button' aria-expanded='false'>
        {{user.display_name}}
      </div>
      <div class='dropdown-menu w-56'>
        <div class='dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white'>
          <div class='p-4 border-b border-theme-27 dark:border-dark-3'>
            <div class='font-medium'>{{ user.display_name }}</div>
            <div class='text-xs text-theme-28 mt-0.5 dark:text-gray-600'>
              {{ $t('account.account_role_'+user.user_role.replace(/\ /g, '_').toLowerCase()) }}
            </div>
          </div>
          <div class='p-2'>
            <a href='/account' data-dismiss="dropdown" class='items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md'>
              <UserIcon class='w-4 h-4 mr-2' /> {{ $t('account.account_profile') }}
            </a>
            <a href='javascript:;' data-dismiss="dropdown" class='items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md' @click='gotoUpdatePassword' >
              <LockIcon class='w-4 h-4 mr-2' /> {{ $t('auth.update.update_update') }}
            </a>
          </div>
          <div class='p-2 border-t border-theme-27 dark:border-dark-3'>
            <a href='javascript:;' data-dismiss="dropdown" class='items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md' @click='logout'>
              <ToggleRightIcon class='w-4 h-4 mr-2' /> {{ $t('account.account_main_logout') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
    <!-- START: Alert Modal -->
    <div id="acknowledge-notification" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto"> {{viewingNotification.title}} </h2>
          </div>
          <!-- END: Modal Header -->
          <div class="modal-body mx-8">
            {{viewingNotification.content}}
            <div class="text-right">
              <br />
              {{moment(viewingNotification.when).format(dateTimeFormat)}}
            </div>
          </div>
          <div class="modal-footer text-right">
            <button v-if="viewingNotification.status == 'Complete'" type="button" class="btn btn-primary w-24" @click="makeAsRead">{{ $t('alerts.alerts_ok') }}</button>
            <button v-else type="button" data-dismiss="modal" class="btn btn-primary w-24">{{ $t('alerts.alerts_cancel') }}</button>
          </div> <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Alert Modal -->
  </div>

  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { sysAxios } from '@/plugins/axios';
import { useStore } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { useRouter } from 'vue-router';


export default defineComponent({
  setup() {
    const dateTimeFormat = ref(process.env.VUE_APP_DATETIME_FORMAT);
    const dateFormat = ref(process.env.VUE_APP_DATE_FORMAT);
    const store = useStore()
    const router = useRouter()
    const dismiss = ref(false)
    const user = ref(store.state.auth)
    const defaultEcosystem = ref(store.state.main.defaultEcosystem)
    const ecoSystems = ref(store.state.main.ecoSystem)
    const locales = ref(store.state.main.locales)

    const alerts = ref([])
    const unreadAlerts = ref(false)
    const holidays = store.state.account.holidays
    const dayDiff = ref(100)
    const viewingNotification = ref({
      notificationId: null,
      title: null,
      content: null,
      when: null,
    });
    const logout = () => {
      store.dispatch('auth/logout')
    }
    const gotoUpdatePassword = () => {
      router.push({path: '/update_password'})
    }
    const gotoAlertCenter = () => {
      router.push({path: '/alerts'})
    }
    const updateDefaultEcosystem = async (system) => {
      await store.dispatch('main/updateDefaultEcosystem', system)
    }
    const setLocale = async (locale) => {
      await store.dispatch('main/setLocale', locale)
      router.go()
    }
    const computedDefaultEcosystem = computed({
      get: () => store.state.main.defaultEcosystem
    })
    const computedLocale = computed({
      get: () => store.state.main.locale
    })
    const openAcknowledgeNotificationModal = (notification) => {
      viewingNotification.value = notification;
      cash("#acknowledge-notification").modal("show")
    }
    const makeAsRead = async () => {
      const api = `communications/v1/notification/${viewingNotification.value.notificationId}/Read`
      _.remove(alerts.value, alert => alert.notificationId === viewingNotification.value.notificationId)
      await sysAxios.put(api)
      if(_.findIndex(alerts, {status: 'Complete'}) != -1) unreadAlerts.value = true
      cash("#acknowledge-notification").modal("hide")
    }
    onMounted(async () => {
      const company_uuid = store.state.account.company_uuid
      if(company_uuid !== '00000000-0000-0000-0000-000000000000' && company_uuid) {
        sysAxios.get(`/communications/v1/notification/${company_uuid}`).then(res => {
          alerts.value = _.filter(res.data, {status: 'Complete'})
          if(_.findIndex(res.data, {status: 'Complete'}) != -1) unreadAlerts.value = true
        })
      }
    })

    return {
      viewingNotification,
      dateFormat,
      dateTimeFormat,
      alerts,
      holidays,
      moment,
      user,
      dayDiff,
      unreadAlerts,
      ecoSystems,
      locales,
      defaultEcosystem,
      computedDefaultEcosystem,
      computedLocale,
      store,
      logout,
      gotoUpdatePassword,
      gotoAlertCenter,
      updateDefaultEcosystem,
      setLocale,
      openAcknowledgeNotificationModal,
      makeAsRead,
      dismiss
    };
  },
});
</script>