import {appAxios} from '@/plugins/axios'

const getPaymentAdvice = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    const api = `/ledger/v1/paymentadvice/byworkflowexecutionreferenceid/${workflowExecutionReferenceId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

const getPaymentinstruction = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    const api = `/ledger/v1/paymentinstruction/byworkflowexecutionreferenceid/${workflowExecutionReferenceId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}
export default {
  getPaymentAdvice,
  getPaymentinstruction
}
