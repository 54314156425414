import { appAxios } from '@/plugins/axios'
import moment from 'moment'

export const getEstimate = ({workflowExecutionReferenceId, bidValue, interestRateDuration, valueDate}) => {
  var date = moment(valueDate);
  return new Promise(async resolve => {
    if(date.isValid()) {
      const api = `/workflow/v2/seller-led-invoice-financing-workflow-1/estimates?what=PayableAmounts&refId=${workflowExecutionReferenceId}&interestRate=${bidValue}&interestRateDuration=${interestRateDuration}&valueDate=${valueDate}`
      const response = await appAxios.get(api)
      resolve({status: 'success', data: response.data})
    } resolve({status: 'error', messages: ['the value date is invalid']})
  })
}

export const getValueDateOfInvoice = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    const api = `/workflow/v2/seller-led-invoice-financing-workflow-1/estimates?what=ValueDate&refId=${workflowExecutionReferenceId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

export const buyerApproveAcknowledge = ({workflowExecutionReferenceId, remarks, signatureUrl}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/seller-led-invoice-financing-workflow-1/buyer-acknowledge-the-transaction-branch/0'
    const response = await appAxios.post(api, {
      externalReferenceId: workflowExecutionReferenceId,
      remarks,
      signatureUri: signatureUrl,
    })
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const buyerDeclineAcknowledge = ({workflowExecutionReferenceId, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/seller-led-invoice-financing-workflow-1/buyer-not-acknowledge-the-transaction-branch/0'
    const response = await appAxios.post(api, { externalReferenceId: workflowExecutionReferenceId, remarks,})
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderSubmitDisbursementAdvice = ({disbursementStep, requestBody}) => {
  return new Promise(async (resolve, reject) => {
    let api = ''
    if (disbursementStep === "first") api = '/workflow/v2/seller-led-invoice-financing-workflow-1/funder-identified-after-bidding-branch/20'
    else if (disbursementStep === "final") api = '/workflow/v2/seller-led-invoice-financing-workflow-1/funder-acknowledge-received-of-repayment-branch/20'

    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const sellerAcknowledgeOfReceiveDisbursement = ({disbursementStep, workflowExecutionReferenceId, signatureUrl, remarks}) => {
  return new Promise(async (resolve, reject) => {
    let api = ''
    if(disbursementStep === 'first') api = '/workflow/v2/seller-led-invoice-financing-workflow-1/seller-acknowledged-receive-of-first-disbursement-branch/0'
    else if(disbursementStep === 'final') api = '/workflow/v2/seller-led-invoice-financing-workflow-1/seller-acknowledged-receive-of-final-disbursement-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      signatureUri: signatureUrl,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const sellerDeclineOfReceiveDisbursement = ({disbursementStep, workflowExecutionReferenceId, remarks}) => {
  return new Promise(async (resolve, reject) => {
    let api = ''
    if(disbursementStep === 'first') api = '/workflow/v2/seller-led-invoice-financing-workflow-1/seller-not-acknowledge-receive-of-first-disbursement-branch/0'
    else if(disbursementStep === 'final') api = 'workflow/v2/seller-led-invoice-financing-workflow-1/seller-not-acknowledge-receive-of-final-disbursement-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const buyerUploadRepaymentAdvice = (repaymentAdvice) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/seller-led-invoice-financing-workflow-1/seller-acknowledged-receive-of-first-disbursement-branch/20'
    const response = await appAxios.post(api, repaymentAdvice)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderAcknowledgeOfRepaymentComfirm = ({workflowExecutionReferenceId, signatureUrl, remarks}) => {
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/seller-led-invoice-financing-workflow-1/funder-acknowledge-received-of-repayment-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      signatureUri: signatureUrl,
      remarks
    }
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export const funderAcknowledgeOfRepaymentDecline = ({workflowExecutionReferenceId, remarks}) => {
  // console.log("123122131231")
  return new Promise(async (resolve, reject) => {
    const api = '/workflow/v2/seller-led-invoice-financing-workflow-1/funder-not-acknowledged-receive-of-repayment-branch/0'
    const requestBody = {
      externalReferenceId: workflowExecutionReferenceId,
      remarks
    }
    await appAxios.post(api, requestBody)
    const response = await appAxios.post(api, requestBody)
    if(response.status === 'error') reject(response.error.response.data)
    resolve(response.data)
  })
}

export default {
  getEstimate,
  getValueDateOfInvoice,
  buyerApproveAcknowledge,
  buyerDeclineAcknowledge,
  funderSubmitDisbursementAdvice,
  sellerAcknowledgeOfReceiveDisbursement,
  sellerDeclineOfReceiveDisbursement,
  buyerUploadRepaymentAdvice,
  funderAcknowledgeOfRepaymentComfirm,
  funderAcknowledgeOfRepaymentDecline
}
