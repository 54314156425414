<template>
  <h2 class='intro-y text-lg font-medium mt-10'>{{ $t('users.new.users_new_users') }}</h2>
  <!-- BEGIN: Users Layout -->
  <div class='intro-y box px-3 pb-3 mt-3 w-full'>
    <div v-if='companyLoading' class='py-16'>
      <div class='w-full h-8 px-8'>
        <LoadingIcon icon='spinning-circles' color='gray' class='w-4 h-4 py-8' />
      </div>
    </div>
    <div :class="`${companyLoading ? 'hidden' : 'block'}`" class='overflow-x-auto scrollbar-hidden'>
      <div id='tabulator' ref='tableRef' class='mt-5 table-report table-report--tabulator'></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Tabulator from 'tabulator-tables'
import feather from 'feather-icons'
import _ from 'lodash'
import userService from '@/services/userService'
import companyService from "@/services/companyService"
import accessService from "@/services/accessService"

export default {
  setup() {
    const companies = ref([])
    const tableRef = ref()
    const tabulator = ref()
    const companyLoading = ref(true)
    const approveRoleId = ref('')
    const dialogShow = ref(false)

    const modalConfirm = () => {
      return new Promise(resolve => {
        resolve('testing modal confirm promise call')
      })
    }

    const openDialog = () => dialogShow.value = true
    const cancelDialog = () => dialogShow.value = false

    const init = async () => {
      companyLoading.value = true
      var users = await userService.getUsers().then( res => {return res.data})
      await Promise.all(
        users.map( async user => {
          const isGuest = await userService.isGuest(user.userId)
          if(isGuest) {
            const company_uuid = await companyService.getCompanyIdByUserId(user.userId)
            if(company_uuid !== '00000000-0000-0000-0000-000000000000') {
              const company = await companyService.getCompanyDataByCompanyID(company_uuid)
              companies.value.push({...company, user})
            }
          }
        })
      )
      companyLoading.value = false
      initTabulator(companies.value)
    }

    const initTabulator = (data) => {
      tabulator.value = new Tabulator(tableRef.value, {
        data: data,
        ajaxLoaderLoading: '<span>Loading Data</span>',
        pagination: 'local',
        paginationSize: 10,
        paginationSizeSelector: [5, 10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: i18n.global.t('users.new.users_new_ph_nomatch'),
        columns: [
          {
            title: i18n.global.t('users.new.users_new_ttl_reg'),
            field: 'registrationNo',
            minWidth: 50,
            maxWidth: 200,
            hozAlign: 'left',
            resizable: true,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_name'),
            field: 'companyDisplayName',
            resizable: false,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_legal'),
            field: 'companyLegalName',
            headerHozAlign: 'center',
            hozAlign: 'center',
            resizable: true,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_add'),
            field: 'addressLine1',
            headerHozAlign: 'center',
            hozAlign: 'center',
            resizable: true,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_type'),
            field: 'companyType',
            headerHozAlign: 'center',
            hozAlign: 'center',
            resizable: true,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_contact'),
            field: 'phone',
            minWidth: 100,
            maxWidth: 200,
            hozAlign: 'right',
            resizable: true,
            headerSort: true,
          }, {
            title: i18n.global.t('users.new.users_new_ttl_email'),
            field: 'primaryEmail',
            hozAlign: 'center',
            resizable: true,
            headerSort: false
          }, {
            title: i18n.global.t('users.new.users_new_ttl_actions'),
            minWidth: 100,
            maxWidth: 150,
            responsive: 1,
            headerHozAlign: 'center',
            hozAlign: 'center',
            vertAlign: 'middle',
            formatter(cell) {
              const a = cash(`<div class='flex lg:justify-center items-center'>
                <a class='flex items-center btn btn-sm btn-primary' href='javascript:;'>
                  Approve
                </a>
              </div>`)
              cash(a).on('click', function() {
                const userId = cell.getData().user.userId
                approveCompany(userId)
              })
              return a[0]
            }
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    const approveCompany = async userId => {
      try {
        await accessService.approveCompany({userId, roleIds: [approveRoleId.value]})
        init()
      } catch (err) {
        toast({
          state: "error",
          title: "Grant permission faild",
          content: err
        })
      }

    }

    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(async () => {
      approveRoleId.value = await accessService.getRoleIdByRoleName('System Admin')
      await init()
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      companyLoading,
      modalConfirm,
      dialogShow,
      openDialog,
      cancelDialog
    }
  },
}
</script>
