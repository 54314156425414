<template>
	<div class="dark:border-dark-5">
		<div class="font-medium text-2xl">{{ $t('account.profile.profile_information') }}</div>
		<div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-user-name" class="form-label">{{ $t('account.profile.profile_username') }}</label>
				<input id="user-profile-user-name" v-model="userProfile.userName" type="text" class="form-control" placeholder="input company name"/>
			</div>
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-display-name" class="form-label">{{ $t('account.profile.profile_display_name') }}</label>
				<input id="user-profile-display-name" v-model="userProfile.displayName" type="text" class="form-control" placeholder="input company name"/>
			</div>
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-first-name" class="form-label">{{ $t('account.profile.profile_first_name') }}</label>
				<input id="user-profile-first-name" v-model="userProfile.firstName" type="text" class="form-control" placeholder="Registration no"/>
			</div>
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-last-name" class="form-label">{{ $t('account.profile.profile_last_name') }}</label>
				<input id="user-profile-last-name" v-model="userProfile.lastName" type="text" class="form-control" placeholder="Tax Number"/>
			</div>
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-email-address" class="form-label">{{ $t('account.profile.profile_email') }}</label>
				<input id="user-profile-email-address" v-model="userProfile.emailAddress" type="text" class="form-control" placeholder="Registration no"/>
			</div>
			<div class="intro-y col-span-12 sm:col-span-6">
				<label for="user-profile-phone-number" class="form-label">{{ $t('account.profile.profile_phone_number') }}</label>
				<div class="icon-input">
					<div class="icon">
						<PhoneIcon class="w-4 h-4"/>
					</div>
					<input id="user-profile-phone-number" value="+86 153 6099 8199" type="text" class="form-control" placeholder="City"/>
				</div>
			</div>

		</div>
		<div class="bg-yellow-100 p-2 mt-4 intro-y">
			{{ $t('account.profile.profile_email_fail') }}&nbsp;
			<a href="" class="underline text-theme-1">{{ $t('account.profile.profile_resend') }}</a>
		</div>
		<div class="intro-y col-span-12 flex items-center justify-center sm:justify-start mt-5">
			<button class="btn btn-primary w-24" @click="submitUserProfile">{{ $t('account.profile.profile_save') }}</button>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted } from "vue";
import toast from '@/utils/toast';
import userService from '@/services/userService';
import i18n from '@/plugins/i18n';

export default {
	setup() {
    const router = useRouter();
    const store = useStore();
		const userProfile = ref({});

		onMounted(async () => {
      const reponse = await userService.getUserProfileByUserID(store.state.auth.user_id)
      userProfile.value = reponse.data
		})

		const showNotification = (flg) => {
			toast({
        status: flg ? 'success' : 'error',
        title: flg ? i18n.global.t('account.profile.profile_update_success') : i18n.global.t('account.profile.profile_update_fail'),
        content: flg ? '' : i18n.global.t('account.profile.profile_please')
      })
		}

		const gotoNext = () => {
			store.commit('account/SET_STEP', {step: "bank-information"});
			router.push({path: "/account/bank-information"});
		}

		const submitUserProfile = async () => {
			const request = {
				"emailAddress": userProfile.value.emailAddress,
				"firstName": userProfile.value.firstName,
				"lastName": userProfile.value.lastName,
				"displayName": userProfile.value.displayName
			}
      const response = await userService.updateUserProfile(userProfile.value.userId,  request)
      if(response.status === 200) showNotification(true)
      else showNotification(false)
		}

    return {
			submitUserProfile,
      gotoNext,
			userProfile
    }
	},
}
</script>
