import { sysAxios } from '@/plugins/axios'
import _ from 'lodash'

export const getPermissions = async () => {
  return new Promise(async resolve => {
    const api = "access/v1/permission"
    const response = await sysAxios.get(api)
    resolve(response.data)
  })
}

export const createPermission = async (permission) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = "access/v1/permission"
      const response = await sysAxios.post(api, permission)
      // console.log("creating permission response: ", response)
      if(response.status === 'error') reject('validation error')
      else resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const updatePermission = async ({permissionId, permission}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `/access/v1/permission/${permissionId}`
      const response = await sysAxios.put(api, permission)
      if(response.status === 'error') reject('no changes were made')
      else resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const deletePermission = (permissionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `access/v1/permission/${permissionId}`
      const response = await sysAxios.delete(api)
      if(response.status === 200) resolve(response)
      reject(response.error.response.data)
    } catch (err) {
      reject(err)
    }
  })

}

export const getRoles = async () => {
  return new Promise(async resolve => {
    const api = 'access/v1/role'
    const response = await sysAxios.get(api)
    resolve(response.data)
  })
}

export const addRole = async (role) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = "access/v1/role";
      const response = await sysAxios.post(api, role)
      if(response.status === "error") reject(response.error.response.data)
      const roles = await getRoles()
      resolve({roles: roles, addedRole: response.data})
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteRole = (roleId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `access/v1/role/${roleId}`
      const response = await sysAxios.delete(api)
      if(response.status === "error") reject(response.error.response.data)
      else resolve(response.data)
    } catch (err) {
      reject(err)
    }
  })
}

export const updateRole = ({roleId, roleData}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `access/v1/role/${roleId}`
      const response = await sysAxios.put(api, roleData)
      if(response.status === "error") reject(response.error.response.data)
      else resolve(response.data)
    } catch (err) {
      reject(err)
    }
  })
}

export const getRoleIdByRoleName = async (roleName) => {
  return new Promise(async resolve => {
    const api = 'access/v1/role'
    const response = await sysAxios.get(api)
    resolve(_.find(response.data, {roleName}).roleId)
  })
}

export const approveCompany = async ({userId, roleIds}) => {
  return new Promise(async (resolve,  reject) => {
    try {
      const authorizedIdApi = `access/v1/authorization/user/${userId}`
      const grantAccessApi = `access/v1/authorization/${authorized_uuid}`
      const authorized_uuid = await sysAxios.get(authorizedIdApi).then(res => res.data.authorizationId)
      const response = sysAxios.put(grantAccessApi, {applicationDomain: 'genie', roleIds})
      if(response.status === 'error') reject(res.error.response.data)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const updateUserRole = ({authorizationId, roleIds}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `access/v1/authorization/${authorizationId}`
      const response = await sysAxios.put(api, {applicationDomain: "genie", roleIds})
      if(response.status === 'error') reject(res.error.response.data)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const getUserAccess = (useId) => {
  return new Promise(async resolve => {
    const api = `access/v1/authorization/user/${useId}`
    const response = await sysAxios.get(api)
    resolve({
      authorizationId: response.data.authorizationId,
      roles: _.map(response.data.roles, "roleId")
    })
  })
}

export default {
  getPermissions,
  createPermission,
  updatePermission,
  deletePermission,
  getRoles,
  addRole,
  updateRole,
  deleteRole,
  getRoleIdByRoleName,
  approveCompany,
  updateUserRole,
  getUserAccess
}
