<template>
  <div v-bind="getRootProps()" class="flex justify-center">
    <div>
      <input v-bind="getInputProps()" :id="index">
      <UploadCloudIcon v-if="!uploadingFiles" class="w-4 h-4 text-red-400" />
      <div v-if="uploadingFiles" class="w-4, h-4 flex items-center">
        {{ $t('invoice.support.support_support') }}
        <LoadingIcon icon="oval" color="red" class="w-3 h-3 ml-2" />
      </div>
    </div>
  </div>
    <div class="justify-center flex-flow">
        <div v-for="(item, index) in data" :key="index" class="flex justify-center">
          <p class="w-48 truncate">
            {{item.documentName}}
          </p>
          <button @click="onRemove(index)" style="color:red;"><Trash2Icon class="w-3 h-3" /></button>
          <br />
      </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import toast from '@/utils/toast'
import fileService from '@/services/fileService';
import i18n from '@/plugins/i18n';
export default {
  props: {
    addSupportDoc: {
      type: Function,
      default: () => ({})
    },
    removeSupportDoc: {
      type: Function,
      default: () => ({})
    },
    batchIndex: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    data:{
      type: Object,
      default: null
    }
  },
  setup(props){
    const files = ref([]);
    const uploadingFiles = ref(false)

    const onDrop = async (acceptFiles, rejectReasons ) => {
      if(rejectReasons.length) {
        rejectReasons.forEach(reason => {
          var content = reason.errors[0].message
          var title = i18n.global.t('invoice.support.support_s_cannot_upload1') + reason.file.name + i18n.global.t('invoice.support.support_s_cannot_upload2')
          toast({status: 'error', title: title, content: content})
        })
      } else {
        uploadingFiles.value = true
        acceptFiles.forEach(async file => {
          let formData = new FormData();
          uploadingFiles.value = true
          formData.append('file', file)
          try {
            const response = await fileService.fileUpload('supporting_document', formData)
            files.value.push(file)
            uploadingFiles.value = false
            props.addSupportDoc(props.batchIndex, props.index, response, file.name);
          } catch (err) {
            toast({status: "error", title: i18n.global.t('invoice.support.support_s_upload_fail'), content: err})
          }

        })
      }
    }
    const options = reactive({
      multiple: true,
      onDrop,
      accept: '.jpg, .png, .csv, .txt, .pdf, .docx, .xlsx',
    })
    const onRemove = async(index) => {
      props.removeSupportDoc(props.batchIndex, props.index, index);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone(options)

    return {
      onRemove,
      files,
      getRootProps,
      getInputProps,
      uploadingFiles,
      ...rest
    }
  },
}
</script>

<style scoped>
.dropzone-document-title {
  display: block;
  text-align: center;
}
</style>
