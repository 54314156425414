import { sysAxios } from '@/plugins/axios'
import _ from 'lodash'

const getDataExtraction = (fileId, dataSourceSystem) => {
  const api = `/uploads/v1/${fileId}/extractdata/${dataSourceSystem}`
  return sysAxios.get(api)
}

const fileUpload = (tagName, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `uploads/v1/${tagName}`
      const response = await sysAxios.post(api, formData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      })
      if(response.status === 200) resolve(response.data)
      else reject(response.error.response.data)
    } catch (err) {
      reject(err)
    }
  })
}

const getExtractdata = ({fileId, format}) => {
  return new Promise(async (resolve, reject) => {
    const api = `/uploads/v1/${fileId}/extractdata/${format}`
    const response = await sysAxios.get(api)
    if(response.status === 204) reject(`You have choosen ${format}. It's Invalid file format for your upload invoice file. Please try again with a valid file format`)
    else resolve(response.data)
  })
}

export default {
  getDataExtraction,
  fileUpload,
  getExtractdata
}
