<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ $t('auth.update.update_update') }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Validation -->
        <div class="intro-y box">
          <div id="form-validation" class="p-5">
            <div class="preview">
              <!-- BEGIN: Validation Form -->
              <form class="validate-form" @submit.prevent="save">
                <div class="input-form">
                  <label for="validation-form-2" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('auth.update.update_new') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('auth.update.update_required') }}</span>
                  </label>
                  <input
                    id="validation-form-2"
                    v-model.trim="validate.newPassword.$model"
                    type="password"
                    name="newPassword"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.newPassword.$error }"
                    placeholder="John Legend"
                  />
                  <template v-if="validate.newPassword.$error">
                    <div
                      v-for="(error, index) in validate.newPassword.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label for="validation-form-1" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('auth.update.update_current') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('auth.update.update_required') }}</span>
                  </label>
                  <input
                    id="validation-form-1"
                    v-model.trim="validate.confirmPassword.$model"
                    type="password"
                    name="confirmPassword"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.confirmPassword.$error }"
                    placeholder="John Legend"
                  />
                  <template v-if="validate.confirmPassword.$error">
                    <div
                      v-for="(error, index) in validate.confirmPassword.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <button type="submit" class="btn btn-primary mt-5">{{ $t('auth.update.update_update') }}</button>
              </form>
              <!-- END: Validation Form -->
            </div>
          </div>
        </div>
        <!-- END: Form Validation -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import toast from '@/utils/toast';
import userService from '@/services/userService'
import i18n from '@/plugins/i18n';


export default {
  setup() {
    const formData = reactive({
      newPassword: "",
      confirmPassword: "",
    });
    const rules = {
      newPassword: { required, minLength: minLength(6)},
      confirmPassword: { required, minLength: minLength(6) },
    };
    const validate = useVuelidate(rules, toRefs(formData));
    const store = useStore()
    const save = async () => {
      validate.value.$touch();

      if (validate.value.$invalid) {
        toast({
          status: 'error',
          title: i18n.global.t('auth.update.update_update_failed'),
          content: i18n.global.t('auth.update.update_please')
        })
      } else {
        if (formData.confirmPassword != formData.newPassword) {
          toast({
            status: 'error',
            title: i18n.global.t('auth.update.update_update_failed'),
            content: 'The passwords must match'
          })
        } else {
          try {
            await userService.updatePassword(store.state.auth.user_id, formData.newPassword)
            toast({
              status: 'success',
              title: i18n.global.t('auth.update.update_update_success'),
              content: i18n.global.t('auth.update.update_password_success')
            })
          } catch (err) {
            toast({
              status: 'error',
              title: i18n.global.t('auth.update.update_update_failed'),
              content: err
            })
          }
        }
      }
    };

    return {
      validate,
      formData,
      save
    };
  }
};
</script>
