/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import * as types from "./mutation-types";
import companyService from "@/services/companyService";

export const setCompanyId = ({commit}, payload) => {
	commit(types.SET_COMPANYID, payload)
}

export const setCompanyIdFromApi = ({commit}, payload) => {
  return new Promise(async resolve => {
    const companyId = await companyService.getCompanyIdByUserId(payload.userId)
    commit(types.SET_COMPANYID, {company_uuid: companyId});
    resolve(companyId)
  })
}

export const setCompanyType = ({commit}, payload) => {
  if(payload.company_uuid === "00000000-0000-0000-0000-000000000000") {
    commit(types.SET_COMPANYTYPE, {company_type: 'guest'});
  } else {
    return new Promise(async (resolve) => {
      const company_type = await companyService.getCompanyTypeByCompanyID(payload.company_uuid)
      commit(types.SET_COMPANYTYPE, {company_type: company_type});
      resolve();
    })
  }
}

export const setCompanyHolidays = ({commit}, payload) => {
  return new Promise(async (resolve) => {
    if(payload.company_uuid !== '00000000-0000-0000-0000-000000000000') {
      const holidayResponse = await companyService.getHolidays(payload.company_uuid)
      const holidays = _.filter(holidayResponse, (holiday) => new Date(holiday.endDate) > new Date())
      commit(types.SET_COMPANY_HOLIDAYS, { holidays })
    }
    resolve();
  })
}

export default {
	setCompanyId,
	setCompanyIdFromApi,
  setCompanyType,
  setCompanyHolidays
};
