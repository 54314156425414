<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">{{ $t('auth.reset.reset_reset') }}</h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              {{ $t('auth.reset.reset_few') }}
            </div>
            <div class="intro-x mt-8">
              <input v-model="newPassword" type="password" class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" :placeholder="$t('auth.reset.reset_ph_newpass')"/>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button class="btn btn-primary py-3 px-4 w-full xl:mr-3 align-top" @click="resetPassword"> {{ $t('auth.reset.reset_ecommerce') }} </button>
              <div class="mt-2 text-center">
                <router-link to="/login" class="text-theme-1 text-center">{{ $t('auth.reset.reset_instead') }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import toast from '@/utils/toast';
import userService from '@/services/userService';
import i18n from '@/plugins/i18n';

export default {
  setup() {
    const route = useRoute();
    const userId = ref('');
    const newPassword = ref('');
    const init = async() => {
      cash("body").removeClass("main").removeClass("error-page").addClass("login");
      try {
        const response = await userService.resetPasswordCheckValidity(route.params.checkValidity)
        userId.value = response.data.userId
        localStorage.setItem('id_token', response.data.token);
      } catch (err) {
        toast({
          status: 'error',
          title: 'token validation error',
          content: err
        })
      }
    }
    onMounted(() => {
      init()
    });

    const resetPassword = async () => {
      try {
        await userService.resetPassword(userId.value, newPassword.value)
        toast({
          status: 'success',
          title: i18n.global.t('auth.reset.reset_reset_success'),
          content: i18n.global.t('auth.reset.reset_you_can')
        })
      } catch (err) {
        toast({
          status: 'error',
          title: i18n.global.t('auth.reset.reset_reset_fail'),
          content: err
        })
      }
    }
    return {
      newPassword,
      resetPassword
    }
  },
};
</script>
