<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ $t('roles.grant.grant_access') }}</h2>
    </div>
    <!-- <div class="grid grid-cols-2 gap-4"> -->
    <div class="grid grid-cols-2 gap-6">
      <div class="box mt-5 px-5 pb-5">
        <RoleList v-if="roles" :roles="roles" :selectRole="selectRole" :addRole="addRole" :deleteRole="deleteRole"/>
      </div>
      <div class="box mt-5 px-5 pb-5">
        <AccessList v-if="roles"
          :rolePermissions="rolePermissions"
          :grantPermissionsDelete="grantPermissionsDelete"
          :grantPermissionsAdd="grantPermissionsAdd"
          :permissionList="permissionList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import AccessList from "./AccessList.vue";
import RoleList from "./RoleList.vue";
import _ from "lodash";
import accessService from "@/services/accessService";
import toast from "@/utils/toast"
import i18n from '@/plugins/i18n';

export default {
  name: 'GrantAccess',
  components: {
    RoleList,
    AccessList,
  },
  setup() {
    const permissionList = ref(null);
    const roles = ref(null);
    const rolePermissions = ref(null);

    const selectRole = (roleId) => {
      rolePermissions.value = _.find(roles.value, {roleId: roleId});
    }

    const addRole = async (role) => {
      const response = await accessService.addRole(role)
      roles.value = response.roles
      rolePermissions.value = response.roles[0]
    }

    const deleteRole = async (role) => {
      try {
        await accessService.deleteRole(role.roleId)
        // console.log(role)
        roles.value = _.remove(roles.value, (item) => item.roleId != role.roleId);
        if(!_.find(roles.value, {roleId: rolePermissions.value.roleId})) rolePermissions.value = roles.value[0];
        toast({status: "success", title: i18n.global.t('roles.grant.grant_delete_success'), content: `i18n.global.t('roles.grant.grant_delete_success_has1') ${role.roleName} i18n.global.t('roles.grant.grant_delete_success_has2')`})
      } catch (err) {
        toast({status: "error", title: i18n.global.t('roles.grant.grant_delete_fail'), content: err})
      }
    }

    const grantPermissionsDelete = async (role) => {
      try {
        const { permissionName } = _.find(rolePermissions.value.permissions, { permissionId: role.permissionId })
        const { roleName } = rolePermissions.value

        rolePermissions.value.permissions = _.remove(rolePermissions.value.permissions, item => item.permissionId != role.permissionId);
        const roleData = {
          roleName: rolePermissions.value.roleName,
          permissionsIds: _.toArray(rolePermissions.value.permissions).map(item => item.permissionId),
          validUntil: rolePermissions.value.validUntil
        }
        await accessService.updateRole({roleId: rolePermissions.value.roleId, roleData})
        toast({ status: "success", title: i18n.global.t('roles.grant.grant_delete_permission_done'), content: `You removed ${permissionName} in ${roleName} user role`})
      } catch (err) {
        toast({ status: "error", title: i18n.global.t('roles.grant.grant_delete_permission_fail'), content: err})
      }
    }

    const grantPermissionsAdd = async (addedPermission) => {
      try {
        const roleData = {
          roleName: rolePermissions.value.roleName,
          permissionsIds: _.map([...rolePermissions.value.permissions, ...addedPermission], "permissionId"),
          validUntil: rolePermissions.value.validUntil
        }
        await accessService.updateRole({roleId: rolePermissions.value.roleId, roleData})
        rolePermissions.value.permissions = [...rolePermissions.value.permissions, ...addedPermission]
        toast({ status: "success", title: i18n.global.t('roles.grant.grant_add_permission_done'), content: ""})
      } catch (err) {
        toast({ status: "error", title: i18n.global.t('roles.grant.grant_add_permission_fail'), content: err})
      }
    }

    onMounted(async () => {
      permissionList.value = await accessService.getPermissions()
      roles.value = await accessService.getRoles()
      rolePermissions.value = roles.value[0];
    })

    return {
      roles,
      permissionList,
      rolePermissions,
      selectRole,
      addRole,
      deleteRole,
      grantPermissionsDelete,
      grantPermissionsAdd
    }
  },
}
</script>
