<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">{{ $t('permissions.list.edit_list_permissions') }}</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <button class="btn btn-primary shadow-md mr-2" @click="router.push('/permission/add')">{{ $t('permissions.list.edit_list_add') }}</button>
        <div class="dropdown">
          <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a href="" class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                <PrinterIcon class="w-4 h-4 mr-2" /> {{ $t('permissions.list.edit_list_print') }}
              </a>
              <a href="" class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                <FileTextIcon class="w-4 h-4 mr-2" /> {{ $t('permissions.list.edit_list_excel') }}
              </a>
              <a href="" class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                <FileTextIcon class="w-4 h-4 mr-2" /> {{ $t('permissions.list.edit_list_pdf') }}
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600"></div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..."/>
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"/>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">{{ $t('permissions.list.edit_list_name') }}</th>
              <th class="whitespace-nowrap">{{ $t('permissions.list.edit_list_url') }}</th>
              <th class="text-center whitespace-nowrap">{{ $t('permissions.list.edit_list_expire') }}</th>
              <th class="text-center whitespace-nowrap">{{ $t('permissions.list.edit_list_type') }}</th>
              <th class="text-center whitespace-nowrap">{{ $t('permissions.list.edit_list_actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(permission, permissionKey) in permissions" :key="permissionKey" class="intro-x">
              <td>
                <a class="font-medium whitespace-nowrap">{{permission.permissionName}}</a>
              </td>
              <td>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5"> {{ permission.resourceURI }}</div>
              </td>
              <td class="text-center">{{ !permission.validUntil ? "never expired" : "permission.validUntil" }}</td>
              <td class="w-40">
                <div class="flex items-center justify-center">
                  {{ permission.type }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center cursor-pointer">
                  <a class="flex items-center mr-3" @click="router.push(`/permission/edit/${permission.permissionId}`)">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    {{ $t('permissions.list.edit_list_edit') }}
                  </a>
                  <a class="flex items-center text-theme-6 cursor-pointer" data-toggle="modal" data-target="#delete-confirmation-modal" @click="setDeletePermissionId(permission.permissionId)">
                    <Trash2Icon class="w-4 h-4 mr-1" /> {{ $t('permissions.list.edit_list_delete') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <ConfirmDialog
      title="Deleting Permission"
      content="Do you really want to delete these permission? This processcannot be undone."
      :dialogShow="dialog"
      :cancelDialog="cancelDialog"
      :callBack="deletePermission"
    />
    <router-view></router-view>
  </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router"
import toast from "@/utils/toast";
import accessService from "@/services/accessService"
import _ from "lodash";
import i18n from '@/plugins/i18n';

export default {
  setup() {
    const router = useRouter()
    const permissions = ref([]);
    const deletePermissionId = ref(null);
    const dialog = ref(false)

    const setDeletePermissionId = (permissionId) => {
      deletePermissionId.value = permissionId;
      dialog.value = true;
    }
    const deletePermission = async () => {
      try {
        await accessService.deletePermission(deletePermissionId.value)
        _.remove(permissions.value, {permissionId: deletePermissionId.value})
        dialog.value = false
        toast({status: "success", title: i18n.global.t('permissions.list.edit_delete_done'), content: ""})
      } catch (err) {
        toast({status: "error", title: i18n.global.t('permissions.list.edit_delete_fail'), content: err})
      }
    }
    const cancelDialog = () => {
      dialog.value = false
    }

    onMounted(async () => {
      permissions.value = await accessService.getPermissions()
    })

    return {
      permissions,
      setDeletePermissionId,
      deletePermission,
      dialog,
      cancelDialog,
      router
    }
  }
}
</script>
