<template>
  <div>
    <div class='intro-y flex items-center mt-8'>
      <h2 class='text-lg font-medium mr-auto'>{{ $t('invoice.invoice_invoices') }}</h2>
    </div>
    <div class='intro-y box p-5 mt-5'>
      <div class='flex flex-col sm:flex-row sm:items-end xl:items-start gap-2'>
        <div class='flex mt-5 sm:mt-0' v-if="userRole == 'System Admin'">
          <a href='javascript:;' data-toggle='modal' data-target='#upload-invoice-modal' class='btn btn-outline-primary w-full w-max mr-2' v-if='isCompany'>
            <UploadIcon id="unique_id01" class='w-4 h-4 mr-2' /> {{ $t('invoice.invoice_upload_invoice') }}&nbsp;{{ $t('invoice.invoice_upload_invoice2') }}
          </a>
        </div>
        <div class='w-full grid grid-cols-2 lg:flex justify-end gap-2'>
          <div class='sm:flex justify-end items-center'>
            <label class='w-12 flex-none xl:w-auto xl:flex-initial mr-2'>{{ $t('invoice.invoice_field') }}</label>
            <select
              id='tabulator-html-filter-field'
              v-model='filter.field'
              class='form-select w-full xxl:w-full mt-2 sm:mt-0 sm:w-auto'
            >
              <!-- <option value='lastUpdatedBy'>{{ $t('invoice.invoice_last_updated') }}</option> -->
              <option value='sellerCompanyName'>{{ $t('invoice.invoice_seller') }}</option>
              <option value='buyerCompanyName'>{{ $t('invoice.invoice_buyer') }}</option>
              <option value='funderCompanyName'>{{ $t('invoice.invoice_funder') }}</option>
              <option value='batchNumber'>{{ $t('invoice.invoice_batch_no') }}</option>
              <option value='batchStatus'>{{ $t('invoice.invoice_batch_st') }}</option>
              <option value='remarks' selected>{{ $t('invoice.invoice_batch_remark') }}</option>
            </select>
          </div>
          <div class='sm:flex justify-end items-center xl:mt-0 '>
            <label class='w-12 flex-none xl:w-auto xl:flex-initial mr-2'>{{ $t('invoice.invoice_type') }}</label>
            <select
              id='tabulator-html-filter-type'
              v-model='filter.type'
              class='form-select w-full mt-2 sm:mt-0 sm:w-auto'
            >
              <option value='eq' selected>{{ $t('invoice.invoice_like') }}</option>
              <option value='neq'>{{ $t('invoice.invoice_nlike') }}</option>
              <!-- <option value='like' selected>{{ $t('invoice.invoice_like') }}</option>
              <option value='='>=</option>
              <option value='<'>&lt;</option>
              <option value='<='>&lt;=</option>
              <option value='>'>></option>
              <option value='>='>>=</option>
              <option value='!='>!=</option> -->
            </select>
          </div>
          <div class='sm:flex justify-end items-center '>
            <label class='w-12 flex-none xl:w-auto xl:flex-initial mr-2'>{{ $t('invoice.invoice_search') }}</label>
            <input
              id='tabulator-html-filter-value'
              v-model='filter.value'
              type='text'
              class='form-control sm:w-40 xxl:w-full mt-2 sm:mt-0'
              :placeholder="$t('invoice.invoice_ph_search')"
            />
          </div>
          <div class='flex justify-end items-end gap-2'>
            <button
              id='tabulator-html-filter-go'
              type='button'
              class='btn btn-primary w-16 h-10'
              @click='onFilter'
            >
              {{ $t('invoice.invoice_go') }}
            </button>
            <button
              id='tabulator-html-filter-reset'
              type='button'
              class='btn btn-secondary w-16 h-10'
              @click='onResetFilter'
            >
              {{ $t('invoice.invoice_reset') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class='flex divide-x-2'>
      <div>
        <button id="unique_id01" :class="`btn btn-sm mr-2 ${selectedTab ==='Pending Action' ? 'btn-primary' : 'btn-outline-primary'}`" @click='invoiceFromPendingAction'>{{ $t('invoice.invoice_pending_action') }}</button>
      </div>
      <div>
        <button id="unique_id02" :class="`btn btn-sm ml-2 mx-2 ${selectedTab ==='My Invoice' ? 'btn-primary' : 'btn-outline-primary'}`" @click='invoiceFromMe'>{{ $t('invoice.invoice_my_invoice') }}</button>
      </div>
      <div v-if="!isCompany">
        <button id="unique_id03" :class="`btn btn-sm ml-2 mx-2 ${selectedTab ==='My Bid' ? 'btn-primary' : 'btn-outline-primary'}`" @click='invoiceMyBid'>{{ $t('invoice.invoice_my_bid') }}</button>
      </div>
      <div v-if='isCompany'>
        <button id="unique_id04" :class="`btn btn-sm ml-2 ${selectedTab =='Invoice From My Partner' ? 'btn-primary' : 'btn-outline-primary'}`" @click='invoiceFromMyPartner'>{{ $t('invoice.invoice_invoice_partner') }}</button>
      </div>
    </div>
    <div class='intro-y box px-3 pb-3 mt-3 pt-4 overflow-x-scroll xl:overflow-x-visible'>
      <div v-if='loading' class='py-16'>
        <div class='w-full h-8 px-8'>
          <LoadingIcon icon='spinning-circles' color='gray' class='w-4 h-4 py-8' />
        </div>
      </div>
      <!-- <div v-if='!loading' class='overflow-x-auto scrollbar-hidden'>
        <div id='tabulator' ref='tableRef' class='mt-5 table-report table-report--tabulator'></div>
      </div> -->
      <table class="table stripe" id="datatable" ref="tableRef" style="width:100%"/>

      <!-- Attach this to table after init -->
      <div class="dataTables_info dataTables_length" role="status" aria-live="polite">
        <label>{{ $t('invoice.invoice_page_size') }} 
          <select name="datatable_length" aria-controls="datatable" class="" 
              v-model='mainPage.size' @change="onChangePageSize">
            <option value="10" selected>10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
        <!-- <span class="ml-4" id="datatable_info">Showing 1 to 10 of 10 entries</span> -->
      </div>
      <div class="dataTables_paginate paging_simple_numbers">
        <a href="javascript:void(0);" class="paginate_button first disabled" aria-controls="datatable" tabindex="-1" @click='firstPage'>First</a>
        <a href="javascript:void(0);" class="paginate_button previous disabled" aria-controls="datatable" tabindex="-1" @click='prevPage'>Prev</a>
        <span><a class="paginate_button current" aria-controls="datatable" tabindex="0">1</a></span>
        <a href="javascript:void(0);" class="paginate_button next disabled" aria-controls="datatable" tabindex="-1" @click='nextPage'>Next</a>
        <a href="javascript:void(0);" class="paginate_button last disabled" aria-controls="datatable" tabindex="-1" @click='lastPage'>Last</a>
      </div>
      <!-- Attach this to table after init -->
    </div>
    <InvoiceUploadModal :callback='getInvoiceOverview'/>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watchEffect, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import InvoiceUploadModal from './InvoiceUploadModal'
import _ from 'lodash'
import moment from 'moment'
import ProvenanceLang from '@/utils/provenanceLanguage'
import journalBatchService from '@/services/journalBatchService'
import companyService from '@/services/companyService'
import workflowService from '@/services/workflowService'
import bidService from '@/services/bidService'
import i18n from '@/plugins/i18n';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import $ from "jquery";
import { appAxios } from "@/plugins/axios"

export default {
  components: {
    InvoiceUploadModal
  },
  setup() {
    const store = useStore()
    const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT
    const company_uuid = store.state.account.company_uuid
    const company_type = store.state.account.company_type
    const defaultEcosystemId = ref(store.state.main.defaultEcosystem.ecosystemId)
    const selectedTab = ref('Pending Action')
    const router = useRouter()
    const tableRef = ref()
    const tabulator = ref()
    const datatable = ref()
    const loading = ref(true)
    const isCompany = ref(false)
    const invoiceOverview = ref([])
    const funderVotedBatches = ref([])
    const pendingActions = ref([])
    const companyRole = ref()
    // table variables
    const datatableRequestBody = ref()
    let prevNo = -1
    let nextNo = 1
    let currentPage = 0
    let initialTotalCount = 0
    let initialPageCount = 0
    let initialPerPage = 0
    let initialPage = 0
    let tableColumnFields = [
      'batchNumber',
      'buyerCompanyName',
      'sellerCompanyName',
      'totalAmount',
      'remarks',
      'batchStatus',
      'createdTime',
    ]
    let mainSortField = 'createdTime'
    let mainSortOrder = 'desc'
    // let mainPageSize = 10
    // table variables
    const mainPage = reactive({
      size: 10
    })
    const filter = reactive({
      field: 'remarks',
      type: 'eq',
      value: ''
    })
    // temp
    const products = ref()

    function toolTipHeader(cell, formatterParams, onRendered){
      return '<a href="javascript:;" class="tooltip btn btn-primary"> Show Tooltip </a>'
    };

    const initDatatable = (data) => {
      datatable.value = $(tableRef.value).DataTable({
        data: data,
        info: false,
        ordering: false,
        paging: false,
        searching: false,
        processing: true,
        language: {
          emptyTable: i18n.global.t('invoice.invoice_empty_table'),
          processing: i18n.global.t('invoice.invoice_processing'),
        },
        // serverSide: true,
        // ajax: function (data, callback, settings) {
        //   callback (
        //     data = appAxios.post("/journalbatch/v1/header/d086a4eb-4dbf-4184-71b4-08d99a78ff02?page_number=2&page_size=100", requestBody)
        //   )
        // },
        columnDefs: [
            { width: '15%', targets: 1 },
            { width: '10%', targets: 2 },
            { width: '10%', targets: 3 }
        ],
        columns: [
          {
            title: '',
            data: null,
            className: 'dt-control',
            defaultContent: ''
          }, {
            title: i18n.global.t('invoice.invoice_ttl_batch'),
            data: 'batchNumber',
            render: function(data, type, row) {
              return data;
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_buyer'),
            data: 'buyerCompanyName',
            render: function(data, type, row) {
              return data;
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_seller'),
            data: 'sellerCompanyName',
            render: function(data, type, row) {
              return data;
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_total'),
            data: 'totalAmount',
            render: function(data, type, row) {
              return row.currencyCode + ' ' +row.totalAmount.toFixed(2)
            }
          }, 
          // {
          //   title: i18n.global.t('invoice.invoice_ttl_stage'),
          //   data: 'action',
          //   render: function(data, type, row) {
          //     return ProvenanceLang[row.action]//moment(row.paymentDueDate).format('LL')
          //   }
          // }, 
          {
            title: i18n.global.t('invoice.invoice_ttl_remark'),
            data: 'remarks',
            render: function(data, type, row) {
              return data;
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_status'),
            data: 'batchStatus',
            render: function(data, type, row) {
              // return data;
              return i18n.global.t('invoice.batch.eco.eco_batch_status_'+data.replace(/\ /g, '_').toLowerCase());
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_created'),
            data: 'createdTime',
            render: function(data, type, row) {
              return moment(row.createdTime).format(dateTimeFormat)
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_actions'),
            className: 'nosort',
            data: 'workflowExecutionReferenceId',
            render: function(data, type, row) {
              const a = `<div class='flex lg:justify-center items-center'>
                <a class='flex items-center btn btn-sm btn-primary' href='/invoice/detail/`+row.workflowExecutionReferenceId+`'>
                  `+i18n.global.t('dashboard.dashboard_view')+`
                </a>
              </div>`
              return a;
            }
          },
        ]
      });

      $(".dataTables_info.dataTables_length").detach().appendTo("#datatable_wrapper");
      $(".dataTables_paginate").detach().appendTo("#datatable_wrapper");

      $('.dataTables_wrapper > table th.sorting_disabled:not(.dt-control):not(.nosort)').each(function(index){
        $(this).removeClass('sorting_disabled')
        $(this).addClass('sorting')
        $(this).attr('data-field', tableColumnFields[index])
      })
      $('.dataTables_wrapper > table th.sorting').click(function(event){
        $('.dataTables_wrapper > table th.sorting:not([data-field='+$(this).data('field')+'])').each(function(){
          $(this).removeClass('sorting_asc')
          $(this).removeClass('sorting_desc')
        })
        if ($(this).hasClass('sorting_asc')) {
          // sort descending
          sortTable($(this).data('field'), 'desc')
          $(this).removeClass('sorting_asc')
          $(this).addClass('sorting_desc')
        } else if ($(this).hasClass('sorting_desc')) {
          // sort ascending
          sortTable($(this).data('field'), 'asc')
          $(this).removeClass('sorting_desc')
          $(this).addClass('sorting_asc')
        } else {
          // sort ascending
          sortTable($(this).data('field'), 'asc')
          $(this).removeClass('sorting_desc')
          $(this).addClass('sorting_asc')
        }
      })
 
      // Add event listener for opening and closing details
      $('#datatable tbody').on('click', 'td.dt-control', function () {
          var tr = $(this).closest('tr');
          var row = datatable.value.row(tr);
   
          if (row.child.isShown()) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('shown');
          } else {
              // Open this row
              row.child(format(row.data())).show();
              tr.addClass('shown');
          }

          if (tr.hasClass('odd')) {
            row.child().addClass('odd')
          } else {
            row.child().addClass('even')
          }
      });
    }

    const resetSortHeaderClass = () => { 
      $('.dataTables_wrapper > table th.sorting').each(function(){
        $(this).removeClass('sorting_asc')
        $(this).removeClass('sorting_desc')
      })
    }

    /* Formatting function for row details - modify as you need */
    function format(d) {
        // `d` is the original data object for the row
        return (
            '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
            '<tr style="background: none;">' +
            '<td class="font-bold">'+i18n.global.t('invoice.invoice_ttl_stage')+':</td>' +
            '<td>' +
            ProvenanceLang[d.action] +
            '</td>' +
            '</tr>' +
            '<tr style="background: none;">' +
            '<td class="font-bold">'+i18n.global.t('invoice.invoice_ttl_bidendtime')+':</td>' +
            '<td>' +
            moment(d.bidEndTime).format(dateTimeFormat) +
            '</td>' +
            '</tr>' +
            '</table>'
        );
    }

    const showLimitedPagination = (selector) => {
      $(selector+'[data-page='+(currentPage)+']').attr('style', 'display: inherit')
      if (!$(selector+'[data-page='+(currentPage-2)+']').length) {
        $(selector+'[data-page='+(currentPage+3)+']').attr('style', 'display: inherit')
      } else {
        $(selector+'[data-page='+(currentPage-2)+']').attr('style', 'display: inherit')
      }
      if (!$(selector+'[data-page='+(currentPage-1)+']').length) {
        $(selector+'[data-page='+(currentPage+4)+']').attr('style', 'display: inherit')
      } else {
        $(selector+'[data-page='+(currentPage-1)+']').attr('style', 'display: inherit')
      }
      $(selector+'[data-page='+(currentPage-1)+']').attr('style', 'display: inherit')
      $(selector+'[data-page='+(currentPage-2)+']').attr('style', 'display: inherit')
      $(selector+'[data-page='+(currentPage+1)+']').attr('style', 'display: inherit')
      $(selector+'[data-page='+(currentPage+2)+']').attr('style', 'display: inherit')
      if (!$(selector+'[data-page='+(currentPage+1)+']').length) {
        $(selector+'[data-page='+(currentPage-4)+']').attr('style', 'display: inherit')
      } else {
        $(selector+'[data-page='+(currentPage+1)+']').attr('style', 'display: inherit')
      }
      if (!$(selector+'[data-page='+(currentPage+2)+']').length) {
        $(selector+'[data-page='+(currentPage-3)+']').attr('style', 'display: inherit')
      } else {
        $(selector+'[data-page='+(currentPage+2)+']').attr('style', 'display: inherit')
      }
    }

    const updateDatatable = (updatedData) => {
      // console.log(updatedData)
      datatable.value.clear().draw()
      if (updatedData && updatedData.data && updatedData.metadata) {
        const data = updatedData.data
        initialTotalCount = updatedData.metadata.totalCount
        initialPageCount = updatedData.metadata.pageCount
        initialPerPage = updatedData.metadata.perPage
        mainPage.size = initialPerPage
        initialPage = updatedData.metadata.page
        // if (initialPage > 1) {
        //   $('#datatable_info').text('Showing '+((data.length > 0)? (initialPage*initialPerPage)-10:'0')+' to '+((initialTotalCount < initialPerPage)? initialTotalCount:(initialPage*initialPerPage)+initialPerPage-10)+' of '+initialTotalCount+' entries')
        // } else {
        //   $('#datatable_info').text('Showing '+((data.length > 0)? (initialPage):'0')+' to '+((initialTotalCount < initialPerPage)? initialTotalCount:(initialPage)+initialPerPage)+' of '+initialTotalCount+' entries')
        // }
        // console.log('datatableRequestBody:')
        // console.log(datatableRequestBody.value)
        // console.log('data:')
        // console.log(data)
        // console.log('filter:')
        // console.log(filter)
        // console.log('initialTotalCount:')
        // console.log(initialTotalCount)
        // console.log('initialPerPage:')
        // console.log(initialPerPage)
        // console.log('initialPageCount:')
        // console.log(initialPageCount)
        // console.log('initialPage:')
        // console.log(initialPage)

        if(data.length > 0 ){
          datatable.value.rows.add(data)
          datatable.value.columns.adjust().draw()
        }
        // clear the page numbers
        $('.dataTables_paginate span').empty()
        // update page numbers every time
        for (var i = 0; i < initialPageCount; i++) {
          $('.dataTables_paginate span').append('<a class="paginate_button" aria-controls="datatable" tabindex="0" data-page="'+i+'" style="display: none;">'+(i+1)+'</a>')
        }
        $('.dataTables_paginate span > a.paginate_button[data-page='+currentPage+']').addClass('current')
        showLimitedPagination('.dataTables_paginate span > a.paginate_button')
        $('.dataTables_paginate span > a.paginate_button').click(function(){
          gotoPageNo($(this).data('page'))
          $('.dataTables_paginate span a.paginate_button').removeClass('current')
          currentPage = $(this).data('page')
          $(this).addClass('current')
        })
        // console.log(currentPage)
        // console.log(initialPageCount)
        if (initialPageCount == 1 || currentPage == (initialPageCount-1)) {
          $('.paginate_button.next').addClass('disabled')
          $('.paginate_button.last').addClass('disabled')
        } else { 
          $('.paginate_button.next').removeClass('disabled')
          $('.paginate_button.last').removeClass('disabled')
        }
      } else {
        datatableRequestBody.value = {
          "workflowIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
          "buyerCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
          "sellerCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
          "funderCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
        }
      }
    }

    const sortTable = async (sortField, sortOrder) => {
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
      mainSortField = sortField
      mainSortOrder = sortOrder
      let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, currentPage, mainPage.size, sortField, sortOrder, filter.field, filter.type)
        updateDatatable(updatedData)
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
    }

    const resetPagination = () => {
      prevNo = -1
      nextNo = 1
      currentPage = 0
    }

    const firstPage = async (event) => {
      // console.log(prevNo)

      if (currentPage > 0) {
        prevNo = 0

        $('.paginate_button.previous').addClass('disabled')
        $('.paginate_button.first').addClass('disabled')

        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, prevNo, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
        currentPage = prevNo
        updateDatatable(updatedData)
        $('.paginate_button.next').removeClass('disabled')
        $('.paginate_button.last').removeClass('disabled')
        prevNo = -1
        nextNo = 1
      }
    }
    const lastPage = async (event) => {
      // console.log(currentPage)
      // console.log(initialPageCount)

      let mlastPage = initialPageCount-1
      if (currentPage < mlastPage) {

        $('.paginate_button.next').addClass('disabled')
        $('.paginate_button.last').addClass('disabled')

        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, mlastPage, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
        currentPage = mlastPage
        updateDatatable(updatedData)
        $('.paginate_button.previous').removeClass('disabled')
        $('.paginate_button.first').removeClass('disabled')
        prevNo = initialPageCount-2
        nextNo = initialPageCount
      }
    }
    const prevPage = async (event) => {
      // console.log(prevNo)

      if (prevNo >= 0) {
        if (prevNo == 0) {
          $('.paginate_button.previous').addClass('disabled')
          $('.paginate_button.first').addClass('disabled')
        }
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, prevNo, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
        currentPage = prevNo
        updateDatatable(updatedData)
        $('.paginate_button.next').removeClass('disabled')
        $('.paginate_button.last').removeClass('disabled')
        prevNo--
        nextNo--
      }
    }
    const nextPage = async (event) => {
      // console.log(nextNo)
      // console.log(initialPageCount)

      if (nextNo <= (initialPageCount-1)) {
        if (nextNo == (initialPageCount-1)) {
          $('.paginate_button.next').addClass('disabled')
          $('.paginate_button.last').addClass('disabled')
        }
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, nextNo, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
        currentPage = nextNo
        updateDatatable(updatedData)
        $('.paginate_button.previous').removeClass('disabled')
        $('.paginate_button.first').removeClass('disabled')
        prevNo++
        nextNo++
      }
    }
    const gotoPageNo = async (page_no, event) => {
      // console.log(page_no)
      // console.log(initialPageCount)

      if (page_no >= 0) {
        if (page_no == 0) {
          $('.paginate_button.previous').addClass('disabled')
          $('.paginate_button.first').addClass('disabled')
        } else {
          $('.paginate_button.previous').removeClass('disabled')
          $('.paginate_button.first').removeClass('disabled')
        }
        if (page_no == (initialPageCount-1)) {
          $('.paginate_button.next').addClass('disabled')
          $('.paginate_button.last').addClass('disabled')
        } else {
          $('.paginate_button.next').removeClass('disabled')
          $('.paginate_button.last').removeClass('disabled')
        }
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, page_no, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
        updateDatatable(updatedData)
        prevNo = page_no - 1
        nextNo = page_no + 1
      }
    }

    const initTabulator = (data) => {
      tabulator.value = new Tabulator(tableRef.value, {
        data: data,
        pagination: 'local',
        paginationSize: 10,
        paginationSizeSelector: [5, mainPage.size, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: i18n.global.t('invoice.invoice_ph_nomatch'),
        tooltipsHeader:true,
        columns: [
          {
            title: i18n.global.t('invoice.invoice_ttl_batch'),
            field: 'batchNumber',
            minWidth: 50,
            resizable: true,
            headerTooltip:'THIS IS HEADER TOOL TIP'
          }, {
            title: i18n.global.t('invoice.invoice_ttl_buyer'),
            field: 'buyerCompanyName',
            headerHozAlign: 'center',
            hozAlign: 'center',
            resizable: true,
          }, {
            title: i18n.global.t('invoice.invoice_ttl_seller'),
            field: 'sellerCompanyName',
            headerHozAlign: 'center',
            hozAlign: 'center',
            resizable: true,
          }, {
            title: i18n.global.t('invoice.invoice_ttl_total'),
            field: 'totalAmount',
            minWidth: 100,
            maxWidth: 170,
            hozAlign: 'right',
            resizable: true,
            headerSort: true,
            formatter(cell) {
              return cell.getData().currencyCode + ' ' +cell.getData().totalAmount.toFixed(2)
            },
          }, {
            title: i18n.global.t('invoice.invoice_ttl_stage'),
            hozAlign: 'center',
            headerHozAlign: 'center',
            resizable: true,
            formatter(cell) {
              return ProvenanceLang[cell.getData().action]//moment(cell.getData().paymentDueDate).format('LL')
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_remark'),
            field: 'remarks',
            hozAlign: 'center',
            resizable: true,
          }, {
            title: i18n.global.t('invoice.invoice_ttl_status'),
            field: 'batchStatus',
            hozAlign: 'center',
            headerHozAlign: 'center',
            resizable: true,
          }, {
            title: i18n.global.t('invoice.invoice_ttl_created'),
            minWidth: 150,
            hozAlign: 'center',
            headerHozAlign: 'center',
            resizable: true,
            headerSort: true,
            formatter(cell) {
              return moment(cell.getData().createdTime).format(dateTimeFormat)
            }
          }, {
            title: i18n.global.t('invoice.invoice_ttl_actions'),
            maxWidth: 130,
            responsive: 1,
            headerHozAlign: 'center',
            hozAlign: 'center',
            vertAlign: 'middle',
            formatter(cell) {
              const a = cash(`<div class='flex lg:justify-center items-center'>
                <a class='flex items-center btn btn-sm btn-primary' href='javascript:;'>
                  View
                </a>
              </div>`)
              cash(a).on('click', function() {
                router.push({name: 'BATCH_DETAIL', params: {'workflowExecutionReferenceId': cell.getData().workflowExecutionReferenceId}})
              })
              return a[0]
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    // Filter function
    const onFilter = async () => {
      // tabulator.value.setFilter(filter.field, filter.type, filter.value)
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
      let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
      resetPagination()
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
      updateDatatable(updatedData)
    }
    // On reset filter
    const onResetFilter = () => {
      filter.field = 'remarks'
      filter.type = 'eq'
      filter.value = ''
      onFilter()
    }
    // On change page size
    const onChangePageSize = async () => {
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
      let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, mainSortField, mainSortOrder, filter.field, filter.type)
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
      resetPagination()
      updateDatatable(updatedData)
    }
    const getInvoiceOverview = async () => {
      invoiceOverview.value = await journalBatchService.getInvoicesRelatedToCompanyId(store.state.account.company_uuid)
    }
    const getPendingAction = async () => {
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
      initialTotalCount = 0
      initialPageCount = 0
      initialPerPage = 0
      let updatedData = []
      const dashboardData = await companyService.getCompanyDashboardData({companyId: company_uuid, ecosystemId: defaultEcosystemId.value})

      let pendingItem = dashboardData.data.transactionsSnapShot.pendingForAction.groupingByAction
      let pendingAction = {}
      let workflowExecutionidList = []
      if(pendingItem.length > 0) {
        for(let i = 0; i < pendingItem.length; i ++) {
          for (var j = 0; j < pendingItem[i].workflowExecutionids.length; j++) {
            workflowExecutionidList.push(pendingItem[i].workflowExecutionids[j])
          }
        }
        // create array batch
        datatableRequestBody.value = {
          "workflowIds": workflowExecutionidList,
          "buyerCompanyIds": [],
          "sellerCompanyIds": [],
          "funderCompanyIds": [],
        }
        updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, 'createdTime', 'desc', filter.field, filter.type)
      }
      if(store.state.account.company_type.toLowerCase() == 'funder') {
        if(dashboardData.data.bidInvitations != null) {
          let pendingBid = dashboardData.data.bidInvitations.open
          if(pendingBid.workflowExecutionids.length > 0) {
            // create array batch
            datatableRequestBody.value = {
              "workflowIds": pendingBid.workflowExecutionids,
              "buyerCompanyIds": [],
              "sellerCompanyIds": [],
              "funderCompanyIds": [],
            }
            updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, 'createdTime', 'desc', filter.field, filter.type)
          }
        }
      }
      resetSortHeaderClass()
      updateDatatable(updatedData)
      $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
    }
    const getMyBidInvoices = async () => {
     if(company_type !== 'company') {
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();
        let funderMyBidWorkflowIds = await bidService.funderGetMyBidWorkflowIds(company_uuid)
        // console.log(company_uuid)
        // console.log(funderMyBidWorkflowIds)
        if (funderMyBidWorkflowIds && funderMyBidWorkflowIds.length > 0) {
          // create array batch
          datatableRequestBody.value = {
            "workflowIds": funderMyBidWorkflowIds,
            "buyerCompanyIds": [],
            "sellerCompanyIds": [],
            "funderCompanyIds": [],
          }
        } else {
          datatableRequestBody.value = {
            "workflowIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
            "buyerCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
            "sellerCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
            "funderCompanyIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa0"],
          }
        }
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, 'createdTime', 'desc', filter.field, filter.type)
        resetSortHeaderClass()
        updateDatatable(updatedData)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
      }
    }
    const invoiceFromMe = async () => {
      if (selectedTab.value != 'My Invoice') {
        selectedTab.value = 'My Invoice'
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();

        initialTotalCount = 0
        initialPageCount = 0
        initialPerPage = 0

        if(store.state.account.company_type.toLowerCase() == 'funder') {
          datatableRequestBody.value = {
            "workflowIds": [],
            "buyerCompanyIds": [],
            "sellerCompanyIds": [],
            "funderCompanyIds": [
              store.state.account.company_uuid
            ],
          }
        } else {
          if (companyRole.value == 'buyer') {
            datatableRequestBody.value = {
              "workflowIds": [],
              "buyerCompanyIds": [
                store.state.account.company_uuid
              ],
              "sellerCompanyIds": [
              ],
              "funderCompanyIds": []
            }
          } else {
            datatableRequestBody.value = {
              "workflowIds": [],
              "buyerCompanyIds": [
              ],
              "sellerCompanyIds": [
                store.state.account.company_uuid
              ],
              "funderCompanyIds": []
            }
          }
        }
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, 'createdTime', 'desc', filter.field, filter.type)
        resetSortHeaderClass()
        updateDatatable(updatedData)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
      }
    }
    const invoiceFromMyPartner = async () => {
      if (selectedTab.value != 'Invoice From My Partner') {
        selectedTab.value = 'Invoice From My Partner'
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).show();

        initialTotalCount = 0
        initialPageCount = 0
        initialPerPage = 0

        // let initiatedByMe = _.filter(invoiceOverview.value, {initiatedByCompanyId: store.state.account.company_uuid})
        if (companyRole.value == 'buyer') {
          datatableRequestBody.value = {
            "workflowIds": [],
            "buyerCompanyIds": [
            ],
            "sellerCompanyIds": [
              store.state.account.company_uuid
            ],
            "funderCompanyIds": []
          }
        } else {
          datatableRequestBody.value = {
            "workflowIds": [],
            "buyerCompanyIds": [
              store.state.account.company_uuid
            ],
            "sellerCompanyIds": [
            ],
            "funderCompanyIds": []
          }
        }
        let updatedData = await journalBatchService.getInvoicesByPagination(datatableRequestBody.value, filter.value, 0, mainPage.size, 'createdTime', 'desc', filter.field, filter.type)
        // let updatedData = _.orderBy(_.differenceBy(invoiceOverview.value, initiatedByMe, 'workflowExecutionReferenceId'),'createdTime','desc')
        resetSortHeaderClass()
        updateDatatable(updatedData)
        $('.dataTables_processing', $(tableRef.value).closest('.dataTables_wrapper')).hide();
      }
    }
    const invoiceMyBid = async() => {
      if (selectedTab.value != 'My Bid') {
        selectedTab.value = 'My Bid'
        getMyBidInvoices()
      }
    }
    const invoiceFromPendingAction = () => {
      if (selectedTab.value != 'Pending Action') {
        selectedTab.value = 'Pending Action'
        getPendingAction()
      }
    }

    const checkBuyerOrSeller = async () => {
      // check buyer or seller
      let initalCheckRequestBody = {
        "workflowIds": [],
        "buyerCompanyIds": [
          store.state.account.company_uuid
        ],
        "sellerCompanyIds": [
          store.state.account.company_uuid
        ],
        "funderCompanyIds": []
      }
      let initialData = await journalBatchService.getInvoicesByPagination(initalCheckRequestBody, null, 1, 1, null, null)
      // set buyer or seller
      if (initialData.data.length > 0) {
        if (initialData.data[0].buyerCompanyId == store.state.account.company_uuid) {
          companyRole.value = "buyer"
        } else {
          companyRole.value = "seller"
        }
      }
    }

    watchEffect(() => {
      if(store.state.main.defaultEcosystem.ecosystemId !== defaultEcosystemId.value) {
        defaultEcosystemId.value = store.state.main.defaultEcosystem.ecosystemId
        getPendingAction()
      }
    })

    onMounted(async () => {
      checkBuyerOrSeller()
      initDatatable()
      // getInvoiceOverview()
      getPendingAction()
      // getMyBidInvoices()
      if(store.state.account.company_type.toLowerCase() == 'company'){
        isCompany.value = true
      }
      reInitOnResizeWindow()
      loading.value = false
    })

    return {
      company_type,
      dateTimeFormat,
      selectedTab,
      isCompany,
      loading,
      tableRef,
      filter,
      mainPage,
      prevPage,
      nextPage,
      onFilter,
      onResetFilter,
      onChangePageSize,
      firstPage,
      lastPage,
      getPendingAction,
      getInvoiceOverview,
      invoiceFromMe,
      invoiceFromMyPartner,
      invoiceFromPendingAction,
      invoiceMyBid,
      ProvenanceLang,
      userRole: store.state.auth.user_role,
      // temp
      datatable,
      initialPageCount
    }
  },
}
</script>
<style>
  table.dataTable thead th {
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    color: #4e5764;
  }
  table.dataTable.no-footer {
    border-bottom: 1px solid #e2e8f0;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    border: none!important;
    background:none!important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #e2e8f0!important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    color: #cbd5e0!important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    color: #cbd5e0!important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:not(.disabled):hover {
    background: #e2e8f0!important;
    color: #333!important;
  }
  .dataTables_wrapper .dataTables_paginate {
    padding-top: 0.755em;
  }
</style>