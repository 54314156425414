<template>
  <FullCalendar :options="options" ref="calendarRef"/>
</template>

<script>
import { ref } from "vue"
import "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from 'moment';
import { useStore } from 'vuex';
import CompanyService from '@/services/companyService';

const isInRecycleBin = (jsEvent) => {
  const recycleBinPosition = cash("#event-trash-bin").offset()
  const recycleBinWidth = cash("#event-trash-bin").width()
  const recycleBinHeight = cash("#event-trash-bin").height()
  if(jsEvent.pageX > recycleBinPosition.left
    && jsEvent.pageX < recycleBinPosition.left + recycleBinWidth
    && jsEvent.pageY > recycleBinPosition.top
    && jsEvent.pageY < recycleBinPosition.top + recycleBinHeight)
  return true
  return false
}

export default {
  props: {
    holidays: Array,
    addToRecycleDays: Function,
  },
  setup(props) {
    const store = useStore()
    const calendarRef = ref()
    const companyId = store.state.account.company_uuid
    const options = {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      droppable: true,
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
      },
      initialDate: new Date(),
      navLinks: true,
      editable: true,
      dayMaxEvents: true,
      events: props.holidays,
      eventDragStop: async function({event, jsEvent}) {
        if(isInRecycleBin(jsEvent)){
          props.addToRecycleDays({
            title: event._def.title,
            duration: {
              days: moment(event._instance.range.end).diff(moment(event._instance.range.start), "days"),
            },
            time: "7:00AM"
          })
          event.remove()
          await CompanyService.removeHoliday({ companyId, holidayId: event._def.publicId })
        }
      },
      eventChange: async function({event}) {
        const { id, title, startStr, endStr } = event
        await CompanyService.updateHoliday({
          companyId,
          holidayCalendarEntryId: id,
          startDate: startStr,
          endDate: endStr,
          description: title,
          label: "holiday"
        })
      },
      drop: async function(info) {
        const eventData = cash(info.draggedEl).data("event")
        const startDate = new Date(info.date)
        const endDate = new Date(info.date)
        endDate.setDate(endDate.getDate() + eventData.duration.days)
        await CompanyService.addHoliday({
          companyId,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          description: eventData.title,
          label: 'holiday'
        })
        if(cash(info.draggedEl).parent().parent().parent().attr("id") === "event-trash-bin") {
          cash(info.draggedEl).parent().remove();
          if (cash("#recycle-calendar-events").children().length == 1) {
            cash("#recycle-calendar-no-events").removeClass("hidden");
          }
        } else {
          if ( cash("#checkbox-events").length && cash("#checkbox-events")[0].checked ) {
            cash(info.draggedEl).parent().remove();
            if (cash("#draft-calendar-events").children().length == 1) {
              cash("#draft-calendar-no-events").removeClass("hidden");
            }
          }
        }
      }
    };
    return {
      options,
      calendarRef
    };
  }
};
</script>
