/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  company_uuid: "",
  company_type: "",
  holidays: []
};
