/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import * as types from "./mutation-types";
import router from "@/router";
import { useStore } from "@/store";
import userService from "@/services/userService";

export const login = async ({commit}, payload) => {
  return new Promise( async (resolve, reject) => {
    const store = useStore();
    try {
      const response = await userService.login(payload)
      commit(types.LOGIN, response.data);
      await store.dispatch('account/setCompanyIdFromApi', {userId: response.data.userId})
      await store.dispatch('account/setCompanyType', {company_uuid: store.state.account.company_uuid})
      await store.dispatch('main/updateEcosystems', {company_uuid: store.state.account.company_uuid})
      store.dispatch('main/updateMenu', {userId: response.data.userId}).then(() => {
        if(response.data.roles[0] === 'guest') router.push({path: 'account'})
        else router.push({name: 'GENIE_DASHBOARD'})
      })
      resolve(response)
    } catch(err) {
      reject(err)
    }
  })

};

export const logout = ({commit}) => {
	commit(types.LOGOUT);
	router.push({name: 'login'})
};

export default {
	login,
	logout,
};
