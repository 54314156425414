import { sysAxios } from "@/plugins/axios"
import _ from "lodash"
import moment from "moment"

const verifyEntitySupportingDocuments = ({ batchId, traceId, documentNumber, entryType, documents = [] }) => {
  return new Promise(async resolve => {
    const api = `traceability/v3/verifyblockbybatch`
    const requestBody = documents.map(document => {
      return {
        entryHash: document.entryHash,
        metadata: {
          batchId: batchId,
          parentEntryHash: traceId,
          supportingdocumentname: document.documentName,
          supportingdocumentcategory: "Supporting Document",
          identity: document.uploadedByUserId,
          dataHash: JSON.stringify(document.dataHash)
        }
      }
    })
    const response = await sysAxios.post(api, requestBody)
    resolve(response.data)
  })
}

const verifyWorkflowStatus = ({traceId, workStatusList}) => {
  return new Promise(async resolve => {
    const api = `/traceability/v3/verifyblockbybatch`
    const response = await sysAxios.post(api, workStatusList)
    resolve(response.data)
  })
}

export default {
  verifyEntitySupportingDocuments,
  verifyWorkflowStatus
}
