import { appAxios } from '@/plugins/axios'
import userService from './userService';

export const getWorkflowLists = () => {
  const api = 'workflow/v2/00000000-0000-0000-0000-000000000000/children?visibility=true';
  return appAxios.get(api)
}

export const getWorkflowById = (workflowId) => {
  return new Promise(async resolve => {
    const api = `/workflow/v2/${workflowId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

export const getCurrentWorkflowStatus = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    var api = '/workflow/v2/statustransition/retrieve/byreferenceids?visibility=true'
    const response = await appAxios.post(api, [workflowExecutionReferenceId])
    resolve(response.data[0])
  })
}

export const getLastWorkflowStatus = (workflowExecutionReferenceIds) => {
  return new Promise(async resolve => {
    const api = '/workflow/v2/statustransition/retrieve/byreferenceids/limittolaststatustransition'
    const response = await appAxios.post(api, [...workflowExecutionReferenceIds])
    resolve(response.data[0].workflow.lastStatusTransition)
  })
}

export const getBatchWorkflowLed = ({workflowExecutionReferenceId, buyerLedWorkflowId, sellerLedWorkflowId}) => {
  return new Promise(async (resolve, reject) => {
    var api = '/workflow/v2/statustransition/retrieve/byreferenceids?visibility=true'
    const response = await appAxios.post(api, [workflowExecutionReferenceId])
    if(response.data[0].rootWorkflowId === buyerLedWorkflowId) resolve('Buyer Led')
    else if(response.data[0].rootWorkflowId === sellerLedWorkflowId) resolve('Seller Led')
    else reject('no matched workflow')
  })
}

export const getProvenanceHistory = (workflowExecutionReferenceId) => {
  return new Promise(async resolve => {
    const { workflows } = await getCurrentWorkflowStatus(workflowExecutionReferenceId)
    resolve(workflows.map(item => item.statusTransitions.sort((a, b) => a.order - b.order)).flat())
  })
}

export const getLastUpdatedBy = async (invoices) => {
  invoices = invoices ?? []
  // console.log("invoices: ", invoices)
  const api = '/workflow/v2/statustransition/retrieve/byreferenceids/limittolaststatustransition'
  const lastWorkflowStatus = await appAxios.post(api, _.map(invoices, 'workflowExecutionReferenceId')).then(res => res.data)
  var withLastUpdatedBy = []
  await Promise.all(
    invoices.map( async invoice => {
      const lastWorkflowData = _.find(lastWorkflowStatus, {externalReferenceId: invoice.workflowExecutionReferenceId})
      const userId = lastWorkflowData.workflow.lastStatusTransition.updateBy

      if(userId === '00000000-0000-0000-0000-000000000000') {
        withLastUpdatedBy.push({...invoice, lastUpdatedBy: 'System', action: lastWorkflowData.workflow.lastStatusTransition.statusName})
      }
      else {
        // const userData = await userService.getUserProfileByUserID(userId).then(res => res.data)
        // console.log(userData.firstName)
        // withLastUpdatedBy.push({...invoice, lastUpdatedBy: userData.firstName + ' ' + userData.lastName, action: lastWorkflowData.workflow.lastStatusTransition.statusName})
        withLastUpdatedBy.push({...invoice, action: lastWorkflowData.workflow.lastStatusTransition.statusName})
      }
    })
  )
  return new Promise(resolve => resolve(withLastUpdatedBy))
}

export default {
  getWorkflowLists,
  getWorkflowById,
  getBatchWorkflowLed,
  getCurrentWorkflowStatus,
  getLastWorkflowStatus,
  getProvenanceHistory,
  getLastUpdatedBy
}
