<template>
  <div class='flex items-center h-full'>
    <div class='grid grid-cols-6 gap-4 w-full'>
      <!-- BEGIN: Register Form -->
      <div></div>
      <div class='box intro-x col-span-4 self-center px-8 py-12'>
        <img class='' :src='require(`@/assets/images/logo.svg`)' height='100' width='100'/>
        <h2 class='intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left pt-4'>{{ $t('auth.register.register_portal') }}l</h2>
        <div class='intro-x mt-2 dark:text-gray-500'>
          {{ $t('auth.register.register_new') }}
        </div>
        <div class='intro-x mt-8 grid grid-cols-2 gap-4'>
          <div>
            <label>{{ $t('auth.register.register_first_name') }}</label>
            <input
              type='text'
              v-model='formData.firstName'
              name='firstName'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_first')"/>
            <template v-if='$v.firstName.$error'>
              <div v-for='(error, index) in $v.firstName.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
          <div>
            <label>{{ $t('auth.register.register_last_name') }}</label>
            <input
              type='text'
              v-model='formData.lastName'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_last')"/>
            <template v-if='$v.lastName.$error'>
              <div v-for='(error, index) in $v.lastName.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
          <div>
            <label>{{ $t('auth.register.register_username') }}</label>
            <input
              type='text'
              v-model='formData.userName'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_user')"/>
            <template v-if='$v.userName.$error'>
              <div v-for='(error, index) in $v.userName.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
          <div>
            <label>{{ $t('auth.register.register_email') }}</label>
            <input
              type='text'
              v-model='formData.emailAddress'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_email')"/>
            <template v-if='$v.emailAddress.$error'>
              <div v-for='(error, index) in $v.emailAddress.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
          <div class='col-span-2'>
            <label>{{ $t('auth.register.register_display_name') }}</label>
            <input
              type='text'
              v-model='formData.displayName'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_display')"/>
            <template v-if='$v.displayName.$error'>
              <div v-for='(error, index) in $v.displayName.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
          <password-strength v-model='formData.secret'/>
          <div>
            <label>{{ $t('auth.register.register_confirm_password') }}</label>
            <input
              type='password'
              v-model='formData.confirmSecret'
              class='intro-x form-control py-3 px-4 border-gray-300 block'
              :placeholder="$t('auth.register.register_ph_passconfirm')"/>
            <template v-if='$v.confirmSecret.$error'>
              <div v-for='(error, index) in $v.confirmSecret.$errors' :key='index' class='text-theme-6 mt-2'>
                {{ error.$message }}
              </div>
            </template>
          </div>
        </div>
        <div class='flex justify-center'>
          <div class='intro-x mt-5 xl:mt-8 text-center xl:text-left w-1/2'>
            <button class='btn btn-primary py-3 px-4 w-full xl:mr-3 align-top' @click='submit' :disabled='loading'>
              {{ $t('auth.register.register_sign_up') }}
              <LoadingIcon v-if='loading' icon='oval' color='white' class='w-4 h-4 ml-2' />
            </button>
            <div class='intro-x flex justify-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm'>
              <label class='cursor-pointer select-none' for='remember-me'>{{ $t('auth.register.register_already') }} </label>
              <a class='text-theme-1 dark:text-theme-10 ml-1' href='javascript:;' @click='gotoSignIn'>{{ $t('auth.register.register_instead') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <!-- END: Register Form -->
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref, computed } from 'vue';
import {useRouter} from 'vue-router'
import { useStore } from 'vuex'
import passwordStrength from '@/components/password-strength/Main.vue'
import { required, minLength, email, sameAs, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core'
import toast from '@/utils/toast'
import userService from '@/services/userService'
import i18n from '@/plugins/i18n';

export default {
  components: {passwordStrength},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formData = reactive({
      userName: null,
      secret: '',
      confirmSecret: '',
      emailAddress: null,
      firstName: null,
      lastName: null,
      displayName: null,
      applicationDomain: 'genie'
    });
    const rules = computed(() => {
      return {
        userName: { required },
        emailAddress: { required, email },
        firstName: { required },
        lastName: { required },
        displayName: { required, minLength: minLength(5) },
        secret: { required, minLength: minLength(6) },
        confirmSecret: {
          required,
          sameAsSecret: helpers.withMessage(() => i18n.global.t('auth.register.register_s_confirm'), sameAs(formData.secret))
        }
      }
    })
    const loading = ref(false)
    const $v = useVuelidate(rules, formData);
    const submit = async () => {
      loading.value = true
      $v.value.$touch();
      if ($v.value.$invalid) {
        loading.value = false
      } else {
        try {
          await userService.register(formData)
          await store.dispatch('auth/login', {userName: formData.userName, applicationDomain: formData.applicationDomain, secret: formData.secret})
          cash('body').removeClass('register')
          loading.value = false
        } catch(err) {
          // console.log("err: ", err)
          loading.value = false
          toast({status: 'error', title: i18n.global.t('auth.register.register_s_error'), content: err})
        }
      }
    }

    const gotoSignIn = () => {
      router.push({path: 'login'})
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('register');
    });
    return {
      formData,
      $v,
      gotoSignIn,
      submit,
      loading
    }
  }
};
</script>
