{
  "main": {
    "menu": {
      "menu_permissions": "การอนุญาต",
      "menu_system_config": "การกำหนดค่าระบบ",
      "menu_user_management": "การจัดการผู้ใช้",
      "menu_user_group": "กลุ่มผู้ใช้",
      "menu_new_users": "ผู้ใช้ใหม่",
      "menu_relasionship": "ความสัมพันธ์",
      "menu_workflow": "ขั้นตอนการทำงาน",
      "menu_dashboard": "แผงควบคุม",
      "menu_invoices": "ใบแจ้งหนี้",
      "menu_companies": "บริษัท",
      "menu_company_onboarding": "บริษัท onboarding",
      "menu_profile": "ประวัติโดยย่อ"
    },
    "ecosystem": {
      "ecosystem_public_ecosystem": "ระบบนิเวศสาธารณะ"
    }
  },
  "account": {
    "account_settings": "การตั้งค่าบัญชี",
    "account_profile": "ประวัติโดยย่อ",
    "account_role_funder_admin": "ผู้ดูแลระบบ",
    "account_role_buyer_admin": "ผู้ดูแลระบบ",
    "account_role_seller_admin": "ผู้ขายผู้ขาย",
    "account_role_system_admin": "ผู้ดูแลระบบ",
    "account_company": "บริษัท",
    "account_bank": "ธนาคาร",
    "account_currency": "สกุลเงิน",
    "account_kyc": "KYC",
    "account_holidays": "วันหยุด",
    "account_main_logout": "ออกจากระบบ",
    "account_main_appli": "แอปพลิเคชัน",
    "account_main_hi": "สวัสดี",
    "bank": {
      "bank_info": "ข้อมูลธนาคาร",
      "bank_name": "ชื่อธนาคาร",
      "bank_remove": "ลบ",
      "bank_branch_name": "ชื่อสาขาธนาคาร",
      "bank_address": "ที่อยู่ธนาคาร",
      "bank_account_number": "หมายเลขบัญชีธนาคาร",
      "bank_swift_code": "รหัส Swift Bank",
      "bank_currency": "สกุลเงินธนาคาร",
      "bank_add_more": "เพิ่มธนาคารอื่น",
      "bank_save": "บันทึก",
      "bank_add_success": "เพิ่มข้อมูลธนาคารเรียบร้อยแล้ว",
      "bank_add_fail": "เพิ่มข้อมูลธนาคารล้มเหลว!",
      "bank_please": "โปรดเติมเต็มมูลค่าที่ถูกต้องของโปรไฟล์ บริษัท",
      "bank_update_success": "อัปเดตธนาคารสำเร็จ",
      "bank_update_fail": "โปรดระบุฟิลด์ที่หายไป",
      "bank_ph_name": "ชื่อสาขาธนาคาร",
      "bank_ph_address": "ที่อยู่ธนาคาร",
      "bank_ph_account": "หมายเลขบัญชีธนาคาร",
      "bank_ph_swift": "รหัส Swift Bank"
    },
    "company": {
      "company_info": "ข้อมูล บริษัท",
      "company_display_name": "ชื่อแสดง บริษัท",
      "company_legal_name": "ชื่อกฎหมายของ บริษัท",
      "company_reg_no": "หมายเลขลงทะเบียน",
      "company_tax_no": "หมายเลขภาษี",
      "company_reg_address": "ที่อยู่ที่ลงทะเบียนไว้",
      "company_description": "คำอธิบายเกี่ยวกับ บริษัท",
      "company_city": "เมือง",
      "company_state": "สถานะ",
      "company_country": "ประเทศ",
      "company_phone": "โทรศัพท์",
      "company_email": "อีเมลหลัก",
      "company_type": "ประเภท บริษัท",
      "company_company": "บริษัท",
      "company_funder": "ผู้ระดมทุน",
      "company_save": "บันทึก",
      "company_add_success": "เพิ่มโปรไฟล์ บริษัท สำเร็จ !",
      "company_add_fail": "เพิ่มโปรไฟล์ บริษัท ล้มเหลว!",
      "company_please": "โปรดระบุฟิลด์ที่หายไป",
      "company_please1": "ที่",
      "company_please2": "สนามหายไป",
      "company_ph_name": "ชื่อ บริษัท อินพุต",
      "company_ph_reg": "หมายเลขลงทะเบียน",
      "company_ph_tax": "หมายเลขภาษี",
      "company_ph_add1": "สายที่อยู่ของ บริษัท 1",
      "company_ph_add2": "สายที่อยู่ของ บริษัท 2",
      "company_ph_add3": "สายที่อยู่ของ บริษัท 3",
      "company_ph_desc": "อธิบายเกี่ยวกับ บริษัท",
      "company_ph_city": "เมือง",
      "company_ph_state": "สถานะ",
      "company_ph_contact": "หมายเลขโทรศัพท์",
      "company_ph_email": "อีเมลหลัก"
    },
    "currency": {
      "currency_info": "ข้อมูลสกุลเงิน",
      "currency_code": "รหัสสกุลเงิน",
      "currency_name": "ชื่อสกุลเงิน",
      "currency_symbol": "สัญลักษณ์สกุลเงิน",
      "currency_support": "สนับสนุน",
      "currency_default": "ค่าเริ่มต้น",
      "currency_save": "บันทึก",
      "currency_add_success": "ตั้งค่าสกุลเงินสำเร็จ!",
      "currency_add_fail": "การตั้งค่าสกุลเงินล้มเหลว!",
      "currency_please": "โปรดเติมเต็มมูลค่าที่ถูกต้องของโปรไฟล์ บริษัท"
    },
    "holidays": {
      "holidays_holidays": "วันหยุด",
      "holidays_add_new": "เพิ่มวันล็อคใหม่",
      "holidays_no_events": "ยังไม่มีเหตุการณ์",
      "holidays_remove": "ลบหลังจากดร็อป",
      "holidays_recycle_bin": "ถังขยะรีไซเคิล",
      "holidays_no_recycle": "ยังไม่มีการรีไซเคิลปฏิทิน",
      "holidays_new": "วันล็อคใหม่",
      "holidays_title": "ชื่อ",
      "holidays_number": "จำนวนวัน",
      "holidays_time": "เวลา",
      "holidays_add": "เพิ่ม",
      "holidays_ph_please": "กรุณาเพิ่มชื่อวันล็อคของคุณ"
    },
    "kyc": {
      "kyc_please_upload": "โปรดอัปโหลดเอกสารที่จำเป็น",
      "kyc_kyc_docs": "เอกสาร KYC",
      "kyc_category": "หมวดหมู่เอกสาร",
      "kyc_doc_name": "ชื่อเอกสาร",
      "kyc_status": "สถานะ",
      "kyc_act_log": "บันทึกกิจกรรม",
      "kyc_act_log1": "บันทึกกิจกรรม",
      "kyc_updated_by": "อัปเดตโดย",
      "kyc_time_stamp": "ตราประทับเวลา",
      "kyc_details": "รายละเอียด",
      "kyc_actions": "การกระทำ",
      "kyc_save": "บันทึก",
      "kyc_submit": "ส่งเพื่อขออนุมัติ",
      "kyc_file": "อัปโหลดไฟล์",
      "kyc_drag_drop": "ลากและวางที่นี่",
      "kyc_drag_drop1": "หรือ",
      "kyc_browse": "เรียกดู",
      "kyc_doc_waiting": "รอการอัปโหลด",
      "kyc_doc_fail": "การอัปโหลดเอกสาร KYC ล้มเหลว"
    },
    "profile": {
      "profile_information": "ข้อมูลโปรไฟล์",
      "profile_username": "ชื่อผู้ใช้",
      "profile_display_name": "ชื่อที่แสดง",
      "profile_first_name": "ชื่อจริง",
      "profile_last_name": "นามสกุล",
      "profile_email": "อีเมล",
      "profile_phone_number": "หมายเลขโทรศัพท์",
      "profile_save": "บันทึก",
      "profile_email_fail": "อีเมลของคุณไม่ได้รับการยืนยัน โปรดตรวจสอบกล่องจดหมายของคุณ",
      "profile_resend": "คลิกที่นี่เพื่อส่งอีเมลยืนยันอีกครั้ง",
      "profile_update_success": "อัปเดตโปรไฟล์ของคุณสำเร็จ!",
      "profile_update_fail": "โปรไฟล์อัปเดตล้มเหลว!",
      "profile_please": "โปรดเติมค่าที่ถูกต้องของโปรไฟล์ผู้ใช้",
      "profile_ph_name": "ชื่อ บริษัท อินพุต",
      "profile_ph_reg": "หมายเลขลงทะเบียน",
      "profile_ph_tax": "หมายเลขภาษี",
      "profile_ph_city": "เมือง"
    }
  },
  "alerts": {
    "alerts_alert": "ศูนย์แจ้งเตือน",
    "alerts_show_all": "แสดงการแจ้งเตือนทั้งหมด",
    "alerts_all": "ทั้งหมด",
    "alerts_unread": "ยังไม่ได้อ่าน",
    "alerts_mark_read": "ทำเครื่องหมายทั้งหมดเป็นอ่าน",
    "alerts_ok": "ตกลง",
    "alerts_cancel": "ยกเลิก",
    "alerts_ph_search": "ค้นหา...",
    "alerts_ph_nomatch": "ไม่พบบันทึกการจับคู่",
    "alerts_ttl_notif": "การแจ้งเตือน",
    "alerts_ttl_timestamp": "การประทับเวลาเหตุการณ์",
    "alerts_ttl_status": "สถานะ / เฟสสุดท้าย",
    "alerts_ttl_actions": "การกระทำ"
  },
  "auth": {
    "forgot": {
      "forgot_forgot": "ลืมรหัสผ่าน",
      "forgot_send_reset": "ส่งอีเมลรีเซ็ตรหัสผ่าน",
      "forgot_reset_success": "ส่งรหัสผ่านรีเซ็ตสำเร็จ",
      "forgot_please": "โปรดตรวจสอบลิงค์รหัสผ่านรีเซ็ตอีเมลของคุณ",
      "forgot_reset_error": "รีเซ็ตข้อผิดพลาดขอรหัสผ่าน",
      "forgot_ph_email": "พิมพ์ที่อยู่อีเมล"
    },
    "login": {
      "login_login": "เข้าสู่ระบบ",
      "login_register": "ลงทะเบียน",
      "login_portal": "พอร์ทัลเข้าสู่ระบบ",
      "login_welcome": "ยินดีต้อนรับกลับ! โปรดป้อนชื่อผู้ใช้และรหัสผ่านของคุณเพื่อเข้าสู่บัญชีของคุณ",
      "login_username": "ชื่อผู้ใช้",
      "login_password": "รหัสผ่าน",
      "login_forgot": "ลืมรหัสผ่าน?",
      "login_remember": "จดจำฉัน",
      "login_login_fail": "ลงชื่อเข้าใช้ล้มเหลว!",
      "login_wrong": "ชื่อผู้ใช้และรหัสผ่านผิด",
      "login_new": "ใหม่บนแพลตฟอร์มของเรา?",
      "login_new1": "ลงทะเบียนบัญชี",
      "login_ph_user": "ป้อนชื่อผู้ใช้ของคุณ",
      "login_ph_pass": "ป้อนรหัสผ่านของคุณ"
    },
    "register": {
      "register_portal": "พอร์ทัลลงทะเบียน",
      "register_new": "การลงทะเบียนบัญชีใหม่",
      "register_first_name": "ชื่อจริง",
      "register_last_name": "นามสกุล",
      "register_username": "ชื่อผู้ใช้",
      "register_email": "อีเมลทำงาน",
      "register_display_name": "ชื่อที่แสดง",
      "register_confirm_password": "ยืนยันรหัสผ่าน",
      "register_sign_up": "ลงชื่อ",
      "register_already": "มีบัญชีอยู่แล้ว?",
      "register_instead": "ลงชื่อเข้าใช้แทน",
      "register_ph_first": "ชื่อจริง",
      "register_ph_last": "นามสกุล",
      "register_ph_user": "ชื่อผู้ใช้",
      "register_ph_email": "อีเมลทำงาน",
      "register_ph_display": "ชื่อที่แสดง",
      "register_ph_passconfirm": "การยืนยันรหัสผ่าน",
      "register_s_confirm": "ต้องยืนยันรหัสผ่าน",
      "register_s_wrong": "มีบางอย่างผิดปกติในการลงทะเบียน กรุณาลองใหม่อีกครั้งในภายหลัง",
      "register_s_error": "ลงทะเบียนข้อผิดพลาด"
    },
    "reset": {
      "reset_reset": "รีเซ็ตรหัสผ่าน",
      "reset_few": "คลิกเพิ่มอีกสองสามครั้งเพื่อลงชื่อเข้าใช้บัญชีของคุณ จัดการไฟล์",
      "reset_ecommerce": "บัญชีอีคอมเมิร์ซในที่เดียว",
      "reset_instead": "ลงชื่อเข้าใช้แทน",
      "reset_reset_success": "รีเซ็ตรหัสผ่านสำเร็จ",
      "reset_you_can": "คุณสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่ของคุณ",
      "reset_reset_fail": "รีเซ็ตรหัสผ่านล้มเหลว",
      "reset_ph_newpass": "รหัสผ่านใหม่"
    },
    "update": {
      "update_update": "อัปเดตรหัสผ่าน",
      "update_current": "ยืนยันรหัสผ่าน",
      "update_new": "รหัสผ่านใหม่",
      "update_required": "ที่จำเป็น",
      "update_update_success": "อัปเดตความสำเร็จ!",
      "update_password_success": "รหัสผ่านอัปเดตสำเร็จ",
      "update_update_failed": "การอัพเดทล้มเหลว!",
      "update_please": "โปรดตรวจสอบแบบฟอร์มที่กรอก"
    },
    "validate": {
      "validate_genie": "ตรวจสอบ Genie",
      "validate_portal": "ตรวจสอบพอร์ทัล",
      "validate_new": "การลงทะเบียนบัญชีใหม่",
      "validate_validate": "ตรวจสอบหมายเลขโทรศัพท์",
      "validate_enter": "ป้อนรหัสยืนยันที่ส่งไปยังมือถือของคุณ",
      "validate_please_wait": "โปรดรอ (15) วินาที",
      "validate_request": "เพื่อขอรหัสใหม่",
      "validate_validate_mobile": "ตรวจสอบหมายเลขโทรศัพท์มือถือ",
      "validate_back": "กลับไปสมัคร"
    }
  },
  "company": {
    "companies": {
      "companies_ph_nomatch": "ไม่พบบันทึกการจับคู่",
      "companies_ttl_display": "ชื่อที่แสดง.",
      "companies_ttl_legal": "ชื่อทางกฎหมาย",
      "companies_ttl_reg": "ไม่มี",
      "companies_ttl_tax": "หมายเลขภาษี",
      "companies_ttl_address": "ที่อยู่",
      "companies_ttl_city": "เมือง",
      "companies_ttl_country": "ประเทศ",
      "companies_ttl_contact": "โทรศัพท์",
      "companies_ttl_email": "อีเมล",
      "companies_ttl_actions": "การกระทำ"
    },
    "detail": {
      "detail_company_info": "ข้อมูล บริษัท",
      "detail_bank_info": "ข้อมูลธนาคาร",
      "detail_currency_settings": "การตั้งค่าสกุลเงิน",
      "detail_kyc_documents": "เอกสาร KYC",
      "detail_user_lists": "รายชื่อผู้ใช้"
    }
  },
  "dashboard": {
    "dashboard_dashboard": "แผงควบคุม",
    "dashboard_reload_data": "โหลดข้อมูลใหม่",
    "dashboard_pending": "รอดำเนินการ",
    "dashboard_transaction_complete": "การทำธุรกรรมเสร็จสมบูรณ์",
    "dashboard_awaiting_final_disbursement_acknowledgement_communication": "การแจ้งเตือนทางอีเมลถึง Funder เมื่อรับทราบผู้ขาย",
    "dashboard_awaiting_seller_acknowledge_receive_of_final_disbursement": "ผู้ขายรับทราบการตั้งถิ่นฐานจาก Funder",
    "dashboard_awaiting_final_fund_disbursement_notification_to_seller": "การแจ้งเตือนทางอีเมลถึงผู้ขายสำหรับการชำระเงินกองทุน",
    "dashboard_awaiting_funder_final_disbursement": "การเบิกจ่ายจำนวนเงินชำระยอดคงเหลือให้กับผู้ขาย",
    "dashboard_funder_acknowledged_receive_of_repayment": "Funder รับทราบการชำระคืนจากผู้ซื้อ",
    "dashboard_awaiting_funder_acknowledge_repayment": "การแจ้งเตือนทางอีเมลถึง Funder เมื่อชำระคืนจากผู้ซื้อ",
    "dashboard_awaiting_buyer_repayment_on_due_date": "ผู้ซื้อชำระเงินในวันที่ครบกำหนด",
    "dashboard_awaiting_disbursement_acknowledgement_communication": "กำลังรอการสื่อสารการรับทราบการจ่ายเงิน",
    "dashboard_awaiting_seller_acknowledge_disbursement": "รอผู้ขายรับทราบการเบิกจ่าย",
    "dashboard_awaiting_fund_disbursement_notification_to_seller": "รอการแจ้งเตือนการเบิกจ่ายกองทุนให้กับผู้ขาย",
    "dashboard_awaiting_funder_disbursement": "รอการเบิกจ่าย",
    "dashboard_awaiting_first_disbursement_acknowledgement_communication": "การแจ้งเตือนทางอีเมลถึง Funder เมื่อรับทราบผู้ขาย",
    "dashboard_awaiting_seller_acknowledge_receive_of_first_disbursement": "ผู้ขายรับทราบการเบิกจ่ายจาก Funder",
    "dashboard_awaiting_first_fund_disbursement_notification_to_seller": "การแจ้งเตือนทางอีเมลถึงผู้ขายสำหรับการเบิกจ่ายกองทุน",
    "dashboard_awaiting_funder_first_disbursement": "การเบิกจ่ายจำนวนเงินที่ได้รับจากดอกเบี้ยและค่าธรรมเนียมน้อยลง",
    "dashboard_awaiting_bidding_result": "การแจ้งเตือนทางอีเมลไปยังซัพพลายเออร์และผู้ซื้อ",
    "dashboard_bidding_in_progress": "Funder อนุมัติธุรกรรม",
    "dashboard_invite_funders_to_bid": "การแจ้งเตือนทางอีเมลถึง Funder",
    "dashboard_awaiting_seller_acknowledgement": "รอผู้ขาย acknoledGement",
    "dashboard_awaiting_buyer_acknowledgement": "ผู้ซื้ออนุมัติธุรกรรม",
    "dashboard_start": "ส่งคำขอทางการเงิน",
    "dashboard_notification_sent_to_buyer": "การแจ้งเตือนส่งไปยังผู้ซื้อ",
    "dashboard_transaction_acknowledged_by_buyer": "ธุรกรรมที่ผู้ซื้อยอมรับ",
    "dashboard_invitation_sent_to_funders": "คำเชิญส่งไปยังกองทุน",
    "dashboard_transaction_approved_by_funder": "ธุรกรรมที่ได้รับอนุมัติจาก Funder",
    "dashboard_first_fund_disbursement_instruction_sent_to_funder": "คำแนะนำการเบิกจ่ายกองทุนครั้งแรกส่งไปยัง Funder",
    "dashboard_first_fund_disbursed_by_funder": "กองทุนแรกที่จ่ายโดย Funder",
    "dashboard_first_fund_disbursement_notification_sent_to_seller": "การแจ้งเตือนการเบิกจ่ายกองทุนครั้งแรกส่งไปยังผู้ขาย",
    "dashboard_receipt_of_first_fund_acknowledged_by_seller": "การรับทราบกองทุนครั้งแรกโดยผู้ขาย",
    "dashboard_repayment_instruction_sent_to_buyer": "คำแนะนำการชำระคืนส่งไปยังผู้ซื้อ",
    "dashboard_repaid_by_buyer": "ชำระคืนโดยผู้ซื้อ",
    "dashboard_receipt_of_repayment_acknowledged_by_funder": "การรับทราบการชำระคืนโดย Funder",
    "dashboard_final_fund_disbursement_instruction_sent_to_funder": "คำแนะนำการเบิกจ่ายกองทุนสุดท้ายส่งไปยัง Funder",
    "dashboard_final_fund_disbursed_by_funder": "กองทุนสุดท้ายที่จ่ายโดย Funder",
    "dashboard_final_fund_disbursement_notification_sent_to_seller": "การแจ้งเตือนการเบิกจ่ายกองทุนสุดท้ายส่งถึงผู้ขาย",
    "dashboard_receipt_of_final_fund_acknowledged_by_seller": "ใบเสร็จรับเงินของกองทุนสุดท้ายรับทราบโดยผู้ขาย",
    "dashboard_transaction_declined_by_buyer": "ธุรกรรมลดลงโดยผู้ซื้อ",
    "dashboard_transaction_not_approved_by_funder": "ธุรกรรมที่ไม่ได้รับการอนุมัติจาก Funder",
    "dashboard_receipt_of_first_funds_declined_by_seller": "การรับเงินทุนครั้งแรกถูกปฏิเสธโดยผู้ขาย",
    "dashboard_receipt_of_repayment_declined_by_funder": "ใบเสร็จรับเงินของการชำระคืนถูกปฏิเสธโดย Funder",
    "dashboard_receipt_of_final_funds_declined_by_seller": "ใบเสร็จรับเงินของกองทุนสุดท้ายถูกปฏิเสธโดยผู้ขาย",
    "dashboard_notification_sent_to_seller": "การแจ้งเตือนส่งถึงผู้ขาย",
    "dashboard_transaction_acknowledged_by_seller": "ธุรกรรมที่ได้รับการยอมรับจากผู้ขาย",
    "dashboard_fund_disbursement_instruction_sent_to_funder": "คำแนะนำในการชำระเงินกองทุนส่งไปยัง Funder",
    "dashboard_fund_disbursed_by_funder": "กองทุนจ่ายโดย Funder",
    "dashboard_fund_disbursement_notification_sent_to_seller": "การแจ้งเตือนการเบิกจ่ายกองทุนส่งไปยังผู้ขาย",
    "dashboard_receipt_of_fund_acknowledged_by_seller": "ใบเสร็จรับเงินจากผู้ขาย",
    "dashboard_notification_sent_to_funder": "การแจ้งเตือนส่งไปยัง Funder",
    "dashboard_invoice": "ชุดใบแจ้งหนี้",
    "dashboard_created": "สร้างบน :",
    "dashboard_view": "ดู",
    "dashboard_no_pending": "ไม่มีรายการที่รอดำเนินการ",
    "dashboard_calendar": "ปฏิทินวันหยุดที่กำลังจะมาถึง",
    "borrowers": {
      "borrowers_companies": "บริษัท ผู้กู้ที่ใช้งานอยู่"
    },
    "cards": {
      "cards_line_info": "ข้อมูลสาย",
      "cards_limit": "ขีด จำกัด ที่มีอยู่",
      "cards_funded_amount": "จำนวนเงินทุน",
      "cards_total_allocated": "จำนวนเงินที่จัดสรรรวม:",
      "cards_outstand_amount": "จำนวนเงินคงค้างสำหรับการเบิกจ่าย",
      "cards_utilized_amount": "ใช้จำนวนเงิน",
      "cards_overall_limit": "ขีด จำกัด โดยรวม",
      "cards_financing_funded": "เงินทุนสนับสนุน",
      "cards_count": "นับ",
      "cards_total_amount": "จำนวนเงินทั้งหมด",
      "cards_requested": "ขอเงินทุน"
    }
  },
  "eco": {
    "relationship": {
      "relationship_systems": "ระบบนิเวศ",
      "relationship_create": "สร้างระบบนิเวศ",
      "relationship_ph_nomatch": "ไม่พบบันทึกการจับคู่",
      "relationship_ttl_name": "ชื่อ",
      "relationship_ttl_funder": "ผู้ระดมทุน",
      "relationship_ttl_buyer": "ผู้ซื้อ",
      "relationship_ttl_seller": "ผู้ขาย",
      "relationship_ttl_expire": "วันหมดอายุ",
      "relationship_ttl_status": "สถานะ",
      "relationship_ttl_actions": "การกระทำ"
    },
    "modal": {
      "modal_system": "ระบบนิเวศ",
      "modal_name": "ชื่อ",
      "modal_buyer": "บริษัท ผู้ซื้อ",
      "modal_seller": "บริษัท ผู้ขาย",
      "modal_funder": "บริษัท Funder",
      "modal_seller_credit": "เครดิตผู้ขาย",
      "modal_buyer_credit": "เครดิตผู้ซื้อ",
      "modal_base_currency": "รหัสสกุลเงินพื้นฐาน",
      "modal_interest": "อัตราดอกเบี้ย(%)",
      "modal_interest_duration": "ระยะเวลาอัตราดอกเบี้ย",
      "modal_expired": "วันหมดอายุ:",
      "modal_status": "สถานะ:",
      "modal_buyer_workflow": "ขั้นตอนการทำงาน LED ผู้ซื้อ",
      "modal_seller_workflow": "ขั้นตอนการทำงาน LED ผู้ขาย",
      "modal_delete": "ลบ",
      "modal_active": "คล่องแคล่ว",
      "modal_save": "บันทึก"
    },
    "workflow": {
      "workflow_workflow": "ขั้นตอนการทำงาน"
    }
  },
  "error": {
    "denied": {
      "denied_403": "403",
      "denied_oops": "อ๊ะ. คุณไม่สามารถเข้าถึงหน้านี้ได้",
      "denied_mistyped": "คุณอาจมีที่อยู่ผิดพลาด",
      "denied_back": "กลับไปที่บ้าน"
    },
    "error": {
      "error_404": "404",
      "error_oops": "อ๊ะ. หน้านี้หายไป",
      "error_mistyped": "คุณอาจมีที่อยู่ผิดพลาดหรือหน้าอาจย้าย",
      "error_back": "กลับไปที่บ้าน"
    }
  },
  "invoice": {
    "invoice_search": "ค้นหา",
    "invoice_page_size": "ขนาดหน้า",
    "invoice_invoices": "ใบแจ้งหนี้",
    "invoice_upload_invoice": "ที่อัพโหลด",
    "invoice_upload_invoice2": "ใบแจ้งหนี้",
    "invoice_field": "สนาม",
    "invoice_last_updated": "อัปเดตล่าสุดโดย",
    "invoice_seller": "ผู้ขาย",
    "invoice_buyer": "ผู้ซื้อ",
    "invoice_funder": "ผู้ระดมทุน",
    "invoice_batch_no": "หมายเลขชุด",
    "invoice_batch_st": "สถานะแบทช์",
    "invoice_batch_remark": "หมายเหตุแบตช์",
    "invoice_type": "พิมพ์",
    "invoice_like": "เท่ากับ",
    "invoice_nlike": "ไม่เท่ากับ",
    "invoice_value": "ค่า",
    "invoice_go": "ไป",
    "invoice_reset": "รีเซ็ต",
    "invoice_pending_action": "กำลังดำเนินการ",
    "invoice_my_invoice": "ใบแจ้งหนี้ของฉัน",
    "invoice_my_bid": "การเสนอราคาของฉัน",
    "invoice_invoice_partner": "ใบแจ้งหนี้จากคู่ของฉัน",
    "invoice_empty_table": "ไม่มีข้อมูลในตาราง",
    "invoice_processing": "กำลังประมวลผล...",
    "invoice_ph_search": "ค้นหา...",
    "invoice_ph_nomatch": "ไม่มีข้อมูลในตาราง",
    "invoice_ttl_batch": "หมายเลขชุด",
    "invoice_ttl_buyer": "ผู้ซื้อ",
    "invoice_ttl_seller": "ผู้ขาย",
    "invoice_ttl_total": "จำนวนเงินทั้งหมด",
    "invoice_ttl_lastupdate": "อัปเดตล่าสุดโดย",
    "invoice_ttl_stage": "ขั้นตอนปัจจุบัน",
    "invoice_ttl_bidendtime": "เวลาสิ้นสุดการเสนอราคา",
    "invoice_ttl_remark": "หมายเหตุแบตช์",
    "invoice_ttl_status": "สถานะแบทช์",
    "invoice_ttl_created": "สร้างขึ้นที่",
    "invoice_ttl_actions": "การกระทำ",
    "upload": {
      "upload_upload_invoice": "อัปโหลดใบแจ้งหนี้",
      "upload_upload_selecttype": "เลือกประเภทเอกสาร",
      "upload_company_name": "ชื่อ บริษัท",
      "upload_seller_name": "ชื่อผู้ขาย",
      "upload_buyer_name": "ชื่อผู้ซื้อ",
      "upload_bid_end": "เวลาสิ้นสุดการเสนอราคา",
      "upload_select_disb": "เลือกบัญชีธนาคารที่ชำระไม่ได้",
      "upload_select_disb1": "เลือกบัญชีธนาคารจ่ายเงิน",
      "upload_batch_remark": "หมายเหตุแบทช์อินพุต",
      "upload_upload_documents": "อัปโหลดเอกสารสนับสนุน",
      "upload_payment_date": "วันครบกำหนดชำระเงินจะต้องเป็นวันที่ในอนาคต",
      "upload_amount_zero": "จำนวนเงินจะต้องไม่เป็นศูนย์",
      "upload_uploading": "การอัพโหลด",
      "upload_number": "หมายเลขเอกสาร",
      "upload_type": "ประเภทเอกสาร",
      "upload_date": "วันที่เอกสาร",
      "upload_payment_date1": "วันครบกำหนดชำระเงิน",
      "upload_currency_code": "รหัสสกุลเงิน",
      "upload_amount": "จำนวน",
      "upload_support": "เอกสารสนับสนุน",
      "upload_action": "การกระทำ",
      "upload_remark": "หมายเหตุแบตช์",
      "upload_cancel": "ยกเลิก",
      "upload_submit": "ส่ง",
      "upload_upload_fail": "การอัพโหลดล้มเหลว!",
      "upload_upload_s_file_error": "ข้อผิดพลาดรูปแบบไฟล์",
      "upload_upload_s_invoice_fail": "การอัปโหลดใบแจ้งหนี้ล้มเหลว"
    },
    "support": {
      "support_support": "การอัพโหลด",
      "support_s_cannot_upload1": "ไม่สามารถอัปโหลดได้",
      "support_s_cannot_upload2": "ไฟล์",
      "support_s_upload_fail": "การอัปโหลดเอกสารสนับสนุนล้มเหลว"
    },
    "detail": {
      "detail_upload_document": "อัปโหลดเอกสาร",
      "invoice": "ใบแจ้งหนี้",
      "batch_no": "ไม่มีชุด",
      "uploads": "ภาพรวมใบแจ้งหนี้",
      "yellow_warning": "โปรดอัปโหลดเอกสารสนับสนุนสำหรับใบแจ้งหนี้ของคุณ",
      "no": "ใบแจ้งหนี้ไม่",
      "amount": "จำนวนใบแจ้งหนี้",
      "date": "วันที่ใบแจ้งหนี้",
      "payment_date": "วันครบกำหนดชำระเงิน",
      "upload_date": "วันที่อัปโหลด",
      "actions": "การกระทำ",
      "more": "ดูเพิ่มเติม",
      "less": "ดูน้อยลง",
      "provenance_txt": "ที่มา",
      "documents": {
        "documents_supporting": "เอกสารสนับสนุน",
        "documents_uploaded": "วันที่อัปโหลด",
        "documents_uploaded_by": "อัปโหลดโดย",
        "documents_verifying": "การยืนยัน"
      },
      "provenance": {
        "provenance_verifying": "การยืนยัน",
        "provenance_verified": "ตรวจสอบแล้ว",
        "provenance_not_verified": "ไม่ได้รับการยืนยัน",
        "provenance_pending": "รอดำเนินการ",
        "provenance_not_started": "ไม่ได้เริ่ม",
        "provenance_updated": "อัปเดต",
        "provenance_hash": "แฮชธุรกรรม",
        "provenance_approval": "อนุมัติโดย"
      }
    },
    "batch": {
      "signature": {
        "signature_undo": "ปลดลายเซ็น",
        "signature_clear": "ลายเซ็นที่ชัดเจน"
      },
      "eco": {
        "eco_batch_details": "รายละเอียดชุด",
        "eco_batch_workflow_seller_led": "LED ผู้ขาย",
        "eco_batch_workflow_buyer_led": "LED ผู้ซื้อ",
        "eco_batch_status_expired": "ที่หมดอายุแล้ว",
        "eco_batch_status_rejected": "ถูกปฏิเสธ",
        "eco_batch_status_funded": "ได้รับเงินทุน",
        "eco_batch_status_completed": "สมบูรณ์",
        "eco_batch_status_pending": "รอดำเนินการ",
        "eco_batch_status_pending_repayment": "รอการชำระหนี้",
        "eco_batch_status_pending_final_disbursement": "รอการเบิกจ่ายขั้นสุดท้าย",
        "eco_batch_status_bidding_expired": "การเสนอราคาหมดอายุ",
        "eco_batch_info": "ข้อมูลแบทช์",
        "eco_buyer_company": "บริษัท ผู้ซื้อ",
        "eco_seller_company": "บริษัท ผู้ขาย",
        "eco_funder_company": "บริษัท Funder",
        "eco_no_batch": "ไม่มีรายการแบทช์",
        "eco_upload_date": "วันที่อัปโหลด",
        "eco_total_amount": "จำนวนเงินทั้งหมด",
        "eco_bank_details": "รายละเอียดธนาคาร",
        "eco_disbursement_account": "บัญชีธนาคารจ่ายเงิน",
        "eco_account_no": "หมายเลขบัญชี",
        "eco_bank_name": "ชื่อธนาคาร",
        "eco_address": "ที่อยู่",
        "eco_swift": "รหัสที่รวดเร็ว",
        "eco_formula": "สูตร",
        "eco_interest": "อัตราดอกเบี้ย (อัตรารายปี %)",
        "eco_interest_earn": "รายได้ดอกเบี้ย",
        "eco_platform_fee": "จำนวนค่าธรรมเนียมแพลตฟอร์ม",
        "eco_disbursement_amount": "จำนวนเงินเบิกจ่ายทางการเงินดอกเบี้ยและค่าธรรมเนียมน้อยลง",
        "eco_repayment_account": "บัญชีธนาคารชำระคืน",
        "eco_repayment_amount": "จำนวนการชำระคืนให้กับ Funder",
        "eco_repayment_date": "วันที่ชำระคืน",
        "eco_decline": "ปฏิเสธ",
        "eco_approve": "อนุมัติ",
        "eco_submit_disb": "ส่งการเบิกจ่าย",
        "eco_receive_disb": "รับทราบการรับการเบิกจ่าย",
        "eco_upload_advice": "อัปโหลดคำแนะนำการชำระคืน",
        "eco_repay_advice": "รับทราบคำแนะนำการชำระคืน",
        "eco_approve_invoice": "อนุมัติใบแจ้งหนี้",
        "eco_batch_no": "หมายเลขแบทช์",
        "eco_invoice_upload": "วันที่อัปโหลดใบแจ้งหนี้",
        "eco_invoice_amount": "จำนวนใบแจ้งหนี้",
        "eco_payment_due": "วันครบกำหนดชำระเงิน",
        "eco_select_disb": "เลือกบัญชีธนาคารจ่ายเงิน",
        "eco_remark": "ข้อสังเกต",
        "eco_cancel": "ยกเลิก",
        "eco_approve_trx": "อนุมัติธุรกรรม",
        "eco_total_invoice": "จำนวนใบแจ้งหนี้ทั้งหมด",
        "eco_value_date": "วันที่มูลค่า",
        "eco_invoice_due": "วันครบกำหนดใบแจ้งหนี้",
        "eco_no_days": "จำนวนวัน",
        "eco_decline_invoice": "ใบแจ้งหนี้ปฏิเสธ",
        "eco_upload_disb": "อัพโหลดคำแนะนำการชำระเงินกองทุนกองทุน",
        "eco_payment_upload": "อัพโหลดไฟล์คำแนะนำการชำระเงิน",
        "eco_or": "หรือ",
        "eco_browse": "เรียกดู",
        "eco_currency": "รหัสสกุลเงิน",
        "eco_submit": "ส่ง",
        "eco_advice_no": "หมายเลขคำแนะนำการชำระเงิน",
        "eco_advice_file": "ไฟล์คำแนะนำการชำระเงิน",
        "eco_advice_amount": "จำนวนคำแนะนำการชำระเงิน",
        "eco_advice_date": "วันที่แนะนำการชำระเงิน",
        "eco_confirm": "ยืนยัน",
        "eco_receive_repay": "รับทราบการได้รับการชำระคืน",
        "eco_drag_drop": "ลากและวางที่นี่",
        "eco_s_approve_fail": "การอนุมัติล้มเหลว",
        "eco_s_please_interest": "กรุณาตั้งค่าอัตราการประสาน",
        "eco_s_please_valid": "โปรดเลือกบัญชีธนาคารชำระคืนที่ถูกต้อง",
        "eco_s_seller_ack_fail": "ผู้ขายรับทราบล้มเหลว",
        "eco_s_decline_fail": "การลดลงล้มเหลว",
        "eco_s_funder_approve_fail": "การรับทราบการอนุมัติการอนุมัติล้มเหลว",
        "eco_s_funder_decline_fail": "การรับทราบการลดลงของการลดลงนั้นล้มเหลว",
        "eco_s_platform_error": "ข้อผิดพลาดการคำนวณค่าธรรมเนียมแพลตฟอร์ม",
        "eco_s_funder_advice_fail": "การส่งคำแนะนำการเบิกจ่ายล้มเหลว",
        "eco_s_funder_advice_req": "จำเป็นต้องมีฟิลด์คำแนะนำการชำระเงิน/จำนวนเงิน",
        "eco_s_funder_payment_req": "กรุณาอัปโหลดไฟล์คำแนะนำการชำระเงิน",
        "eco_s_ack_fail": "รับทราบล้มเหลว",
        "eco_s_upload_fail": "การอัปโหลดคำแนะนำการชำระคืนล้มเหลว",
        "eco_s_funder_ack_fail": "รับทราบความล้มเหลว",
        "eco_s_signature_req": "จำเป็นต้องมีฟิลด์ลายเซ็น/หมายเหตุ!",
        "eco_s_funder_decline": "การลดลงของ Funder ล้มเหลว",
        "eco_batch_message1": "ผู้ซื้อไม่อนุมัติใบแจ้งหนี้นี้เนื่องจากเวลาสิ้นสุดการเสนอราคาผ่านการเสนอราคา",
        "eco_batch_message2": "การทำธุรกรรมนี้ไม่ได้รับการอนุมัติจาก Funder",
        "eco_batch_message3": "ใบแจ้งหนี้นี้หมดอายุแล้ว วันครบกำหนดชำระเงินคือ"
      },
      "led": {
        "led_maturity": "วันครบกำหนด",
        "led_note": "บันทึก",
        "led_interest_r": "อัตราดอกเบี้ย(%)",
        "led_monthly": "รายเดือน",
        "led_yearly": "ทุกปี",
        "led_disb_amt_fee": "จำนวนเงินเบิกจ่ายทางการเงินค่าธรรมเนียมแพลตฟอร์มน้อยลง",
        "led_reject_fail": "การปฏิเสธใบแจ้งหนี้ล้มเหลว"
      }
    }
  },
  "onboard": {
    "onboard_pending_approval": "รอการพิสูจน์",
    "onboard_pending_request": "การร้องขอที่ค้าง",
    "onboard_no_data": "ไม่มีข้อมูลที่มีอยู่",
    "onboard_rejected_request": "คำขอปฏิเสธ",
    "onboard_remove_company": "ลบ บริษัท นี้?",
    "onboard_onboard": "บริษัท ออนบอร์ด",
    "onboard_company_display_name": "ชื่อแสดง บริษัท",
    "onboard_required": "ที่จำเป็น",
    "onboard_company_email": "อีเมลของ บริษัท",
    "onboard_invite": "เชิญ",
    "onboard_onboared": "บริษัท ที่อยู่บนเรือ",
    "onboard_credit": "วงเงิน",
    "onboard_start": "วันที่เริ่มต้น",
    "onboard_end": "วันที่สิ้นสุด",
    "onboard_status": "สถานะ",
    "onboard_actions": "การกระทำ",
    "onboard_reinvite": "เชิญอีกครั้ง",
    "onboard_available": "บริษัท ที่มีอยู่",
    "onboard_company_name": "ชื่อ บริษัท",
    "onboard_company_code": "รหัส บริษัท",
    "onboard_email": "อีเมล",
    "onboard_display_name": "ชื่อที่แสดง",
    "onboard_address": "ที่อยู่",
    "onboard_pending": "รอดำเนินการ",
    "onboard_invite_company": "เชิญ บริษัท",
    "onboard_currency_code": "รหัสสกุลเงิน",
    "onboard_credit_limit": "วงเงิน",
    "onboard_end_date": "วันที่สิ้นสุด",
    "onboard_cancel": "ยกเลิก",
    "onboard_invite_success": "เชิญความสำเร็จ!",
    "onboard_reject_success": "ปฏิเสธความสำเร็จ!",
    "onboard_approve_success": "อนุมัติความสำเร็จ!",
    "onboard_remove_success": "ลบความสำเร็จ!",
    "onboard_failed": "ล้มเหลว!",
    "onboard_invite_fail": "เชิญล้มเหลว!",
    "onboard_invite_success1": "เชิญ บริษัท สำเร็จ",
    "onboard_invite_success2": "ปฏิเสธคำเชิญสำเร็จ",
    "onboard_invite_success3": "อนุมัติคำเชิญได้สำเร็จ",
    "onboard_invite_success4": "ลบ บริษัท สำเร็จ",
    "onboard_upload_fail": "การอัพโหลดล้มเหลว!",
    "onboard_ph_display": "ชื่อแสดง บริษัท",
    "onboard_s_please": "กรุณากรอกข้อมูลในฟิลด์ที่จำเป็น"
  },
  "permissions": {
    "add": {
      "add_create": "สร้างสิทธิ์",
      "add_name": "ชื่ออนุญาต",
      "add_required": "ที่จำเป็น",
      "add_resource": "URL ทรัพยากร",
      "add_verbs": "เข้าถึงคำกริยา",
      "add_get": "รับ",
      "add_post": "โพสต์",
      "add_put": "ใส่",
      "add_delete": "ลบ",
      "add_valid": "ใช้ได้ถึงวันที่",
      "add_format": "รูปแบบวันที่ที่ต้องการ",
      "add_unexpired": "ยังไม่หมดอายุ",
      "add_type": "พิมพ์",
      "add_menu": "เมนู",
      "add_api": "API",
      "add_create_success": "สร้างความสำเร็จ!",
      "add_create_success_done": "การสร้างการอนุญาตเสร็จสิ้นแล้ว",
      "add_success": "สร้างการอนุญาตใหม่สำเร็จ",
      "add_create_fail": "สร้างล้มเหลว!",
      "add_valid_error": "ข้อผิดพลาดการตรวจสอบความถูกต้อง",
      "add_please_fields": "โปรดตรวจสอบฟิลด์แบบฟอร์ม",
      "add_please.": "โปรดตรวจสอบแบบฟอร์มที่กรอก"
    },
    "edit": {
      "edit_update": "อัปเดตการอนุญาต",
      "edit_name": "ชื่ออนุญาต",
      "edit_required": "ที่จำเป็น",
      "edit_resource": "URL ทรัพยากร",
      "edit_verbs": "เข้าถึงคำกริยา",
      "edit_get": "รับ",
      "edit_post": "โพสต์",
      "edit_put": "ใส่",
      "edit_delete": "ลบ",
      "edit_valid": "ใช้ได้ถึงวันที่",
      "edit_format": "รูปแบบวันที่ที่ต้องการ",
      "edit_unexpired": "ยังไม่หมดอายุ",
      "edit_type": "พิมพ์",
      "edit_menu": "เมนู",
      "edit_api": "API",
      "edit_update_success": "อัปเดตความสำเร็จ!",
      "edit_success": "อัปเดตการอนุญาตให้สำเร็จ",
      "edit_update_fail": "การอัพเดทล้มเหลว!",
      "add_valid_error": "ข้อผิดพลาดการตรวจสอบความถูกต้อง",
      "add_please_fields": "โปรดตรวจสอบฟิลด์แบบฟอร์ม",
      "edit_please.": "โปรดตรวจสอบแบบฟอร์มที่กรอก"
    },
    "list": {
      "edit_list_permissions": "การอนุญาต",
      "edit_list_add": "เพิ่มการอนุญาตใหม่",
      "edit_list_print": "พิมพ์",
      "edit_list_excel": "ส่งออกไปยัง Excel",
      "edit_list_pdf": "ส่งออกไปยัง PDF",
      "edit_list_name": "ชื่ออนุญาต",
      "edit_list_url": "url",
      "edit_list_expire": "วันที่หมดอายุ",
      "edit_list_type": "พิมพ์",
      "edit_list_actions": "การกระทำ",
      "edit_list_edit": "แก้ไข",
      "edit_list_delete": "ลบ",
      "edit_list_confirm": "คุณแน่ใจไหม?",
      "edit_list_confirm_delete": "คุณต้องการลบการอนุญาตเหล่านี้หรือไม่? <br /> กระบวนการนี้",
      "edit_list_cannot": "ไม่สามารถยกเลิกได้",
      "edit_list_cancel": "ยกเลิก",
      "edit_delete_done": "การลบการอนุญาตเสร็จสมบูรณ์",
      "edit_delete_fail": "การลบการอนุญาตล้มเหลว",
      "edit_list_ph_search": "ค้นหา..."
    }
  },
  "roles": {
    "roles_list_permissions": "การอนุญาต",
    "roles_list_grant": "อนุญาต",
    "roles_list_grant_role ": "ให้สิทธิ์แก่บทบาท",
    "roles_list_available": "รายการสิทธิ์ที่มีอยู่",
    "roles_list_name": "ชื่ออนุญาต",
    "roles_list_url": "url",
    "roles_list_expire": "วันที่หมดอายุ",
    "roles_list_type": "พิมพ์",
    "roles_list_cancel": "ยกเลิก",
    "roles_list_ok": "ตกลง",
    "roles_list_confirm": "คุณแน่ใจไหม?",
    "roles_list_confirm_remove": "คุณต้องการลบออกจริงๆ",
    "roles_list_confirm_remove1": "การอนุญาต?",
    "roles_list_confirm_remove2": "กระบวนการนี้ไม่สามารถยกเลิกได้",
    "roles_list_remove": "ลบ",
    "roles_list_ph_nomatch": "ไม่พบบันทึกการจับคู่",
    "roles_list_ttl_name": "ชื่ออนุญาต",
    "roles_list_ttl_type": "พิมพ์",
    "roles_list_ttl_actions": "การกระทำ",
    "grant": {
      "grant_delete_success": "การลบบทบาทเสร็จสิ้น",
      "grant_delete_success_has1": "ที่",
      "grant_delete_success_has2": "ถูกลบ",
      "grant_delete_fail": "การลบบทบาทล้มเหลว",
      "grant_delete_permission_done": "ลบการอนุญาตให้สำเร็จ",
      "grant_delete_permission_fail": "ลบการอนุญาตล้มเหลว",
      "grant_add_permission_done": "เพิ่มการอนุญาตให้สำเร็จ",
      "grant_add_permission_fail": "เพิ่มสิทธิ์ล้มเหลว",
      "grant_access": "ให้การเข้าถึงบทบาท"
    },
    "list": {
      "list_roles": "บทบาท",
      "list_add": "เพิ่มบทบาทใหม่",
      "list_name": "ชื่อผู้ใช้ชื่อ",
      "list_confirm": "คุณแน่ใจไหม?",
      "list_confirm_delete": "คุณต้องการลบจริงๆ",
      "list_confirm_delete1": "บทบาท?",
      "list_confirm_delete2": "กระบวนการนี้ไม่สามารถยกเลิกได้",
      "list_cancel": "ยกเลิก",
      "list_delete": "ลบ",
      "list_ph_search": "ค้นหา...",
      "list_ph_input": "ชื่อบทบาทผู้ใช้ป้อนข้อมูล",
      "list_ph_nomatch": "ไม่พบบันทึกการจับคู่",
      "list_ttl_name": "ชื่อบทบาท",
      "list_ttl_actions": "การกระทำ"
    }
  },
  "settings": {
    "configure": {
      "settings_configure_configure": "กำหนดค่าระบบ",
      "settings_configure_add_group": "เพิ่มกลุ่มใหม่",
      "settings_configure_name": "ชื่อ",
      "settings_configure_value": "ค่า",
      "settings_configure_data_type": "ประเภทข้อมูล",
      "settings_configure_save": "บันทึก",
      "settings_configure_remove": "ลบ",
      "settings_configure_add_configuration": "เพิ่มการกำหนดค่า",
      "settings_configure_json": "JSON",
      "settings_configure_cancel": "ยกเลิก",
      "settings_configure_add": "เพิ่ม",
      "settings_configure_confirm": "คุณแน่ใจไหม?",
      "settings_confirmdddd": "กระบวนการนี้ไม่สามารถยกเลิกได้",
      "settings_confirm": "กระบวนการนี้ไม่สามารถยกเลิกได้",
      "settings_configure_cannot": "กระบวนการนี้ไม่สามารถยกเลิกได้",
      "settings_configure_delete": "ลบ",
      "settings_add_done": "เพิ่มกลุ่มใหม่สำเร็จ",
      "settings_add_fail": "เพิ่มกลุ่มใหม่ล้มเหลว!",
      "settings_save_done": "การบันทึกการกำหนดค่าทำได้สำเร็จ",
      "settings_add_config_done": "การเพิ่มการกำหนดค่าเสร็จสมบูรณ์",
      "settings_add_config_fail": "การเพิ่มการกำหนดค่าล้มเหลว",
      "settings_delete_config_done": "การลบการกำหนดค่าเสร็จสมบูรณ์",
      "settings_delete_config_fail": "การลบการกำหนดค่าล้มเหลว",
      "settings_configure_group_name": "ชื่อกลุ่ม"
    }
  },
  "users": {
    "add": {
      "users_add_create": "สร้างผู้ใช้",
      "users_add_first_name": "ชื่อจริง",
      "users_add_required": "ที่จำเป็น",
      "users_add_last_name": "นามสกุล",
      "users_add_email": "อีเมล",
      "users_add_required_email": "ที่จำเป็น",
      "users_add_user_name": "ชื่อผู้ใช้",
      "users_add_display_name": "ชื่อที่แสดง",
      "users_add_user_role": "บทบาทผู้ใช้",
      "users_add_multiple": "หลายรายการ",
      "users_add_password": "รหัสผ่าน",
      "users_add_confirm_password": "ยืนยันรหัสผ่าน",
      "users_add_update_success": "สร้างความสำเร็จ!",
      "users_add_please": "โปรดตรวจสอบอีเมลของคุณสำหรับข้อมูลเพิ่มเติม!",
      "users_add_update_fail": "สร้างล้มเหลว!",
      "users_add_please1": "โปรดตรวจสอบแบบฟอร์มที่กรอก"
    },
    "edit": {
      "users_edit_user_data": "ข้อมูลผู้ใช้",
      "users_edit_company_info": "ข้อมูล บริษัท",
      "users_edit_bank_info": "ข้อมูลธนาคาร",
      "users_edit_currency": "การตั้งค่าสกุลเงิน",
      "users_edit_kyc_docs": "เอกสาร KYC",
      "users_edit_first_name": "ชื่อจริง",
      "users_edit_required": "ที่จำเป็น",
      "users_edit_last_name": "นามสกุล",
      "users_edit_email": "อีเมล",
      "users_edit_required_email": "ที่จำเป็น",
      "users_edit_display_name": "ชื่อที่แสดง",
      "users_edit_user_role": "บทบาทผู้ใช้",
      "users_edit_multiple": "หลายรายการ",
      "users_edit_update_user": "อัปเดตผู้ใช้",
      "users_edit_update_success": "อัปเดตความสำเร็จ!",
      "users_edit_please": "โปรดตรวจสอบอีเมลของคุณสำหรับข้อมูลเพิ่มเติม!",
      "users_edit_update_fail": "การอัพเดทล้มเหลว!",
      "users_edit_please1": "โปรดตรวจสอบแบบฟอร์มที่กรอก"
    },
    "new": {
      "users_new_users": "เค้าโครงของผู้ใช้",
      "users_new_dialog": "เปิดกล่องโต้ตอบยืนยัน",
      "users_new_ph_nomatch": "ไม่พบบันทึกการจับคู่",
      "users_new_ttl_reg": "หมายเลขลงทะเบียน",
      "users_new_ttl_name": "ชื่อ บริษัท",
      "users_new_ttl_legal": "ชื่อกฎหมายของ บริษัท",
      "users_new_ttl_add": "ที่อยู่",
      "users_new_ttl_type": "พิมพ์",
      "users_new_ttl_contact": "โทรศัพท์",
      "users_new_ttl_email": "อีเมล",
      "users_new_ttl_actions": "การกระทำ"
    },
    "list": {
      "users_list_users": "เค้าโครงของผู้ใช้",
      "users_list_add": "เพิ่มผู้ใช้ใหม่",
      "users_list_showing": "แสดง 1 ถึง 10 จาก 150 รายการ",
      "users_list_edit": "แก้ไข",
      "users_list_delete": "ลบ",
      "users_list_ellipsis": "...",
      "users_list_ph_search": "ค้นหา..."
    }
  }
}