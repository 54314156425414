<template>
  <!-- BEGIN: upload invoice modal content -->
    <div id="upload-invoice-modal" class="modal" tabindex="-1" aria-hidden="true">
     <div class="modal-dialog modal-xl" style="width: 90vw;">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto"> {{ $t('invoice.upload.upload_upload_invoice') }} </h2>
          </div> <!-- END: Modal Header -->
          <div class="m-8 mt-4">
            <div class="flex items-center mt-2 md:mt-0">
              <div class="items-center form-inline block md:flex">
                <div v-if="documentFormats.length">
                  <button class="btn btn-outline-primary mr-1 inline-block" @click="chooseFiles">
                    <UploadIcon class="w-4 h-4 mr-2" />
                    {{ $t('invoice.upload.upload_upload_invoice') }}
                  </button>
                  <div class="dropdown inline-block" data-placement="bottom">
                    <button class="dropdown-toggle btn btn-primary w-44 mr-1" aria-expanded="false" :disabled='!uploadedFileId.length'> {{documentFormat}} </button>
                    <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a v-for="(document, index) in documentFormats" :key="index"
                          href="javascript:;"
                          class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          @click="setDocumentFormat(document.dataSourceSystemName)"
                        >
                          {{document.dataSourceSystemName}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center md:mt-0" v-if="defaultEcosystemId === '00000000-0000-0000-0000-000000000000'">
                <label for="bid-end-time" class="md:pl-4 pr-4">{{ $t('invoice.upload.upload_bid_end') }}</label>
                <DatePicker v-model="bidEndTime" mode="datetime" :masks="{inputDateTime: dateTimeFormat}">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      id="bid-end-time"
                      class="form-control w-56 block mx-auto border rounded focus:outline-none focus:border-blue-300"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </div>
              <!-- <div v-if="invoicesBatch.length" class="grid grid-cols-2 gap-x-4 mx-4"> -->
              <div v-if="invoicesBatch.length" class="grid grid-cols-2 gap-x-4 mx-4">
                <div v-if="workflowLed == 'Seller Led'" class="flex gap-x-2 items-center col-span-1" :class="`${submitableInvoice.selectDisbursableBankAccount ? 'text-gray-500 line-through' : 'text-theme-6'}`">
                  <CheckIcon v-if="submitableInvoice.selectDisbursableBankAccount" class="w-4 text-green-700"/>
                  <AlertCircleIcon v-else class="w-4"/>
                  <p>{{ $t('invoice.upload.upload_select_disb') }}</p>
                </div>
                <div class="flex gap-x-2 items-center col-span-1"  :class="`${submitableInvoice.batchRemark ? 'text-gray-500 line-through' : 'text-theme-6'}`">
                  <CheckIcon v-if="submitableInvoice.batchRemark" class="w-4 text-green-700"/>
                  <AlertCircleIcon v-else class="w-4"/>
                  <p>{{ $t('invoice.upload.upload_batch_remark') }}</p>
                </div>
                <div class="flex gap-x-2 items-center col-span-1" :class="`${submitableInvoice.uploadSupportDocuments ? 'text-gray-500 line-through' : 'text-theme-6'}`">
                  <CheckIcon v-if="submitableInvoice.uploadSupportDocuments" class="w-4 text-green-700"/>
                  <AlertCircleIcon v-else class="w-4"/>
                  <p>{{ $t('invoice.upload.upload_upload_documents') }}</p>
                </div>
                <div class="flex gap-x-2 items-center col-span-1" :class="`${submitableInvoice.paymentDueDate ? 'text-gray-500 line-through' : 'text-theme-6'}`">
                  <CheckIcon v-if="submitableInvoice.paymentDueDate" class="w-4 text-green-700"/>
                  <AlertCircleIcon v-else class="w-4"/>
                  <p>{{ $t('invoice.upload.upload_payment_date') }}</p>
                </div>
                <div class="flex gap-x-2 items-center col-span-1" :class="`${submitableInvoice.amount ? 'text-gray-500 line-through' : 'text-theme-6'}`">
                  <CheckIcon v-if="submitableInvoice.amount" class="w-4 text-green-700"/>
                  <AlertCircleIcon v-else class="w-4"/>
                  <p>{{ $t('invoice.upload.upload_amount_zero') }}</p>
                </div>
              </div>
            </div>

            <input id="file-upload" ref="fileUpload" type="file" class="hidden" @change="fileChoosen">
            <div class="col-span-12 h-full overflow-y-auto overflow-x-invisible bg-gray-200 p-1 mt-5">
              <div v-if="loading" class="py-16 h-full flex">
                <div class="w-full h-8 px-8 self-center flex justify-center">
                  <span class="text-xl pr-2">{{ $t('invoice.upload.upload_uploading') }}</span>
                  <div class="h-8">
                    <LoadingIcon icon="spinning-circles" color="#9a428a" class="w-4 h-4" />
                  </div>
                </div>
              </div>
              <div v-if="!loading" id="basic-table" class="p-5">
                <div class="preview">
                  <div class="overflow-x-auto">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_number') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_type') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{companyTypeHeaderLoc}} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_date') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_payment_date1') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_currency_code') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_amount') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_support') }} </th>
                          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"> {{ $t('invoice.upload.upload_action') }} </th>
                        </tr>
                      </thead>
                      <tbody v-for="(batch, batchIndex) in invoicesBatch" :key="batchIndex">
                        <tr>
                          <td colspan="4" v-if="workflowLed == 'Seller Led'">
                            <div class="flex w-100 items-center">
                              <label class="w-5/12">{{ $t('invoice.upload.upload_select_disb1') }}</label>
                              <select v-model="invoicesBatch[batchIndex].bankId" class="form-select w-6/12">
                                <option v-for="bank in bankAccount" :key="bank.bankAccountId" :value="bank.bankAccountId">
                                  {{bank.accountNumber}} ({{bank.bankName}})
                                </option>
                              </select>
                            </div>
                          </td>
                          <td colspan="4">
                            <div class="flex w-100 items-center">
                              <label class="w-5/12">{{ $t('invoice.upload.upload_remark') }}</label>
                              <input type="text" v-model="invoicesBatch[batchIndex].remark" class="form-control"/>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="(item, index) in batch.invoices" :key="index">
                          <td class="border-b dark:border-dark-5">
                            <input v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" type="text" v-model="invoicesBatch[batchIndex].invoices[index].documentNumber"/>
                            <span v-else>{{item.documentNumber}}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <input v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" type="text" v-model="invoicesBatch[batchIndex].invoices[index].documentType" size="5"/>
                            <span v-else>{{item.documentType}}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <input v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index && companyTypeHeader === 'Seller Name'" type="text" v-model="invoicesBatch[batchIndex].invoices[index].invoiceFromCompanyName"/>
                            <input v-else-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index && companyTypeHeader === 'Buyer Name'" type="text" v-model="invoicesBatch[batchIndex].invoices[index].invoiceToCompanyName"/>
                            <span v-else-if="companyTypeHeader === 'Seller Name'">{{item.invoiceFromCompanyName}}</span>
                            <span v-else-if="companyTypeHeader === 'Buyer Name'">{{item.invoiceToCompanyName}}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <DatePicker v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" v-model="invoicesBatch[batchIndex].invoices[index].documentDate" mode="date" :masks="{input: dateFormat}">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input
                                  class="block mx-auto border rounded focus:outline-none focus:border-blue-300"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                  size="11"
                                />
                              </template>
                            </DatePicker>
                            <span v-else>{{moment(item.documentDate).format(dateFormat) }}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <DatePicker v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" v-model="invoicesBatch[batchIndex].invoices[index].paymentDueDate" mode="date" :masks="{input: dateFormat}">
                              <template v-slot="{inputValue,  inputEvents }">
                                <input
                                  class="block mx-auto border rounded focus:outline-none focus:border-blue-300"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                  size="11"
                                />
                              </template>
                            </DatePicker>
                            <span v-else>{{moment(item.paymentDueDate).format(dateFormat) }}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <input v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" type="text" v-model="invoicesBatch[batchIndex].invoices[index].currencyCode" size="5"/>
                            <span v-else>{{item.currencyCode}}</span>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <input v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" type="number" v-model="invoicesBatch[batchIndex].invoices[index].amount" size="8"/>
                            <span v-else>{{parseFloat(item.amount).toFixed(2)}}</span>
                          </td>
                          <td class="border-b dark:border-dark-5 fileupload-col cursor-pointer">
                            <SupportDropzone :batchIndex="batchIndex" :index="index" :data="invoicesBatch[batchIndex].invoices[index].supportingDocuments" :addSupportDoc="addSupportDoc" :removeSupportDoc="removeSupportDoc"/>
                          </td>
                          <td class="flex">
                            <button class="btn btn-sm btn-danger" @click="removeRow(batchIndex, index)">
                              <Trash2Icon class="w-4 h-4" />
                            </button>
                            <button v-if="batchIndex == editRowIndex.batchIndex && index == editRowIndex.index" class="btn btn-sm ml-2 btn-primary" @click="saveRow(batchIndex, index)">
                              <SaveIcon class="w-4 h-4" />
                            </button>
                            <button v-else class="btn btn-sm ml-2 btn-primary" @click="editRow(batchIndex, index)">
                              <EditIcon class="w-4 h-4" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mx-3">
            <button type="button" @click="cancelModal" class="btn btn-outline-secondary mr-1"> {{ $t('invoice.upload.upload_cancel') }} </button>
            <button type="button" class="btn btn-primary w-20" @click="submitInvoice" :disabled='!submitableInvoice.verified || loading'> 
              <span>{{ $t('invoice.upload.upload_submit') }}</span>
            </button>
          </div> <!-- END: Modal Footer -->
        </div>
      </div>
      <div id="failed-notification-content" class="toastify-content hidden flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('invoice.upload.upload_upload_fail') }}</div>
          <div class="text-gray-600 mt-1" id="error-content"></div>
        </div>
      </div>
    </div>
    <!-- END: uploading invoice modal content -->
</template>

<script>
import { ref, onMounted, watchEffect, watch } from "vue";
import { useStore } from 'vuex';
import moment from "moment";
import _ from "lodash";
import { appAxios } from "@/plugins/axios";
import SupportDropzone from "./SupportFileDropzone";
import Toastify from "toastify-js";
import toast from '@/utils/toast';
import companyService from '@/services/companyService';
import fileService from '@/services/fileService';
import workflowService from '@/services/workflowService';
import i18n from '@/plugins/i18n';

export default {
  components: {
    SupportDropzone
  },
  props: {
    callback: {
      type: Function,
    }
  },
  setup(props){
    const dateFormat = ref(process.env.VUE_APP_DATE_FORMAT);
    const dateTimeFormat = ref(process.env.VUE_APP_DATETIME_FORMAT);
    const store = useStore();
    const company_uuid = store.state.account.company_uuid;
    const defaultEcosystemId = ref(store.state.main.defaultEcosystem.ecosystemId)
    const bankAccount = ref([]);
    const jsonData = ref([]);
    const fileUpload = ref(null);
    const documentFormat = ref(i18n.global.t('invoice.upload.upload_upload_selecttype'));
    const companyTypeHeader = ref("Company Name");
    const companyTypeHeaderLoc = ref(i18n.global.t('invoice.upload.upload_company_name'));
    const documentFormats = ref([])
    const bidEndTime = ref(new Date());
    const loading = ref(false);
    const editRowIndex = ref({
      batchIndex: null,
      index: null
    });
    const uploadedFileId = ref('');
    const workflowLed = ref('');
    const invoiceFromCompanyName = ref('');
    const invoiceToCompanyName = ref('');
    const requestValide = ref(true);
    const invoicesBatch = ref([]);
    const submitableInvoice = ref({
      selectDisbursableBankAccount: false,
      batchRemark: false,
      uploadSupportDocuments: false,
      paymentDueDate: false,
      amount: false,
      verified: false
    })

    const setDocumentFormat = async (format) => {
      documentFormat.value = format
      try {
        const response = await fileService.getExtractdata({fileId: uploadedFileId.value, format})
        workflowLed.value = response.workflow;
        response.invoices.forEach((entity) => {
          entity['supportingDocuments'] = []
        })
        jsonData.value = response.invoices
        //group the invoices to batch by payment due date and company name
        let paymentDueDate = jsonData.value[0].paymentDueDate
        let companyName = jsonData.value[0].invoiceFromCompanyName ? jsonData.value[0].invoiceFromCompanyName : jsonData.value[0].invoiceToCompanyName
        let batch = [];
        invoicesBatch.value = []
        for(var i = 0; i < jsonData.value.length; i++) {
          if( jsonData.value[i].paymentDueDate == paymentDueDate  && jsonData.value[i].invoiceFromCompanyName == companyName ||
            jsonData.value[i].paymentDueDate == paymentDueDate && jsonData.value[i].invoiceToCompanyName == companyName
          ) {
            batch.push({...jsonData.value[i]})
          } else {
            invoicesBatch.value.push({bankId: '', remark: '', invoices: batch})
            batch = []
            batch.push({...jsonData.value[i]})
            paymentDueDate = jsonData.value[i].paymentDueDate
            companyName = jsonData.value[i].invoiceFromCompanyName ? jsonData.value[0].invoiceFromCompanyName : jsonData.value[0].invoiceToCompanyName
            if(i == jsonData.value.length - 1) {
              invoicesBatch.value.push({bankId: '', remark: '', invoices: batch})
              batch = []
            }
          }
        }
        if(batch.length) invoicesBatch.value.push({bankId: '', remark: '', invoices: batch})

        //identify the invoice detail show table header and it will use to determine current invoice is seller led or buyer led
        // console.log("workflow led = ", response.workflow)
        // console.log("invoicesBatch = ", invoicesBatch.value)
        if(response.workflow === 'Buyer Led') {
          companyTypeHeader.value = "Seller Name";
          invoiceToCompanyName.value = response.invoiceToCompanyName;
        }
        else {
          companyTypeHeader.value = "Buyer Name";
          invoiceFromCompanyName.value = response.invoiceFromCompanyName;
        }
        companyTypeHeaderLoc.value = i18n.global.t('invoice.upload.upload_'+_.replace(_.lowerCase(companyTypeHeader.value), ' ', '_'))
      } catch (err) {
        toast({status: 'error', title: i18n.global.t('invoice.upload.upload_upload_s_file_error'), content: err})
      }
      cash(".dropdown-menu").dropdown("hide");
    }

    const removeRow = (batchIndex, index) => {
      invoicesBatch.value[batchIndex].invoices.splice(index, 1)
      if(!invoicesBatch.value[batchIndex].invoices.length) {
        invoicesBatch.value.splice(batchIndex, 1)
      }
    }

    const editRow = (batchIndex, index) => {
      editRowIndex.value.batchIndex = batchIndex;
      editRowIndex.value.index = index;
    }

    const saveRow = (batchIndex, index) => {
      editRowIndex.value.batchIndex = null;
      editRowIndex.value.index = null;
      var companyName = invoicesBatch.value[batchIndex].invoices[index].invoiceFromCompanyName ? invoicesBatch.value[batchIndex].invoices[index].invoiceFromCompanyName : invoicesBatch.value[batchIndex].invoices[index].invoiceToCompanyName
      var paymentDueDate = invoicesBatch.value[batchIndex].invoices[index].paymentDueDate

      for(var i = 0; i < invoicesBatch.value.length; i++) {
        for(var j = 0; j < invoicesBatch.value[i].invoices.length; j++) {
          var compareCompanyName = invoicesBatch.value[i].invoices[j].invoiceFromCompanyName ? invoicesBatch.value[i].invoices[j].invoiceFromCompanyName : invoicesBatch.value[i].invoices[j].invoiceToCompanyName
          var comparePaymentDuedate = invoicesBatch.value[i].invoices[j].paymentDueDate
          var paymentDueDateDayDiff = moment(paymentDueDate).diff(moment(comparePaymentDuedate), 'days')
          if(i == batchIndex && j == index) continue;
          if(i == batchIndex) {
            if(companyName != compareCompanyName || paymentDueDateDayDiff) {
              invoicesBatch.value.push({bankId: invoicesBatch.value[batchIndex].bankId, remark: invoicesBatch.value[batchIndex].remark, invoices: [{...invoicesBatch.value[batchIndex].invoices[index]}]})
              invoicesBatch.value[batchIndex].invoices.splice(index, 1)
              batchIndex = invoicesBatch.value.length
              index = i = j = 0;
              if(invoicesBatch.value[batchIndex].invoices.length == 0) invoicesBatch.value.splice(batchIndex, 1);
              continue;
            }
          }
          if(companyName == compareCompanyName && paymentDueDateDayDiff == 0) {
            invoicesBatch.value[i].invoices.push(invoicesBatch.value[batchIndex].invoices[index]);
            invoicesBatch.value[batchIndex].invoices.splice(index, 1);
            if(invoicesBatch.value[batchIndex].invoices.length == 0) invoicesBatch.value.splice(batchIndex, 1);
            break;
          }
        }
      }
    }

    const addSupportDoc = (batchIndex, index, documentId, documentName) => {
      invoicesBatch.value[batchIndex].invoices[index].supportingDocuments.push({
        documentName: documentName,
        documentURI: process.env.VUE_APP_SYSTEM_API_URL + "/uploads/v1/" + documentId
      })
    }

    const removeSupportDoc = (batchIndex, index, documentIndex) => {
      invoicesBatch.value[batchIndex].invoices[index].supportingDocuments.splice(documentIndex, 1)
    }

    const chooseFiles = () => {
      document.getElementById("file-upload").click();
    }
    // to getting the upload invoice url and private ecosystem detail
    const getPrivateEcosystemDetail = () => {
      return new Promise( async resolve => {
        const ecosystemId = store.state.main.defaultEcosystem.ecosystemId
        const ecosystem = await companyService.getEcosystemById(ecosystemId)
        // console.log("ecosystem: ", ecosystem)
        var buyerLedWorkflowAPIEndpoint = ''
        var sellerLedWorkflowAPIEndpoint = ''

        if(ecosystem.buyerLedWorkflowId !== '00000000-0000-0000-0000-000000000000') {
          const workflow = await workflowService.getWorkflowById(ecosystem.buyerLedWorkflowId)
          // console.log("workflow: ", workflow)
          buyerLedWorkflowAPIEndpoint = workflow.workflowStatuses[0].statusUpdateHandlerAPIEndpoint
        }

        if(ecosystem.sellerLedWorkflowId !== '00000000-0000-0000-0000-000000000000') {
          const workflow = await workflowService.getWorkflowById(ecosystem.sellerLedWorkflowId)
          sellerLedWorkflowAPIEndpoint = workflow.workflowStatuses[0].statusUpdateHandlerAPIEndpoint
        }

        const rlt = {
          ecosystem: ecosystem,
          buyerLedUploadUrl: buyerLedWorkflowAPIEndpoint,
          sellerLedUploadUrl: sellerLedWorkflowAPIEndpoint
        }
        resolve(rlt)
      })

    }

    const submitInvoice = async () => {
      var api = ''
      var buyerCompanyId = '';
      var sellerCompanyId = '';
      var requestBodys = [];

      if(store.state.main.defaultEcosystem.ecosystemId === '00000000-0000-0000-0000-000000000000') {
        //This is public ecosystem
        //preparing invoice upload request body
        if(workflowLed.value === 'Buyer Led') {
          api = "/workflow/v2/buyer-led-invoice-financing-workflow-0/0"
          buyerCompanyId = await companyService.getCompanyIdByCompanyName(invoiceToCompanyName.value)
          await Promise.all(
            invoicesBatch.value.map(async (batch, index) => {
              var journalBatchEntries = []
              await Promise.all(
                batch.invoices.map( async invoice => {
                  const { companyId } = await companyService.getCompanyIdByCompanyName(invoice.invoiceFromCompanyName)
                  journalBatchEntries.push({
                    ...invoice,
                    sellerCompanyId: companyId
                  })
                })
              )
              requestBodys.push({
                invoicesBatchIndex: index,
                buyerCompanyId: buyerCompanyId.companyId,
                journalBatchEntries,
                bidEndTime: moment(bidEndTime.value).format(),
                remarks: batch.remark
              })
            })
          )
        } else {
          api = "/workflow/v2/seller-led-invoice-financing-workflow-1/0"
          sellerCompanyId = await companyService.getCompanyIdByCompanyName(invoiceFromCompanyName.value)

          await Promise.all(
            invoicesBatch.value.map(async (batch, index) => {
              var journalBatchEntries = []
              await Promise.all(
                batch.invoices.map(async invoice => {
                  const { companyId } = await companyService.getCompanyIdByCompanyName(invoice.invoiceToCompanyName)
                  journalBatchEntries.push({
                    ...invoice,
                    buyerCompanyId: companyId
                  })
                })
              )
              requestBodys.push({
                invoicesBatchIndex: index,
                sellerCompanyId: sellerCompanyId.companyId,
                journalBatchEntries,
                bidEndTime: moment(bidEndTime.value).format(),
                disbursableBankAccount: {
                  ..._.find(bankAccount.value, {bankAccountId: batch.bankId})
                },
                remarks: batch.remark
              })
            })
          )
        }
        //verification request body.
        if(sellerCompanyId === '00000000-0000-0000-0000-000000000000') {
          requestValide.value = false;
          showValidationError(-1, `can not find seller company with ${invoiceFromCompanyName.value}`)
          return;
        } else if(buyerCompanyId === '00000000-0000-0000-0000-000000000000') {
          requestValide.value = false;
          showValidationError(-1, `Can not find buyer company with ${invoiceToCompanyName.value}`);
          return;
        }
      } else {
        //This is private Ecosystem
        const PrivateEcosystem = await getPrivateEcosystemDetail()
        if(workflowLed.value === 'Buyer Led') api = PrivateEcosystem.buyerLedUploadUrl.replace('/api/genie', '')
        else api = PrivateEcosystem.sellerLedUploadUrl

        invoicesBatch.value.map(async (batch, index) => {
          var journalBatchEntries = []
          batch.invoices.map( async invoice => {
            journalBatchEntries.push({
              ...invoice,
              sellerCompanyId: PrivateEcosystem.ecosystem.sellerCompanyId
            })
          })
          requestBodys.push({
            invoicesBatchIndex: index,
            buyerCompanyId: PrivateEcosystem.ecosystem.buyerCompanyId,
            ecosystemId: PrivateEcosystem.ecosystem.ecosystemId,
            journalBatchEntries,
            remarks: batch.remark
          })
        })
      }

      loading.value = !loading.value
      var noError = true
      var errorMessages = []
      await Promise.all(
        requestBodys.map( async (requestBody) => {
          var invoiceUploadResponse = await appAxios.post(api, requestBody)
          //notify to show invoice upload result
          if(invoiceUploadResponse.status === 'error') {
            noError = false
            errorMessages.push(invoiceUploadResponse.error.response.data)
          } else {
            // console.log(requestBody.invoicesBatchIndex)
            invoicesBatch.value = _.filter(invoicesBatch.value, (batch, index) => {
              return (index !== requestBody.invoicesBatchIndex)
            })
          }
        })
      )
      if(noError) {
        //initialize the values.
        invoicesBatch.value = [];
        jsonData.value = [];
        bankAccount.value = [];
        fileUpload.value = null;
        documentFormat.value = "Select Document Type";
        bidEndTime.value = new Date();
        uploadedFileId.value = ''
        workflowLed.value = '';
        invoiceFromCompanyName.value = '';
        invoiceToCompanyName.value = '';
        requestValide.value = true
        submitableInvoice.value = {
          selectDisbursableBankAccount: false,
          batchRemark: false,
          uploadSupportDocuments: false,
          paymentDueDate: false,
          amount: false,
          verified: false
        }
        cash("#upload-invoice-modal").modal("hide");
        props.callback()
      } else errorMessages.forEach(message => toast({status: "error", title: i18n.global.t('invoice.upload.upload_upload_s_invoice_fail'), content: message}))
      loading.value = !loading.value;
    }

    const cancelModal = async () => {
      jsonData.value = []
      fileUpload.value = null
      bidEndTime.value = new Date()
      editRowIndex.value = {batchIndex: null, index: null}
      uploadedFileId.value = ''
      workflowLed.value = ''
      invoiceFromCompanyName.value = ''
      invoiceToCompanyName.value = ''
      requestValide.value = true
      invoicesBatch.value = []
      submitableInvoice.value = {
        selectDisbursableBankAccount: false,
        batchRemark: false,
        uploadSupportDocuments: false,
        paymentDueDate: false,
        amount: false,
        verified: false
      }
      documentFormat.value = 'Select Document Type'
      cash("#file-upload")[0].value = ''
      cash("#upload-invoice-modal").modal("hide");
    }

    const showValidationError = (index, errorMessage) => {
      cash("#error-content").text(errorMessage);
      Toastify({
        node: cash("#failed-notification-content").clone().removeClass("hidden")[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
      }).showToast();
    }

    const fileChoosen = async (event) => {
      let formData = new FormData();
      if(event.target.files.length) {
        formData.append('file', event.target.files[0])
        uploadedFileId.value = await fileService.fileUpload('invoice_batch', formData)
      }
    }

    const init = async () => {
      bankAccount.value = await companyService.getAccountBankInfoByCompanyId(company_uuid)
      documentFormats.value = await companyService.getCompanyDataSource({ecosystemId: store.state.main.defaultEcosystem.ecosystemId, companyId: company_uuid})
      loading.value = false
    }

    watch(
      () => [invoicesBatch.value],
      () => {
        var supportDocumentVerification = true
        var paymentDueDateVerification = true
        var amountVerification = true
        var flg = true
        invoicesBatch.value.forEach(batch => {
          if(!batch.remark) {
            flg = false
            submitableInvoice.value.batchRemark = false
          } else {
            submitableInvoice.value.batchRemark = true
          }
          if(workflowLed.value === 'Seller Led') {
            if(!batch.bankId) {
              submitableInvoice.value.selectDisbursableBankAccount = false
              flg = false
            }
            else submitableInvoice.value.selectDisbursableBankAccount = true
          }
          batch.invoices.forEach(invoice => {
            if(new Date(invoice.paymentDueDate) < new Date()) {
              flg = false
              paymentDueDateVerification = false
              
            }
            // GEN-21 Should avoid 0 amount invoice to be submitted (+)
            if(invoice.amount < 1) {
              flg = false
              amountVerification = false
              
            }
            // GEN-21 Should avoid 0 amount invoice to be submitted (-)
            if(!invoice.supportingDocuments.length) {
              flg = false
              supportDocumentVerification = false
              
            }
          })
        })
        submitableInvoice.value.uploadSupportDocuments = supportDocumentVerification
        submitableInvoice.value.paymentDueDate = paymentDueDateVerification
        // GEN-21 Should avoid 0 amount invoice to be submitted (+)
        submitableInvoice.value.amount = amountVerification
        // GEN-21 Should avoid 0 amount invoice to be submitted (-)
        submitableInvoice.value.verified = flg
      },
      { deep: true }
    )

    watchEffect(() => {
      if(store.state.main.defaultEcosystem.ecosystemId !== defaultEcosystemId.value) {
        defaultEcosystemId.value = store.state.main.defaultEcosystem.ecosystemId
        init()
      }
    })

    onMounted(() => {
      init()
    })
    return {
      dateFormat,
      dateTimeFormat,
      loading,
      jsonData,
      fileUpload,
      uploadedFileId,
      documentFormat,
      companyTypeHeader,
      companyTypeHeaderLoc,
      workflowLed,
      defaultEcosystemId,
      documentFormats,
      bankAccount,
      setDocumentFormat,
      removeRow,
      editRow,
      saveRow,
      chooseFiles,
      submitInvoice,
      cancelModal,
      fileChoosen,
      addSupportDoc,
      removeSupportDoc,
      bidEndTime,
      editRowIndex,
      submitableInvoice,
      moment,
      invoicesBatch
    }
  }
}
</script>

<style scoped>
.header-Table {
  width: 110px;
  border: 1px solid rosybrown;
  border-radius: 7px;
  padding: 3px 2px;
  outline: none;
}
.fileupload-col {
  padding: 0px;
}
.fileupload-col > div {
  border: 2px dashed #e295c5;
  border-radius: 5px;
}
</style>
