<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ $t('permissions.add.add_create') }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Validation -->
        <div class="intro-y box">
          <div id="form-validation" class="p-5">
            <div class="preview">
              <!-- BEGIN: Validation Form -->
              <form class="validate-form" @submit.prevent="create">
                <div class="input-form">
                  <label for="validation-form-1" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('permissions.add.add_name') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('permissions.add.add_required') }}</span>
                  </label>
                  <input
                    id="validation-form-1"
                    v-model.trim="validate.permissionName.$model"
                    type="text"
                    name="permissionName"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.permissionName.$error }"
                    placeholder="John Legend"
                  />
                  <template v-if="validate.permissionName.$error">
                    <div
                      v-for="(error, index) in validate.permissionName.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label for="validation-form-2" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('permissions.add.add_resource') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('permissions.add.add_required') }}</span>
                  </label>
                  <input
                    id="validation-form-2"
                    v-model.trim="validate.resourceUrl.$model"
                    type="text"
                    name="resourceUrl"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.resourceUrl.$error }"
                    placeholder="John Legend"
                  />
                  <template v-if="validate.resourceUrl.$error">
                    <div
                      v-for="(error, index) in validate.resourceUrl.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label for="validation-form-3" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('permissions.add.add_verbs') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('permissions.add.add_required') }}</span>
                  </label>
                  <div class="mt-2">
                    <TailSelect
                      v-model="validate.accessVerb.$model"
                      name="accessVerb"
                      :options="{ search: true, descriptions: true, hideSelected: true, hideDisabled: true, multiLimit: 4, multiShowCount: false, multiContainer: true, classNames: 'w-full' }"
                      multiple>
                      <option value="Get">Get</option>
                      <option value="Post">Post</option>
                      <option value="Put">Put</option>
                      <option value="Delete">Delete</option>
                    </TailSelect>
                  </div>
                </div>
                <div class="input-form mt-3">
                  <label for="validation-form-4" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('permissions.add.add_valid') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('permissions.add.add_format') }}</span>
                  </label>
                  <div class="flex items-center">
                    <div>
                      <div class="relative w-56">
                        <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                          <CalendarIcon class="w-4 h-4" />
                        </div>
                        <Litepicker
                          v-model="validate.validUntil.$model"
                          name="validUntil"
                          :options="litePicker"
                          class="form-control pl-12"
                          aria-disabled="true"
                        />
                      </div>
                    </div>
                    <div class="form-check ml-5">
                      <input id="un-expire-date" v-model="validate.unExpired.$model" class="form-check-input" name="unExpired" type="checkbox" value="" />
                      <label class="form-check-label" for="un-expire-date">{{ $t('permissions.add.add_unexpired') }}</label>
                    </div>
                  </div>
                </div>
                <div class="input-form mt-3">
                  <label for="validation-form-5" class="form-label w-full flex flex-col sm:flex-row">
                    {{ $t('permissions.add.add_type') }}
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">{{ $t('permissions.add.add_required') }}</span>
                  </label>
                  <div class="flex flex-col sm:flex-row mt-2">
                    <div class="form-check mr-2">
                      <input id="type-1" v-model.trim="validate.type.$model" class="form-check-input" type="radio" name="type" value="Menu" />
                      <label class="form-check-label" for="type-1">{{ $t('permissions.add.add_menu') }}</label>
                    </div>
                    <div class="form-check mr-2 mt-2 sm:mt-0">
                      <input id="type-2" v-model.trim="validate.type.$model" class="form-check-input" type="radio" name="type" value="API" />
                      <label class="form-check-label" for="type-2">{{ $t('permissions.add.add_api') }}</label>
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mt-5">{{ $t('permissions.add.add_create') }}</button>
              </form>
              <!-- END: Validation Form -->
            </div>
          </div>
        </div>
        <!-- END: Form Validation -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import accessService from "@/services/accessService";
import { helper } from "@/utils/helper";
import toast from "@/utils/toast"
import i18n from '@/plugins/i18n';

export default {
  setup() {
    const litePicker = ref({
      autoApply: false,
      showWeekNumbers: true,
      minDate: new Date(),
      format: "D MMM, YYYY",
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true
      },
      setup: (picker) => {
        picker.on('button:apply', (date) => {
          formData.validUntil = helper.formatDate(date.dateInstance, "D MMM, YYYY")
          // picker.preventClick = true;
          // some action
        });
      },
    });

    const formData = reactive({
      permissionName: "",
      resourceUrl: "",
      accessVerb: "",
      validUntil: "",
      unExpired: false,
      type: "Menu"
    });
    const rules = {
      permissionName: { required, minLength: minLength(2) },
      resourceUrl: { required, minLength: minLength(2)},
      accessVerb: { required },
      validUntil: { required },
      unExpired: {  },
      type: { required }
    };
    const validate = useVuelidate(rules, toRefs(formData));
    const create = async () => {
      // console.log(formData);
      validate.value.$touch();
      if (validate.value.$invalid) toast({ status: "error", title: i18n.global.t('permissions.add.add_valid_error'), content: i18n.global.t('permissions.add.add_please_fields') })
      else {
        try {
          const requestBody = {
            permissionName: formData.permissionName,
            resourceURI: formData.resourceUrl,
            accessVerbs: formData.accessVerb,
            validUntil: formData.unExpired ? null : formData.validUntil,
            type: formData.type,
          }
          await accessService.createPermission(requestBody)
          toast({ status: "success", title: i18n.global.t('permissions.add.add_create_success'), content: i18n.global.t('permissions.add.add_create_success_done')})
        } catch (err) {
          toast({ status: "error", title: i18n.global.t('permissions.add.add_create_fail'), content: err})
        }
      }
    };

    return {
      litePicker,
      validate,
      formData,
      create
    };
  }
};
</script>
