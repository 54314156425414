<template>
  <div class='intro-y box p-5 mt-5'>
    <div class='flex items-center'>
      <ListIcon class='w-6 h-6 mr-3' /><span class='text-lg'>{{ $t('invoice.batch.eco.eco_batch_details') }} {{ $t('invoice.batch.eco.eco_batch_workflow_'+batchDetails.workflowLed.toLowerCase().replace(' ', '_')) }}</span>
      <div
        class='alert show flex items-center h-5 p-3 text-sm justify-center ml-3'
        :class="`${batchDetails.batchStatus == 'Expired' || batchDetails.batchStatus == 'Rejected' ? 'bg-red-300' : 'bg-green-200'} `"
        role="alert"
      >
        <span>
          {{ $t('invoice.batch.eco.eco_batch_status_'+batchDetails.batchStatus.replace(/\ /g, '_').toLowerCase()) }}
          <!-- {{ batchDetails.batchStatus }} -->
        </span>
      </div>
    </div>

    <div class='mt-5'>
      <span>{{ $t('invoice.batch.eco.eco_batch_info') }}</span>
      <table class='table mt-2'>
        <tr class='hover:bg-gray-200'>
          <td class='border w-1/2'>{{ $t('invoice.batch.eco.eco_buyer_company') }}</td>
          <td class='border'>{{batchDetails.buyerCompanyName}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_seller_company') }}</td>
          <td class='border'>{{batchDetails.sellerCompanyName}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_funder_company') }}</td>
          <td class='border'>{{batchDetails.funderCompanyName ? batchDetails.funderCompanyName : 'Not Selected Yet'}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_no_batch') }}</td>
          <td class='border'>{{batchDetails.batchInformation.noOfBatchEntries}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_upload_date') }}</td>
          <td class='border'>{{batchDetails.batchInformation.uploadDate}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_total_amount') }}</td>
          <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.batchInformation.totalAmount)}}</td>
        </tr>
      </table>
    </div>
    <div class='mt-8' v-if="lodash.find(provenance, {statusName: 'AWAITING_FUNDER_FIRST_DISBURSEMENT'})?.passed || lodash.find(provenance, {statusName: 'AWAITING_FUNDER_DISBURSEMENT'})?.passed">
      <span>{{ $t('invoice.batch.eco.eco_bank_details') }}</span>
      <table class='table mt-2'>
        <tr class='hover:bg-gray-200'>
          <td class='border w-1/2'>{{ $t('invoice.batch.eco.eco_disbursement_account') }}</td>
          <td class='border'>
            <p v-if='batchDetails.extraData.disbursableAccount'>
            {{ $t('invoice.batch.eco.eco_account_no') }} {{batchDetails.extraData.disbursableAccount.accountNumber}}
            <br>
            {{ $t('invoice.batch.eco.eco_bank_name') }}{{batchDetails.extraData.disbursableAccount.bankName}}
            <br>
            {{ $t('invoice.batch.eco.eco_address') }}{{batchDetails.extraData.disbursableAccount.address}}
            <br>
            {{ $t('invoice.batch.eco.eco_swift') }}{{batchDetails.extraData.disbursableAccount.swiftCode}}
            </p>
          </td>
        </tr>
      </table>
    </div>
    <div class='mt-5' v-if="lodash.find(provenance, {statusName: 'TRANSACTION_APPROVED_BY_FUNDER'})?.state === 'Completed'">
      <span>{{ $t('invoice.batch.eco.eco_formula') }}</span>
      <table class='table mt-2'>
        <tr class='hover:bg-gray-200' v-if="user.user_role === 'Funder Admin' || currentCompanyRole === 'Buyer Admin'">
          <td class='border w-1/2'>Interest Rate</td>
          <td class='border'>{{batchDetails.formula.interestRate}}% {{batchDetails.formula.interestRateDuration}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_value_date') }}</td>
          <td class='border'>{{batchDetails.formula.valueDate}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.led.led_maturity') }}</td>
          <td class='border'>{{batchDetails.formula.maturityDate}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('account.holidays.holidays_number') }}</td>
          <td class='border'>{{batchDetails.formula.numberOfDays}}</td>
        </tr>
        <tr class='hover:bg-gray-200' v-if="user.user_role === 'Funder Admin' || currentCompanyRole === 'Buyer Admin'">
          <td class='border'>{{ $t('invoice.batch.eco.eco_interest_earn') }}</td>
          <td class='border'>{{batchDetails.currencyCode}} {{batchDetails.formula.interestAmount}}</td>
        </tr>
        <tr class='hover:bg-gray-200' v-if="user.user_role === 'Funder Admin' || currentCompanyRole === 'Buyer Admin'">
          <td class='border'>{{ $t('invoice.batch.eco.eco_platform_fee') }}</td>
          <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.platformFeeAmount)}} </td>
        </tr>
        <tr class='hover:bg-gray-200' v-if="user.user_role === 'Funder Admin' && batchDetails.extraData?.disbursableAccount">
          <td class='border'>{{ $t('invoice.batch.eco.eco_disbursement_account') }}</td>
          <td class='border'>{{batchDetails.extraData.disbursableAccount.bankName}} {{batchDetails.extraData.disbursableAccount.accountNumber}}</td>
        </tr>
        <tr class='hover:bg-gray-200' v-if="user.user_role === 'Funder Admin' || currentCompanyRole === 'Buyer Admin'">
          <td class='border'>Disbursement Amount</td>
          <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.disbursableAmount1)}}</td>
        </tr>
        <tr class='hover:bg-gray-200' v-if="currentCompanyRole === 'Buyer Admin' && batchDetail.extraData?.repaymentAccount">
          <td class='border'>{{ $t('invoice.batch.eco.eco_repayment_account') }}</td>
          <td class='border'>{{batchDetails.extraData.repaymentAccount.bankName}} {{batchDetails.extraData.repaymentAccount.accountNumber}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_repayment_amount') }}</td>
          <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.repaymentAmountToFunder)}}</td>
        </tr>
        <tr class='hover:bg-gray-200'>
          <td class='border'>{{ $t('invoice.batch.eco.eco_repayment_date') }}</td>
          <td class='border'>{{batchDetails.formula.repaymentDate}}</td>
        </tr>
      </table>
    </div>

    <div v-if="batchMessage.length" class="bg-gray-300 mt-6 text-center">
      <p class='text-gray-700 px-2 py-4 text-left'>{{ $t('invoice.batch.led.led_note') }} {{batchMessage}}</p>
    </div>
    <div v-if='initComponent'>
      <div v-if='visibleWorkflowActions.visibleApproveButton' class='pt-8 flex justify-center'>
        <a href='javascript:;' data-toggle='modal' data-target='#decline-invoice-modal' class='btn btn-secondary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_decline') }}</a>
        <a href='javascript:;' data-toggle='modal' data-target='#approve-invoice-modal' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_approve') }}</a>
      </div>
      <div v-if='visibleWorkflowActions.visibleSubmitProposal' class='pt-8 flex justify-center'>
        <a href='javascript:;' data-toggle='modal' data-target='#submit-proposal-modal' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_approve_trx') }}</a>
        <a href='javascript:;' data-toggle='modal' data-target='#reject-proposal-modal' class='btn btn-secondary w-48 sm:w-auto mr-2' >Reject Transaction</a>
      </div>
      <div v-if='visibleWorkflowActions.visibleSubmitDisbursementAdvice' class='pt-8 flex justify-center'>
        <a href='javascript:;' data-toggle='modal' data-target='#submit-disbursement-modal' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_submit_disb') }}</a>
      </div>
      <div v-if='visibleWorkflowActions.visibleSellerAcknowledgeOfReceiveDisbursement' class='pt-8 flex justify-center'>
        <a href='javascript:;' @click='openSellerAcknowledgeOfReceiveDisbursementModel' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_receive_disb') }}</a>
      </div>
      <div v-if='visibleWorkflowActions.visibleBuyerUploadRepaymentAdvice' class='pt-8 flex justify-center'>
        <a href='javascript:;' data-toggle='modal' data-target='#buyer-upload-repayment-advice' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_upload_advice') }}</a>
      </div>
      <div v-if='visibleWorkflowActions.visibleFunderAcknowledgeRepaymentAdvice' class='pt-8 flex justify-center'>
        <a href='javascript:;' @click='openFunderAcknowledgeUploadRepaymentAdviceModel' class='btn btn-primary w-48 sm:w-auto mr-2' >{{ $t('invoice.batch.eco.eco_repay_advice') }}</a>
      </div>
    </div>
  </div>
  <!-- Start: action modal -->
  <div id='approve-invoice-modal' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_approve_invoice') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_batch_no') }}</div>
            <div class='self-center'>{{batchDetails.batchNumber}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_invoice_upload') }}</div>
            <div class='self-center'>{{moment(batchDetails.batchInformation.uploadDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_invoice_amount') }}</div>
            <div class='self-center'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.batchInformation.totalAmount)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_payment_due') }}</div>
            <div class='self-center'>{{moment(batchDetails.batchInformation.paymentDueDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_select_disb') }}</div>
            <div class='self-center'>
              <select v-model="disbursableBankAccount" class="form-select">
                <option v-for="bank in bankAccounts" :key="bank.bankAccountId" :value="bank.bankAccountId">
                  {{bank.accountNumber}} ({{bank.bankName}})
                </option>
              </select>
            </div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_remark') }}</div>
            <div class='self-center'>
              <textarea v-model='remark' class='border-2 w-full form-control' rows='3' />
            </div>
          </div>
          <SignaturePad v-model="signature"/>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='approveAcknowledge' :disabled='modalLoading'>
            {{ $t('invoice.batch.eco.eco_approve') }}
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='decline-invoice-modal' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_decline_invoice') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_batch_no') }}</div>
            <div class='self-center'>{{batchDetails.batchNumber}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_invoice_upload') }}</div>
            <div class='self-center'>{{moment(batchDetails.batchInformation.uploadDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_invoice_amount') }}</div>
            <div class='self-center'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.batchInformation.totalAmount)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_payment_due') }}</div>
            <div class='self-center'>{{moment(batchDetails.batchInformation.paymentDueDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_remark') }}</div>
            <div class='self-center'>
              <textarea v-model='remark' class='border-2 w-full form-control' rows='3' />
            </div>
          </div>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-20 mr-1' @click='declineAcknowledge'> {{ $t('invoice.batch.eco.eco_decline') }} </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='submit-proposal-modal' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_approve_trx') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='mt-5'>
            <span>{{ $t('invoice.batch.eco.eco_formula') }}</span>
            <table class='table mt-2'>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_batch_no') }}</td>
                <td class='border'>{{batchDetails.batchNumber}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>Total {{ $t('invoice.batch.eco.eco_invoice_amount') }}</td>
                <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.totalAmount)}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_value_date') }}</td>
                <td class='border'>
                  <Litepicker v-if="getLockDaysState"
                    v-model='valueDate'
                    :options='{
                      autoApply: false,
                      showWeekNumbers: true,
                      zIndex: 10001,
                      minDate: Date(),
                      maxDate: batchDetail.paymentDueDate,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      },
                      callback: getEstimateCalc,
                      lockDays: lockDays
                    }'
                    class='form-control'
                  />
                </td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_invoice_due') }}</td>
                <td class='border'>{{moment(batchDetails.paymentDueDate).format(dateFormat)}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_payment_due') }}</td>
                <td class='border'>{{moment(batchDetails.paymentDueDate).format(dateFormat)}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('account.holidays.holidays_number') }}</td>
                <td class='border'>{{batchDetails.numberOfDays}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.led.led_interest_r') }}</td>
                <td class='border gap-y-2'>
                  <input type='number' v-model='bidValue' @change='getEstimateCalc' class='form-control'/>
                  <select v-model="interestRateDuration" @change='getEstimateCalc' class="form-select mt-2">
                    <option value="monthly">{{ $t('invoice.batch.led.led_monthly') }}</option>
                    <option value="yearly">{{ $t('invoice.batch.led.led_yearly') }}</option>
                  </select>
                </td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_repayment_account') }}</td>
                <td class='border'>
                  <select v-model="repaymentBankAccount" class="form-select">
                    <option v-for="bank in bankAccounts" :key="bank.bankAccountId" :value="bank.bankAccountId">
                      {{bank.accountNumber}} ({{bank.bankName}})
                    </option>
                  </select>
                </td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_interest_earn') }}</td>
                <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.interestAmount)}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_platform_fee') }}</td>
                <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.platformFeeAmount)}}</td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.led.led_disb_amt_fee') }}</td>
                <td class='border'>
                  {{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.disbursableAmount1)}}
                </td>
              </tr>
              <tr class='hover:bg-gray-200'>
                <td class='border'>{{ $t('invoice.batch.eco.eco_repayment_amount') }}</td>
                <td class='border'>{{batchDetails.currencyCode}} {{$h.formatCurrency(batchDetails.formula.repaymentAmountToFunder)}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='submitProposal' :disabled='modalLoading'>
            {{ $t('invoice.batch.eco.eco_approve') }}
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='reject-proposal-modal' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> Reject Transaction </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_remark') }}</div>
            <div class='self-center'>
              <textarea v-model='remark' class='border-2 w-full form-control' rows='3' />
            </div>
          </div>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='rejectProposal' :disabled='modalLoading'>
            Reject
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='submit-disbursement-modal' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_upload_disb') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div>{{ $t('invoice.batch.eco.eco_payment_upload') }}</div>
          <div v-bind='getRootProps()' class='flex mb-3 justify-center border-red-400 border-dashed border-2 rounded-lg cursor-pointer'>
            <div class='text-center py-5'>
              <template v-if='!files'>
                <input v-bind='getInputProps()' >
                <UploadCloudIcon class='w-24 h-20 text-red-400' />
                <div class='text-lg font-medium text-gray-600'>
                  {{ $t('invoice.batch.eco.eco_drag_drop') }}<br>{{ $t('invoice.batch.eco.eco_or') }}
                </div>
                <div class='text-red-400'>{{ $t('invoice.batch.eco.eco_browse') }}</div>
              </template>
              <template v-else>
                <div class='relative'>
                  <div class='absolute top-0 right-1'>
                    <XCircleIcon @click='removeFile' class='w-6 h-6' />
                  </div>
                  <FileTextIcon class='w-24 h-24'/>
                </div>
                {{files[0].name}}
              </template>
            </div>
          </div>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_no') }}</div>
            <div class='self-center'>
              <input type='text' v-model='disbursementData.paymentAdviceNumber' class='form-control'/>
            </div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_amount') }}</div>
            <div class='self-center'>
              <input type='number' v-model='disbursementData.paymentAdviceAmount' class='form-control'/>
            </div>
            <div class='self-center'>Currency Code</div>
            <div class='dropdown inline-block' data-placement='bottom'>
              <button class='dropdown-toggle btn btn-primary mr-1' aria-expanded='false'> {{disbursementData.currencyCode}} </button>
              <div class='dropdown-menu' id='currencyCodeDropDown' style='z-index: 10001;'>
                <div class='dropdown-menu__content box dark:bg-dark-1 p-2'>

                  <a v-for='(currency, index) in currencies' :key='index'
                    data-dismiss='dropdown'
                    href='javascript:;'
                    class='block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md'
                    @click='setDisbursementCurrencyCode(currency.currencyCode)'
                  >
                    {{currency.currencyCode}}
                  </a>
                </div>
              </div>
            </div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_date') }}</div>
            <div class='self-center'>
              <Litepicker
                v-model='disbursementData.paymentAdviceDate'
                :options='{
                  autoApply: false,
                  showWeekNumbers: true,
                  zIndex: 10001,
                  minDate: moment(batchDetails.batchInformation.bidEndTime).format(dateFormat),
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }'
                class='form-control'
              />
            </div>
          </div>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='submitDisbursementAdvice' :disabled='modalLoading'>
            Submit
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-24'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='seller-acknowledge-of-receive-disbursement' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg' v-if='confirmAbleDisbursementData'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_receive_disb') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_no') }}</div>
            <div class='self-center'>{{confirmAbleDisbursementData.paymentAdviceNumber}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_file') }}</div>
            <div class='self-center'><a @click="openFileViewer(confirmAbleDisbursementData.paymentAdviceUri)" class="cursor-pointer underline text-blue-500">open file</a></div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_amount') }}</div>
            <div class='self-center'>{{confirmAbleDisbursementData.paymentAdviceAmount + ' ' + confirmAbleDisbursementData.currencyCode}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_date') }}</div>
            <div class='self-center'>{{moment(confirmAbleDisbursementData.paymentAdviceDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_remark') }}</div>
            <div class='self-center'>
              <textarea v-model='remark' class='border-2 border w-full form-control' rows='3' />
            </div>
          </div>
          <SignaturePad v-model="signature"/>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='sellerAcknowledgeOfReceiveDisbursement' :disabled='modalLoading'>
            Confirm
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' class='btn btn-danger w-24 mr-1' @click='sellerDeclineOfReceiveDisbursement' :disabled='modalLoading2'>
            Decline
            <LoadingIcon v-if='modalLoading2' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='buyer-upload-repayment-advice' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_upload_advice') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div>{{ $t('invoice.batch.eco.eco_payment_upload') }}</div>
          <div v-bind='getRootProps()' class='flex mb-3 justify-center border-red-400 border-dashed border-2 rounded-lg cursor-pointer'>
            <div class='text-center py-5'>
              <template v-if='!files'>
                <input v-bind='getInputProps()' >
                <UploadCloudIcon class='w-24 h-20 text-red-400' />
                <div class='text-lg font-medium text-gray-600'>
                  {{ $t('invoice.batch.eco.eco_drag_drop') }}<br>{{ $t('invoice.batch.eco.eco_or') }}
                </div>
                <div class='text-red-400'>{{ $t('invoice.batch.eco.eco_browse') }}</div>
              </template>
              <template v-else>
                <div class='relative'>
                  <div class='absolute top-0 right-1'>
                    <XCircleIcon @click='removeFile' class='w-6 h-6' />
                  </div>
                  <FileTextIcon class='w-24 h-24'/>
                </div>
                {{files[0].name}}
              </template>
            </div>
          </div>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_no') }}</div>
            <div class='self-center'>
              <input type='text' v-model='disbursementData.paymentAdviceNumber' class='form-control'/>
            </div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_amount') }}</div>
            <div class='self-center'>
              <input type='number' v-model='disbursementData.paymentAdviceAmount' class='form-control'/>
            </div>
            <div class='self-center'>Currency Code</div>
            <div class='dropdown inline-block' data-placement='bottom'>
              <button class='dropdown-toggle btn btn-primary mr-1' aria-expanded='false'> {{disbursementData.currencyCode}} </button>
              <div class='dropdown-menu' id='currencyCodeDropDown' style='z-index: 10001;'>
                <div class='dropdown-menu__content box dark:bg-dark-1 p-2'>
                  <a v-for='(currency, index) in currencies' :key='index'
                    data-dismiss='dropdown'
                    href='javascript:;'
                    class='block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md'
                    @click='setDisbursementCurrencyCode(currency.currencyCode)'
                  >
                    {{currency.currencyCode}}
                  </a>
                </div>
              </div>
            </div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_date') }}</div>
            <div class='self-center'>
              <Litepicker
                v-model='disbursementData.paymentAdviceDate'
                :options='{
                  autoApply: false,
                  showWeekNumbers: true,
                  zIndex: 10001,
                  minDate: moment(batchDetails.batchInformation.bidEndTime).format(dateFormat),
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }'
                class='form-control'
              />
            </div>
          </div>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='buyerUploadRepaymentAdvice' :disabled='modalLoading'>
            Submit
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-24'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <div id='funder-acknowledge-upload-repayment-advice' class='modal' tabindex='-1' aria-hidden='true'>
    <div class='modal-dialog modal-lg' v-if='confirmFunderAcknowledgeReceiveOfRepaymentData'>
      <div class='modal-content'>
        <!-- BEGIN: Modal Header -->
        <div class='modal-header'>
          <h2 class='font-medium text-base mr-auto'> {{ $t('invoice.batch.eco.eco_receive_repay') }} </h2>
        </div>
        <!-- END: Modal Header -->
        <div class='modal-body mx-8'>
          <div class='grid grid-cols-2 grid-flow-row gap-4'>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_no') }}</div>
            <div class='self-center'>{{confirmFunderAcknowledgeReceiveOfRepaymentData.paymentAdviceNumber}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_file') }}</div>
            <div class='self-center'><a @click="openFileViewer(confirmFunderAcknowledgeReceiveOfRepaymentData.paymentAdviceUri)" class="cursor-pointer underline text-blue-500">open file</a></div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_amount') }}</div>
            <div class='self-center'>{{confirmFunderAcknowledgeReceiveOfRepaymentData.paymentAdviceAmount + ' ' + confirmFunderAcknowledgeReceiveOfRepaymentData.currencyCode}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_advice_date') }}</div>
            <div class='self-center'>{{moment(confirmFunderAcknowledgeReceiveOfRepaymentData.paymentAdviceDate).format(dateFormat)}}</div>
            <div class='self-center'>{{ $t('invoice.batch.eco.eco_remark') }}</div>
            <div class='self-center'>
              <textarea v-model='remark' class='border-2 border w-full form-control' rows='3' />
            </div>
          </div>
          <SignaturePad v-model="signature"/>
        </div>
        <div class='modal-footer text-right'>
          <button type='button' class='btn btn-primary w-24 mr-1' @click='funderAcknowledgeOfRepaymentComfirm' :disabled='modalLoading'>
            Confirm
            <LoadingIcon v-if='modalLoading' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' class='btn btn-danger w-24 mr-1' @click='funderAcknowledgeOfRepaymentDecline' :disabled='modalLoading2'>
            {{ $t('invoice.batch.eco.eco_decline') }}
            <LoadingIcon v-if='modalLoading2' icon='oval' color='white' class='w-4 h-4 ml-2' />
          </button>
          <button type='button' data-dismiss='modal' class='btn btn-outline-secondary w-20'> {{ $t('invoice.batch.eco.eco_cancel') }} </button>
        </div> <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- End: action modal -->
  <!-- Start: pdf file show -->
  <div id="show-pdf-file-viewer" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <embed type="application/pdf" :src="openFileUrl" width="1000" height="1500">
      </div>
    </div>
  </div>
  <!-- End: pdf file show -->
  <!-- Start: xlsx file show -->
  <div id="show-xlsx-file-viewer" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body mx-8 xlsx-viewer">
          <xlsx-read :file="xlsx.file">
            <xlsx-table />
          </xlsx-read>
        </div>
      </div>
    </div>
  </div>
  <!-- End: xlsx file show -->
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useDropzone } from 'vue3-dropzone'
import _ from 'lodash'
import moment from 'moment'
import { XlsxRead, XlsxTable } from "vue3-xlsx"
import SignaturePad from '../SignaturePad.vue'
import toast from '@/utils/toast'
import { sysAxios, appAxios } from '@/plugins/axios'
import companyService from '@/services/companyService'
import configService from '@/services/configService'
import fileService from '@/services/fileService'
import workflowService from '@/services/workflowService'
import buyerLedWorkflowService from '@/services/workflow/publicEcoSystem/buyerLedWorkflowService'
import ledgerService from '@/services/ledgerService'
import bidService from '@/services/bidService'
import i18n from '@/plugins/i18n';

export default {
  props: {
    workflowExecutionReferenceId: {
      type: String,
      required: true
    },
    batchDetail: {
      type: Object,
      required: true
    },
    adminCompany: {
      type: String,
      required: true
    },
    provenance: {
      type: Object,
      required: true
    },
    lastWorkStatus: {
      type: Object,
      required: true
    }
  },
  components: {
    SignaturePad,
    XlsxRead,
    XlsxTable,
  },
  setup(props) {
    const store = useStore()
    const user = store.state.auth
    const xlsx = ref({
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: null,
      collection: null
    });
    const openFileUrl = ref('');
    const dateFormat = process.env.VUE_APP_DATE_FORMAT
    const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT
    const getLockDaysState = ref(false);
    const batchDetails = ref(props.batchDetail)
    const provenance = ref(props.provenance)
    const lastWorkStatus = ref(props.lastWorkStatus)
    const currencies = ref(null)
    const lockDays = ref([])
    const currentCompanyRole = ref('')
    const visibleWorkflowActions = ref({
      visibleApproveButton: false,
      visibleSubmitProposal: false,
      visibleSubmitDisbursementAdvice: false,
      visibleSellerAcknowledgeOfReceiveDisbursement: false,
      visibleBuyerUploadRepaymentAdvice: false,
      visibleFunderAcknowledgeRepaymentAdvice: false,
    })
    const bankAccounts = ref([])
    const disbursableBankAccount = ref('')
    const repaymentBankAccount = ref('')
    const batchMessage = ref('')
    const adminCompany = ref(props.adminCompany)
    const signature = ref(null)

    const initComponent = ref(false)
    const modalLoading = ref(false)
    const modalLoading2 = ref(false)
    const uploadErrorMessage = ref()
    const remark = ref(null)
    const valueDate = ref()
    const bidValue = ref(null)
    const interestRateDuration = ref('monthly')
    const files = ref()

    const disbursementData = ref({
      paymentAdviceNumber: null,
      paymentAdviceAmount: null,
      paymentInstructionId: null,
      currencyCode: 'USD',
      paymentAdviceDate: moment.utc(new Date()).format(dateFormat),
      paymentAdviceUri: null
    })

    const confirmAbleDisbursementData = ref()
    const confirmFunderAcknowledgeReceiveOfRepaymentData = ref()

    const onDrop = (acceptFiles, rejectReasons) => {
      if(acceptFiles.length) {
        files.value = acceptFiles
      }
      if(rejectReasons.length) {
        rejectReasons.forEach(reason => {
          var content = reason.errors[0].message
          var title = i18n.global.t('invoice.support.support_s_cannot_upload1') + reason.file.name + i18n.global.t('invoice.support.support_s_cannot_upload2')
          toast({status: 'error', title: title, content: content})
        })
      }
    }

    const options = reactive({
      multiple: true,
      onDrop,
      accept: '.jpg, .csv, .xlsx, .pdf',
    })

    const { getRootProps, getInputProps, ...rest } = useDropzone(options)

    const getValueDate = () => {
      return new Promise(async resolve => {
        const date = await buyerLedWorkflowService.getValueDateOfInvoice(props.workflowExecutionReferenceId)
        valueDate.value = moment(date).format('DD MMM YYYY')
        if(batchDetails.value.valueDate == '0001-01-01T00:00:00Z') batchDetails.value.valueDate = moment(date).format('DD MMM YYYY')
        resolve(valueDate.value)
      })
    }

    const invoiceDetailApi = async () => {
      return new Promise(async resolve => {
        const response = await ledgerService.getPaymentinstruction(props.workflowExecutionReferenceId)
        const dueDt = moment(batchDetails.value.paymentDueDate)
        const valueDt = moment(batchDetails.value.valueDate)
        const noOfDays = dueDt.diff(valueDt,'days')
        batchDetails.value.numberOfDays = noOfDays

        const repaymentAmount = _.find(response, {label: 'RepaymentAmount'})
        batchDetails.value.formula.repaymentAmountToFunder = repaymentAmount?.amountBeforeTax.toFixed(2)

        const interestAmount1 = _.find(response, {label:'InterestAmount'})
        batchDetails.value.formula.interestAmount = interestAmount1?.amountBeforeTax.toFixed(2)

        const tax1 = _.find(response, {label:'DisbursableAmount'})
        batchDetails.value.formula.disbursableAmount1 = tax1?.amountBeforeTax.toFixed(2)

        const platformFee = _.find(response, {fromCompanyId: batchDetails.value.buyerCompanyId, toCompanyId: adminCompany.value})
        batchDetails.value.formula.platformFeeAmount = platformFee?.amountBeforeTax
        batchDetails.value.formula.platformFeeDate = platformFee?.dueDate

        resolve({
          numberOfDays: noOfDays,
          interestAmount: batchDetails.value.formula.interestAmount,
          disbursableAmount1: batchDetails.value.formula.disbursableAmount1,
          disburableAmount1DueDate: batchDetails.value.formula.disburableAmount1DueDate,
          disbursableAmount2: batchDetails.value.formula.disbursableAmount2,
          disburableAmount2DueDate: batchDetails.value.formula.disburableAmount2DueDate,
          platformFeeAmount: batchDetails.value.formula.platformFeeAmount,
          platformFeeDate: batchDetails.value.formula.platformFeeDate
        })
      })
    }

    const saveSignature = async () => {
      return new Promise(async resolve => {
        let formData = new FormData()
        if(signature.value === null) resolve(null)
        formData.append('file', signature.value.file)
        const response = await fileService.fileUpload('acknowledgement_signature', formData)
        resolve(`${process.env.VUE_APP_SYSTEM_API_URL}/uploads/v1/${response}`)
      })
    }

    const setDisbursementCurrencyCode = (currencyCode) => {
      disbursementData.value.currencyCode = currencyCode
    } 

    const uploadFile = async () => {
      let formData = new FormData()
      if (!files.value) return false
      formData.append('file', files.value[0])
      const response = await fileService.fileUpload('payment_advice', formData)
      disbursementData.value.paymentAdviceUri = process.env.VUE_APP_SYSTEM_API_URL + '/uploads/v1/' + response
      return true;
    }

    const removeFile = () => files.value = null

    const openFileViewer = async (url) => {
      const api = url
      const fileResponse = await sysAxios.get(api, {responseType: 'blob'})
      const externalLinkContentType = ['image/jpeg', 'image/png', 'text/plain']
      if(externalLinkContentType.includes(fileResponse.headers['content-type'])) {
        const file = new Blob([fileResponse.data], {type: fileResponse.headers['content-type']});
        const fileURL = URL.createObjectURL(file);
        // openFileUrl.value = fileURL
        // cash("#show-pdf-file-viewer").modal("show")
        window.open(fileURL);
      } else if (fileResponse.headers['content-type'] === 'application/pdf') {
        const file = new Blob([fileResponse.data], {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(file);
        openFileUrl.value = fileURL
        cash('#show-pdf-file-viewer').modal("show")
      } else if (fileResponse.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const file = new Blob([fileResponse.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        xlsx.value.file = file

        cash("#show-xlsx-file-viewer").modal("show")
      }
    }

    const openSellerAcknowledgeOfReceiveDisbursementModel = async () => {
      const paymentInstructionId = await getpaymentInstructionId('DisbursableAmount')
      const paymentAdvice = await ledgerService.getPaymentAdvice(props.workflowExecutionReferenceId)
      confirmAbleDisbursementData.value = {..._.find(paymentAdvice, {paymentInstructionId}) }
      cash('#seller-acknowledge-of-receive-disbursement').modal('show')
    }

    const openFunderAcknowledgeUploadRepaymentAdviceModel = async () => {
      const paymentInstructionId = await getpaymentInstructionId('RepaymentAmount')
      const paymentAdvice = await ledgerService.getPaymentAdvice(props.workflowExecutionReferenceId)
      confirmFunderAcknowledgeReceiveOfRepaymentData.value = {..._.find(paymentAdvice, {paymentInstructionId}) }
      cash('#funder-acknowledge-upload-repayment-advice').modal('show')
    }

    const getpaymentInstructionId = async (label) => {
      return new Promise( async resolve => {
        const paymentInstruction = await ledgerService.getPaymentinstruction(props.workflowExecutionReferenceId)
        const paymentInstructionByLabel = _.find(paymentInstruction, {label: label}) //RepaymentAmount | DisbursableAmount
        resolve(paymentInstructionByLabel.paymentInstructionId)
      })
    }

    const approveAcknowledge = async () => {
      modalLoading.value = true
      const signatureUrl = await saveSignature()
      if(signatureUrl && remark.value) {
        try {
          await buyerLedWorkflowService.sellerApproveAcknowledge({
            workflowExecutionReferenceId: props.workflowExecutionReferenceId,
            remarks: remark.value,
            signatureUrl,
            disbursableBankAccount: _.find(bankAccounts.value, {bankAccountId: disbursableBankAccount.value})
          })
          visibleWorkflowActions.value.visibleApproveButton = false
          cash('#approve-invoice-modal').modal('hide')
          updateProvenanceApi()
        } catch (err) {
          toast({status: "error", title: i18n.global.t('invoice.batch.eco.eco_s_approve_fail'), content: err})
        }
      }
      else {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_approve_fail'), content: i18n.global.t('invoice.batch.eco.eco_s_signature_req')})
        modalLoading.value = false
      }
      modalLoading.value = false
    }

    const declineAcknowledge = async () => {
      modalLoading.value = true
      try {
        await buyerLedWorkflowService.sellerDeclineAcknowledge({ workflowExecutionReferenceId: props.workflowExecutionReferenceId, remarks: remark.value })
        visibleWorkflowActions.value.visibleApproveButton = false
        cash('#decline-invoice-modal').modal('hide')
        updateProvenanceApi()
      } catch (err) {
        toast({status: "error", title: i18n.global.t('invoice.batch.eco.eco_s_decline_fail'), content: err})
      }
      modalLoading.value = true
    }

    const getEstimateCalc = async () => {
      const vDate = moment(valueDate.value);
      const bDate = moment(bidValue.value);
      if(vDate.isValid()){
        batchDetails.value.valueDate = valueDate.value
        const dueDt = moment(batchDetails.value.paymentDueDate)
        const valueDt = moment(valueDate.value)
        const noOfDays = dueDt.diff(valueDt,'days')
        batchDetails.value.numberOfDays = noOfDays
      }
      if(bidValue.value && vDate.isValid()) {
        const response = await buyerLedWorkflowService.getEstimate({
          workflowExecutionReferenceId: props.workflowExecutionReferenceId,
          bidValue: bidValue.value,
          interestRateDuration: interestRateDuration.value,
          valueDate: valueDate.value
        })
        if(response.status === 'success') {
          const { data } = response
          batchDetails.value.formula.disburableAmount1DueDate = moment(data.disburableAmount1DueDate).format(dateFormat)
          batchDetails.value.formula.disburableAmount2DueDate = moment(data.disburableAmount2DueDate).format(dateFormat)
          batchDetails.value.formula.disbursableAmount1 = data.disbursableAmount.toFixed(2)
          batchDetails.value.formula.interestAmount = data.interestAmount.toFixed(2)
          batchDetails.value.formula.platformFeeAmount = data.platformFeeAmount.toFixed(2)
          batchDetails.value.formula.platformFeeAmountDueDate = moment(data.platformFeeAmountDueDate).format(dateFormat)
          batchDetails.value.formula.repaymentAmountToFunder = data.repaymentAmount.toFixed(2)
          batchDetails.value.formula.repaymentAmountDueDate = moment(data.repaymentAmountDueDate).format(dateFormat)
        } else {
          response.messages.forEach(message => toast({status: error, title: i18n.global.t('invoice.batch.eco.eco_s_platform_error'), content: message}))
        }
      }
    }

    const validationSubmitProposal = () => {
      var errors = []
      if(!bidValue.value) errors.push(i18n.global.t('invoice.batch.eco.eco_s_please_interest'))
      if(_.findIndex(bankAccounts.value, {bankAccountId: repaymentBankAccount.value}) < 0) errors.push(i18n.global.t('invoice.batch.eco.eco_s_please_valid'))
      return errors
    }

    const submitProposal = async () => {
      modalLoading.value = true
      const errors = validationSubmitProposal()
      if(errors.length) {
        errors.forEach(error => toast({status: 'error', title: i18n.global.t('onboard.permissions.add.add_valid_error'), content: error}))
      } else {
        try {
          await bidService.vote({
            workflowExecutionReferenceId: props.workflowExecutionReferenceId,
            companyId: store.state.account.company_uuid,
            bidValue: bidValue.value,
            valueDate: moment.utc(valueDate.value).format(),
            interestRateDuration: interestRateDuration.value,
            repaymentAccount: _.find(bankAccounts.value, {bankAccountId: repaymentBankAccount.value})
          })
          visibleWorkflowActions.value.visibleSubmitProposal = false
          cash('#submit-proposal-modal').modal('hide')
          updateProvenanceApi()
        } catch (err) {
          toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_approve_fail'), content: err})
        }
      }
      modalLoading.value = false
    }

    const rejectProposal = async () => {
      modalLoading.value = true
      try {
        await bidService.reject({
          workflowExecutionReferenceId: batchDetails.value.workflowExecutionReferenceId,
          companyId: store.state.account.company_uuid,
          remark: remark.value
        })
        visibleWorkflowActions.value.visibleRejectProposal = false
        cash('#reject-proposal-modal').modal('hide')
        updateProvenanceApi()
      } catch (err) {
        toast({status: 'error', title: i18n.global.t('invoice.batch.led.led_reject_fail'), content: err})
      }
      modalLoading.value = false
    }

    const submitDisbursementAdvice = async () => {
      if (await uploadFile()) {
        modalLoading.value = true
        try {
          disbursementData.value.paymentInstructionId = await getpaymentInstructionId('DisbursableAmount')
          const requestBody = {
            externalReferenceId: batchDetails.value.workflowExecutionReferenceId,
            paymentInstructionId: disbursementData.value.paymentInstructionId,
            paymentAdviceNumber: disbursementData.value.paymentAdviceNumber,
            paymentAdviceFileName: files.value[0].name,
            paymentAdviceUri: disbursementData.value.paymentAdviceUri,
            paymentAdviceAmount: disbursementData.value.paymentAdviceAmount.toString(),
            currencyCode: disbursementData.value.currencyCode,
            paymentAdviceDate: moment.utc(disbursementData.value.paymentAdviceDate).format()
          }
          await buyerLedWorkflowService.funderSubmitDisbursementAdvice(requestBody)
          visibleWorkflowActions.value.visibleSubmitDisbursementAdvice = false
          cash('#submit-disbursement-modal').modal('hide')
          updateProvenanceApi()
        } catch (err) {
          toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_funder_advice_fail'), content: err})
        }
      } else {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_funder_advice_fail'), content: i18n.global.t('invoice.batch.eco.eco_s_funder_payment_req')})
      }
    }

    const sellerAcknowledgeOfReceiveDisbursement = async () => {
      modalLoading.value = true
      const signatureUrl = await saveSignature()
      if(signatureUrl) {
        try {
          await buyerLedWorkflowService.sellerAcknowledgeOfReceiveDisbursement({
            workflowExecutionReferenceId: props.workflowExecutionReferenceId,
            signatureUrl,
            remarks: remark.value,
          })
          visibleWorkflowActions.value.visibleSellerAcknowledgeOfReceiveDisbursement = false
          cash('#seller-acknowledge-of-receive-disbursement').modal('hide')
          updateProvenanceApi()
        } catch (err) {
          toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_ack_fail'), content: err})
        }
        modalLoading.value = false
      } else {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_ack_fail'), content: i18n.global.t('invoice.batch.eco.eco_s_signature_req')})
      }
      modalLoading.value = false
    }

    const sellerDeclineOfReceiveDisbursement = async () => {
      modalLoading2.value = true
      try {
        await buyerLedWorkflowService.sellerDeclineOfReceiveDisbursement({
          workflowExecutionReferenceId: props.workflowExecutionReferenceId,
          remarks: remark.value,
        })
        visibleWorkflowActions.value.visibleSellerAcknowledgeOfReceiveDisbursement = false
        cash('#seller-acknowledge-of-receive-disbursement').modal('hide')
        updateProvenanceApi()
      } catch (err) {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_ack_fail'), content: err})
      }
      modalLoading2.value = false
    }

    const buyerUploadRepaymentAdvice = async () => {
      modalLoading.value = true
      try {
        await uploadFile()
        disbursementData.value.paymentInstructionId = await getpaymentInstructionId('RepaymentAmount')
        const repaymentAdvice = {
          externalReferenceId: batchDetails.value.workflowExecutionReferenceId,
          paymentInstructionId: disbursementData.value.paymentInstructionId,
          paymentAdviceNumber: disbursementData.value.paymentAdviceNumber,
          paymentAdviceFileName: files.value[0].name,
          paymentAdviceUri: disbursementData.value.paymentAdviceUri,
          paymentAdviceAmount: disbursementData.value.paymentAdviceAmount.toString(),
          currencyCode: disbursementData.value.currencyCode,
          paymentAdviceDate: moment.utc(disbursementData.value.paymentAdviceDate).format()
        }
        await buyerLedWorkflowService.buyerUploadRepaymentAdvice(repaymentAdvice)
        visibleWorkflowActions.value.visibleBuyerUploadRepaymentAdvice = false
        cash('#buyer-upload-repayment-advice').modal('hide')
        updateProvenanceApi()
      } catch (err) {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_upload_fail'), content: err})
      }
      modalLoading.value = false
    }

    const funderAcknowledgeOfRepaymentComfirm = async () => {
      modalLoading.value = true
      const signatureUrl = await saveSignature()
      if(signatureUrl) {
        try {
          await buyerLedWorkflowService.funderAcknowledgeOfRepaymentComfirm({
            workflowExecutionReferenceId: props.workflowExecutionReferenceId,
            signatureUrl,
            remarks: remark.value
          })
          visibleWorkflowActions.value.visibleFunderAcknowledgeRepaymentAdvice = false
          cash('#funder-acknowledge-upload-repayment-advice').modal('hide')
          updateProvenanceApi()
        } catch (err) {
          toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_funder_ack_fail'), content: JSON.stringify(err)})
        }
      }
      else toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_funder_ack_fail'), content: i18n.global.t('invoice.batch.eco.eco_s_signature_req')})
      modalLoading.value = false
    }

    const funderAcknowledgeOfRepaymentDecline = async () => {
      modalLoading2.value = true
      try {
        await buyerLedWorkflowService.funderAcknowledgeOfRepaymentDecline({ workflowExecutionReferenceId: props.workflowExecutionReferenceId, remarks: remark.value })
        visibleWorkflowActions.value.visibleFunderAcknowledgeRepaymentAdvice = false
        cash('#funder-acknowledge-upload-repayment-advice').modal('hide')
        updateProvenanceApi()
      } catch (err) {
        toast({status: 'error', title: i18n.global.t('invoice.batch.eco.eco_s_funder_decline'), content: err})
      }
      modalLoading2.value = false
    }

    const updateProvenanceApi =  () => {
      // console.log('need to update provenance api because new action was invoked')
      store.dispatch('main/NeedUpdateProvenanceHistory')
      init()
    }

    const init = async () => {
      await getValueDate()
      // disbursementData.value.currencyCode = await getCompanyDefaultCurrencyCode(store.state.account.company_uuid);
      const companyId = store.state.account.company_uuid
      await Promise.all([
        // getFormulaFee(),
        companyService.getCompanyDefaultCurrencyCode(companyId),
        companyService.getAccountBankInfoByCompanyId(companyId),
        // workflowService.getProvenanceHistory(props.workflowExecutionReferenceId),
        workflowService.getLastWorkflowStatus([props.workflowExecutionReferenceId]),
        configService.getCompanyProfileSystemConfig(),
        companyService.getLockDays([batchDetails.value.buyerCompanyId, batchDetails.value.sellerCompanyId, batchDetails.value.funderCompanyId]),
        invoiceDetailApi(),
      ]).then(values => {
        //set disbursement currency code as a current company default currency
        disbursementData.value.currencyCode = values[0]
        //set current company bank account
        bankAccounts.value = values[1]
        //set provenance history
        // provenance.value = values[2]
        //set last work status
        lastWorkStatus.value = values[2]
        //get currencies from genie company profile system config
        currencies.value = values[3].currencies
        //get lock days from buyer, seller, funder company holidays
        lockDays.value = values[4]
        getLockDaysState.value = true
        // console.log("promise all return value = ", values)
      })

      //determine current company is seller role or buyer role in this invoice
      if(batchDetails.value.initiatedByCompanyId === store.state.account.company_uuid) currentCompanyRole.value = 'Buyer Admin'
      else if(batchDetails.value.initiatedByCompanyId !== store.state.account.company_uuid && user.user_role === 'System Admin') currentCompanyRole.value = 'Seller Admin'
      else currentCompanyRole.value = 'Funder Admin'


      // console.log("last work status  = ", lastWorkStatus.value['statusName'])
      // console.log("current user role = ", user.user_role)
      // console.log("current company role = ", currentCompanyRole.value)
      // console.log("batchdetails workflow led = ", batchDetails.value.workflowLed)

      if(batchDetails.value.batchStatus !== "Rejected") {
        //determine what action button should be showed in Batch Detail page
        if(lastWorkStatus.value['statusName'] === 'NOTIFICATION_SENT_TO_BUYER' && batchDetails.value.batchStatus == "Expired" && currentCompanyRole.value === 'Seller Admin') {
          if(new Date(batchDetails.value.paymentDueDate) < new Date() || new Date(batchDetails.value.bidEndTime) < new Date()) {
            batchMessage.value = i18n.global.t('invoice.batch.eco.eco_batch_message1') + ' ('+moment(batchDetails.value.bidEndTime).format(dateTimeFormat) + ')'
          }
        }
        if(lastWorkStatus.value['statusName'] === 'TRANSACTION_NOT_APPROVED_BY_FUNDER' && batchDetails.value.batchStatus == "Expired" && (currentCompanyRole.value === 'Seller Admin' || currentCompanyRole.value === 'Buyer Admin')) {
          if(new Date(batchDetails.value.paymentDueDate) < new Date() || new Date(batchDetails.value.bidEndTime) < new Date()) {
            batchMessage.value = i18n.global.t('invoice.batch.eco.eco_batch_message2') + ''
          }
        }
        if(lastWorkStatus.value['statusName'] === 'NOTIFICATION_SENT_TO_SELLER' && currentCompanyRole.value === 'Seller Admin') {
          if(new Date(batchDetails.value.paymentDueDate) < new Date() || new Date(batchDetails.value.bidEndTime) < new Date()) {
            batchMessage.value = i18n.global.t('invoice.batch.eco.eco_batch_message3') + ' ' + moment(batchDetails.value.paymentDueDate).format(dateTimeFormat)
          } else visibleWorkflowActions.value.visibleApproveButton = true
        }
        else if(lastWorkStatus.value['statusName'] === 'INVITATION_SENT_TO_FUNDERS') {
          if(user.user_role === 'Funder Admin') {
            if(new Date(batchDetails.value.bidEndTime) < new Date()) {
              batchMessage.value = 'You cannot approve this invoice due to passed bid end time ('+moment(batchDetails.value.bidEndTime).format(dateTimeFormat) + ')'
            } else {
              const api = `bidding/v1/${props.workflowExecutionReferenceId}`
              await appAxios.get(api).then(res => {
                let hasVoted = _.findIndex(res.data[0].votes, {companyId: store.state.account.company_uuid}) >= 0
                let hasRejected = _.findIndex(res.data[0].rejections, {companyId: store.state.account.company_uuid}) >= 0
                if(!hasVoted && !hasRejected) {
                  visibleWorkflowActions.value.visibleSubmitProposal = true
                  visibleWorkflowActions.value.visibleSubmitReject = true
                  batchMessage.value = 'Please approve this transaction before the bidding is finished at '+moment(batchDetails.value.bidEndTime).format(dateTimeFormat)
                } else {
                  visibleWorkflowActions.value.visibleSubmitProposal = false
                  visibleWorkflowActions.value.visibleSubmitReject = false
                  if(hasVoted) batchMessage.value = 'You have already responded to this bid. Please wait until the bidding is finished at '+moment(batchDetails.value.bidEndTime).format(dateTimeFormat)
                  if(hasRejected) batchMessage.value = 'You have already rejected this batch'
                }
              })
            }
          } else {
            batchMessage.value = 'The bid end time is ('+moment(batchDetails.value.bidEndTime).format(dateTimeFormat) + ')'
          }

        }
        else if(lastWorkStatus.value['statusName'] === 'FUND_DISBURSEMENT_INSTRUCTION_SENT_TO_FUNDER' && user.user_role === 'Funder Admin') visibleWorkflowActions.value.visibleSubmitDisbursementAdvice = true
        else if(lastWorkStatus.value['statusName'] === 'FUND_DISBURSEMENT_NOTIFICATION_SENT_TO_SELLER' && currentCompanyRole.value === 'Seller Admin') visibleWorkflowActions.value.visibleSellerAcknowledgeOfReceiveDisbursement = true
        else if(lastWorkStatus.value['statusName'] === 'REPAYMENT_INSTRUCTION_SENT_TO_BUYER' && currentCompanyRole.value === 'Buyer Admin') visibleWorkflowActions.value.visibleBuyerUploadRepaymentAdvice = true
        else if(lastWorkStatus.value['statusName'] === 'REPAID_BY_BUYER' && user.user_role === 'Funder Admin') visibleWorkflowActions.value.visibleFunderAcknowledgeRepaymentAdvice = true
      } else {
        batchMessage.value = batchDetails.value.remarks
        if(lastWorkStatus.value.statusName === 'INVITATION_SENT_TO_FUNDERS') {
          batchMessage.value = 'The invoice has been rejected by funders. No one approved this invoice'
        }
        // console.log('lastworkstatus: ', lastWorkStatus.value)
        // console.log('currentCompanyRole.value: ',  currentCompanyRole.value)
      }
      // console.log('visibleWorkflowActions = ', visibleWorkflowActions.value)
    }

    onMounted(async () => {
      await init()
      initComponent.value = true
    })

    return {
      lodash: _,
      moment,
      xlsx,
      openFileUrl,
      initComponent,
      dateFormat,
      dateTimeFormat,
      batchDetails,
      modalLoading,
      // provenance,
      user,
      currentCompanyRole,
      // lastWorkStatus,
      currencies,
      lockDays,
      signature,
      visibleWorkflowActions,
      batchMessage,
      approveAcknowledge,
      bankAccounts,
      disbursableBankAccount,
      repaymentBankAccount,
      declineAcknowledge,
      remark,
      valueDate,
      getEstimateCalc,
      bidValue,
      interestRateDuration,
      submitProposal,
      rejectProposal,
      getRootProps,
      getInputProps,
      uploadFile,
      removeFile,
      disbursementData,
      setDisbursementCurrencyCode,
      files,
      uploadErrorMessage,
      submitDisbursementAdvice,
      confirmAbleDisbursementData,
      confirmFunderAcknowledgeReceiveOfRepaymentData,
      openSellerAcknowledgeOfReceiveDisbursementModel,
      openFunderAcknowledgeUploadRepaymentAdviceModel,
      sellerAcknowledgeOfReceiveDisbursement,
      sellerDeclineOfReceiveDisbursement,
      buyerUploadRepaymentAdvice,
      funderAcknowledgeOfRepaymentComfirm,
      funderAcknowledgeOfRepaymentDecline,
      getLockDaysState,
      openFileViewer
    }
  },
}
</script>
