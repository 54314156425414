import { sysAxios } from "@/plugins/axios";
import _ from "lodash"
import { helper } from "@/utils/helper";

export const getConfig = () => {
  return new Promise (async resolve => {
    const api = "configuration/v1";
    const response = await sysAxios.get(api)
    resolve(helper.jsonDecodeArray(response.data))
  })
}

export const addNewGroup = ({groupName, configData}) => {
  return new Promise (async (resolve, reject) => {
    try {
      const api = `configuration/v1/${groupName}`;
      const response = await sysAxios.put(api, helper.jsonEncodeArray(configData))
      if(response.status === 'error') reject(response.error.response.data)
      else resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const udpateConfig = ({groupName, configData}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `configuration/v1/${groupName}`;
      const response = await sysAxios.put(api, helper.jsonEncodeArray(configData))
      if(response.status === "error") reject(response.error.response.data)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const getGenieGlobalSettings = () => {
  return new Promise(async resolve => {
    const api = 'configuration/v1/Genie Global Settings'
    const response = await sysAxios.get(api)
    resolve({
      interestRateDuration: JSON.parse(_.find(response.data[0].configurations, {name: "Interest Rate Duration"}).value),
      platformRegistrationInvitation: JSON.parse(_.find(response.data[0].configurations, {name: 'Platform Registration Invitation'}).value),
      adminCompanyId: _.find(response.data[0].configurations, {name: 'Admin Company Id'}).value,
      buyerLedWorkflowId: _.find(response.data[0].configurations, {name: 'Buyer Led Workflow Id V2'}).value,
      sellerLedWorkflowId: _.find(response.data[0].configurations, {name: 'Seller Led Workflow Id V2'}).value,
      paymentAdviceWorksStatus: JSON.parse(_.find(response.data[0].configurations, {name: 'Workflow V2 Status With Payment Advice'}).value)
    })
  })
}

export const getCompanyProfileSystemConfig = () => {
  return new Promise(async resolve => {
    const api = 'configuration/v1/Company Profile'
    const response = await sysAxios.get(api)
    resolve({
      banks: JSON.parse(_.find(response.data[0].configurations, { name: "banks" }).value),
      currencies: JSON.parse(_.find(response.data[0].configurations, { name: "currencies" }).value),
      countries: JSON.parse(_.find(response.data[0].configurations, { name: "countries" }).value),
      kycDocumentCategory: JSON.parse(_.find(response.data[0].configurations, { name: 'kyc_dument_category' }).value)
    })
  })
}

export default {
  getConfig,
  addNewGroup,
  udpateConfig,
  getGenieGlobalSettings,
  getCompanyProfileSystemConfig
}
