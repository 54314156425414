<template>
  <tr v-for="doc in documents" class="even:bg-gray-50 intro-y" :key="doc.documentURL">
    <td class="dark:border-dark-5 text-blue-500"><a @click="openFileViewer(doc)" class="cursor-pointer text-theme-1 underline">{{doc.documentName}}</a></td>
    <td class="dark:border-dark-5">{{moment(doc.uploadTime).format(dateFormat)}}</td>
    <td class="dark:border-dark-5">{{doc.userName}}</td>
    <td class="dark:border-dark-5">
      <div v-if="verifying">
        <div class='alert show flex items-center h-5 p-3 text-sm justify-center alert-secondary' role='alert'>
          <LoadingIcon icon="puff" color="gray" class="w-3 h-3 mr-2" />
          <span>{{ $t('invoice.detail.documents.documents_verifying') }}</span>
        </div>
      </div>
      <div
        v-else
        class='alert show flex items-center h-5 p-3 text-sm justify-center'
        :class="`${doc.verified ? 'bg-green-200' : 'bg-red-300'} `"
        role="alert"
      >
        <ShieldIcon v-if="doc.verified" class="w-3 h-3 mr-2"/>
        <ShieldOffIcon v-else class="w-3 h-3 mr-2" />
        <span>
          {{doc.verified ? $t('invoice.detail.provenance.provenance_verified') : $t('invoice.detail.provenance.provenance_not_verified')}}
        </span>
      </div>
    </td>
    <td class="dark:border-dark-5"></td>
    <td class="dark:border-dark-5 flex justify-center">
      <div class='grid grid-cols-1'>
        <div class='flex justify-center'>
          <MinusCircleIcon @click.stop="deleteDocument(doc)" class='w-4 h-4 text-theme-6'/>
        </div>
      </div>
    </td>
  </tr>
  <div :id="`show-pdf-file-viewer-${journalBatchEntryId}`" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <embed type="application/pdf" :src="openFileUrl" width="1000" height="1500">
      </div>
    </div>
  </div>
  <div :id="`show-xlsx-file-viewer-${journalBatchEntryId}`" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body mx-8 xlsx-viewer">
          <xlsx-read :file="xlsx.file">
            <xlsx-table />
          </xlsx-read>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { sysAxios } from '@/plugins/axios'
import moment from 'moment'
import { XlsxRead, XlsxTable } from "vue3-xlsx"
import journalBatchService from '@/services/journalBatchService'
import traceabilityService from '@/services/traceabilityService'

export default {
  props: {
    journalBatchHeaderId: {
      type: String,
      required: true
    },
    journalBatchEntryId: {
      type: String,
      required: true
    },
    traceId: {
      type: String,
      required: true
    },
    entryType: {
      type: String,
      required: true
    },
    documentNumber: {
      type: String,
      required: true
    },
    batchId: {
      type: Object,
      required: true
    }
  },
  components: {
    XlsxRead,
    XlsxTable,
  },
  setup(props) {
    const dateFormat = process.env.VUE_APP_DATE_FORMAT;
    const documents = ref([]);
    const openFileUrl = ref('');
    const verifying = ref(true);
    const xlsx = ref({
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: null,
      collection: null
    });
    const openFileViewer = async (doc) => {
      const api = doc.documentURI
      const fileResponse = await sysAxios.get(api, {responseType: 'blob'})
      const externalLinkContentType = ['image/jpeg', 'image/png', 'text/plain']
      if(externalLinkContentType.includes(fileResponse.headers['content-type'])) {
        const file = new Blob([fileResponse.data], {type: fileResponse.headers['content-type']});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else if (fileResponse.headers['content-type'] === 'application/pdf') {
        const file = new Blob([fileResponse.data], {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(file);
        openFileUrl.value = fileURL
        cash(`#show-pdf-file-viewer-${props.journalBatchEntryId}`).modal("show")
      } else if (fileResponse.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const file = new Blob([fileResponse.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        xlsx.value.file = file
        cash(`#show-xlsx-file-viewer-${props.journalBatchEntryId}`).modal("show")
      }
    }

    const deleteDocument = async (doc) => {
      if (confirm("Delete this document?")) {
        let response = await journalBatchService.deleteBatchHeaderSupportingDocument({journalBatchHeaderId: props.journalBatchHeaderId, supportingDocumentId: doc.supportingDocumentId})
        init()
      }
    }

    const init = async () => {
      documents.value = await journalBatchService.getBatchEntrySupportDocuments({journalBatchHeaderId: props.journalBatchHeaderId, journalBatchEntryId: props.journalBatchEntryId})
      const documentsVerifications = await traceabilityService.verifyEntitySupportingDocuments({ batchId: props.batchId, traceId: props.traceId, documentNumber: props.documentNumber, entryType: props.entryType, documents: documents.value})
      documentsVerifications.forEach((verification, index) => {
        documents.value[index].verified = verification.verification
      })
      verifying.value = false
      // console.log(documents)
    }
    onMounted(async () => {
      init()
    });


    return {
      dateFormat,
      documents,
      moment,
      openFileUrl,
      openFileViewer,
      deleteDocument,
      init,
      xlsx,
      verifying
    }
  }
}
</script>
