<template>
  <div v-if='props.dashboardData?.funderCompanyDashBoard' class='col-span-12 intro-y w-100'>
    <div class='grid grid-cols-4 gap-6'>
      <div class='report-box zoom-in'>
        <div class='box p-5'>
          <div class='flex'>
            <div class='w-full'>
              <div class='text-2xl font-bold leading-8'>{{ $t('dashboard.cards.cards_line_info') }}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_limit') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.funderCompanyDashBoard.availableLimit)}}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_funded_amount') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.funderCompanyDashBoard.fundedAmount)}}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_total_allocated') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.funderCompanyDashBoard.totalAllocatedLimit)}}</div>
            </div>
            <div class='flex justify-end items-center'>
              <ShoppingCartIcon class='report-box__icon text-theme-10' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if='props.dashboardData?.companyDashBoard' class='col-span-12 intro-y w-100'>
    <div class='grid grid-cols-3 gap-6'>
      <div class='report-box zoom-in'>
        <div class='box p-5'>
          <div class='flex'>
            <div class='w-full'>
              <div class='text-2xl font-bold leading-8'>{{ $t('dashboard.cards.cards_line_info') }}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_limit') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.lineInformation.availableLimit)}}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_outstand_amount') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.lineInformation.outstandingForDisbursementAmount)}}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_utilized_amount') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.lineInformation.utilisedAmount)}}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_overall_limit') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.lineInformation.overallLimit)}}</div>
            </div>
            <div class='flex justify-end items-center'>
              <ShoppingCartIcon class='report-box__icon text-theme-10' />
            </div>
          </div>
        </div>
      </div>
      <div class='report-box zoom-in'>
        <div class='box p-5'>
          <div class='flex'>
            <div class='w-full'>
              <div class='text-2xl font-bold leading-8'>{{ $t('dashboard.cards.cards_financing_funded') }}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_count') }} {{props.dashboardData?.companyDashBoard.historicalRelationship.funded.count}}</div>
              <div class='text-base text-gray-600 mt-1'>{{ $t('dashboard.cards.cards_total_amount') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.historicalRelationship.funded.totalAmount)}}</div>
            </div>
            <div class='flex justify-end items-center'>
              <ShoppingCartIcon class='report-box__icon text-theme-10' />
            </div>
          </div>
        </div>
      </div>
      <div class='report-box zoom-in'>
        <div class='box p-5'>
          <div class='flex'>
            <div class='w-full'>
              <div class='text-2xl font-bold leading-8'>{{ $t('dashboard.cards.cards_requested') }}</div>
              <div class='ml-auto'>{{ $t('dashboard.cards.cards_count') }} {{props.dashboardData?.companyDashBoard.historicalRelationship.finacingRequested.count}}</div>
              <div class='text-base text-gray-600 mt-1'>{{ $t('dashboard.cards.cards_total_amount') }} {{props.dashboardData.currencyCode}} {{$h.formatCurrency(props.dashboardData?.companyDashBoard.historicalRelationship.finacingRequested.totalAmount)}}</div>
            </div>
            <div class='flex justify-end items-center'>
              <ShoppingCartIcon class='report-box__icon text-theme-10' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    dashboardData: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    return {
      props
    }
  }
}
</script>
