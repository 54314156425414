import { appAxios } from "@/plugins/axios"
import userService from "./userService"
import workflowService from "./workflowService"
import _ from "lodash"

const getInvoicesByPagination = (requestBody, value = "", page_number = 1, page_size = 10, sort_field = "createdTime", sort_order = "desc", paramFilter = "", op = "") => {
  return new Promise(async resolve => {
    let params = `page_number=${page_number+1}&page_size=${page_size}`
    if (value) {
      params += `&value=${value}`
    }
    if (sort_field) {
      params += `&sort_field=${sort_field}`
    }
    if (sort_order) {
      params += `&sort_order=${sort_order}`
    }
    if (paramFilter) {
      params += `&paramFilter=${paramFilter}`
    }
    if (op) {
      params += `&op=${op}`
    }
    const api = `/journalbatch/v1/header/bypagination?${params}`
    const response = await appAxios.post(api, requestBody)
    const rawInvoices = _.filter(response.data.data, {workflowVersion: 'v2'})
    const invoices = await workflowService.getLastUpdatedBy(rawInvoices)
    resolve({
      data: invoices,
      metadata: response.data.metadata
    })
  })
}
const getInvoicesRelatedToCompanyId = (companyId) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/${companyId}`
    const response = await appAxios.get(api)
    const rawInvoices = _.filter(response.data, {workflowVersion: 'v2'})
    const invoices = await workflowService.getLastUpdatedBy(rawInvoices)
    resolve(_.orderBy(invoices, 'createdTime', 'desc'))
  })
}
const getBatchDetailByExecutionReferenceId = (referenceId) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/byworkflowexecutionid/${referenceId}`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

const getBatchEntities = (headerid) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/${headerid}/entries`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

const getBatchEntrySupportDocuments = ({journalBatchHeaderId, journalBatchEntryId}) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/${journalBatchHeaderId}/entry/${journalBatchEntryId}/supportingdocuments`;
    const response = await appAxios.get(api)
    let documents = [...response.data]
    for(let i=0; i<documents.length; i++){
      const fileInfoApi = documents[i].documentURI + '/info';
      const documentInfo = await appAxios.get(fileInfoApi)
      documents[i].contentType = documentInfo.data.contentType
      documents[i].dataHash = documentInfo.data.dataHash
      if(documents[i].uploadedByUserId === '00000000-0000-0000-0000-000000000000') documents[i].userName = "System";
      else documents[i].userName = await userService.getUserProfileByUserID(documents[i].uploadedByUserId).then(res => res.data.firstName + ' ' + res.data.lastName)
    }

    resolve(documents)
  })
}

const deleteBatchHeaderSupportingDocument = ({journalBatchHeaderId, supportingDocumentId}) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/${journalBatchHeaderId}/supportingdocuments/${supportingDocumentId}`;
    const response = await appAxios.delete(api)
    resolve(response)
  })
}

const postBatchEntrySupportDocuments = ({journalBatchHeaderId, journalBatchEntryId, requestBody}) => {
  return new Promise(async resolve => {
    const api = `/journalbatch/v1/header/${journalBatchHeaderId}/entry/${journalBatchEntryId}/supportingdocumentwithblock`;
    const response = await appAxios.post(api, requestBody)
    resolve(response)
  })
}

export default {
  getInvoicesByPagination,
  getInvoicesRelatedToCompanyId,
  getBatchDetailByExecutionReferenceId,
  getBatchEntities,
  getBatchEntrySupportDocuments,
  deleteBatchHeaderSupportingDocument,
  postBatchEntrySupportDocuments
}
