/* ============
 * Mutation types for the main module
 * ============
 *
 */
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_MENU = 'SET_MENU'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_ECOSYSTEMS = 'SET_ECOSYSTEMS'
export const UPDATE_DEFAULTECOSYSTEM = 'UPDATE_DEFAULTECOSYSTEM'
export const UPDATED_PROVENANCE_HISTORY = 'UPDATED_PROVENANCE_HISTORY'
export const NEED_UPDATE_PROVENANCE_HISTORY = 'NEED_UPDATE_PROVENANCE_HISTORY'
export const SET_LOCALE = 'SET_LOCALE'

export default {
  SET_DARK_MODE,
  SET_MENU,
  SET_PERMISSIONS,
  SET_ECOSYSTEMS,
  UPDATE_DEFAULTECOSYSTEM,
  UPDATED_PROVENANCE_HISTORY,
  NEED_UPDATE_PROVENANCE_HISTORY,
  SET_LOCALE
};
