<template>
  <div class="">
    <div class="font-medium text-2xl">{{ $t('account.bank.bank_info') }}</div>
    <div
      v-for="(item, index) in bankInfos"
      :key="index"
      class="intro-y grid grid-cols-2 gap-4 border-b border-gray-200 py-4 pb-8"
    >
      <div class="">
        <div class="flex justify-between">
          <label
            :for="'input-wizard-3-bank-name-' + index"
            class="flex self-end"
            >{{ $t('account.bank.bank_name') }} <span class="text-red-400">*</span></label
          >
          <a
            class="flex items-center text-theme-6"
            href="javascript:;"
            @click="removeBank(index, item)"
          >
            <Trash2Icon class="w-4 h-4 mr-1" /> {{ $t('account.bank.bank_remove') }}
          </a>
        </div>
        <select
          :id="'input-wizard-3-bank-name-' + index"
          v-model="bankInfos[index].bankName"
          :data-refs="'bankName-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          class="form-select"
        >
          <option v-for="(bank, bank_index) in banks" :key="bank_index">
            {{ bank }}
          </option>
        </select>
      </div>
      <div class="">
        <label :for="'input-wizard-3-bank-branch-name-' + index" class=""
          >{{ $t('account.bank.bank_branch_name') }} <span class="text-red-400">*</span></label
        >
        <input
          :id="'input-wizard-3-bank-branch-name-' + index"
          v-model="bankInfos[index].branchName"
          :data-refs="'branchName-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          type="text"
          class="form-control"
          :placeholder="$t('account.bank.bank_ph_name')"
        />
      </div>
      <div class="">
        <label :for="'input-wizard-3-bank-address-' + index" class=""
          >{{ $t('account.bank.bank_address') }} <span class="text-red-400">*</span></label
        >
        <input
          :id="'input-wizard-3-bank-address-' + index"
          v-model="bankInfos[index].address"
          :data-refs="'address-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          type="text"
          class="form-control"
          :placeholder="$t('account.bank.bank_address')"
        />
      </div>
      <div class="">
        <label :for="'input-wizard-3-bank-account-number-' + index" class=""
          >{{ $t('account.bank.bank_account_number') }} <span class="text-red-400">*</span></label
        >
        <input
          :id="'input-wizard-3-bank-account-number-' + index"
          v-model="bankInfos[index].accountNumber"
          :data-refs="'accountNumber-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          type="text"
          class="form-control"
          :placeholder="$t('account.bank.bank_account_number')"
        />
      </div>
      <div class="">
        <label :for="'input-wizard-3-bank-swift-code-' + index" class=""
          >{{ $t('account.bank.bank_swift_code') }} <span class="text-red-400">*</span></label
        >
        <input
          :id="'input-wizard-3-bank-swift-code-' + index"
          v-model="bankInfos[index].swiftCode"
          :data-refs="'swiftCode-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          type="text"
          class="form-control"
          :placeholder="$t('account.bank.bank_swift_code')"
        />
      </div>
      <div class="">
        <label :for="'input-wizard-3-bank-name-' + index" class=""
          >{{ $t('account.bank.bank_currency') }} <span class="text-red-400">*</span></label
        >
        <select
          :id="'input-wizard-3-bank-name-' + index"
          v-model="bankInfos[index].currency"
          :data-refs="'currency-'+index"
          @change="removeRedBorder($event)"
          @input="removeRedBorder($event)"
          class="form-select"
        >
          <option
            v-for="(currency, currency_index) in currencies"
            :key="currency_index"
          >
            {{ currency.currencyCode }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-4 intro-y">
      <a
        class="btn-sm text-primary flex hover:bg-red focus:bg-red text-theme-1"
        @click="addBank"
      >
        <PlusCircleIcon class="w-4 h-4 mr-1" /><span class="self-center"
          >{{ $t('account.bank.bank_add_more') }}</span
        >
      </a>
    </div>
    <div
      class="
        intro-y
        col-span-12
        flex
        items-center
        justify-center
        sm:justify-start
        mt-5
      "
    >
      <button class="btn btn-primary w-24 ml-2" @click="submitBanks">
        {{ $t('account.bank.bank_save') }}
      </button>
    </div>
    <div id="success-notification-content" class="toastify-content hidden">
      <div class="flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('account.bank.bank_add_success') }}</div>
        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden">
      <div class="flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('account.bank.bank_add_fail') }}</div>
          <div class="text-gray-600 mt-1">
            {{ $t('account.bank.bank_please') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import toast from "@/utils/toast";
import _ from "lodash";
import companyService from "@/services/companyService";
import i18n from '@/plugins/i18n';
import configService from "@/services/configService";
import $ from "jquery";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const companyId = store.state.account.company_uuid;
    const banks = ref([]);
    const currencies = ref([]);
    const bankInfos = ref([
      {
        bankName: "",
        branchName: "",
        address: "",
        accountNumber: "",
        swiftCode: "",
        currency: "",
      },
    ]);
    const deletedBank = ref([]);
    const originBankInfo = ref([]);

    onMounted(async () => {
      const systemConfig = await configService.getCompanyProfileSystemConfig();
      banks.value = systemConfig.banks;
      currencies.value = systemConfig.currencies;
      const companyBanks = await companyService.getAccountBankInfoByCompanyId(companyId)
      if (companyBanks.length !== 0) {
        bankInfos.value = [...companyBanks];
        companyBanks.forEach((item) => {
          originBankInfo.value.push({ ...item });
        });
      }
      $(document).ready(function () {
        $('input[data-refs]').each(function () {
          $(this).on('change input', function () {
            $(this).removeClass('border-red-400')
          })
        })
      })
    });

    const removeRedBorder = (event) => {
      $(event.target).removeClass('border-red-400')
    }

    const addBank = () => {
      bankInfos.value.push({
        bankName: "",
        branchName: "",
        address: "",
        accountNumber: "",
        swiftCode: "",
        currency: "",
      });
    };

    const removeBank = (index, item) => {
      if (item.bankAccountId) deletedBank.value.push(item.bankAccountId);
      _.pullAt(bankInfos.value, [index]);
    };

    const showNotification = (flg) => {
      toast({
        status: flg ? "success" : "error",
        title: flg ? i18n.global.t('account.bank.bank_update_success') : i18n.global.t('account.bank.bank_update_fail'),
        content: "",
      });
    };

    const submitBanks = async () => {
      if (originBankInfo.value.length === 0) {
        const status = await companyService.registerBanksByCompanyId(
          companyId,
          bankInfos.value
        );
        if (status.result) {
          showNotification(true);
          gotoNext();
        } else {
          showNotification(false);
          // console.log(status.response);
        }
      } else {
        for (const bankId of deletedBank.value) {
          // console.log(bankId);
          var deleteRes = await companyService.deleteBankByCompanyId(
            companyId,
            bankId
          );
          if (!deleteRes.result) {
            showNotification(false);
            return;
          }
        }

        var res = await companyService.registerBanksByCompanyId(
          companyId,
          _.filter(bankInfos.value, (item) => {
            if (!item.bankAccountId) return item;
          })
        );
        if (!res.result) {
          let responseErrors = res.response.error.response.data.errors
          for (let key in responseErrors) {
            let leftBracketIndex = key.toString().indexOf('[')+1
            let rightBracketIndex = key.toString().indexOf(']')
            let index = key.substr(leftBracketIndex, rightBracketIndex-leftBracketIndex)
            index = parseInt(index)+1
            let dotIndex = key.toString().indexOf('.')+1
            let field = key.substr(dotIndex)
            $('[data-refs='+field+'-'+index+']').addClass('border-red-400')
          }
          showNotification(false);
        } else {
          _.filter(bankInfos.value, async (item) => {
            if (item.bankAccountId) {
              if (
                JSON.stringify(
                  _.find(originBankInfo.value, {
                    bankAccountId: item.bankAccountId,
                  })
                ) !== JSON.stringify(item)
              ) {
                var res = await companyService.updateBankByCompanyId(
                  companyId,
                  item
                );
                if (!res.result) {
                  let responseErrors = res.response.error.response.data.errors
                  for (let key in responseErrors) {
                    $('[data-refs='+key+'-0]').addClass('border-red-400')
                  }
                  showNotification(false);
                  // return;
                } else {
                  showNotification(true);
                  gotoNext();
                }
              } else {
                showNotification(true);
                gotoNext();
              }
            }
          });
        }
        // showNotification(true);
        // gotoNext();
      }
    };

    const gotoBack = () => {
      store.commit("account/SET_STEP", { step: "company-information" });
      router.go(-1);
    };

    const gotoNext = () => {
      // store.commit("account/SET_STEP", { step: "currency-settings" });
      router.push({ path: "/account/currency-settings" });
      $('.post__tabs.nav.nav-tabs > a.active').removeClass('active')
      $('.post__tabs.nav.nav-tabs > a[data-refs=currency]').addClass('active')
    };

    return {
      gotoNext,
      gotoBack,
      bankInfos,
      banks,
      currencies,
      addBank,
      removeRedBorder,
      removeBank,
      submitBanks,
    };
  },
};
</script>
