import { appAxios } from '@/plugins/axios'
import _ from 'lodash'

export const getCompanies = () => {
  const api = '/company/v1'
  return appAxios.get(api)
}

export const getCompanyIdByUserId = (userId) => {
  return new Promise(async resolve => {
    const api = `/company/v1/user/${userId}`;
    const response = await appAxios.get(api)
    resolve(response.data);
  })
}

export const getCompanyIdByCompanyName = (companyName) => {
  return new Promise(async resolve => {
    const api = `/company/v1/${companyName}`;
    const response = await appAxios.get(api)
    resolve(response.data);
  })
}

export const getCompanyTypeByCompanyID = (companyId) => {
  return new Promise((resolve) => {
    const api = `/company/v1/${companyId}`;
    appAxios.get(api).then((res) => {
      console.log(res)
      resolve(res.data.companyType);
    });
  })
}

export const getCompanyDataByCompanyID = (companyId) => {
  return new Promise(async resolve => {
    const api = `/company/v1/${companyId}`;
    const response = await appAxios.get(api);
    resolve(response)
  })
}

export const registerCompany = (CompanyData) => {
  return new Promise((resolve, reject) => {
    const api = "/company/v1/register";
    try {
      appAxios.post(api, CompanyData).then((res) => {
        if (res.status === 201) resolve(res.data);
        else resolve(res)
      });
    } catch (err) {
      reject(err)
    }
  })
}

export const getCompanyUsers = (companyId) => {
  const api = `/company/v1/${companyId}/users`
  return appAxios.get(api)
}

export const unlinkAllUsersToCompany = (companyId) => {
  const api = `/company/v1/${companyId}/users`
  return appAxios.delete(api)
}

export const unlinkUserToCompany = (companyId, userId) => {
  const api = `/company/v1/${companyId}/user/${userId}`
  return appAxios.delete(api)
}

export const combileUserToCompany = (userId, companyId, displayName) => {
  const api = `/company/v1/${companyId}/user`;
  return appAxios.post(api, { userId: userId, displayPicture: displayName })
}

export const getCompanyDefaultCurrencyCode = (companyId) => {
  return new Promise((resolve, reject) => {
    appAxios.get(`company/v1/${companyId}/currencies`).then(res => {
      if (Array.isArray(res.data)) {
        resolve(_.find(res.data, { isDefault: true }).currencyCode)
      } else resolve('USD')
    })
  })
}

export const getCompanyCurrencies = (companyId) => {
  const api = `/company/v1/${companyId}/currencies`;
  return appAxios.get(api)
}

export const getAccountBankInfoByCompanyId = (companyId) => {
  return new Promise(async resolve => {
    const api = `/company/v1/${companyId}/bankaccounts`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

export const getCompanyDataSource = ({ecosystemId, companyId}) => {
  return new Promise(async resolve => {
    const api = `/company/v1/${ecosystemId}/${companyId}/datasourcesystem`
    const response = await appAxios.get(api)
    resolve(response.data)
  })
}

export const registerBanksByCompanyId = (companyId, banks) => {
  return new Promise(async resolve => {
    const registerBankApiUrl = `/company/v1/${companyId}/bankaccount`
    const response = await appAxios.post(registerBankApiUrl, [...banks])
    if (response.status === 201) resolve({ result: true })
    else resolve({ result: false, response: response })
  })
}

export const updateCompanyProfile = (companyId, companyData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const api = `/company/v1/${companyId}`;
      appAxios.put(api, companyData).then(res => {
        if (res.status === 200) {
          resolve(res.data)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteCompanyById = (companyId) => {
  const api = `company/v1/${companyId}`;
  return appAxios.delete(api)
}

export const updateBankByCompanyId = (companyId, bank) => {
  return new Promise(async resolve => {
    const updateBankApiUrl = `/company/v1/${companyId}/bankaccount/${bank.bankAccountId}`
    delete bank['bankAccountId']
    const response = await appAxios.put(updateBankApiUrl, bank)
    if (response.status === 200) resolve({ result: true })
    else resolve({ result: false, response: response })
  })
}

export const deleteBankByCompanyId = (companyId, bankId) => {
  return new Promise(async resolve => {
    const deleteBankApiUrl = `/company/v1/${companyId}/bankaccount/${bankId}`
    const response = await appAxios.delete(deleteBankApiUrl)
    if (response.status === 200 || response.status === 204) resolve({ result: true })
    else resolve({ result: false, response: response.data })
  })
}

export const getHolidays = (company_uuid) => {
  return new Promise((resolve, reject) => {
    appAxios.get(`company/v1/${company_uuid}/holidays`).then(res => {
      if (Array.isArray(res.data)) resolve(res.data)
      else resolve([])
    })
  })
}

export const getCompanyCorporateinfo = (companyId) => {
  const api = `/company/v1/${companyId}/corporateinfo`
  return appAxios.get(api)
}

export const getCompanyCorporateinfoLogs = (companyId, corporateInfoHeaderId) => {
  const api = `/company/v1/${companyId}/corporateinfo/${corporateInfoHeaderId}/logs`
  return appAxios.get(api)
}

export const updateCompanyCoporateInfo = (companyId, corporateInfoHeaderId, fileId) => {
  const api = `/company/v1/${companyId}/corporateinfo/${corporateInfoHeaderId}`
  return appAxios.put(api, {
    corporateInfoFile: { fileURI: process.env.VUE_APP_SERVICE_API_URL + 'uploads/v1/' + fileId }
  })
}

export const setCompanyCoporateInfo = (companyId, category, fileId) => {
  const api = `/company/v1/${companyId}/corporateinfo`
  return appAxios.post(api, [{
    corporateInfoDocumentCategoryName: category,
    corporateInfoFile: {
      fileURI: process.env.VUE_APP_SERVICE_API_URL + 'uploads/v1/' + fileId
    }
  }])
}

export const unsubscribeCoporateInfo = (companyId, corporateInfoHeaderId) => {
  const api = `/company/v1/${companyId}/corporateinfo/${corporateInfoHeaderId}`
  return appAxios.delete(api)
}

export const requestCompanyApprove = (companyId, userId) => {
  const api = `/company/v1/register/${companyId}?step=approval`
  return appAxios.post(api, { userId, displayPicture: 'I do not know' })
}

export const addHoliday = ({ companyId, startDate, endDate, description, label,  }) => {
  return new Promise((resolve, reject) => {
    appAxios.post(`company/v1/${companyId}/holidays`, [
      { startDate, endDate, description, label }
    ]).then(res => {
      resolve(res.data[0])
    })
  })
}

export const updateHoliday = ({ companyId, holidayCalendarEntryId, description, label, startDate, endDate }) => {
  return  appAxios.put(`company/v1/${companyId}/holidays`, { holidayCalendarEntryId, description, label, startDate, endDate })
}

export const removeHoliday = ({ companyId, holidayId }) => {
  return new Promise((resolve, reject) => {
    appAxios.delete(`company/v1/${companyId}/holidays/${holidayId}`).then(res => {
      if (res.data) resolve(res.data)
      else reject(res.data)
    })
  })
}

export const getCompanyDashboardData = ({ companyId, ecosystemId }) => {
  let api = ''
  if(ecosystemId === '00000000-0000-0000-0000-000000000000')api = `/company/v1/${companyId}/dashboarddata`
  else api = `/company/v1/ecosystem/${ecosystemId}/${companyId}/dashboarddata`
  return appAxios.get(api)
}

export const getCompanyDashboardDataActiveBorrowerCompanies = async ({ companyId, ecosystemId }) => {
  var api = ''
  if(ecosystemId === '00000000-0000-0000-0000-000000000000') api = `/company/v1/${companyId}/dashboarddata`
  else api = `/company/v1/ecosystem/${ecosystemId}/${companyId}/dashboarddata`
  const companyIds = await appAxios.get(api).then(res => res.data?.funderCompanyDashBoard.activeBorrowerCompanyIds)
  var companise = await Promise.all(
    companyIds.map(companyId => getCompanyDataByCompanyID(companyId).then(res => res.data))
  )
  return companise
}

export const getEcoSystems = () => {
  const api = 'company/v1/ecosystems'
  return appAxios.get(api)
}

export const getCompaniesByType = (type) => {
  const api = `company/v1?type=${type}`
  return appAxios.get(api)
}

export const updateEcoSystem = (ecoSystem) => {
  const api = '/company/v1/ecosystems'
  return appAxios.put(api, ecoSystem)
}

export const getOnboardedCompanies = async (companyId) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/${companyId}/relationships`
  return appAxios.get(api)
}

export const postCompanyCircles = async (companyId, registrationNo, displayName, email) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/${companyId}/circles`
  return appAxios.post(api, {
    "registrationNo": registrationNo,
    "displayName": displayName,
    "email": email
  })
}

export const getCompanyCircles = async (companyId, status) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/${companyId}/circles?status=${status}`
  return appAxios.get(api)
}

export const getCompanyInvitations = async (companyId, status) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/${companyId}/invitations?status=${status}`
  return appAxios.get(api)
}

export const updateCircleStatus = async (circleId, status) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/circles/${circleId}/updateStatus`
  return appAxios.put(api, {
    "status": status
  })
}

export const deleteCompanyCircle = async (circleId) => {
  // console.log("companyID: ",  companyId)
  const api = `/company/v1/circles/${circleId}`
  return appAxios.delete(api)
}

export const inviteRelationShip = ({companyId, invitation}) => {
  return new Promise(async (resolve, rejcet) => {
    try {
      const api = `/company/v1/${companyId}/relationships`
      const response = await appAxios.post(api, invitation)
      if(response.status === 'error') reject(response.error.response.data)
      else resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}

export const getEcosystemById = (ecosystemId) => {
  return new Promise(async resolve => {
    const response = await appAxios.get(`/company/v1/ecosystems/${ecosystemId}`)
    resolve(response.data)
  })
}

export const getLockDays = (companyIds) => {
  return new Promise(async resolve => {
    const availableComapnies = companyIds.filter((companyId) => companyId !== "00000000-0000-0000-0000-000000000000")
    const response = await Promise.all(availableComapnies.map(companyId => appAxios.get(`/company/v1/${companyId}/holiday-list`)))
    const holidays = response.map(({data}) => data.dates).flat()
    const lockDays = _.uniq(holidays);
    resolve(lockDays)
  })
}

export default {
  getCompanies,
  getCompanyIdByUserId,
  getCompanyIdByCompanyName,
  getCompanyDataByCompanyID,
  getCompanyTypeByCompanyID,
  getCompanyDataSource,
  registerCompany,
  getCompanyUsers,
  unlinkAllUsersToCompany,
  unlinkUserToCompany,
  updateCompanyProfile,
  deleteCompanyById,
  combileUserToCompany,
  getCompanyCurrencies,
  getAccountBankInfoByCompanyId,
  registerBanksByCompanyId,
  updateBankByCompanyId,
  deleteBankByCompanyId,
  getCompanyDefaultCurrencyCode,
  getCompanyCorporateinfo,
  getCompanyCorporateinfoLogs,
  updateCompanyCoporateInfo,
  setCompanyCoporateInfo,
  requestCompanyApprove,
  unsubscribeCoporateInfo,
  getHolidays,
  addHoliday,
  updateHoliday,
  removeHoliday,
  getCompanyDashboardData,
  getCompanyDashboardDataActiveBorrowerCompanies,
  getEcoSystems,
  getEcosystemById,
  getCompaniesByType,
  updateEcoSystem,
  inviteRelationShip,
  getOnboardedCompanies,
  postCompanyCircles,
  getCompanyCircles,
  getCompanyInvitations,
  updateCircleStatus,
  deleteCompanyCircle,
  getLockDays
}
