<template>
  <div class="dark:border-dark-5">
    <div class="font-medium text-2xl">{{ $t('account.company.company_info') }}</div>
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-company-display-name" class="form-label"
          >{{ $t('account.company.company_display_name') }} <span class="text-red-400">*</span></label
        >
        <input
          id="account-1-company-display-name"
          v-model="companyProfile.companyDisplayName"
          data-refs="companyDisplayName"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_name')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-company-legal-name" class="form-label"
          >{{ $t('account.company.company_legal_name') }} <span class="text-red-400">*</span></label
        >
        <input
          id="account-1-company-legal-name"
          v-model="companyProfile.companyLegalName"
          data-refs="companyLegalName"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_legal_name')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-company-registration-no" class="form-label"
          >{{ $t('account.company.company_reg_no') }} <span class="text-red-400">*</span></label
        >
        <input
          id="account-1-company-registration-no"
          v-model="companyProfile.registrationNo"
          data-refs="registrationNo"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_reg')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-company-tax-number" class="form-label"
          >{{ $t('account.company.company_tax_no') }} <span class="text-red-400">*</span></label
        >
        <input
          id="account-1-company-tax-number"
          v-model="companyProfile.taxNumber"
          data-refs="taxNumber"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_tax')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-registered-address-1" class="form-label"
          >{{ $t('account.company.company_reg_address') }} <span class="text-red-400">*</span></label
        >
        <input
          id="account-1-registered-address-1"
          v-model="companyProfile.addressLine1"
          data-refs="addressLine1"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_add1')"
        />
        <input
          id="account-1-registered-address-2"
          v-model="companyProfile.addressLine2"
          data-refs="addressLine2"
          type="text"
          class="form-control mt-3"
          :placeholder="$t('account.company.company_ph_add2')"
        />
        <input
          id="account-1-registered-address-3"
          v-model="companyProfile.addressLine3"
          data-refs="addressLine3"
          type="text"
          class="form-control mt-3"
          :placeholder="$t('account.company.company_ph_add3')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label
          for="account-1-company-description-about-company"
          class="form-label"
          >{{ $t('account.company.company_description') }}</label
        >
        <textarea
          id="account-1-company-description-about-company"
          v-model="companyProfile.descriptionAboutCompany"
          data-refs="descriptionAboutCompany"
          rows="6"
          class="form-control"
          :placeholder="$t('account.company.company_ph_desc')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-city" class="form-label">{{ $t('account.company.company_city') }} <span class="text-red-400">*</span></label>
        <input
          id="account-1-city"
          v-model="companyProfile.city"
          data-refs="city"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_city')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-state" class="form-label">{{ $t('account.company.company_state') }}</label>
        <input
          id="account-1-state"
          v-model="companyProfile.state"
          data-refs="state"
          type="text"
          class="form-control"
          :placeholder="$t('account.company.company_ph_state')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-country" class="form-label">{{ $t('account.company.company_country') }} <span class="text-red-400">*</span></label>
        <select
          id="account-1-country"
          v-model="companyProfile.country"
          data-refs="country"
          class="form-select"
        >
          <option v-for="(country, index) in countries" :key="index">
            {{ country }}
          </option>
        </select>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-phone" class="form-label">{{ $t('account.company.company_phone') }} <span class="text-red-400">*</span></label>
        <input
          id="account-1-Phone"
          v-model="companyProfile.phone"
          data-refs="phone"
          type="number"
          class="form-control"
          :placeholder="$t('account.company.company_ph_contact')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="account-1-email" class="form-label">{{ $t('account.company.company_email') }} <span class="text-red-400">*</span></label>
        <input
          id="account-1-email"
          v-model="companyProfile.primaryEmail"
          data-refs="primaryEmail"
          type="tel"
          class="form-control"
          :placeholder="$t('account.company.company_ph_email')"
        />
      </div>
      <div class="intro-y col-span-12 sm:col-span-6">
        <label for="company-type" class="form-label">{{ $t('account.company.company_type') }} <span class="text-red-400">*</span></label>
        <select v-model="companyProfile.companyType" class="form-select" data-refs="companyType">
          <option value="company">{{ $t('account.company.company_company') }}</option>
          <option value="funder">{{ $t('account.company.company_funder') }}</option>
        </select>
      </div>
    </div>
    <div class="bg-yellow-100 p-2 mt-5 intro-y">
      {{ $t('account.profile.profile_email_fail') }}&nbsp;
      <a href="" class="underline text-theme-1">{{ $t('account.profile.profile_resend') }}</a>
    </div>
      <div
        class="
          intro-y
          col-span-12
          flex
          items-center
          justify-center
          sm:justify-start
          mt-5
        "
      >
        <button class="btn btn-primary w-24" @click="submitCompanyProfile">
          {{ $t('account.company.company_save') }}
        </button>
      </div>
  </div>
</template>
<style>
  #account-1-Phone::-webkit-outer-spin-button,
  #account-1-Phone::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  #account-1-Phone[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
</style>
<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import toast from "@/utils/toast";
import _ from "lodash";
import companyService from "@/services/companyService";
import i18n from '@/plugins/i18n';
import configService from "@/services/configService";
import $ from "jquery";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const countries = ref([]);
    const companyProfile = ref({
      companyDisplayName: "",
      companyLegalName: "",
      descriptionAboutCompany: "",
      registrationNo: "",
      taxNumber: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      country: null,
      phone: "",
      primaryEmail: "",
      companyType: "company",
    });
    const companyProfilePlaceholders = ref({
      companyDisplayName: "Company Display Name",
      companyLegalName: "Company Legal Name",
      descriptionAboutCompany: "Description About Company",
      registrationNo: "Registration No",
      taxNumber: "Tax Number",
      addressLine1: "Registered Address",
      addressLine2: "Registered Address",
      addressLine3: "Registered Address",
      city: "City",
      state: "State",
      country: "Country",
      phone: "Phone",
      primaryEmail: "Primary Email",
      companyType: "Company Type",
    })

    const originalCompanyProfile = ref(null);

    onMounted(async () => {
      const companyProfileSystemConfig =
        await configService.getCompanyProfileSystemConfig();
      countries.value = companyProfileSystemConfig.countries;
      companyProfile.value.country = countries.value[0]
      if (
        store.state.account.company_uuid !==
        "00000000-0000-0000-0000-000000000000"
      ) {
        const response = await companyService.getCompanyDataByCompanyID(store.state.account.company_uuid);
        originalCompanyProfile.value = { ...response.data };
        companyProfile.value = { ...response.data };
      }
      $(document).ready(function () {
        $('input[data-refs]').each(function () {
          $(this).on('change input', function () {
            $(this).removeClass('border-red-400')
          })
        })
      })
    });

    const showNotification = (flg) => {
      toast({
        status: flg ? "success" : "error",
        title: flg
          ? i18n.global.t('account.company.company_add_success')
          : i18n.global.t('account.company.company_please'),
        content: "",
      });
    };

    const showNotification2 = (flg, field) => {
      toast({
        status: flg ? "success" : "error",
        title: flg
          ? i18n.global.t('account.company.company_add_success')
          : i18n.global.t('account.company.company_please1') + field + i18n.global.t('account.company.company_please2'),
        content: "",
      });
    };

    const gotoNext = () => {
      // store.commit("account/SET_STEP", { step: "bank-information" });
      router.push({ path: "/account/bank-information", name: "GENIE_COMPANY_BANK_INFORMATION" });
      $('.post__tabs.nav.nav-tabs > a.active').removeClass('active')
      $('.post__tabs.nav.nav-tabs > a[data-refs=bank]').addClass('active')
    };

    const submitCompanyProfile = async () => {
      if (_.isEqual(companyProfile.value, originalCompanyProfile.value)) {
        gotoNext();
      } else {
        companyProfile.value.phone = companyProfile.value.phone.toString()
        if (
          store.state.account.company_uuid ===
          "00000000-0000-0000-0000-000000000000"
        ) {
          const companyId = await companyService.registerCompany(
            companyProfile.value
          );
          if (!companyId.error) {
            await companyService.combileUserToCompany(
              store.state.auth.user_id,
              companyId,
              companyProfile.value.companyDisplayName
            );
            store.commit("account/SET_COMPANYID", { company_uuid: companyId });
            showNotification(true);
            gotoNext();
          } else {
            let responseErrors = companyId.error.response.data.errors
            for (let key in responseErrors) {
              $('[data-refs='+key+']').addClass('border-red-400')
            }
            showNotification(false);
          }
        } else {
          const response = await companyService.updateCompanyProfile(
            store.state.account.company_uuid,
            companyProfile.value
          );
          if (!response.error) {
            showNotification(true);
            gotoNext();
          } else {
            let responseErrors = response.error.response.data.errors
            for (let key in responseErrors) {
              $('[data-refs='+key+']').addClass('border-red-400')
            }
            showNotification(false);
          }
        }
      }
    };

    return {
      submitCompanyProfile,
      gotoNext,
      countries,
      companyProfile,
    };
  },
};
</script>
